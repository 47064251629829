import Overlay from 'components/desktop/_general/Overlay/Overlay';
import 'components/desktop/_general/StandingDataList/StandingDataList.scss';

export type Row = {
	id: string;
	jsx: JSX.Element;
};

interface IProps {
	archive?: boolean;
	displayOverlay: boolean;
	draggable?: boolean;
	handleOverlayClose: () => void;
	handleOverlayOpen: (id: string) => void;
	overlay: JSX.Element;
	overlayTitle?: string;
	rows: Row[];
}

const StandingDataList = (props: IProps) => {
	const renderOverlay = (): JSX.Element => {
		if (props.displayOverlay) {
			return (
				<Overlay
					contentStyle={{ overflow: 'scroll' }}
					displayOverlay={props.displayOverlay}
					handleClose={props.handleOverlayClose}
					children={props.overlay}
				></Overlay>
			);
		}
		return <></>;
	};

	return (
		<>
			<div className="row gy-4">
				<div className="col-lg-12">
					{renderOverlay()}
					{props.draggable ? (
						<>
							{props.rows.map((row: Row, key: number) => {
								return (
									<div
										className="standing-data-list-table-item grab-hand"
										key={key}
									>
										{row.jsx}
									</div>
								);
							})}
						</>
					) : (
						<>
							{props.rows.map((row: Row, key: number) => {
								if (props.archive) {
									return (
										<div
											className="standing-data-list-table-item cursor-default"
											key={key}
										>
											{row.jsx}
										</div>
									);
								} else {
									return (
										<div
											className="standing-data-list-table-item"
											key={key}
											onClick={() => props.handleOverlayOpen(row.id)}
										>
											{row.jsx}
										</div>
									);
								}
							})}
						</>
					)}
				</div>
			</div>
		</>
	);
};

export default StandingDataList;
