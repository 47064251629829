import { faCircleInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import 'components/desktop/Recipe/Validation/RecipeValidationComponent.scss';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import LoadingSpinner from 'components/desktop/_general/Loading/LoadingSpinner';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import UrlParams from 'enums/Recipe/Validation/UrlParams';
import useCultureCode from 'hooks/redux/useCultureCode';
import useAirNavigation, { AirUrlParamsClass } from 'hooks/useAirNavigation';
import { useReaction } from 'hooks/useReaction';
import * as Search from 'types/Validation/Search';
import { Type } from 'types/Validation/Validation';
import * as ValidationError from 'types/Validation/ValidationError';
import * as ValidationList from 'types/Validation/ValidationList';
import { isInconsistentType, isUnrecognizedIngredient } from 'types/Validation/ValidationType';

const maxNumberOfButtons = 10;

const hasInconsistencies = (validationErrors: ValidationError.Type[]) => {
	return !validationErrors
		.filter(
			(x) => isInconsistentType(x.validationType) && x.errors.some((y) => !y.isAcknowledged)
		)
		.isEmpty();
};

const areIngredientNotRecognized = (validationErrors: ValidationError.Type[]) => {
	return !validationErrors
		.filter((x) => isUnrecognizedIngredient(x.validationType))
		.flatMap((x) => x.errors)
		.filter((x) => !x.isAcknowledged)
		.isEmpty();
};
const getNumberOfUnrecognizedIngredients = (validationErrors: ValidationError.Type[]) => {
	return validationErrors
		.filter((x) => isUnrecognizedIngredient(x.validationType))
		.flatMap((x) => x.errors)
		.filter((x) => !x.isAcknowledged).length;
};

const RecipeValidationComponent = () => {
	const { t } = useTranslation();
	const { params, navigate } = useAirNavigation();
	const search = useMemo(() => Search.getFromUrl(params), [params.toString()]);
	const locale = useCultureCode();
	moment.locale(locale);
	const [validationList, isLoaded] = useReaction<ValidationList.Type, Search.Type>(
		ValidationList.create(),
		ValidationList.getFromApi,
		search
	);

	const goToEntity = (e: Type) => {
		if (e.entityType === 'Recipe') {
			navigate(AirUrlParamsClass.From('/recipe/detail/' + e.entityId));
		}
	};
	const renderListRows = () => {
		return (
			<tbody>
				{validationList?.data.map((e: Type, i: number) => {
					return (
						<tr key={i} className="validation-row" onClick={() => goToEntity(e)}>
							<td>{e.name}</td>
							<td>
								{e.entityType === 'Recipe' ? t('_general:RECIPE') : e.entityType}
							</td>
							<td>{moment(e.lastValidationDateUtc).fromNow()}</td>
							<td>
								{hasInconsistencies(e.validationErrors) ? (
									<span className="badge text-bg-warning">
										{t('_general:VALIDATION_DATA_NOT_IN_SYNC')}
									</span>
								) : (
									<span className="badge text-bg-success">
										{t('_general:VALIDATION_DATA_OK')}
									</span>
								)}
							</td>
							<td>
								{areIngredientNotRecognized(e.validationErrors) ? (
									<span className="badge text-bg-warning">
										{getNumberOfUnrecognizedIngredients(e.validationErrors)}
									</span>
								) : (
									<span className="badge text-bg-success">
										{t('_general:VALIDATION_DATA_OK')}
									</span>
								)}
							</td>
						</tr>
					);
				})}
			</tbody>
		);
	};

	const navigateToDifferentPage = (index: number) => {
		const newParams = params.delete(UrlParams.PageIndex);
		if (index === 0) {
			navigate(newParams);
		} else {
			navigate(newParams.add(UrlParams.PageIndex, String(index)));
		}
	};

	return (
		<>
			<div className="row">
				<div className="d-flex flex-column" style={{ gap: '20px' }}>
					<h1>{t('recipe:RECIPE_VALIDATION')}</h1>

					<RenderIf condition={isLoaded}>
						<table
							className="table mb-0"
							style={{ backgroundColor: 'white', overflowX: 'auto' }}
						>
							<thead>
								<tr>
									<th>{t('_general:VALIDATION_NAME')}</th>
									<th>{t('_general:VALIDATION_TYPE')}</th>
									<th>{t('_general:VALIDATION_LAST_VALIDATED')}</th>
									<th>
										{t('_general:VALIDATION_CONSISTENCY')}
										<FontAwesomeIcon
											style={{
												pointerEvents: 'all',
												marginLeft: '5px',
												cursor: 'default',
												marginBottom: '1px',
											}}
											title={t('_general:VALIDATION_CONSISTENCY_INFO_TXT')}
											icon={faCircleInfo}
										></FontAwesomeIcon>
									</th>
									<th>{t('_general:VALIDATION_UNRECOGNIZED_INGREDIENT')}</th>
								</tr>
							</thead>
							{renderListRows()}
						</table>
					</RenderIf>
					<RenderIf condition={validationList.data.isEmpty() && isLoaded}>
						<div>
							<h4>{t('_general:NO_DATA_FOUND')}</h4>
							<RenderIf condition={Boolean(validationList.data.length)}>
								<Link to={'/recipe/validation'}>{t('_general:GO_TO_PAGE_1')}</Link>
							</RenderIf>
						</div>
					</RenderIf>
					<RenderIf condition={!isLoaded}>
						<div
							style={{
								padding: '100px 0px',
								borderBottom: '1px solid rgba(210, 210, 210, 1)',
								borderTop: '1px solid rgba(210, 210, 210, 1)',
							}}
						>
							<LoadingSpinner />
						</div>
					</RenderIf>
					<Pagination
						countOfAllListItems={validationList?.totalCount ?? 0}
						pageIndex={search.pageIndex ?? 0}
						setPageIndex={navigateToDifferentPage}
						listItemAmount={search.pageSize}
						maxNumberOfButtons={maxNumberOfButtons}
					/>
				</div>
			</div>
		</>
	);
};

export default RecipeValidationComponent;
