import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate } from 'react-router-dom';

import Accessory from 'classes/Accessory/Search/Accessory';
import Search from 'classes/Recipe/Search/Search';
import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import FormatedDate from 'components/desktop/_general/FormatedDate/FormatedDate';
import IconComponent from 'components/desktop/_general/Icon/IconComponent';
import IconCo2Round from 'components/desktop/_general/IconCo2/IconCo2Round';
import ENTITLEMENTS from 'enums/entitlements';
import PERMISSIONS from 'enums/permissions';
import { SearchLogic } from 'enums/searchLogic';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { IExclusionLogicSelectItem } from 'interfaces/IExclusionLogicSelectItem';
import { Icon } from 'types/Icon/Icon';

interface IProps {
	accessory: Accessory;
	archive: boolean;
	handleArchiveAccessory: (input: Accessory) => Promise<void>;
	handleRestoreAccessory: (input: Accessory) => Promise<void>;
}

const SearchResultListRow = (props: IProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const accessoryEditor = arePermissionsInUserPermissions([PERMISSIONS.WRITEACCESSORY]);

	const onRecipeLinkClick = async (id: string, name: string, e: any) => {
		e.preventDefault();
		const recipeAdvancedSearch = new Search();
		recipeAdvancedSearch.selectAccessory.saved.push({
			id: id,
			name: name,
			logic: SearchLogic.Include,
		} as IExclusionLogicSelectItem);
		const url: string | null = recipeAdvancedSearch.mapToUrl();
		if (url) navigate(`/recipe/search${url}`);
	};

	const getButtonDataRestore = (): BtnData | undefined => {
		if (props.archive) {
			return {
				onClick: () => props.handleRestoreAccessory(props.accessory),
			};
		}
	};

	const getButtonDataArchive = (): BtnData | undefined => {
		if (!props.archive && accessoryEditor) {
			return {
				onClick: () => props.handleArchiveAccessory(props.accessory),
			};
		}
	};

	const getButtonDataEdit = (): BtnData | undefined => {
		if (!props.archive && accessoryEditor) {
			return {
				onClick: handleAccessoryDetailClick,
			};
		}
	};

	const getButtonDataView = (): BtnData | undefined => {
		if (!props.archive && !accessoryEditor) {
			return {
				onClick: handleAccessoryDetailClick,
			};
		}
	};

	const renderButtons = (): JSX.Element => {
		return (
			<BtnGroup
				archive={getButtonDataArchive()}
				edit={getButtonDataEdit()}
				restore={getButtonDataRestore()}
				view={getButtonDataView()}
			/>
		);
	};

	const getAuthor = (): string => {
		if (props.accessory.lastEditor) {
			return props.accessory.lastEditor;
		}
		return t('_general:UNKNOWN_AUTHOR');
	};

	const handleAccessoryDetailClick = (): void => {
		navigate(`/accessory/detail/${props.accessory.id}`);
	};

	const getRecipeLink = (): JSX.Element => {
		if (props.accessory.countOfRecipes > 0) {
			return (
				<span
					className="primary"
					onClick={(e: any) =>
						onRecipeLinkClick(props.accessory.id, props.accessory.name ?? '', e)
					}
					style={{ zIndex: 1000 }}
				>
					{`${props.accessory.countOfRecipes} ${t('_general:RECIPE', {
						count: props.accessory.countOfRecipes,
					})}`}
				</span>
			);
		}
		return <>{`0 ${t('_general:RECIPE', { count: props.accessory.countOfRecipes })}`}</>;
	};

	const getPicture = (): JSX.Element => {
		if (props.accessory.image) {
			return (
				<div className="card-picture">
					<img alt="thumbnail" src={props.accessory.image.url} />
				</div>
			);
		} else {
			return (
				<div className="card-picture-placeholder d-flex justify-content-center align-items-center">
					<FontAwesomeIcon size="3x" icon={faCamera} />
				</div>
			);
		}
	};

	const getIcons = (): Icon[] => {
		return props.accessory.tags.mapToIcons();
	};

	const renderContent = () => {
		if (props.archive) {
			return (
				<div className="recipe-table-item" key={props.accessory.name}>
					{getPicture()}

					<div id="column-first">
						<div id="title">
							<strong>{props.accessory.name}</strong>
						</div>
						<div id="info">
							{getRecipeLink()}
							<br />
						</div>
					</div>
					<div id="column-second">
						<div className="row">
							<div className="col-5">
								<span className="grey">{t('_general:LAST_CHANGE_DATE')}:</span>
								<br />
								<span className="grey">{t('_general:LAST_EDITOR')}:</span>
								<br />
							</div>
							<div className="col-7">
								<FormatedDate date={props.accessory.lastEditedDateUtc} />
								<br />
								<span>{getAuthor()}</span>
							</div>
						</div>
					</div>
					<div id="column-last">
						<RenderIf entitlements={[ENTITLEMENTS.CARBONDIOXIDE]}>
							<div id="icon-rows">
								{getIcons()
									.slice(0, 4)
									.map((icon: Icon, i: number) => (
										<IconComponent icon={icon} key={i} />
									))}
								<IconCo2Round
									color={props.accessory.carbonDioxideLabel.color!}
									label={props.accessory.carbonDioxideLabel.label!}
								/>
							</div>
						</RenderIf>
						<div>{renderButtons()}</div>
					</div>
				</div>
			);
		} else {
			return (
				<Link to={`/accessory/detail/${props.accessory.id}`} className="unstyled-link">
					<div className="recipe-table-item">
						{getPicture()}

						<div id="column-first">
							<div id="title">
								<strong>{props.accessory.name}</strong>
							</div>
							<RenderIf permissions={[PERMISSIONS.READRECIPE]}>
								<div id="info">
									{getRecipeLink()}
									<br />
								</div>
							</RenderIf>
						</div>
						<div id="column-second">
							<div className="row">
								<div className="col-5">
									<span className="grey">{t('_general:LAST_CHANGE_DATE')}:</span>
									<br />
									<span className="grey">{t('_general:LAST_EDITOR')}:</span>
									<br />
								</div>
								<div className="col-7">
									<FormatedDate date={props.accessory.lastEditedDateUtc} />
									<br />
									<span>{getAuthor()}</span>
								</div>
							</div>
						</div>
						<div id="column-last">
							<div id="icon-rows">
								{getIcons()
									.slice(0, 4)
									.map((icon: Icon, i: number) => (
										<IconComponent icon={icon} key={i} />
									))}
								<IconCo2Round
									color={props.accessory.carbonDioxideLabel.color!}
									label={props.accessory.carbonDioxideLabel.label!}
								/>
							</div>
							<div>{renderButtons()}</div>
						</div>
					</div>
				</Link>
			);
		}
	};

	return <>{renderContent()}</>;
};

export default SearchResultListRow;
