import * as apiGet from 'api/menu/GetMenuIdV1';
import NutrientList from 'classes/MenuPlan/Detail/NutrientList';

export default class MenuRecipe {
	public id: string = '';
	public calories: number | null = 0;
	public hasNutriScore: boolean = false;
	public nutrientValues: NutrientList = new NutrientList();
	public parts: number | null = null;
	public persons: number | null = null;

	public constructor(input?: apiGet.Recipe) {
		if (input) {
			this.mapFromApi(input);
		}
	}

	private mapFromApi(input: apiGet.Recipe) {
		this.id = input.id;
		this.calories = input.calories;
		this.hasNutriScore = input.hasNutriScore;
		this.nutrientValues.mapFromApi(input.nutrientValues);
		this.parts = input.parts;
		this.persons = input.persons;
	}
}
