import { useTranslation } from 'react-i18next';

export type SelectOption = {
	id: string;
	text: string;
};

interface IProps {
	options: SelectOption[];
	currentValue: string | null;
	handleValueChange: (id: string) => void;
	disabled?: boolean;
	errorKey?: string;
}

const SelectSimple = (props: IProps) => {
	const handleSelectTag = (event: React.ChangeEvent<HTMLSelectElement>) => {
		const value: string = event.target.value;
		props.handleValueChange(value);
	};
	const { t } = useTranslation();
	return (
		<>
			<select
				className="form-select"
				value={props.currentValue ?? ''}
				name="statusSelect"
				onChange={handleSelectTag}
				disabled={props.disabled}
				error-key={props.errorKey}
			>
				{!props.currentValue ? (
					<option value="">{t('_general:SELECT_AN_OPTION')}</option>
				) : (
					<></>
				)}
				{props.options.map((option: SelectOption, key: number) => (
					<option key={key} value={option.id}>
						{option.text}
					</option>
				))}
			</select>
		</>
	);
};

export default SelectSimple;
