import * as ProductMappings from 'types/Recipe/Detail/ProductMappings';
import { Segment } from 'types/Recipe/Segment';

export type Type = Segment[];

export function create(): Type {
	return [];
}

export function mergeWithProductMappings(input: Type, productMapping: ProductMappings.Type): Type {
	for (const segment of input) {
		for (const ingredient of segment.ingredients) {
			if (!ingredient.ingredientId) continue;
			if (productMapping[ingredient.ingredientId]) {
				ingredient.productId = productMapping[ingredient.ingredientId].id;
			}
		}
	}
	return input;
}
