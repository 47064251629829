import Accordion from 'react-bootstrap/Accordion';

import * as apiGetMatchProduct from 'api/match/GetMatchProduct';
import * as apiGetNutrient from 'api/nutrient/GetNutrientV1';
import Calculation from 'classes/Recipe/Detail/Calculation/Calculation';
import ProductAccordionItem from 'components/desktop/Recipe/RecipeTabs/Components/RecipeErpView/IngredientProductMappingComponent/ProductAccordionItem';
import 'components/desktop/Recipe/RecipeTabs/Components/RecipeErpView/RecipeErpView.scss';
import { ValueScope } from 'enums/valueScope';
import { Optional } from 'functions/promiseExtensions';
import { useReaction } from 'hooks/useReaction';
import * as ProductMappings from 'types/Recipe/Detail/ProductMappings';
import { Recipe } from 'types/Recipe/Recipe';
import { SegmentIngredient } from 'types/Recipe/SegmentIngredient';
import { NutriScore } from 'types/_general/NutriScore';

type IProps = {
	ingredients: SegmentIngredient[];
	calculation: Calculation | null;
	nutriScore: NutriScore | null;
	recipe: Recipe;
	valueScope: ValueScope;
	productMappings: ProductMappings.Type;
	selectProduct: (input: { ingredientId: string; product: apiGetMatchProduct.Match }) => void;
	deSelectProduct: (ingredientId: string) => void;
	selectedColumns: string[];
};

export const ProductAccordion = (props: IProps) => {
	const [nutrients] = useReaction<Optional<apiGetNutrient.Nutrient[]>>(
		Optional.None(),
		async () => await apiGetNutrient.callApi()
	);

	return (
		nutrients.hasValue() && (
			<Accordion defaultActiveKey="0" className="margin-top-25">
				{props.ingredients.map((ingredient, i) => (
					<Accordion.Item eventKey={String(i)} key={i + 'item'}>
						<ProductAccordionItem
							selectedColumns={props.selectedColumns}
							deSelectProduct={props.deSelectProduct}
							nutrients={nutrients.get()}
							ingredient={ingredient}
							valueScope={props.valueScope}
							selectProduct={props.selectProduct}
							product={
								ingredient.ingredientId
									? props.productMappings[ingredient.ingredientId]
									: null
							}
						/>
					</Accordion.Item>
				))}
			</Accordion>
		)
	);
};
