import { InputHTMLAttributes } from 'react';

import 'components/desktop/_general/Input/RoundedInput/RoundedInput.scss';
import * as State from 'components/desktop/_general/Input/RoundedInput/RoundedInputState';
import useRoundedInputState from 'components/desktop/_general/Input/RoundedInput/useRoundedInputState';

interface IProps extends InputHTMLAttributes<any> {
	handleChange: (input: number) => void;
	value: number;
}

const RoundedInput = (props: IProps) => {
	const { state, handlers } = useRoundedInputState();

	return (
		<input
			{...props}
			type="number"
			className="form-control rounded-input"
			name={props.name}
			onChange={(e) => handlers.setValue(e.currentTarget.value)}
			value={State.getDisplayValue(state, props.value)}
			onFocus={() => {
				handlers.setIsActive(true);
				handlers.onFocus(String(props.value));
			}}
			onBlur={() =>
				handlers.onBlur({
					value: State.getValue(state, props.value),
					callback: props.handleChange,
				})
			}
		/>
	);
};

export default RoundedInput;
