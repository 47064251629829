import { useMenu } from 'context/Store';
import { useLocation, useParams } from 'react-router-dom';

import MenuComponent from 'components/desktop/Menu/MenuComponent';

const MenuDetailContainer = () => {
	const { id } = useParams();
	const menu = useMenu();
	const location = useLocation();

	return (
		<main className="container">
			{menu && (
				<MenuComponent
					id={id}
					copy={location.pathname.includes('/copy') ? true : false}
					menu={menu}
				/>
			)}
		</main>
	);
};

export default MenuDetailContainer;
