import * as apiGetAllStatus from 'api/status/GetAllStatusV1';

export type Type = apiGetAllStatus.ResponseStatus[];

export function create(): Type {
	return [];
}

export async function getFromApi(): Promise<Type> {
	const response = await apiGetAllStatus.callApi();
	return response.getOrDefault([]);
}
