import { useNavigate } from 'react-router-dom';

import * as apiPostMenuV1 from 'api/menu/PostMenuV1';
import { mapToSaveMenuDto } from 'functions/mappers/Menu/mapToSaveMenuDto';
import { ReducerBuilder, useAirDecorators } from 'reducers/ReducerBuilder';
import { payloadReducer, stateReducerAsync } from 'reducers/reducerUtils';
import {
	Status,
	Type,
	handleAddMenuRecipeReducer,
	handleMenuTranslateReducer,
	handleSetNutriScoreCategoryReducer,
	handleSetSystemStatusReducer,
	handleSetTagsReducer,
	handleStatusReducer,
	putMenuReducer,
	setNutriScoreReducer,
	setRecipesReducer,
	valueChangeReducer,
} from 'types/Menu/Menu';

const rb = new ReducerBuilder<Type>();

export const createMenuLoadedAction = rb.createActionForReducer(
	payloadReducer((x) => x),
	'Menu/LoadedFromBackend'
);

const handleValueChangeAction = rb.createActionForReducer(
	valueChangeReducer,
	'Menu/HandleValueChange'
);
const handleSetRecipes = rb.createActionForReducer(setRecipesReducer, 'Menu/HandleSetRecipes');
const handleSetStatus = rb.createActionForReducer<Status>(handleStatusReducer, 'Menu/SetStatus');
const handleSetTags = rb.createActionForReducer(handleSetTagsReducer, 'Menu/SetTags');
const handleSetSystemStatus = rb.createActionForReducer(
	handleSetSystemStatusReducer,
	'Menu/SetSystemStatus'
);
const handleSetHasNutriScore = rb.createActionForReducer(
	setNutriScoreReducer,
	'Menu/SetNutriscore'
);
const handleSetNutriScoreCategory = rb.createActionForReducer(
	handleSetNutriScoreCategoryReducer,
	'Menu/SetNutriscoreCategory'
);

const handleTranslate = rb.createAsyncActionForReducer(
	handleMenuTranslateReducer,
	'Menu/Translate'
);
const saveExistingMenu = rb.createAsyncActionForReducer(
	stateReducerAsync(putMenuReducer),
	'Menu/PutMenu'
);
const handleAddRecipe = rb.createAsyncActionForReducer(
	handleAddMenuRecipeReducer,
	'Menu/AddRecipe'
);

const createNewMenu = async (navigate: any, payload: Type) => {
	const id = await apiPostMenuV1.callApi(mapToSaveMenuDto(payload));
	id.do((id) => {
		navigate(`/menu/detail/${id}`);
	});
};

export function useMenuHandlers() {
	const { decorate, decorateAsync, wrapNoArgs } = useAirDecorators();

	const handlers = decorate({
		handleValueChangeAction,
		handleSetRecipes,
		handleSetStatus,
		handleSetTags,
		handleSetNutriScoreCategory,
		handleSetSystemStatus,
		handleSetHasNutriScore,
	});

	const asyncHandlers = decorateAsync({
		handleAddRecipe,
		handleTranslate,
		saveExistingMenu,
	});

	const navigate = useNavigate();

	return {
		...handlers,
		...asyncHandlers,
		saveExistingMenu: wrapNoArgs(asyncHandlers.saveExistingMenu),
		createNewMenu: (menu: Type) => createNewMenu(navigate, menu),
	};
}

export const menuReducer = rb.createReducer();
