import ENTITLEMENTS from 'enums/entitlements';
import PERMISSIONS from 'enums/permissions';
import { asBoolean } from 'functions/coercions';
import { Optional } from 'functions/promiseExtensions';
import { areEntitlementsInUserEntitlements } from 'functions/tokenFunctions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';

interface IProps {
	condition?: boolean;
	permissions?: PERMISSIONS[];
	entitlements?: ENTITLEMENTS[];
	children: JSX.Element[] | JSX.Element;
}

export const RenderIf = (props: IProps) => {
	if (shouldBeRendered(props)) {
		if (Array.isArray(props.children)) {
			return <>{props.children}</>;
		}
		return props.children;
	}
	return <></>;
};

export function shouldBeRendered(input: {
	condition?: boolean;
	permissions?: PERMISSIONS[];
	entitlements?: ENTITLEMENTS[];
}): boolean {
	if (input.condition !== undefined) {
		const check: boolean = asBoolean(input.condition);
		if (!check) return false;
	}
	if (input.permissions !== undefined) {
		const check: boolean = arePermissionsInUserPermissions(input.permissions);
		if (!check) return false;
	}
	if (input.entitlements !== undefined) {
		const check: boolean = areEntitlementsInUserEntitlements(input.entitlements);
		if (!check) return false;
	}
	return true;
}
interface IOptionalProps<T> {
	optional: Optional<T>;

	children: (t: T) => JSX.Element[] | JSX.Element;
}

export const RenderOptional = <T,>(props: IOptionalProps<T>) => {
	return props.optional.map(props.children).getOrDefault(<></>);
};
