import { faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import 'components/desktop/BaseIngredient/UnitWeightEditor/UnitWeightEditor.scss';
import InputAiButton from 'components/desktop/_general/Input/InputExtensions/InputAiButton/InputAiButton';
import InputPlusButton from 'components/desktop/_general/Input/InputExtensions/InputPlusButton/InputPlusButton';
import InputSelect from 'components/desktop/_general/Input/InputExtensions/InputSelect/InputSelect';
import RoundedInput from 'components/desktop/_general/Input/RoundedInput/RoundedInput';
import { SpinnerWhenLoading } from 'components/desktop/_general/Loading/Loading';
import { Characteristic } from 'enums/characteristic';
import { getNumberString } from 'functions/numberToString';
import { Optional } from 'functions/promiseExtensions';
import * as inputValidation from 'functions/validation/inputValidation';
import { RootState } from 'reducers/rootReducer';
import { UnitLight } from 'types/Unit/UnitLight';
import { UnitWeight } from 'types/UnitWeight/UnitWeight';

interface IProps {
	baseIngredientCharacteristic: Characteristic | null;
	baseIngredientUnitWeights: UnitWeight[];
	disabled?: boolean;
	setBaseIngredientUnitWeights: (unitWeights: UnitWeight[]) => void;
	units: UnitLight[];
	id: string | null;
	getSuggestedUnitWeight: (selectedUnit: UnitLight) => Promise<Optional<number>>;
}

const UnitWeightEditor = (props: IProps) => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [weight, setWeight] = useState(0);
	const [selectedUnit, setSelectedUnit] = useState<number>(0);
	const [isLoadingAi, setIsLoadingAi] = useState<boolean>(false);

	useEffect((): void => {
		setSelectedUnit(0);
	}, [props.baseIngredientCharacteristic]);

	const handleAddUnitWeight = (): void => {
		if (!weight) {
			inputValidation.show('WeightAmount');
		} else {
			inputValidation.hide('WeightAmount');
			inputValidation.hide('UnitWeights');
			const allUnits: UnitLight[] = getUnitSuggestions();
			if (!allUnits.length) return;

			const unit: UnitLight = allUnits[selectedUnit];
			if (!unit) return;

			const unitWeightsCpy = [...props.baseIngredientUnitWeights];
			unitWeightsCpy.push({
				id: unit.id,
				weight: weight,
				name: unit.description,
			} as UnitWeight);

			props.setBaseIngredientUnitWeights(unitWeightsCpy);
			setWeight(0);
			setSelectedUnit(0);
		}
	};

	const handleSelectUnit = (input: number) => {
		setSelectedUnit(input);
	};

	const handleDeleteUnitWeight = (unitWeight: UnitWeight) => {
		var baseIngredientUnitWeightsCpy = [...props.baseIngredientUnitWeights];

		var index = baseIngredientUnitWeightsCpy.indexOf(unitWeight);

		if (index !== -1) {
			baseIngredientUnitWeightsCpy.splice(index, 1);
			props.setBaseIngredientUnitWeights(baseIngredientUnitWeightsCpy);
		}
	};

	const getTitle = (unitWeight: UnitWeight) => {
		if (unitWeight.source) {
			return unitWeight.source;
		}
	};

	const renderTableRow = (unitWeight: UnitWeight, unitName: string, key: number): JSX.Element => {
		return (
			<tr key={key} error-key={`UnitWeights[${key}]`} title={getTitle(unitWeight)}>
				<td>
					<span className="table-unit-weight-editor-span-unit-weight">
						{getNumberString(unitWeight.weight, reduxCultureCode)}
					</span>
					<span className="grey">{unitName}</span>
				</td>
				<td className="grey">{t('_general:PER')}</td>
				<td>{unitWeight.name}</td>
				<td className="table-unit-weight-editor-td-trash-can">
					{!props.disabled && (
						<FontAwesomeIcon
							className="trash-can"
							icon={faTrashCan}
							onClick={() => handleDeleteUnitWeight(unitWeight)}
						/>
					)}
				</td>
			</tr>
		);
	};

	const handleValueChange = (value: number): void => {
		setWeight(value);
	};

	const handleGetSuggestedUnitWeightClick = async (): Promise<void> => {
		setIsLoadingAi(true);

		const suggestedUnitWeight = await props.getSuggestedUnitWeight(props.units[selectedUnit]);

		const weightCpy = suggestedUnitWeight;
		setWeight(weightCpy.getOrDefault(0));
		setIsLoadingAi(false);
	};

	const getUnitSuggestions = (): UnitLight[] => {
		const alreadyUsedIds: string[] = props.baseIngredientUnitWeights.map((e: UnitWeight) => {
			return e.id;
		});
		const output: UnitLight[] = props.units.filter((e: UnitLight) => {
			if (!alreadyUsedIds.includes(e.id)) {
				return e;
			}
		});
		return output;
	};

	const getUnit = (): string => {
		if (props.baseIngredientCharacteristic === Characteristic.Fluid) {
			return t('measurments:MILLILITERS');
		} else {
			return t('measurments:GRAMM');
		}
	};

	const getWeightName = (): string => {
		if (props.baseIngredientCharacteristic === Characteristic.Fluid) {
			return t('baseIngredient:VOLUME');
		} else {
			return t('baseIngredient:WEIGHT');
		}
	};

	const getLabel = (): string => {
		return `${getWeightName()} (${getUnit()}) ${t('_general:PER')} ${t('_general:UNIT')}`;
	};

	return (
		<SpinnerWhenLoading isLoading={isLoadingAi}>
			<>
				{!props.disabled && (
					<>
						<label>{getLabel()}</label>
						<div className="input-group">
							<RoundedInput
								disabled={props.disabled || !getUnitSuggestions().length}
								error-key="WeightAmount"
								handleChange={handleValueChange}
								name="weightInput"
								value={weight}
							/>
							<InputAiButton onClick={handleGetSuggestedUnitWeightClick} />
							<InputSelect
								disabled={props.disabled || !getUnitSuggestions().length}
								handleSelect={handleSelectUnit}
								value={selectedUnit ?? getUnitSuggestions()[0].id ?? ''}
								suggestions={getUnitSuggestions()}
							/>
							<InputPlusButton id="weightInput" onPlusClick={handleAddUnitWeight} />
						</div>
					</>
				)}
				<div style={{ overflow: 'auto' }}>
					<table
						className="table mb-0"
						style={{ backgroundColor: 'white', overflowX: 'auto' }}
					>
						<tbody>
							{Array.from(props.baseIngredientUnitWeights).map(
								(unitWeight: UnitWeight, i: number) => {
									return renderTableRow(unitWeight, getUnit(), i);
								}
							)}
						</tbody>
					</table>
					{props.disabled && props.baseIngredientUnitWeights.length === 0 && (
						<>
							<label>{t('baseIngredient:NO_UNIT_WEIGHTS_DEFINED')}</label>
						</>
					)}
				</div>
			</>
		</SpinnerWhenLoading>
	);
};

export default UnitWeightEditor;
