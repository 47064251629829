import { AnyAction, Dispatch, Store } from 'redux';

import { createAllergenLoadedAction } from 'reducers/AllergensReducer';
import { createCategoriesLoadedAction } from 'reducers/CategoriesReducer';
import { createCo2LabelsLoadedAction } from 'reducers/Co2LabelsReducer';
import { createMenuLoadedAction } from 'reducers/MenuReducer';
import { createNutrientListLoadedAction } from 'reducers/NutrientListReducer';
import { createProductRangesReducerLoadedAction } from 'reducers/ProductRangesReducer';
import { createSeasonLoadedAction } from 'reducers/SeasonsReducer';
import { createStatusLustLoadedAction } from 'reducers/StatusListReducer';
import { createTagListLoadedAction } from 'reducers/TagListReducer';
import { createUnitListLoadedAction } from 'reducers/UnitsReducer';
import * as rootReducer from 'reducers/rootReducer';
import * as Menu from 'types/Menu/Menu';
import * as AllergenList from 'types/_general/Store/AllergenList';
import * as CategoryList from 'types/_general/Store/CategoryList';
import * as Co2LabelList from 'types/_general/Store/Co2LabelList';
import * as NutrientList from 'types/_general/Store/NutrientList';
import * as ProductRanges from 'types/_general/Store/ProductRanges';
import * as SeasonList from 'types/_general/Store/SeasonList';
import * as StatusList from 'types/_general/Store/StatusList';
import * as TagList from 'types/_general/Store/TagList';
import * as UnitList from 'types/_general/Store/UnitList';

export type LoaderFunction = (state: rootReducer.RootState, params?: any) => Promise<any>;
export type ActionCreator = (input: any) => AnyAction;
export type Selector = (input: rootReducer.RootState) => any;
export type State = { [key: string]: [LoaderFunction, ActionCreator, Selector, boolean] };

/**
 * Consider the following problem for our route loader:
 * Given a list of keys ['key1', 'key2', ..] we want to do the following:
 * When the route is loaded by react-router, we want to fetch the data for each resource
 * in the array from the server, and dispatch it to the redux store at a well-defined
 * location, hence for each key we need
 * -> a loading function that we need to call with the actual route parameters
 * -> when the loading is finished, we need to dispatch the result into the store (so we need to create a redux action)
 *
 * Because we want to support 'caching', we also need:
 * -> an information (boolean) whether or not the resources should be cached or not
 * -> a selector function, that when given a current redux state, can retrieve the data in the store that we're about
 * to fetch and write. If the selector retrieves null, there is no object in the store and we can fetch it and store it
 * If it's already there, then the re-fetching depends on the users choice.
 *
 * Usage:
 *
 * var handler = new MultiDispatch()
 *                   .addDispatch('key1', [loader1, actionCreator1, selector1, false])
 *                   .addDispatch('key2', [loader2, actionCreator2, selector2, true])
 * 	                 .create()
 *
 * This will return a handler with the following 3 properties:
 *
 * -> handler.selector("Key1", state)
 * -> handler.dispatcher("Key1", state, dispatch, routeParams)
 * -> handler.cachable("Key1")
 *
 * You can call handler.cachable("Key1") which will return true/false, depending what the user has configured for this Key1.
 * This way you know if the user desires caching or not.
 *
 * If you have access to the current redux state, you can find the data to which the key 'Key1' corresponds:
 * The handler.selector("Key1", state) will use the selector1() function from above to retrieve the value from the
 * store.  Note that this decouples the 'key1' of the resource from the key in the store (e.g. 'menu', 'seasons', etc).
 * E.g. we can use the key 'menuCreator' to decouple the redux key 'menu' from the loader resource 'menuCreator'
 *
 * The handler.dispatcher("Key1", state, dispatch, routeParams) do the hard work:
 * -> will get the current state using the selector1() function,
 * -> pass it to the loader1(state, params) function (which is like a reducer, accepting current-state and payload/params),
 * -> await its esults, and then dispatch them to the store.
 *
 * This design is due to the fact how react-router and redux work. The react-router only accepts a loader({params}) function
 * which must to return something <X>, and then can make that something <X> avaialble to your components via a useLoaderData() hook.
 * However, this is not cachable and will keep reloading your data everytime you navigate to the same page, you'll need to introduce
 * you own cache. But why? Since we have redux anyways to manage state.
 *
 * Redux on the other hand does not let you write directly to the state. You need to dispatch an action {:type "Menu/Load", payload: {...}}
 * using the useDispatch() hook, then write a reducer (menu, action) => menu that will have a switch statement "Menu/Load" and handle
 * that action to return the new state.
 *
 * Conclusion:
 * This class enables registration of various resource-keys to a corresponding handlers config (loaders, reduxActionCreator, selector, cachebleFlag)
 * and returns 3 functions that you can use enable resource-caching, resource-fetching and storing
 *
 */
export class MultiDispatch {
	public state: State = {};

	public addDispatch(key: string, f: [LoaderFunction, ActionCreator, Selector, boolean]) {
		if (this.state[key]) throw Error(`Key ${key} was already added`);
		this.state[key] = f;
		return this;
	}

	public create() {
		/**
		 * The selector function will bind the key to the selector that you pass in at configuration time,
		 * basically mapping a resource-key e.g. 'seasonsLoader' to a key in the redux store 'seasons'
		 * This way when you're loading resource for a react-router route {:resources ['key1'...]}, you don't
		 * need to know how the reducer was mounted in the rootReducer.ts configuration file.
		 * @param key The key that was used to register your handlers
		 * @param state The redux state of your rootReducer.
		 * @returns the state behind the key.
		 */
		const selector = (key: string, state: rootReducer.RootState) => {
			const [_loader, _actionCreator, selector] = this.state[key];
			if (!this.state[key]) {
				throw Error(`No selector for key: ${key}`);
			}
			return selector(state);
		};

		/**
		 * Returns the configuration passed by the user at the given key.
		 *
		 * @param key
		 * @returns
		 */
		const cachable = (key: string) => {
			const [_loader, _actionCreator, _selector, cache] = this.state[key];
			if (!this.state[key]) {
				throw Error(`No cache information for key: ${key}`);
			}
			return cache;
		};

		/**
		 * A function that will locate the appropriate handlers based on the key, then
		 * fetch the data from the store using the user's provided selector and pass it
		 * to the loader function (async reducer function also provided to the user).
		 * Finally it will dispatch the result into the redux store, using the user-provided
		 * redux action creator function.
		 *
		 * @param key
		 * @param state
		 * @param dispatch
		 * @param params
		 * @returns
		 */
		const dispatcher = async (
			key: string,
			state: rootReducer.RootState,
			dispatch: Dispatch,
			params: any
		) => {
			if (!this.state[key]) {
				throw Error(`Cannot dispatch key: ${key}`);
			}
			const [loader, actionCreator, _selector] = this.state[key];
			if (loader) {
				const payload = await loader(_selector(state), params);
				dispatch(actionCreator(payload));
			}
			return null;
		};

		return { dispatcher, selector, cachable };
	}

	public addAllDispatches(m: any) {
		for (const [key, value] of Object.entries(m)) {
			this.addDispatch(key, value as any);
		}
		return this;
	}
}

/**
 * The 'resources' Key in the navigation routes contains an array
 * of keys that should be define here. Keys matching a suffix e.g. menuCopy/:id
 * will map to 'menuCopy' in this map. When a route is loaded, a matching key
 * in this map will be located. this contains an async function, as well an
 * action to dispatch after the data is loaded. The 'resource' key must not
 * necessary correspond to the key in redux. Multiple actions could write into
 * the same key. Note that the 'async' loader function will not be called
 * if the reducer already contains a value.
 */
const mappingBetweenResourcesAndLoaders = {
	allergens: [
		AllergenList.getFromApi,
		createAllergenLoadedAction,
		(state: rootReducer.RootState) => state.allergens,
		true,
	],
	categories: [
		CategoryList.getFromApi,
		createCategoriesLoadedAction,
		(state: rootReducer.RootState) => state.categories,
		true,
	],
	seasons: [
		SeasonList.getFromApi,
		createSeasonLoadedAction,
		(state: rootReducer.RootState) => state.seasons,
		true,
	],
	co2Labels: [
		Co2LabelList.getFromApi,
		createCo2LabelsLoadedAction,
		(state: rootReducer.RootState) => state.co2Labels,
		true,
	],
	productRanges: [
		ProductRanges.getFromApi,
		createProductRangesReducerLoadedAction,
		(state: rootReducer.RootState) => state.productRanges,
		true,
	],
	tagList: [
		TagList.getFromApi,
		createTagListLoadedAction,
		(state: rootReducer.RootState) => state.tagList,
		true,
	],
	unitList: [
		UnitList.getAllFromApi,
		createUnitListLoadedAction,
		(state: rootReducer.RootState) => state.tagList,
		true,
	],
	statusList: [
		StatusList.getFromApi,
		createStatusLustLoadedAction,
		(state: rootReducer.RootState) => state.statusList,
		true,
	],
	menu: [
		Menu.getFromApi,
		createMenuLoadedAction,
		(state: rootReducer.RootState) => state.menu,
		false,
	],
	menuCopy: [
		Menu.getFromApiAsCopy,
		createMenuLoadedAction,
		(state: rootReducer.RootState) => state.menu,
		false,
	],
	menuNew: [
		Menu.createAsync,
		createMenuLoadedAction,
		(state: rootReducer.RootState) => state.menu,
		false,
	],
	nutrientList: [
		NutrientList.getFromApi,
		createNutrientListLoadedAction,
		(state: rootReducer.RootState) => state.statusList,
		true,
	],
};

/**
 * A resource key is either a simple name 'menu' or a composite name with an identifier 'menu/:id'
 * This simple function will split 'menu/:id' into ['menu', 'id']
 *
 * @param key
 * @returns
 */
function parseResourceKey(key: string) {
	const parts = key.split('/');
	const key1 = parts[0];
	const id = parts[1] ? parts[1].replace(':', '') : null;

	return [key1, id] as [string, string | null];
}

const resourceDispatchLoader = new MultiDispatch()
	.addAllDispatches(mappingBetweenResourcesAndLoaders)
	.create();

/**
 *
 * You use this function to wrap the resourceLoader into a function that can be passed to the
 * react-router loader. React-router expects a loader function to be of type async({params})
 * that returns something that's not null. We can not use directly the resource loader here,
 * because the signature required by react-router does not match. Since when we setup our
 * react-router routes, we have the store and dispatch functions at our disposal, we can
 * use them here to close the loader over it. (here close means in terms of a closure.)
 *
 * The only function exported by this namespace.
 * @param store
 * @param dispatch
 * @param resources
 * @returns
 */
export function createLoaderFromResources(
	store: Store<rootReducer.RootState, AnyAction>,
	dispatch: Dispatch<AnyAction>,
	resources: string[]
) {
	// We need a function that will fit the signature of react-router
	return async (params: any) => {
		// in order to use the resourceDispatch loader, we need the (redux) store, the route params, the key and the (redux) dispatch function

		// Here we can get the current state of the store
		const state = store.getState();
		// for all resource key of the route being loaded
		for (const key of resources) {
			// parse the key into the corresponding tuple. Here routeKey would be e.g. 'seasons', 'menuNewCreator', etc..
			const [routeKey, _id] = parseResourceKey(key);

			// we use the selector function on our resource dispatcher since know the key and the state. Note the key is completely
			// decoupled from the state.
			const object = resourceDispatchLoader.selector(routeKey, state);

			// small cache check for the current key
			if (!object || !resourceDispatchLoader.cachable(routeKey)) {
				// Fire our loader and dispatch its result to the store.
				// The registered loader under the current key will be an async function that expects the current state, the route-params
				// and returns the new state.
				await resourceDispatchLoader.dispatcher(routeKey, state, dispatch, params);
			}
		}
		// react-router expects something not null, we return everything we loaded
		return [];
	};
}
