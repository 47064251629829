import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertResult } from 'sweetalert2';

import MenuPlan from 'classes/MenuPlan/Search/MenuPlan';
import MenuPlanList from 'classes/MenuPlan/Search/MenuPlanList';
import Search from 'classes/MenuPlan/Search/Search';
import MenuPlanAdvancedSearch from 'components/desktop/MenuPlan/AdvancedSearch/AdvancedSearch';
import SearchResultListComponent from 'components/desktop/MenuPlan/Search/SearchResultList';
import BtnNew from 'components/desktop/_general/Button/BtnNew/BtnNew';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import DropdownPageSize from 'components/desktop/_general/DropdownPageSize/DropdownPageSize';
import DropdownSortOrder from 'components/desktop/_general/DropdownSortOrder/DropdownSortOrder';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import AdvancedSearchInput from 'components/desktop/_general/Search/AdvancedSearchInput';
import PERMISSIONS from 'enums/permissions';
import { SortOrder } from 'enums/sortOrder';
import clone from 'functions/clone';
import { QueryParams, getQueryParams } from 'functions/getQueryParams';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import useEffectWhenMounted from 'hooks/useEffectWhenMounted';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	archive?: boolean;
}

const maxNumberOfButtons = 10;

const MenuPlanSearchComponent = (props: IProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const menuPlanEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITEMENUPLAN]);

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState(false);
	const [renderRanges, setRenderRanges] = useState<boolean>(false);

	const [search, setSearch] = useState<Search>(new Search());
	const [menuPlanList, setMenuPlanList] = useState<MenuPlanList>(new MenuPlanList());
	const [searchTerm, setSearchTerm] = useState<string>(search.searchTerm.value ?? '');

	useEffect(() => {
		initialize();
	}, []);

	useEffectWhenMounted(() => {
		initialize();
	}, [reduxCultureCode]);

	const initialize = async (): Promise<void> => {
		await search.initialize();
		if (props.archive !== undefined && props.archive == true) {
			search.showOnlyDeleted = true;
		}
		if (window.location.href.includes('?')) {
			openAdvancedSearch();
			const queryParams: QueryParams = getQueryParams();
			search.mapFromUrl(queryParams);
			await getMenuPlans();
		} else {
			await getMenuPlans();
		}
		setSearch(clone(search));
	};

	const handleRestoreMenuPlan = async (menuPlan: MenuPlan): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('menuPlan:RESTORE_TITLE'),
			text: t('menuPlan:RESTORE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#00cc07',
			confirmButtonColor: '#00cc07',
			confirmButtonText: t('_general:RESTORE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await menuPlan.callApiRestore();
				await Swal.fire({
					title: t('menuPlan:RESTORE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getMenuPlans();
			} catch {
				setIsLoading(false);
			}
		}
	};

	const handleArchiveMenuPlan = async (menuPlan: MenuPlan): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('menuPlan:ARCHIVE_TITLE'),
			text: t('menuPlan:ARCHIVE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#ff0004',
			confirmButtonColor: '#ff0004',
			confirmButtonText: t('_general:ARCHIVE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await menuPlan.callApiDelete();
				await Swal.fire({
					title: t('menuPlan:ARCHIVE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getMenuPlans();
			} catch {
				setIsLoading(false);
			}
		}
	};

	const handleChangeUrl = () => {
		const url: string | null = search.mapToUrl();
		if (url) navigate(url);
		getMenuPlans();
	};

	const handleChangeAdvancedSearchUrl = () => {
		search.pageIndex.value = 0;
		handleChangeUrl();
	};

	const openAdvancedSearch = () => {
		const advancedSearchButton = document.getElementById('advancedSearchButton');
		if (advancedSearchButton) advancedSearchButton.click();
	};

	const getMenuPlans = async (): Promise<void> => {
		setIsLoading(true);
		const response = await menuPlanList.callApi(search.mapToRequest());
		response.do((x) => {
			menuPlanList.mapFromApi(x);
			setMenuPlanList(clone(menuPlanList));
			search.mapFromApi(x);
		});
		setIsLoading(false);
	};

	const handleReset = () => {
		search.reset();
		setSearchTerm('');
		handleChangeUrl();
	};

	const handleSearch = (input?: string) => {
		search.setSearchTerm(input ?? searchTerm);
		handleChangeUrl();
	};

	const handleChangeSortOrder = (value: string) => {
		search.sortOrder.value = value as SortOrder;
		handleChangeUrl();
	};

	const handleChangePageSize = (value: string): void => {
		search.pageSize.value = Number(value);
		handleChangeUrl();
	};

	const handleChangePageIndex = (index: number) => {
		search.pageIndex.value = index;
		handleChangeUrl();
	};

	const getSuggestedMenuPlans = async (): Promise<ISelectItem[]> => {
		await menuPlanList.search(searchTerm, props.archive ?? false);
		return menuPlanList.filtered;
	};

	const advancedSearchContainer = document.getElementById('advancedSearch');
	if (advancedSearchContainer) {
		advancedSearchContainer.addEventListener('shown.bs.collapse', function () {
			setRenderRanges(true);
		});
		advancedSearchContainer.addEventListener('hidden.bs.collapse', function () {
			setRenderRanges(false);
		});
	}

	return (
		<main className="container">
			<div className="d-flex justify-content-between">
				<RenderIf condition={!props.archive}>
					<h1>{t('menuPlan:SEARCH_MENUPLAN')}</h1>
				</RenderIf>
				<RenderIf condition={Boolean(props.archive)}>
					<h1>{t('menuPlan:MENUPLAN_ARCHIVE')}</h1>
				</RenderIf>
				<RenderIf condition={menuPlanEditor && !props.archive}>
					<BtnNew link={'/menuPlan/new'} name={t('menuPlan:CREATE_MENUPLAN')} />
				</RenderIf>
			</div>
			<br />
			<div className="row">
				<div className="col-lg-7">
					<div className="input-group mb-3">
						<AdvancedSearchInput
							debounceMilliSeconds={350}
							getSuggestedItems={getSuggestedMenuPlans}
							handleSearch={handleSearch}
							searchTerm={searchTerm}
							setSearchTerm={setSearchTerm}
							triggerSearchLetterAmount={1}
						/>
					</div>
				</div>
				<div className="col-lg-2">
					<button
						id="advancedSearchButton"
						className="btn btn-primary"
						type="button"
						onClick={() => setRenderRanges(true)}
						data-bs-toggle="collapse"
						data-bs-target="#advancedSearch"
						aria-expanded="true"
						aria-controls="advancedSearch"
					>
						{t('_general:ADVANCED_FILTER')}
					</button>
				</div>
				<div className="col-lg-3 text-end">
					<div className="d-flex flex-row justify-content-end">
						<DropdownPageSize
							className="me-2"
							listFunction={handleChangePageSize}
							currentValue={String(search.getPageSize())}
						/>
						<DropdownSortOrder
							type={'menuPlan'}
							listFunction={handleChangeSortOrder}
							currentValue={search.getSortOrder()}
						/>
					</div>
				</div>
			</div>
			<div className={'collapse'} id="advancedSearch">
				<div className="card card-body mb-3">
					<MenuPlanAdvancedSearch
						disabled={menuPlanList.totalCount === 0}
						advancedSearch={search}
						setAdvancedSearch={setSearch}
						handleChangeUrl={handleChangeAdvancedSearchUrl}
						handleReset={handleReset}
						renderRanges={renderRanges}
					/>
				</div>
			</div>
			<LoadingAnimation isLoading={isLoading} />
			<p>{menuPlanList.totalCount + ' ' + t('_general:MENUPLANS')}</p>
			<div className="mb-3">
				<SearchResultListComponent
					archive={props.archive ?? false}
					handleArchiveMenuPlan={handleArchiveMenuPlan}
					handleRestoreMenuPlan={handleRestoreMenuPlan}
					menuPlanList={menuPlanList}
				/>
			</div>
			<Pagination
				countOfAllListItems={menuPlanList.totalCount}
				pageIndex={search.getPageIndex()}
				setPageIndex={handleChangePageIndex}
				listItemAmount={search.getPageSize()}
				maxNumberOfButtons={maxNumberOfButtons}
			/>
		</main>
	);
};

export default MenuPlanSearchComponent;
