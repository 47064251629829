import * as apiGetMatchProduct from 'api/match/GetMatchProduct';
import { ValueScope } from 'enums/valueScope';
import { assoc } from 'functions/objectExtensions';
import * as ProductMappings from 'types/Recipe/Detail/ProductMappings';

export type Type = {
	productMappings: ProductMappings.Type;
	selectedColumns: string[];
	valueScope: ValueScope;
};

export function create(): Type {
	return {
		productMappings: {},
		selectedColumns: [],
		valueScope: ValueScope.HundredGrams,
	};
}

export function selectProduct(
	state: Type,
	input: { ingredientId: string; product: apiGetMatchProduct.Match }
): Type {
	return {
		...state,
		productMappings: assoc(state.productMappings, input.ingredientId, input.product),
	};
}
export function deSelectProduct(state: Type, ingredientId: string): Type {
	return {
		...state,
		productMappings: ProductMappings.removeId(state.productMappings, ingredientId),
	};
}

export function setSelectedColumns(state: Type, ids: string[]): Type {
	return {
		...state,
		selectedColumns: ids,
	};
}

export function setValueScope(state: Type, valueScope: ValueScope): Type {
	return {
		...state,
		valueScope: valueScope,
	};
}
