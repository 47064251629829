import { Navigate } from 'react-router-dom';

import ProtectedRouteContainer from 'components/desktop/_general/ProtectedRouteContainer/ProtectedRouteContainer';
import PERMISSIONS from 'enums/permissions';
import { dissoc } from 'functions/objectExtensions';
import { getTokensFromLocalStorage } from 'functions/tokenFunctions';

type IProps = {
	children: JSX.Element;
	permissions: PERMISSIONS[];
};

const ProtectedRoute = ({ children }: IProps) => {
	if (!getTokensFromLocalStorage().hasValue()) {
		localStorage.setItem('pathname', window.location.pathname);
		return <Navigate to="/login" replace />;
	}
	return <ProtectedRouteContainer>{children}</ProtectedRouteContainer>;
};

/**
 * Input is a route definition of a react-router object and the output is a modified react-router object.
 *
 * The wrapped object will
 * -> be wrapped in a <Protected> route element, that will automatically check the user's permission from JWT in localstorage
 * against the permissions allowed in the route definition
 *
 * @param props
 * @param store
 * @param dispatch
 * @returns
 */
export const wrapProtectedRoute = (props: {
	element: JSX.Element;
	path: string;
	permissions: PERMISSIONS[];
}) => {
	const result = {
		...props,
		element: <ProtectedRoute permissions={props.permissions}>{props.element}</ProtectedRoute>,
		path: props.path,
	};

	return dissoc(result, 'permissions');
};

export function protectRoutes(routes: any[]) {
	return routes.map((x) => wrapProtectedRoute(x));
}

export default ProtectedRoute;
