import { ValueScope } from 'enums/valueScope';
import { getNumberString } from 'functions/numberToString';
import { CalculatedNutrient } from 'types/NutrientValue/CalculatedNutrientValues';
import * as Product from 'types/Recipe/Detail/Product';
import * as ProductNutrientValue from 'types/Recipe/Detail/ProductNutrientValue';
import * as ProductNutrientValueList from 'types/Recipe/Detail/ProductNutrientValueList';

export type Type = {
	id: string;
	category: string[];
	similarity: number;
	externalId: string;
	brand: string;
	price: number;
	imageUrl: string;
	externalUrl: string;
	name: string;
	servingSizeQuantityGram: number;
	nutrientValuesPer100g: ProductNutrientValueList.Type;
	nutrientValuesPerServing: ProductNutrientValueList.Type;
};

export function create(): Type {
	return {
		id: '',
		category: [],
		similarity: 0,
		externalId: '',
		brand: '',
		price: 0,
		imageUrl: '',
		externalUrl: '',
		name: '',
		servingSizeQuantityGram: 100,
		nutrientValuesPer100g: [],
		nutrientValuesPerServing: [],
	};
}

export function toString(obj: Type) {
	if (!obj.brand) return obj.name;
	return `${obj.name} (${obj.brand})`;
}

export function getValue(
	nutrient: CalculatedNutrient,
	servingSizeQuantityGram: number,
	product: Product.Type | null,
	valueScope: ValueScope
): number | null {
	if (!product || !nutrient.id)
		return calculateNutrientValue(nutrient, servingSizeQuantityGram, valueScope);
	const nutrientValue: ProductNutrientValue.Type | undefined = ProductNutrientValueList.getById(
		valueScope === ValueScope.Parts
			? product.nutrientValuesPerServing
			: product.nutrientValuesPer100g,
		nutrient.id
	);
	if (nutrientValue) return nutrientValue.total;
	return null;
}

export function calculateNutrientValue(
	nutrient: CalculatedNutrient,
	servingSizeQuantityGram: number,
	valueScope: ValueScope
) {
	if (valueScope === ValueScope.HundredGrams) return nutrient.value;
	return nutrient.value * (servingSizeQuantityGram / 100);
}

export function getValueString(
	nutrient: CalculatedNutrient,
	servingSizeQuantityGram: number,
	product: Product.Type | null,
	cultureCode: string,
	valueScope: ValueScope
): string {
	const result = getValue(nutrient, servingSizeQuantityGram, product, valueScope);
	if (result === null) return '–';
	if (result === undefined) return '–';
	return getNumberString(Math.round(result), cultureCode);
}

export function getSource(nutrient: CalculatedNutrient, productId: string | null): string {
	if (!productId) return nutrient.source ?? 'USDA';
	return 'Sysco';
}
