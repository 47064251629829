import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
	isLocked: boolean;
	onLockClick: (isLocked: boolean) => void;
}

const InputLockButton = (props: IProps) => {
	return (
		<span
			className="input-group-text input-label-field-button"
			id="basic-addon1"
			onClick={() => props.onLockClick(!props.isLocked)}
		>
			{props.isLocked ? (
				<FontAwesomeIcon icon={faLock} className="icon" />
			) : (
				<FontAwesomeIcon icon={faUnlock} className="icon" />
			)}
		</span>
	);
};

export default InputLockButton;
