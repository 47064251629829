import { SelectHTMLAttributes } from 'react';

import * as SelectItem from 'components/desktop/_general/Input/InputExtensions/InputSelect/SelectItem';

interface IProps extends SelectHTMLAttributes<any> {
	handleSelect: (input: number) => void;
	suggestions: SelectItem.Type[];
	value: number | string;
}

const InputSelect = (props: IProps) => {
	const handleSelect = (e: React.ChangeEvent<HTMLSelectElement>): void => {
		props.handleSelect(Number(e.currentTarget.value));
	};

	return (
		<select
			className="form-select"
			value={props.value}
			onChange={(e: React.ChangeEvent<HTMLSelectElement>) => handleSelect(e)}
		>
			{props.suggestions.map((element: SelectItem.Type, index: number) => (
				<option key={index} value={String(index)}>
					{element.description}
				</option>
			))}
		</select>
	);
};

export default InputSelect;
