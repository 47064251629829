//import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { RootState } from 'reducers/rootReducer';

export interface WithSelectedCultureProps {
	selectedCultureCode: string;
	readOnly: boolean;
}

export const WithSelectedCulture = <T extends WithSelectedCultureProps = WithSelectedCultureProps>(
	WrappedComponent: React.ComponentType<T>
) => {
	const ComponentWithCulture = (props: Omit<T, keyof WithSelectedCultureProps>) => {
		const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

		return <WrappedComponent {...(props as T)} selectedCultureCode={reduxCultureCode} />;
	};

	return ComponentWithCulture;
};
