import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import InputTranslateButton from 'components/desktop/_general/Input/InputExtensions/InputTranslateButton/InputTranslateButton';
import SimpleInput from 'components/desktop/_general/Input/SimpleInput/SimpleInput';
import TranslatableTextArea from 'components/desktop/_general/TranslatableTextArea/TranslatableTextArea';
import PERMISSIONS from 'enums/permissions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { RootState } from 'reducers/rootReducer';
import { Language } from 'types/Language/Language';
import { Menu } from 'types/Menu/Menu';

interface IProps {
	handleValueChange: (key: keyof Menu, value: string) => void;
	menu: Menu;
	onTranslateClick: (name: string) => void;
	selectedLanguage: string;
}

const MenuLanguageInput = (props: IProps) => {
	const { t } = useTranslation();
	const menuEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITEMENU]);

	const reduxLanguages: Language[] = useSelector((state: RootState) => state.languages);
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const createHandleValueChangeFunction = (key: keyof Menu): ((value: string) => void) => {
		return function (value: string) {
			props.handleValueChange(key, value);
		};
	};

	const isTranslatable = (): boolean => {
		if (!menuEditor) return false;
		if (reduxLanguages && reduxCultureCode !== undefined) {
			if (reduxCultureCode === props.selectedLanguage) {
				return false;
			} else {
				return true;
			}
		}
		return false;
	};

	const renderContent = () => {
		return (
			<>
				<div className="row">
					<div className="col-12">
						<label>{t('menu:MENU_NAME')}</label>
						<div className="input-group">
							<SimpleInput
								disabled={!menuEditor}
								error-key="NameTranslations"
								handleChange={createHandleValueChangeFunction('nameTranslations')}
								value={props.menu.nameTranslations[props.selectedLanguage]}
							/>
							<RenderIf condition={isTranslatable()}>
								<InputTranslateButton
									name="nameTranslations"
									onClick={props.onTranslateClick}
								/>
							</RenderIf>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12">
						<label>{t('menu:SHORT_DESCRIPTION')}</label>
						<div>
							<TranslatableTextArea
								value={
									props.menu.shortDescriptionTranslations[
										props.selectedLanguage
									] || ''
								}
								name="shortDescriptionTranslations"
								rows={4}
								handleValueChange={createHandleValueChangeFunction(
									'shortDescriptionTranslations'
								)}
								translatable={isTranslatable()}
								onTranslateClick={props.onTranslateClick}
								disabled={!menuEditor}
								errorKey="ShortDescriptionTranslations"
							/>
						</div>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default MenuLanguageInput;
