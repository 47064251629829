import MenuPlan from 'classes/MenuPlan/Search/MenuPlan';
import MenuPlanList from 'classes/MenuPlan/Search/MenuPlanList';
import SearchResultListRow from 'components/desktop/MenuPlan/Search/SearchResultListRow';

interface IProps {
	archive: boolean;
	handleArchiveMenuPlan: (input: MenuPlan) => Promise<void>;
	handleRestoreMenuPlan: (input: MenuPlan) => Promise<void>;
	menuPlanList: MenuPlanList;
}

const SearchResultListComponent = (props: IProps) => {
	return (
		<div className="d-flex flex-column">
			{props.menuPlanList.all.map((e: MenuPlan, i: number) => {
				return (
					<div key={i}>
						<SearchResultListRow
							archive={props.archive}
							menuPlan={e}
							handleArchiveMenuPlan={props.handleArchiveMenuPlan}
							handleRestoreMenuPlan={props.handleRestoreMenuPlan}
						/>
					</div>
				);
			})}
		</div>
	);
};

export default SearchResultListComponent;
