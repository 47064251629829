import { ReducerBuilder } from 'reducers/ReducerBuilder';
import { payloadReducer } from 'reducers/reducerUtils';
import * as CategoryList from 'types/_general/Store/CategoryList';

const rb = new ReducerBuilder<CategoryList.Type>();

export const createCategoriesLoadedAction = rb.createActionForReducer(
	payloadReducer((x) => x),
	'Categories/LoadedFromBackend'
);

export const categoriesReducer = rb.createReducer();
