import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';

import { Optional } from './promiseExtensions';

/**
 * Dissociate the given the given key from the object obj
 *
 * @param obj the object to dissociate from
 * @param key the key to dissociate from the object obj.
 * @returns A copy of obj, without the given key
 */
export function dissoc<T>(obj: { [key: number | string]: T }, ...keys: (number | string)[]) {
	const result = { ...obj };

	for (const key in keys) {
		delete result[key];
	}

	return result;
}

/**
 * Associate the given value at the given key into the object obj
 *
 * @param obj
 * @param key
 * @param value
 * @returns
 */
export function assoc<T, K extends keyof T>(obj: T, key: K, value: T[K]): T {
	const result = { ...obj };
	result[key] = value;
	return result;
}

/**
 * Associate the given value at the given nested key structure (= path), thereby
 * creating a new root object.
 *
 * assocIn({}, ["a", "b"], 1) => {"a" : {"b" : 1}}
 */
export function assocIn<T>(obj: T, keys: any[], value: any): T {
	if (keys.length === 0) return obj;
	if (keys.length === 1) return assoc(obj, keys[0] as any, value);

	const key = keys.first();
	const childObject = Optional.Maybe((obj as any)[key]).getOrDefault({});

	const result: any = { ...obj };
	result[key] = assocIn(childObject, keys.drop(1), value);

	return result;
}

type Getter<T, K extends keyof T> = (t: T) => T[K];
type Setter<T, K extends keyof T> = (t: T, value: T[K]) => T;

type Picker<T, K extends keyof T> = { getter: Getter<T, K>; setter: Setter<T, K> };

const PropertyPicker = <T, K extends keyof T>(key: K): Picker<T, K> => {
	return {
		getter: (t: T) => t[key],
		setter: (t: T, value: T[K]) => {
			t[key] = value;
			return t;
		},
	};
};

export const BooleanPropertyPicker = <T, K extends keyof T>(
	key: K & (T[K] extends boolean ? K : never)
): Picker<T, K> => {
	return PropertyPicker<T, K>(key) as any;
};
export const StringPropertyPicker = <T, K extends keyof T>(
	key: K & (T[K] extends string ? K : never)
): Picker<T, K> => {
	return PropertyPicker<T, K>(key) as any;
};

export const LocalizedTranslationPropertyPicker = <T, K extends keyof T>(
	key: K & (T[K] extends LocalizedTranslation<string> ? K : never)
): Picker<T, K> => {
	return PropertyPicker<T, K>(key) as any;
};

export function assocSafe<T, K extends keyof T>(obj: T, picker: Picker<T, K>, value: T[K]): T {
	const { setter } = picker;
	const result = { ...obj };
	setter(result, value);
	return result;
}

export function assocTranslation<T, K extends keyof T>(
	obj: T,
	picker: Picker<T, K & (T[K] extends LocalizedTranslation<string> ? K : never)>,
	language: string,
	value: string
): T {
	const { setter, getter } = picker;
	const result = { ...obj };
	const translations = getter(obj) as LocalizedTranslation<string>;
	translations[language] = value;
	setter(result, translations as any);
	return result;
}

export function assocTranslationUnsafe<T>(obj: T, key: string, language: string, value: string): T {
	const result: any = { ...obj };
	const translations = Optional.Maybe(result[key])
		.map((x) => {
			return { ...x };
		})
		.getOrDefault({});
	translations[language] = value;
	result[key] = translations;
	return result;
}

export function dissocTranslationUnsafe<T>(obj: T, key: string, language: string): T {
	const result: any = { ...obj };
	const translations = Optional.Maybe(result[key])
		.map((x) => {
			return { ...x };
		})
		.getOrDefault({});
	delete translations[language];
	result[key] = translations;
	return result;
}

export function getIn<T, O>(obj: T, ...keys: string[]): Optional<O> {
	if (keys.length === 0) throw Error('cannot get value from an object without a path');
	if (keys.length === 1) return (obj as any)[keys[0]];

	const key = keys.first();
	const childObject = Optional.Maybe((obj as any)[key]);

	return childObject.map((x) => getIn(x, ...keys.drop(1))) as any;
}

export function getTranslation<T>(obj: T, ...keys: string[]): Optional<string> {
	return getIn<T, string>(obj, ...keys);
}
