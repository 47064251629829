import { faLineColumns } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as apiGetMatchProduct from 'api/match/GetMatchProduct';
import Calculation from 'classes/Recipe/Detail/Calculation/Calculation';
import ColumnSelectionOverlay from 'components/desktop/Recipe/RecipeTabs/Components/RecipeErpView/IngredientProductMappingComponent/ColumnSelectionOverlay';
import { ProductAccordion } from 'components/desktop/Recipe/RecipeTabs/Components/RecipeErpView/IngredientProductMappingComponent/ProductAccordion';
import ValueScopeButtons from 'components/desktop/Recipe/RecipeTabs/Components/RecipeErpView/IngredientProductMappingComponent/ValueScopeButtons';
import Overlay from 'components/desktop/_general/Overlay/Overlay';
import { ValueScope } from 'enums/valueScope';
import * as ErpNutritionSourceList from 'types/Recipe/Detail/ErpNutritionSourceList';
import { Recipe } from 'types/Recipe/Recipe';
import { SegmentIngredient } from 'types/Recipe/SegmentIngredient';
import { NutriScore } from 'types/_general/NutriScore';

type IProps = {
	calculation: Calculation | null;
	selectedColumns: string[];
	setSelectedColumns: (ids: string[]) => void;
	setValueScope: (input: ValueScope) => void;
	valueScope: ValueScope;
	nutritionSources: ErpNutritionSourceList.Type;
	deSelectProduct: (ingredientId: string) => void;
	ingredients: SegmentIngredient[];
	nutriScore: NutriScore | null;
	productMappings: { [key: string]: apiGetMatchProduct.Match };
	recipe: Recipe;
	selectProduct: (input: { ingredientId: string; product: apiGetMatchProduct.Match }) => void;
};

export const IngredientsProductMappingComponent = (props: IProps) => {
	const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);
	const { t } = useTranslation();
	return (
		<>
			<Overlay
				displayOverlay={displayOverlay}
				handleClose={() => setDisplayOverlay(false)}
				containerStyle={{ minWidth: '40%' }}
			>
				<ColumnSelectionOverlay {...props} close={() => setDisplayOverlay(false)} />
			</Overlay>
			<div className="d-flex justify-content-between" style={{ marginBottom: '-15px' }}>
				<button
					type="button"
					className="btn btn-sm btn-outline-primary"
					onClick={() => setDisplayOverlay(true)}
				>
					<FontAwesomeIcon icon={faLineColumns} style={{ marginRight: '5px' }} />
					{t('_general:COLUMNS')}
				</button>
				<ValueScopeButtons
					valueScope={props.valueScope}
					setValueScope={props.setValueScope}
				/>
			</div>

			<div style={{ flex: '1', minWidth: 0 }}>
				<ProductAccordion {...props} />
			</div>
		</>
	);
};
