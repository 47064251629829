import * as NutrientCategory from 'types/_general/Store/NutrientCategory';

export type Type = {
	id: string;
	sortOrder: number;
	name: string;
	isMacroNutrient: boolean;
	unitShortNameSingular: string;
	nutrientCategory: NutrientCategory.Type;
	value: number;
	source: string | null;
};

export function create(): Type {
	return {
		id: '',
		sortOrder: 0,
		name: '',
		isMacroNutrient: false,
		unitShortNameSingular: '',
		nutrientCategory: NutrientCategory.create(),
		value: 0,
		source: null,
	};
}

export function toString(obj: Type): string | null {
	if (!obj.name) return null;
	if (!obj.unitShortNameSingular) return obj.name;
	return `${obj.name} (${obj.unitShortNameSingular}) `;
}
