import { faSquare, faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertResult } from 'sweetalert2';

import Nutrient from 'classes/StandingData/Nutrient/Search/Nutrient';
import NutrientList from 'classes/StandingData/Nutrient/Search/NutrientList';
import Search from 'classes/StandingData/Nutrient/Search/Search';
import NutrientDetailComponent from 'components/desktop/StandingData/Nutrient/Detail/NutrientDetailComponent';
import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import BtnNew from 'components/desktop/_general/Button/BtnNew/BtnNew';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import AdvancedSearchInput from 'components/desktop/_general/Search/AdvancedSearchInput';
import StandingDataList, {
	Row,
} from 'components/desktop/_general/StandingDataList/StandingDataList';
import clone from 'functions/clone';
import { QueryParams, getQueryParams } from 'functions/getQueryParams';
import useEffectWhenMounted from 'hooks/useEffectWhenMounted';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	archive?: boolean;
}

const maxNumberOfButtons = 5;

const NutrientManagementComponent = (props: IProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);

	const [nutrientList, setNutrientList] = useState<NutrientList>(new NutrientList());
	const [search, setSearch] = useState<Search>(new Search());
	const [searchTerm, setSearchTerm] = useState<string>(search.searchTerm.value ?? '');

	useEffect(() => {
		initialize();
	}, []);

	useEffectWhenMounted(() => {
		initialize();
	}, [reduxCultureCode]);

	const initialize = async (): Promise<void> => {
		if (props.archive !== undefined && props.archive == true) {
			search.showOnlyDeleted = true;
		}
		if (window.location.href.includes('?')) {
			const queryParams: QueryParams = getQueryParams();
			search.mapFromUrl(queryParams);
			if (search.id.value) setDisplayOverlay(true);
			await getNutrients();
		} else {
			await getNutrients();
		}
		setSearch(clone(search));
	};

	const handleArchiveNutrient = async (nutrient: Nutrient): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:ARCHIVE_TITLE'),
			text: t('standingData:NUTRIENT_ARCHIVE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#ff0004',
			confirmButtonColor: '#ff0004',
			confirmButtonText: t('_general:ARCHIVE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await nutrient.callApiDelete();
				await Swal.fire({
					title: t('standingData:NUTRIENT_ARCHIVE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getNutrients();
			} catch {
				setIsLoading(false);
			}
		}
	};

	const handleRestoreNutrient = async (nutrient: Nutrient): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:RESTORE_TITLE'),
			text: t('standingData:NUTRIENT_RESTORE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#00cc07',
			confirmButtonColor: '#00cc07',
			confirmButtonText: t('_general:RESTORE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await nutrient.callApiRestore();
				await Swal.fire({
					title: t('standingData:NUTRIENT_RESTORE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getNutrients();
			} catch {
				setIsLoading(false);
			}
		}
	};

	const getNutrients = async () => {
		setIsLoading(true);
		const response = await nutrientList.callApi(search.mapToRequest());
		response.do((x) => {
			nutrientList.mapFromApi(x);
			setNutrientList(clone(nutrientList));
		});
		setIsLoading(false);
	};

	const handleChangeId = (id: string | null) => {
		search.id.value = id;
		handleChangeUrl();
	};

	const handleOverlayOpen = (input: string): void => {
		setDisplayOverlay(true);
		handleChangeId(input);
	};

	const handleOverlayClose = (): void => {
		setDisplayOverlay(false);
		handleChangeId(null);
	};

	const handleChangeUrl = () => {
		const url: string | null = search.mapToUrl();
		if (url) navigate(url);
		getNutrients();
	};

	const handleChangePageIndex = (index: number) => {
		search.pageIndex.value = index;
		handleChangeUrl();
	};

	const handleChangeIdToNew = (): void => {
		handleChangeId('new');
		setDisplayOverlay(true);
	};

	const getSuggestedNutrients = async (): Promise<ISelectItem[]> => {
		if (searchTerm.length >= 1) {
			await nutrientList.search(searchTerm, props.archive ?? false);
		} else {
			nutrientList.filtered = [];
		}
		setNutrientList(clone(nutrientList));
		return nutrientList.filtered;
	};

	const handleSearch = (input?: string) => {
		search.setSearchTerm(input ?? searchTerm);
		handleChangeUrl();
	};

	const getButtonDataEdit = (id: string): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleOverlayOpen(id),
			};
		}
	};

	const getButtonDataArchive = (input: Nutrient): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleArchiveNutrient(input),
			};
		}
	};

	const getButtonDataRestore = (input: Nutrient): BtnData | undefined => {
		if (props.archive) {
			return {
				onClick: () => handleRestoreNutrient(input),
			};
		}
	};

	const renderButtons = (input: Nutrient): JSX.Element => {
		return (
			<BtnGroup
				edit={getButtonDataEdit(input.id)}
				archive={getButtonDataArchive(input)}
				restore={getButtonDataRestore(input)}
			/>
		);
	};

	const renderIsMacroNutrient = (input: boolean): JSX.Element => {
		if (input) {
			return (
				<>
					<FontAwesomeIcon icon={faSquareCheck} className="fa-1x" />{' '}
					{t('standingData:IS_MACRONUTRIENT')}
				</>
			);
		}
		return (
			<>
				<FontAwesomeIcon icon={faSquare} className="fa-1x" />{' '}
				{t('standingData:IS_MACRONUTRIENT')}
			</>
		);
	};

	const renderListRows = (): Row[] => {
		return nutrientList.all.map((nutrient: Nutrient) => {
			return {
				id: nutrient.id,
				jsx: (
					<div
						className="d-flex flex-row justify-content-start align-items-center"
						style={{ gap: '2em' }}
					>
						<div style={{ width: '300px' }}>{nutrient.name}</div>
						<div className="flex" style={{ width: '300px' }}>
							<span className="grey">{t('standingData:SHORT_DESCRIPTION')}: </span>
							{nutrient.shortName}
						</div>
						<div style={{ width: '300px' }}>
							<span className="grey">{t('standingData:NUTRIENT_CATEGORY')}: </span>
							{nutrient.nutrientCategory}
						</div>
						<div className="flex-fill">
							{renderIsMacroNutrient(nutrient.isMacroNutrient)}
						</div>
						<div>{renderButtons(nutrient)}</div>
					</div>
				),
			};
		});
	};

	const renderContent = (): JSX.Element => {
		return (
			<main className="container">
				<div className="d-flex flex-row justify-content-between">
					{!props.archive ? (
						<>
							<h1>{t('standingData:NUTRIENT_MANAGEMENT')}</h1>
							<div className="d-flex flex-gap-25">
								<BtnNew
									onClick={handleChangeIdToNew}
									name={t('standingData:CREATE_NUTRIENT')}
								/>
							</div>
						</>
					) : (
						<h1>{t('standingData:NUTRIENT_ARCHIVE')}</h1>
					)}
				</div>
				<br />
				<div className="row">
					<div className="col-lg-12">
						<div className="input-group mb-3">
							<AdvancedSearchInput
								debounceMilliSeconds={350}
								getSuggestedItems={getSuggestedNutrients}
								handleSearch={handleSearch}
								searchTerm={searchTerm}
								setSearchTerm={setSearchTerm}
								triggerSearchLetterAmount={1}
							/>
						</div>
					</div>
				</div>
				<br />
				<LoadingAnimation isLoading={isLoading} />
				<p>{nutrientList.totalCount + ' ' + t('_general:NUTRIENTS')}</p>
				<StandingDataList
					archive={props.archive}
					displayOverlay={displayOverlay}
					rows={renderListRows()}
					overlay={<NutrientDetailComponent />}
					handleOverlayOpen={handleOverlayOpen}
					handleOverlayClose={handleOverlayClose}
				/>
				<br />
				<Pagination
					countOfAllListItems={nutrientList.totalCount}
					pageIndex={search.getPageIndex()}
					setPageIndex={handleChangePageIndex}
					listItemAmount={search.getPageSize()}
					maxNumberOfButtons={maxNumberOfButtons}
				/>
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default NutrientManagementComponent;
