import { useTranslation } from 'react-i18next';

import Nutrient from 'classes/StandingData/Nutrient/Nutrient';

interface IProps {
	nutrient: Nutrient;
	handleValueChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	selectedLanguage: string;
}

const NutrientLanguageInput = (props: IProps) => {
	const { t } = useTranslation();

	const renderContent = () => {
		return (
			<>
				<div className="row mt-2">
					<div className="col-12 mt-2">
						<div className="row mb-3">
							<label>{t('_general:DESCRIPTION')}</label>
							<div>
								<input
									value={
										props.nutrient.nameTranslations[props.selectedLanguage] ||
										''
									}
									type="text"
									onChange={props.handleValueChange}
									className="form-control"
									name="descriptionTranslations"
									error-key={'NameTranslations'}
								/>
							</div>
							<div>
								<label>{t('_general:SHORT_DESCRIPTION')}</label>
								<input
									value={
										props.nutrient.shortNameTranslations[
											props.selectedLanguage
										] || ''
									}
									type="text"
									onChange={props.handleValueChange}
									className="form-control"
									name="shortDescriptionTranslations"
									error-key={'ShortNameTranslations'}
								/>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default NutrientLanguageInput;
