export type InconsistentType =
	| 'SeasonCountInconsistent'
	| 'SeasonInconsistent'
	| 'AllergenCountInconsistent'
	| 'AllergenInconsistent'
	| 'CarbonDioxideValueInconsistent'
	| 'CarbonDioxideLabelInconsistent'
	| 'PriceInconsistent'
	| 'CalorieInconsistent'
	| 'WeightInconsistent'
	| 'NutrientValueCountInconsistent'
	| 'NutrientValueInconsistent';

export const inconsistentTypesArray: InconsistentType[] = [
	'SeasonCountInconsistent',
	'SeasonInconsistent',
	'AllergenCountInconsistent',
	'AllergenInconsistent',
	'CarbonDioxideValueInconsistent',
	'CarbonDioxideLabelInconsistent',
	'PriceInconsistent',
	'CalorieInconsistent',
	'WeightInconsistent',
	'NutrientValueCountInconsistent',
	'NutrientValueInconsistent',
];

export function isInconsistentType(value: string): value is InconsistentType {
	return inconsistentTypesArray.includes(value as InconsistentType);
}

export type UnrecognizedIngredientType = 'RecipeSegmentHasUnrecognizedIngredient';

export function isUnrecognizedIngredient(value: string): value is UnrecognizedIngredientType {
	return value === 'RecipeSegmentHasUnrecognizedIngredient';
}

export type RecipeHasNoIngredientTextType = 'RecipeHasNoIngredientText';

export type RecipeSegmentHasUnrecognizedUnitType = 'RecipeSegmentHasUnrecognizedUnit';

export type RecipeSegmentHasUnrecognizedQuantityType = 'RecipeSegmentHasUnrecognizedQuantity';

export type Type =
	| InconsistentType
	| UnrecognizedIngredientType
	| RecipeHasNoIngredientTextType
	| RecipeSegmentHasUnrecognizedUnitType
	| RecipeSegmentHasUnrecognizedQuantityType;

export function create(): Type {
	return 'RecipeHasNoIngredientText';
}
