import { faGlobe } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'components/desktop/_general/Input/InputExtensions/InputTranslateButton/InputTranslateButton.scss';

interface IProps {
	name: string;
	onClick: (name: string) => void;
}

const InputTranslateButton = (props: IProps) => {
	return (
		<span
			className="input-group-text translatable-input-button"
			id="basic-addon1"
			onClick={() => props.onClick(props.name)}
		>
			<FontAwesomeIcon icon={faGlobe} className="icon" />
		</span>
	);
};

export default InputTranslateButton;
