import { useEffect } from 'react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import LabeledCheckbox from 'components/desktop/_general/Checkbox/LabeledCheckbox';
import syscoNutrients from 'constants/syscoNutrients';
import * as StringList from 'types/Product/Search/StringList';
import * as Nutrient from 'types/_general/Store/Nutrient';
import * as NutrientList from 'types/_general/Store/NutrientList';

interface IProps {
	selectedColumns: string[];
	setSelectedColumns: (ids: string[]) => void;
	close: () => void;
}

const ColumnSelectionOverlay = (props: IProps) => {
	const [searchTerm, setSearchTerm] = useState<string>('');
	const [selectedColumns, setSelectedColumns] = useState<string[]>(props.selectedColumns);
	const nutrients: NutrientList.Type = useNutrients();
	const { t } = useTranslation();

	const toggleSelectedColumn = (input: string) => {
		setSelectedColumns(StringList.addOrRemove(selectedColumns, input));
	};

	const handleOkClick = () => {
		props.setSelectedColumns(selectedColumns);
		props.close();
	};

	const handleCancelClick = () => {
		props.close();
	};

	const renderCheckboxes = (text: string, nutrients: NutrientList.Type): JSX.Element => {
		if (!nutrients.length) return <></>;
		return (
			<>
				<strong>{text}</strong>
				{NutrientList.getBySearchTerm(nutrients, searchTerm).map((e, i) => {
					return (
						<LabeledCheckbox
							key={i}
							checked={selectedColumns.includes(e.id)}
							label={Nutrient.toString(e) ?? t('_general:UNKNOWN')}
							setChecked={(_checked: boolean) => toggleSelectedColumn(e.id)}
							id={e.id}
						/>
					);
				})}
			</>
		);
	};

	return (
		<>
			<h3>{t('_general:NUTRIENTS')}</h3>
			<input
				type="text"
				className="form-control mb-2"
				placeholder={t('header:SEARCH') + ' …'}
				onChange={(e) => setSearchTerm(e.target.value)}
			/>
			<div
				className="d-flex flex-column"
				style={{
					height: '350px',
					overflow: 'auto',
					borderTop: '1px solid #ddd',
					borderBottom: '1px solid #ddd',
					marginBottom: '1em',
					marginTop: '1em',
					gap: '10px',
				}}
			>
				<div>
					{renderCheckboxes(
						t('_general:SELECTED'),
						NutrientList.filterByIds(nutrients, props.selectedColumns)
					)}
				</div>
				<div>
					{renderCheckboxes(
						'Sysco',
						NutrientList.filterByIds(
							NutrientList.filterByIdsExclusion(nutrients, props.selectedColumns),
							syscoNutrients
						)
					)}
				</div>
				<div>
					{renderCheckboxes(
						t('_general:NOT_SELECTED'),
						NutrientList.filterByIdsExclusion(
							NutrientList.filterByIdsExclusion(nutrients, syscoNutrients),
							props.selectedColumns
						)
					)}
				</div>
			</div>
			<div className="d-flex justify-content-end" style={{ gap: '5px' }}>
				<button type="button" className="btn btn-outline-primary" onClick={handleOkClick}>
					OK
				</button>
				<button
					type="button"
					className="btn btn-outline-primary"
					onClick={handleCancelClick}
				>
					Cancel
				</button>
			</div>
		</>
	);
};

export default ColumnSelectionOverlay;

export function useNutrients() {
	const [nutrients, setNutrients] = useState<NutrientList.Type>([]);

	useEffect(() => {
		init();
	}, []);

	async function init() {
		const nutrients: NutrientList.Type = await NutrientList.getFromApi();
		setNutrients(nutrients);
	}

	return nutrients;
}
