import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as apiGet from 'api/status/GetStatusIdV1';
import Status from 'classes/StandingData/Status/Status';
import StatusLanguageInput from 'components/desktop/StandingData/Status/LanguageInput/StatusLanguageInput';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import clone from 'functions/clone';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	highestOrder: number;
}

const StatusDetail = (props: IProps) => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
	const [selectedCultureCode, setSelectedCultureCode] = useState<string>('');

	const [status, setStatus] = useState<Status>(new Status());
	const [id, setId] = useState<string | null>(null);

	useEffect(() => {
		initialize();
		setSelectedCultureCode(reduxCultureCode);
	}, []);

	const initialize = async (): Promise<void> => {
		const id: string | null = getIdFromUrl();
		setId(id);
		if (id !== null && id !== 'new') {
			getStatus(id);
		}
	};

	const getStatus = async (id: string): Promise<void> => {
		setIsLoading(true);
		try {
			const response = await apiGet.callApi(id);
			response.do((x) => setStatus(new Status(x)));
		} finally {
			setIsLoading(false);
		}
	};

	const handleColorChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		status.color = e.currentTarget.value;
		setStatus(clone(status));
	};

	const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		status.nameTranslations[selectedCultureCode] = e.currentTarget.value;
		setStatus(clone(status));
	};

	const handleTranslate = async () => {
		setIsLoading(true);
		await status.callApiTranslation(reduxCultureCode, selectedCultureCode);
		setStatus(clone(status));
		setIsLoading(false);
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string) => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSaveStatus = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			if (id !== null && id !== 'new') {
				await status.callApiPut();
			} else {
				status.order = props.highestOrder + 1;
				await status.callApiPost();
				if (!status.id) return;
				setId(status.id);
				setIdToUrl(status.id);
			}
		} catch {
			setIsLoadingSave(false);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={isLoading} />
				<div className="row">
					<div className="d-flex flex-row justify-content-between mb-3">
						<div>
							{status.id ? (
								<>
									<h1>{t('standingData:EDIT_STATUS')}</h1>
								</>
							) : (
								<>
									<h1>{t('standingData:NEW_STATUS')}</h1>
								</>
							)}
						</div>
						<div>
							<BtnSave
								handleSave={() => handleSaveStatus()}
								isLoading={isLoadingSave}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-3 col-xl-3 col-lg-3 col-sm-4 col-12">
						<label className="form-check-label">{t('standingData:COLOR')}:</label>
						<br />
						<input
							id="color"
							type="text"
							className="form-control"
							onChange={handleColorChange}
							value={status.color}
							placeholder={'#2d5776'}
							style={{ marginBottom: '0.5em' }}
							error-key={'Color'}
						/>
						<input type="color" onChange={handleColorChange} value={status.color} />
					</div>
					<div className="col-md-9 col-xl-9 col-sm-8 col-12">
						<LanguageSwitch
							handleSelectLanguage={handleSelectLanguage}
							selectedLanguage={selectedCultureCode}
							translatable={true}
							onTranslateClick={handleTranslate}
							entryLanguage={reduxCultureCode}
							content={
								<StatusLanguageInput
									status={status}
									selectedLanguage={selectedCultureCode}
									handleValueChange={handleInputValueChange}
								/>
							}
						/>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default StatusDetail;
