export function getPriceString(input: number, cultureCode: string): string {
	const separator = getPriceSeparator(cultureCode);
	const delimiter = getDelimiter(cultureCode);
	return createString(input, separator, delimiter);
}

export function getNumberString(input: number, cultureCode: string, rounded?: boolean): string {
	const separator = getNumberSeparator(cultureCode);
	const delimiter = getDelimiter(cultureCode);
	if (rounded) {
		return createStringRounded(input, delimiter);
	}
	return createString(input, separator, delimiter);
}

export function getSeparatedNumberString(input: string, cultureCode: string): string {
	const separator = getNumberSeparator(cultureCode);
	const delimiter = getDelimiter(cultureCode);

	if (input.length === 0) return '0' + separator + '00';

	const array: string[] = input.split('.');
	let before: string = array[0];
	let after: string | undefined = array[1];

	if (before.length >= 4) {
		before = addDelimiter(before, delimiter);
	}

	if (after === undefined) after = '0';
	if (after.length < 2) after = after + '0';

	if (after === '00') {
		return before;
	} else {
		return before + separator + after;
	}
}

export function createString(input: number, separator: string, delimiter: string): string {
	if (input === 0) return '0';

	const roundedInputString = String(Math.round(input * 100) / 100);
	const array: string[] = String(roundedInputString).split('.');
	let before: string = array[0];
	let after: string | undefined = array[1];

	if (before.length >= 4) {
		before = addDelimiter(before, delimiter);
	}

	if (after === undefined) after = '00';
	if (after.length < 2) after = after + '0';

	if (after === '00') {
		return before;
	} else {
		return before + separator + after;
	}
}

export function createStringRounded(input: number, delimiter: string): string {
	if (input === 0) return '0';

	const roundedInputString = String(Math.round(input));
	let before: string = roundedInputString;

	if (before.length >= 4) {
		before = addDelimiter(before, delimiter);
	}
	return before;
}

function separateIn3Parts(input: string): RegExpMatchArray | null {
	return input.match(/\d{1,3}(?=(\d{3})*$)/g);
}

function addDelimiter(input: string, delimiter: string): string {
	const regExArray: RegExpMatchArray | null = separateIn3Parts(input);
	if (regExArray) {
		return regExArray.join(delimiter);
	} else {
		return '0';
	}
}

function getNumberSeparator(cultureCode: string): string {
	switch (cultureCode) {
		case 'en-US':
			return '.';
		default:
			return ',';
	}
}

function getPriceSeparator(cultureCode: string): string {
	switch (cultureCode) {
		case 'en-US':
			return '.';
		default:
			return ',';
	}
}

function getDelimiter(cultureCode: string): string {
	switch (cultureCode) {
		case 'en-US':
			return ',';
		case 'it-IT':
			return '’';
		default:
			return '\u2006';
	}
}

export function isValidNumber(input: string | null | undefined) {
	if (input === null || input === undefined) {
		return false;
	}

	if (typeof input === 'number') {
		return !isNaN(input);
	}

	const num = Number(input);
	return !isNaN(num) && input.trim() !== '';
}
