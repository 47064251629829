import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export async function callApi(request: Request): Promise<Optional<ResponseData>> {
	const url: string = '/backend-service/MenuPlanner/Calculate';
	return axios.post(url, request).toOptionalMapped((x) => x.data);
}

export type Request = {
	items: Item[]; // required
};

export type Item = Row[];

export type Row = {
	id: string;
	type: Type;
};

export type Type = 'Menu' | 'Recipe';

export type Response = {
	data: ResponseData; // required
};

export type ResponseData = Data[];

export type Season = {};
export type Data = {
	currencyCode: string;
	calorieSum: number | null;
	priceSum: number | null;
	carbonDioxideValueSum: number | null;
	carbonDioxideLabel: CarbonDioxideLabel | null;
	nutrientValues: NutrientValues[];
	allergens: Allergens[];
	seasons: Season[];
};

export type CarbonDioxideLabel = {
	label: string;
	color: string;
};

export type NutrientValues = {
	nutrientId: string;
	nutrient: string;
	categoryId: string;
	category: string;
	total: number;
	unitId: string;
	unit: string;
	isMacroNutrient: boolean;
	source: string | null;
	nutrientCategorySortOrder: number;
	value: number;
};

export type Allergens = {
	id: string;
	iconSvgUrl: string | null;
	name: string;
	display: boolean;
	logic: 'Positive' | 'Negative' | 'MayContain';
	source: string | null;
};
