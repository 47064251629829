import BtnGenerateImage from 'components/desktop/_general/Button/BtnGenerateImage/BtnGenerateImage';
import BtnMediaUpload from 'components/desktop/_general/Button/BtnMediaUpload/BtnMediaUpload';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import 'components/desktop/_general/MediaCarousel/MediaCarousel.scss';
import { MEDIATYPES } from 'enums/mediaTypes';

interface IProps {
	accept: MEDIATYPES[];
	buttonLabel: string;
	disabled?: boolean;
	generateImage?: boolean;
	handleUploadMedia: (input: FileList) => Promise<void>;
	renderMediaPreview: () => JSX.Element | null;
	renderMediaThumbnails: () => JSX.Element | null;
	setDisplayGenerateImageOverlay?: (input: boolean) => void;
}

const MediaCarousel = (props: IProps) => {
	return (
		<>
			<div id="media-carousel-container">
				<div id="media">{props.renderMediaPreview()}</div>
				<div id="media-sidebar">
					<div id="media-list">{props.renderMediaThumbnails()}</div>
					<RenderIf condition={!props.disabled}>
						<BtnMediaUpload
							handleUploadMedia={props.handleUploadMedia}
							label={props.buttonLabel}
							accept={props.accept}
						/>
						<RenderIf condition={props.generateImage}>
							<BtnGenerateImage
								setDisplayOverlay={props.setDisplayGenerateImageOverlay!}
							/>
						</RenderIf>
					</RenderIf>
				</div>
			</div>
		</>
	);
};

export default MediaCarousel;
