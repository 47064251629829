import { ReducerBuilder } from 'reducers/ReducerBuilder';
import { payloadReducer } from 'reducers/reducerUtils';
import * as NutrientList from 'types/_general/Store/NutrientList';

const rb = new ReducerBuilder<NutrientList.Type>();
export const createNutrientListLoadedAction = rb.createActionForReducer(
	payloadReducer((x) => x),
	'NutrientList/LoadedFromBackend'
);

export const nutrientListReducer = rb.createReducer();
