import * as State from 'components/desktop/Recipe/RecipeTabs/Components/RecipeErpView/RecipeErpViewState';
import { useHandler } from 'hooks/useHandler';

export default function useRecipeErpViewState() {
	const [state, rf] = useHandler<State.Type>(State.create());
	return {
		state,
		handlers: {
			selectProduct: rf.createReducer(State.selectProduct),
			deSelectProduct: rf.createReducer(State.deSelectProduct),
			setSelectedColumns: rf.createReducer(State.setSelectedColumns),
			setValueScope: rf.createReducer(State.setValueScope),
		},
	};
}
