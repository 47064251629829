import * as apiGet from 'api/baseIngredient/GetBaseIngredientIdV1';
import * as apiPost from 'api/baseIngredient/PostBaseIngredientV1';
import * as apiPut from 'api/baseIngredient/PutBaseIngredientV1';
import * as BaseIngredientState from 'components/desktop/BaseIngredient/BaseIngredientState';
import { Characteristic } from 'enums/characteristic';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { Optional } from 'functions/promiseExtensions';
import * as AllergenList from 'types/BaseIngredient/Detail/AllergenList';
import * as CategoryList from 'types/BaseIngredient/Detail/CategoryList';
import * as Co2Label from 'types/BaseIngredient/Detail/Co2Label';
import * as Co2List from 'types/BaseIngredient/Detail/Co2List';
import * as Image from 'types/BaseIngredient/Detail/Image';
import * as IngredientList from 'types/BaseIngredient/Detail/IngredientList';
import * as NutriScore from 'types/BaseIngredient/Detail/NutriScore';
import * as NutrientValueList from 'types/BaseIngredient/Detail/NutrientValueList';
import * as SeasonList from 'types/BaseIngredient/Detail/SeasonList';
import * as TagList from 'types/BaseIngredient/Detail/TagList';
import * as UnitWeightList from 'types/BaseIngredient/Detail/UnitWeightList';
import * as Translation from 'types/_general/Translation';

const defaultCharacteristic: Characteristic = Characteristic.SolidNonQuantifiable;

export type Type = {
	allergens: AllergenList.Type;
	calories: number;
	carbonDioxide: Co2List.Type;
	carbonDioxideLabel: Co2Label.Type | null;
	carbonDioxideValue: number;
	categories: CategoryList.Type;
	characteristic: Characteristic;
	density: number;
	hasNutriScore: boolean;
	id: string;
	image: Image.Type | null;
	ingredients: IngredientList.Type;
	nameTranslations: Translation.Type<string>;
	nutriScore: NutriScore.Type | null;
	nutriScoreCategory: NutriScoreCategory | null;
	nutriScoreCategoryIsSetByUser: boolean;
	nutrientValues: NutrientValueList.Type;
	scalingFactor: number;
	seasons: SeasonList.Type;
	source: string | null;
	tags: TagList.Type;
	unitWeights: UnitWeightList.Type;
};

export function create(): Type {
	return {
		allergens: [],
		calories: 0,
		carbonDioxide: [],
		carbonDioxideLabel: null,
		carbonDioxideValue: 0,
		categories: [],
		characteristic: defaultCharacteristic,
		density: 0,
		hasNutriScore: false,
		id: '',
		image: null,
		ingredients: [],
		nameTranslations: {},
		nutriScore: null,
		nutriScoreCategory: null,
		nutriScoreCategoryIsSetByUser: false,
		nutrientValues: [],
		scalingFactor: 0,
		seasons: [],
		source: null,
		tags: [],
		unitWeights: [],
	};
}

export async function getFromApi(id: string): Promise<Optional<Type>> {
	const response = await apiGet.callApi(id);
	return response.map(mapFromApi);
}

export async function getFromApiOrCreate(id: string | undefined): Promise<Optional<Type>> {
	if (id) {
		const response = await apiGet.callApi(id);
		return response.map(mapFromApi);
	}
	return Optional.Just(create());
}

export async function putToApi(id: string, request: apiPut.Request) {
	return await apiPut.callApi(id, request);
}

export async function postToApi(request: apiPost.Request): Promise<string> {
	const response = await apiPost.callApi(request);
	return response.getOrDefault('');
}

export function mapFromApi(input: apiGet.ResponseData): Type {
	return {
		...input,
		characteristic: input.characteristic ?? defaultCharacteristic,
	};
}

export function mapToState(obj: Type, selectedCultureCode: string): BaseIngredientState.Type {
	return {
		...obj,
		selectedCultureCode: selectedCultureCode,
		name: obj.nameTranslations,
		allergenIds: AllergenList.mapToUniqIdStringArray(obj.allergens),
		tagIds: obj.tags.map((e) => e.id),
		seasonIds: obj.seasons.map((e) => e.id),
		categorieIds: obj.categories.map((e) => e.id),
		co2Footprints: obj.carbonDioxide,
		density: obj.density ? obj.density : 1,
		scalingFactor: obj.scalingFactor ? obj.scalingFactor : 1,
	};
}
