import axios from 'axios';

import * as apiGet from 'api/recipe/GetRecipeIdV1';
import * as apiPostAnalysis from 'api/recipe/PostRecipeAnalyseV1';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { Optional } from 'functions/promiseExtensions';
import * as Search from 'types/Recipe/Print/Search';

export type Type = apiGet.ResponseData;

export function create(): Type {
	return {
		accessoryProducts: [],
		activityTimes: [],
		allergens: [],
		calories: null,
		carbonDioxide: [],
		carbonDioxideLabel: null,
		carbonDioxideValue: 0,
		categories: [],
		entryLanguage: {} as apiGet.EntryLanguage,
		hasNutriScore: false,
		id: '',
		image: null,
		images: [],
		incorporationShares: [],
		ingredientCalories: 0,
		ingredientProducts: [],
		ingredientWeight: null,
		ingredientsTranslations: {} as apiGet.Translation,
		isSeoActive: false,
		notesTranslations: {} as apiGet.Translation,
		nutriScore: {} as apiGet.NutriScore,
		nutriScoreCategory: NutriScoreCategory.StandardFood,
		nutrientValues: [],
		parts: null,
		persons: null,
		price: null,
		scaledParts: null,
		scaledPersons: null,
		seasons: [],
		segmentMedia: {} as apiGet.SegmentMediaCollection,
		seoTranslations: {} as apiGet.Translation,
		shortDescriptionTranslations: {} as apiGet.Translation,
		status: {} as apiGet.Status,
		stepsTranslations: {} as apiGet.Translation,
		systemStatus: 'New',
		tags: [],
		titleTranslations: {} as apiGet.Translation,
		videos: [],
		weight: null,
	};
}

export async function getFromApi(search: Search.Type, id: string): Promise<Type> {
	const response = await callApiWithCultureCode(id, search.cultureCode);
	const responseData = response.toNullable();
	if (responseData) {
		return mapFromApi(responseData);
	}
	return create();
}

function callApiWithCultureCode(
	id: string,
	cultureCode: string
): Promise<Optional<apiGet.ResponseData>> {
	const url: string = `/backend-service/Recipe/${id}`;
	return axios
		.get(url, {
			headers: {
				'Accept-Language': cultureCode,
			},
		})
		.toOptionalMapped((x) => x.data);
}

export function mapToApiAnalysis(obj: Type, cultureCode: string): apiPostAnalysis.Request {
	return {
		ingredients: obj.ingredientsTranslations[cultureCode],
		instructions: obj.stepsTranslations[cultureCode],
		cultureCode: cultureCode,
		recipeId: obj.id,
	};
}

export function getTitle(obj: Type, cultureCode: string): string {
	return obj.titleTranslations[cultureCode];
}

export function getDescription(obj: Type, cultureCode: string): string {
	return obj.shortDescriptionTranslations[cultureCode];
}

function mapFromApi(input: apiGet.ResponseData): Type {
	return input;
}
