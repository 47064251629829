import * as State from 'components/desktop/Recipe/RecipeTabs/Components/RecipeErpView/IngredientSearch/IngredientSearchState';
import { useHandler } from 'hooks/useHandler';

export default function useIngredientSearchState(initial: State.Type) {
	const [state, rf] = useHandler<State.Type>(initial);
	return {
		state,
		handlers: {
			setSearch: rf.createReducer(State.setSearch),
			setMatchLeftId: rf.createReducer(State.setMatchLeftId),
			setMatchRightId: rf.createReducer(State.setMatchRightId),
			setCriterion: rf.createReducer(State.setCriterion),
			setNrOfMatches: rf.createReducer(State.setNrOfMatches),
		},
	};
}
