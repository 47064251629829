import * as apiGet from 'api/menu/GetMenuIdV1';
import AllergenList from 'classes/MenuPlan/Detail/AllergenList';
import CarbonDioxideLabel from 'classes/MenuPlan/Detail/CarbonDioxideLabel';
import MenuRecipeList from 'classes/MenuPlan/Detail/MenuRecipeList';
import NutrientList from 'classes/MenuPlan/Detail/NutrientList';
import SeasonList from 'classes/MenuPlan/Detail/SeasonList';
import Status from 'classes/MenuPlan/Detail/Status';
import TagList from 'classes/MenuPlan/Detail/TagList';
import { DoughnutData } from 'components/desktop/_general/NutritionDiagram/NutritionDiagram';
import { NutrientCategoryGroup } from 'types/NutrientValue/CalculatedNutrientValues';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';
import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';

const kiloCaloriesToKilojouleFactor: number = 4.1868;

export default class Menu {
	public id: string = '';
	public allergenList: AllergenList = new AllergenList();
	public calories: number | null = null;
	public carbonDioxideLabel: CarbonDioxideLabel = new CarbonDioxideLabel();
	public carbonDioxideValue: number | null = null;
	public nameTranslations: LocalizedTranslation<string> | null = null;
	public nutrientList: NutrientList = new NutrientList();
	public price: number | null = null;
	public seasonList: SeasonList = new SeasonList();
	public status: Status = new Status();
	public systemStatus: SystemStatus = 'New';
	public tagList: TagList = new TagList();
	public weight: number | null = null;
	public recipeList: MenuRecipeList = new MenuRecipeList();

	public async callApiGet(id?: string): Promise<void> {
		const response = await apiGet.callApi(id ?? this.id);
		response.hasValue() && this.mapFromApi(response.get());
	}

	private mapFromApi(input: apiGet.ResponseData) {
		this.id = input.id;
		this.allergenList.mapFromApi(input.allergens);
		this.calories = input.calories;
		this.carbonDioxideLabel.mapFromApi(input.carbonDioxideLabel);
		this.carbonDioxideValue = input.carbonDioxideValue;
		this.nameTranslations = input.nameTranslations;
		this.nutrientList.mapFromApi(input.nutrientValues);
		this.price = input.price;
		this.seasonList.mapFromApi(input.seasons);
		this.status.mapFromApi(input.status);
		this.systemStatus = input.systemStatus;
		this.tagList.mapFromApi(input.tags);
		this.weight = input.weight;
		this.recipeList.mapFromApi(input.recipes);
	}

	public getNutrientPerPart(nutrientValue: number): number {
		return this.recipeList.getNutrientPerPart(nutrientValue);
	}

	public getNutrientPerPerson(nutrientValue: number): number {
		return this.recipeList.getNutrientPerPerson(nutrientValue);
	}

	public getNutrientTotal(nutrientValue: number): number {
		return this.recipeList.getNutrientTotal(nutrientValue);
	}

	public calculateCaloriesPer100Gramm(convertToKilojoule: boolean): number {
		if (!this.weight) return 0;
		if (!this.calories) return 0;
		if (this.weight <= 0) return 0;

		let result = (this.calories / this.weight) * 100;
		if (convertToKilojoule) {
			result = result * kiloCaloriesToKilojouleFactor;
		}
		return result;
	}

	public getNutrientCategoryGroups(): NutrientCategoryGroup[] {
		return this.nutrientList.all
			.groupBy((x) => x.categoryId)
			.map((_, nutrients) => {
				return {
					name: nutrients[0].category!,
					sortOrder: nutrients[0].nutrientCategorySortOrder,
					nutrients: nutrients
						.orderBy((x) => x.nutrient)
						.map((x) => {
							return {
								nutrient: x.nutrient!,
								category: x.category!,
								total: x.total,
								value: 0,
								unit: x.unit!,
								isMacroNutrient: x.isMacroNutrient,
								nutrientCategorySortOrder: x.nutrientCategorySortOrder,
							};
						}),
				};
			})
			.orderBy((x) => [x.sortOrder, x.name]);
	}

	public getMacroNutrientChartData(): DoughnutData[] {
		return this.nutrientList.getMacroNutrientChartData();
	}
}
