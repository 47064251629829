import { wrapProtectedRoute } from 'components/desktop/_general/ProtectedRoute/ProtectedRoute';
import Archive from 'containers/desktop/BaseIngredient/Archive/BaseIngredientArchive';
import Detail from 'containers/desktop/BaseIngredient/Detail/BaseIngredientDetail';
import New from 'containers/desktop/BaseIngredient/New/BaseIngredientNew';
import Search from 'containers/desktop/BaseIngredient/Search/BaseIngredientSearch';
import PERMISSIONS from 'enums/permissions';

const basicResources = [
	'nutrientList',
	'seasons',
	'allergens',
	'tagList',
	'categories',
	'unitList',
];

const routes = [
	{
		path: '/baseIngredient/detail/:id',
		element: <Detail />,
		permissions: [PERMISSIONS.READBASEINGREDIENT],
		resources: basicResources,
	},
	{
		path: '/baseIngredient/search',
		element: <Search />,
		permissions: [PERMISSIONS.READBASEINGREDIENT],
	},
	{
		path: '/baseIngredient/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.WRITEBASEINGREDIENT],
	},
	{
		path: '/baseIngredient/copy/:id',
		element: <Detail />,
		permissions: [PERMISSIONS.WRITEBASEINGREDIENT],
		resources: basicResources,
	},
	{
		path: '/baseIngredient/new',
		element: <New />,
		permissions: [PERMISSIONS.WRITEBASEINGREDIENT],
		resources: basicResources,
	},
];

export default [...routes.map(wrapProtectedRoute)];
