import axios, { AxiosResponse, InternalAxiosRequestConfig } from 'axios';
import i18next from 'i18n';

import { URL_API } from 'config/config';
import { fireAlert } from 'functions/fireAlert';
import { getCultureCodeFromLocalStorage } from 'functions/getCultureCodeFromLocalStorage';
import { getAccessTokenPotentiallyRenewingIt, restart } from 'functions/tokenFunctions';
import IError from 'interfaces/IError';
import IToken from 'interfaces/IToken';

let ErrorNames: string[] = [];

const getHtmlFormattedErrorMessage = (error: IError) => {
	const errorObject = error.response?.data.errors;

	const output: string[] = [];
	for (const key in errorObject) {
		for (const value of errorObject[key]) {
			const formattedValue = value.includes(
				'The JSON value could not be converted to System.Guid'
			)
				? i18next.t('_general:MISSING_VALUE')
				: value;
			output.push(formattedValue);
		}
	}

	return output.join('<br /><br />');
};

const fireApiDetailAlert = (titleKey: string, messageKey: string): void => {
	fireAlert(2, i18next.t(titleKey), i18next.t(messageKey), 10000);
};

const showInputValidation = (error: IError): void => {
	const errorObject = error.response?.data.errors;
	for (const key in errorObject) {
		const elements: any = document.querySelectorAll(`[error-key="${key}"]`);
		for (const element of elements) {
			element.classList.add('is-invalid');
			ErrorNames.push(key);
		}
	}
};

const removeInvalidClasses = () => {
	for (const name of ErrorNames) {
		const elements: any = document.querySelectorAll(`[error-key="${name}"]`);
		for (const element of elements) {
			element.classList.remove('is-invalid');
		}
	}
	ErrorNames = [];
};

function withBearer(config: InternalAxiosRequestConfig, token: IToken) {
	config.headers.Authorization = `Bearer ${token.token}`;
	return config;
}

export const configAxios = () => {
	axios.defaults.baseURL = URL_API;
	axios.defaults.timeout = 30000;
	axios.interceptors.request.use(
		async function (config: InternalAxiosRequestConfig) {
			const accessToken = await getAccessTokenPotentiallyRenewingIt();
			return accessToken.map((token) => withBearer(config, token)).getOrDefault(config);
		},
		function (error) {
			return Promise.reject(error);
		}
	);

	axios.interceptors.request.use(
		async function (config: InternalAxiosRequestConfig) {
			if (config.headers && !config.headers['Accept-Language']) {
				config.headers['Accept-Language'] = getCultureCodeFromLocalStorage() ?? 'de-CH';
			}
			config.headers['Accept'] = 'application/json';
			return config;
		},
		function (error) {
			return Promise.reject(error);
		}
	);

	axios.interceptors.response.use(
		function (response: AxiosResponse) {
			removeInvalidClasses();
			const contentType: String | undefined = response.headers['content-type'];

			if (contentType) {
				if (!contentType.startsWith('application/json')) {
					console.error('the following url did not return json:', response.config.url);
					fireApiDetailAlert(
						'alerts:ALERT_API_ERROR_NO_JSON_TITLE',
						'alerts:ALERT_API_ERROR_NO_JSON_MESSAGE'
					);
					return Promise.reject();
				}
			}

			if ('data' in response && response.status >= 200 && response.status < 300) {
				return response;
			}
			fireApiDetailAlert(
				'alerts:ALERT_API_ERROR_TITLE',
				'alerts:ALERT_API_ERROR_TITLE_MESSAGE'
			);
			return Promise.reject(response);
		},
		async function (error: IError) {
			removeInvalidClasses();
			handleErrorResponse(error);
			return Promise.reject(error);
		}
	);
};

const handleErrorResponse = (error: IError): void => {
	if (!error.response) {
		fireApiDetailAlert('alerts:ALERT_API_NO_NETWORK', 'alerts:ALERT_API_NO_NETWORK_MESSAGE');
		return;
	}

	if (error.response.status === 502 || error.response.status === 503) {
		fireApiDetailAlert(
			'alerts:ALERT_API_ERROR_TITLE',
			'alerts:ALERT_API_SERVICE_NOT_AVAILABLE_MESSAGE'
		);
		return;
	}

	if (error.response.status == 403 && window.location.pathname === '/login') {
		fireApiDetailAlert(
			'alerts:ALERT_API_PERMISSION_DENIED_LOGIN',
			'alerts:ALERT_API_PERMISSION_DENIED_MESSAGE_LOGIN'
		);
		return;
	}

	if (error.response.status == 401 && window.location.pathname === '/login') {
		fireApiDetailAlert(
			'alerts:ALERT_API_PERMISSION_DENIED_LOGIN',
			'alerts:ALERT_API_PERMISSION_DENIED_MESSAGE_LOGIN'
		);
		return;
	}

	switch (error.response.status) {
		case 401:
			restart();
			return;
		case 400:
			fireApiDetailAlert('alerts:ALERT_API_BAD_REQUEST', getHtmlFormattedErrorMessage(error));
			break;
		case 403:
			fireApiDetailAlert(
				'alerts:ALERT_API_PERMISSION_DENIED',
				'alerts:ALERT_API_PERMISSION_DENIED_MESSAGE'
			);
			break;
		case 404:
			fireApiDetailAlert('alerts:ALERT_API_NOT_FOUND', 'alerts:ALERT_API_NOT_FOUND_MESSAGE');
			break;
		case 409:
			fireApiDetailAlert('alerts:ALERT_API_CONFLICT', 'alerts:ALERT_API_CONFLICT_MESSAGE');
			break;
		case 422:
			fireApiDetailAlert(
				'alerts:ALERT_API_UNPROCESSABLE_ENTITY',
				'alerts:ALERT_API_UNPROCESSABLE_ENTITY_MESSAGE'
			);
			break;
		case 500:
			fireApiDetailAlert(
				'alerts:ALERT_API_INTERNAL_SERVER_ERROR',
				'alerts:ALERT_API_INTERNAL_SERVER_ERROR_MESSAGE'
			);
			break;
		case 503:
			fireApiDetailAlert(
				'alerts:ALERT_API_SERVICE_NOT_AVAILABLE',
				'alerts:ALERT_API_SERVICE_NOT_AVAILABLE_MESSAGE'
			);
			break;
		default:
			fireApiDetailAlert(
				'alerts:ALERT_API_ERROR_TITLE',
				'alerts:ALERT_API_ERROR_TITLE_MESSAGE'
			);
	}
	showInputValidation(error);
};
