import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faBarcode } from '@fortawesome/free-solid-svg-icons';
import { faDollarSign, faWeightHanging } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useSelector } from 'react-redux';

import 'components/desktop/_general/Card/ProductCard/ProductCard.scss';
import ImageWithFallback from 'components/desktop/_general/Image/ImageWithFallback';
import { getPriceString } from 'functions/numberToString';
import { RootState } from 'reducers/rootReducer';
import * as RecipeProduct from 'types/Product/RecipeProduct';

interface IProps {
	product: RecipeProduct.Type;
	hideQuantity?: boolean;
}

const ProductCard = (props: IProps) => {
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const reduxCurrency: string = useSelector((state: RootState) => state.currency);

	const getInfos = (): JSX.Element => {
		return (
			<>
				<p>
					<span id="icon">
						<FontAwesomeIcon icon={faDollarSign as IconProp} />
					</span>
					<b>
						{props.product.price
							? getPriceString(props.product.price, reduxCultureCode)
							: '-'}
					</b>
					{' ' + reduxCurrency}
					&nbsp;
				</p>
				{!props.hideQuantity && (
					<p>
						<span id="icon">
							<FontAwesomeIcon icon={faWeightHanging as IconProp} />
						</span>
						{props.product.quantity ? ' ' + props.product.quantity : '–'}
					</p>
				)}
				<p style={{ marginBottom: '-8px' }}>
					<span id="icon" style={{ marginRight: '-7px' }}>
						<FontAwesomeIcon icon={faBarcode as IconProp} />
					</span>
					<span style={{ color: '#aaa', fontFamily: 'monospace' }}>
						{props.product.externalId ? ' ' + props.product.externalId : '–'}
					</span>
				</p>
			</>
		);
	};

	const renderContent = () => {
		return (
			<a href={props.product.externalUrl!} target="_blank" className="unstyled-link">
				<div className="card product-card">
					<div className="card-body">
						{!props.product.imageUrl ? (
							<div className="card-picture-placeholder d-flex justify-content-center align-items-center">
								<FontAwesomeIcon size="3x" icon={faCamera} />
							</div>
						) : (
							<ImageWithFallback src={props.product.imageUrl} />
						)}
						<div>
							<div id="title">
								<strong>{props.product.name}</strong>
							</div>
							<div id="info">{getInfos()}</div>
						</div>
					</div>
				</div>
			</a>
		);
	};

	return <>{renderContent()}</>;
};

export default ProductCard;
