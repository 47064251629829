import * as apiPostRecipeAnalyseV1 from 'api/recipe/PostRecipeAnalyseV1';
import { mapIngredientTypeFromString } from 'enums/ingredientType';
import { mapMatchStatusFromString } from 'enums/matchStatus';
import { SegmentHighlight } from 'types/Recipe/SegmentHighlight';
import { SegmentIngredient } from 'types/Recipe/SegmentIngredient';

import { SegmentStatus } from './SegmentStatus';

export type Segment = {
	index: number;
	ingredients: SegmentIngredient[];
	instruction: string;
	name: string;
	highlightings: SegmentHighlight[];
};

export const mapApiSegmentToSegment = (s: apiPostRecipeAnalyseV1.Segments): Segment => {
	return {
		highlightings: s.highlightings.map(mapApiHighlightsToHighlights),
		index: s.index,
		ingredients: s.ingredients.map(mapApiIngredientToIngredient),
		instruction: s.instruction || '',
		name: s.name || '',
	};
};

function mapApiHighlightsToHighlights(
	value: apiPostRecipeAnalyseV1.Highlightings
): SegmentHighlight {
	return {
		isListed: value.isListed,
		position: value.position,
		type: mapIngredientTypeFromString(value.type),
		word: value.word,
	};
}
function mapApiIngredientToIngredient(x: apiPostRecipeAnalyseV1.Ingredients): SegmentIngredient {
	return {
		additionAfter: x.additionAfter,
		additionBefore: x.additionBefore,
		errorActions: x.errorActions,
		errorMessages: x.errorMessages,
		ingredient: x.ingredient,
		ingredientId: x.ingredientId,
		isFluid: x.isFluid,
		scalingFactor: x.scalingFactor,
		maxQuantity: x.maxQuantity,
		quantity: x.quantity,
		quantityText: x.quantityText,
		status: mapApiStatusToStatus(x.status),
		type: mapIngredientTypeFromString(x.type),
		unit: x.unit,
		unitId: x.unitId,
		productId: null,
	};
}
function mapApiStatusToStatus(x: apiPostRecipeAnalyseV1.Status): SegmentStatus {
	return {
		ingredient: mapMatchStatusFromString(x.ingredient),
		quantity: mapMatchStatusFromString(x.quantity),
		unit: mapMatchStatusFromString(x.unit),
	};
}
