import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export type AirUrlParams = { [key: string]: string };

export class AirUrlParamsClass {
	public params: AirUrlParams;
	public uri: string | undefined;

	static From(url: string) {
		return new AirUrlParamsClass({}, url);
	}
	constructor(input: AirUrlParams, uri?: string) {
		this.params = input;
		this.uri = uri;
	}

	toString(): string {
		return JSON.stringify(this);
	}

	get(key: string): string {
		const params = { ...this.params };
		return params[key];
	}

	delete(key: string): AirUrlParamsClass {
		const newParams = { ...this.params };
		delete newParams[key];
		return new AirUrlParamsClass(newParams, this.uri);
	}

	add(key: string, value: string): AirUrlParamsClass {
		const newParams = { ...this.params };
		newParams[key] = value;
		return new AirUrlParamsClass(newParams, this.uri);
	}

	addUri(uri: string): AirUrlParamsClass {
		return new AirUrlParamsClass(this.params, uri);
	}

	addIf(key: string, value: string, condition: boolean): AirUrlParamsClass {
		if (condition) {
			const newParams = { ...this.params };
			newParams[key] = value;
			return new AirUrlParamsClass(newParams, this.uri);
		}
		return new AirUrlParamsClass(this.params, this.uri);
	}

	addNullable(key: string, value: number | string | null): AirUrlParamsClass {
		if (value) {
			const newParams = { ...this.params };
			newParams[key] = String(value);
			return new AirUrlParamsClass(newParams, this.uri);
		}
		return new AirUrlParamsClass(this.params, this.uri);
	}

	addMultiple(input: AirUrlParams): AirUrlParamsClass {
		return new AirUrlParamsClass({ ...this.params, ...input }, this.uri);
	}

	toUri() {
		const url = new URLSearchParams();
		for (const [key, value] of Object.entries(this.params)) {
			url.set(key, value);
		}
		return `${this.uri}?${url.toString()}`;
	}

	public static fromUri(uri: string) {
		return new AirUrlParamsClass({}, uri);
	}
}

export default function useAirNavigation() {
	const navigate = useNavigate();
	const [params] = useSearchParams();
	const location = useLocation();

	const entries: AirUrlParams = {};
	for (const [key, value] of params.entries()) {
		entries[key] = value;
	}

	return {
		params: new AirUrlParamsClass(entries),
		navigate: (urlParams: AirUrlParamsClass) => {
			const url = new URLSearchParams();
			for (const [key, value] of Object.entries(urlParams.params)) {
				url.set(key, value);
			}

			if (urlParams.uri) {
				navigate(`${urlParams.uri}?${url.toString()}`);
			} else {
				navigate(`${location.pathname}?${url.toString()}`);
			}
		},
	};
}

export function uriToAirUrl(input: string): AirUrlParamsClass {
	return new AirUrlParamsClass({}, input);
}
