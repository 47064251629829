import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as apiGet from 'api/activity/GetActivityIdV1';
import Activity from 'classes/StandingData/Activity/Activity';
import ActivityLanguageInput from 'components/desktop/StandingData/Activity/LanguageInput/ActivityLanguageInput';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import clone from 'functions/clone';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import { RootState } from 'reducers/rootReducer';

const ActivityDetailComponent = () => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
	const [selectedCultureCode, setSelectedCultureCode] = useState<string>('');

	const [activity, setActivity] = useState<Activity>(new Activity());
	const [id, setId] = useState<string | null>(null);

	useEffect(() => {
		initialize();
		setSelectedCultureCode(reduxCultureCode);
	}, []);

	const initialize = async (): Promise<void> => {
		const id: string | null = getIdFromUrl();
		setId(id);
		if (id !== null && id !== 'new') {
			getActivity(id);
		}
	};

	const getActivity = async (id: string): Promise<void> => {
		setIsLoading(true);
		const response = await apiGet.callApi(id);
		response.hasValue() && setActivity(new Activity(response.get()));
		setIsLoading(false);
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string) => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSaveActivity = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			if (id !== null && id !== 'new') {
				await activity.callApiPut();
			} else {
				await activity.callApiPost();
				if (!activity.id) return;
				setId(activity.id);
				setIdToUrl(activity.id);
			}
		} catch {
			setIsLoadingSave(false);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const handleSortOrderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		activity.sortOrder = Number(e.currentTarget.value);
		setActivity(clone(activity));
	};

	const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		activity.nameTranslations[selectedCultureCode] = e.currentTarget.value;
		setActivity(clone(activity));
	};

	const handleTranslate = async () => {
		setIsLoading(true);
		await activity.callApiTranslation(reduxCultureCode, selectedCultureCode);
		setActivity(clone(activity));
		setIsLoading(false);
	};

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={isLoading} />
				<div className="row">
					<div className="d-flex flex-row justify-content-between mb-3">
						<div>
							{activity.id ? (
								<h1>{t('standingData:EDIT_ACTIVITY')}</h1>
							) : (
								<h1>{t('standingData:NEW_ACTIVITY')}</h1>
							)}
						</div>
						<div>
							<BtnSave
								handleSave={() => handleSaveActivity()}
								isLoading={isLoadingSave}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4 col-xl-3 col-lg-3 col-sm-4 col-12">
						<label className="form-control-label" htmlFor="sortOrder">
							{t('standingData:SORT_ORDER')}
						</label>
						<input
							value={activity.sortOrder}
							type="number"
							onChange={handleSortOrderChange}
							className="form-control"
							name="sortOrder"
							id="sortOrder"
							error-key={'SortOrder'}
						/>
					</div>
					<div className="col-md-8 col-xl-9 col-sm-8 col-12">
						<LanguageSwitch
							handleSelectLanguage={handleSelectLanguage}
							selectedLanguage={selectedCultureCode}
							translatable={true}
							onTranslateClick={handleTranslate}
							entryLanguage={reduxCultureCode}
							content={
								<ActivityLanguageInput
									activity={activity}
									selectedLanguage={selectedCultureCode}
									handleValueChange={handleInputValueChange}
								/>
							}
						/>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default ActivityDetailComponent;
