import { NutrientValue } from 'types/NutrientValue/NutrientValue';

export type Type = NutrientValue[];

export function create(): Type {
	return [];
}

export function getById(obj: Type, id: string): NutrientValue | undefined {
	return obj.find((e) => e.id === id);
}

export function mergeTwoArrays(first: Type, second: Type): Type {
	const output = [...first];
	for (const i of second) {
		if (!getById(output, i.id)) output.push(i);
	}
	return output;
}
