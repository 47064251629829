import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as apiGet from 'api/nutrientCategory/GetNutrientCategoryIdV1';
import NutrientCategory from 'classes/StandingData/NutrientCategory/NutrientCategory';
import NutrientCategoryList from 'classes/StandingData/NutrientCategory/Search/NutrientCategoryList';
import NutrientCategoryLanguageInput from 'components/desktop/StandingData/NutrientCategory/LanguageInput/NutrientCategoryLanguageInput';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import SelectSimple, {
	SelectOption,
} from 'components/desktop/_general/Select/SelectSimple/SelectSimple';
import clone from 'functions/clone';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import isNumberValid from 'functions/validation/isNumberValid';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';

const NutrientCategoryDetailComponent = () => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
	const [selectedCultureCode, setSelectedCultureCode] = useState<string>('');

	const [id, setId] = useState<string | null>(null);
	const [parentList, setParentList] = useState<NutrientCategoryList>(new NutrientCategoryList());
	const [nutrientCategory, setNutrientCategory] = useState<NutrientCategory>(
		new NutrientCategory()
	);

	useEffect(() => {
		initialize();
		setSelectedCultureCode(reduxCultureCode);
	}, []);

	const initialize = async (): Promise<void> => {
		const id: string | null = getIdFromUrl();
		setId(id);
		getParents();
		if (id !== null && id !== 'new') {
			getNutrientCategory(id);
		}
	};

	const getParents = async (): Promise<void> => {
		await parentList.search('', false);
		setParentList(clone(parentList));
	};

	const getNutrientCategory = async (id: string): Promise<void> => {
		setIsLoading(true);
		const response = await apiGet.callApi(id);
		response.do((x) => setNutrientCategory(new NutrientCategory(x)));
		setIsLoading(false);
	};

	const handleTranslate = async () => {
		setIsLoading(true);
		await nutrientCategory.callApiTranslation(reduxCultureCode, selectedCultureCode);
		setNutrientCategory(clone(nutrientCategory));
		setIsLoading(false);
	};

	const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		nutrientCategory.nameTranslations[selectedCultureCode] = e.target.value;
		setNutrientCategory(clone(nutrientCategory));
	};

	const handleParentChange = (parentId: string): void => {
		nutrientCategory.parentId = parentId;
		setNutrientCategory(clone(nutrientCategory));
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string): void => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSaveNutrientCategory = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			if (id !== null && id !== 'new') {
				await nutrientCategory.callApiPut();
			} else {
				await nutrientCategory.callApiPost();
				if (!nutrientCategory.id) return;
				setId(nutrientCategory.id);
				setIdToUrl(nutrientCategory.id);
			}
		} catch {
			setIsLoadingSave(false);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const getParentOptions = (): SelectOption[] => {
		const output: SelectOption[] = [];
		output.push({ id: '', text: t('standingData:NOTHING_SELECTED') });
		parentList.filtered.forEach((parent: ISelectItem) => {
			output.push({ id: parent.id, text: parent.name });
		});
		return output;
	};

	const handleSortOrderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const value: string = e.currentTarget.value;
		if (isNumberValid(value)) {
			nutrientCategory.sortOrder = Number(value);
			setNutrientCategory(clone(nutrientCategory));
		}
	};

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={isLoading} />
				<div className="row">
					<div
						className="d-flex flex-row justify-content-between mb-3"
						style={{ gap: '70px' }}
					>
						<div>
							{' '}
							{nutrientCategory.id ? (
								<>
									<h1>{t('standingData:EDIT_NUTRIENTCATEGORY')}</h1>
								</>
							) : (
								<>
									<h1>{t('standingData:NEW_NUTRIENTCATEGORY')}</h1>
								</>
							)}
						</div>
						<div>
							<BtnSave
								handleSave={() => handleSaveNutrientCategory()}
								isLoading={isLoadingSave}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4 col-xl-3 col-lg-3 col-sm-4 col-12">
						<label>{t('standingData:PARENT')}:</label>
						<SelectSimple
							options={getParentOptions()}
							currentValue={nutrientCategory.parentId}
							handleValueChange={handleParentChange}
							errorKey="Parent"
						/>
						<label>{t('standingData:SORT_ORDER')}</label>
						<input
							className={'form-control'}
							id="sortOrder"
							value={String(nutrientCategory.sortOrder)}
							type="number"
							onChange={handleSortOrderChange}
							error-key={'SortOrder'}
						/>
					</div>
					<div className="col-mg-8 col-xl-9 col-sm-8 col-12">
						<LanguageSwitch
							handleSelectLanguage={handleSelectLanguage}
							selectedLanguage={selectedCultureCode}
							translatable={true}
							onTranslateClick={handleTranslate}
							entryLanguage={reduxCultureCode}
							content={
								<NutrientCategoryLanguageInput
									nutrientCategory={nutrientCategory}
									handleValueChange={handleValueChange}
									selectedLanguage={selectedCultureCode}
								/>
							}
						/>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default NutrientCategoryDetailComponent;
