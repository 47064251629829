import axios from 'axios';

import { AirUrlParamsClass } from 'hooks/useAirNavigation';

export async function callApi(request: Request): Promise<Match[]> {
	const params = AirUrlParamsClass.fromUri('/ai-service/match/product/')
		.addNullable('IngredientId', request.ingredientId)
		.addNullable('IngredientName', request.itemName)
		.addNullable('MaxNrOfRecommendations', request.nrOfMatches)
		.addNullable('CultureCode', request.cultureCode);

	const result = await axios.get(params.toUri()).toOptionalMapped((x) => x.data);

	return result
		.filter((x) => x.code == 200)
		.map((x) => x.result)
		.getOrDefault([])
		.map((e: any) => {
			return { ...e, id: e.Id };
		});
}

export interface Request {
	cultureCode: string | null;
	ingredientId: string | null;
	nrOfMatches: number | null;
	itemName: string | null;
}

type NutrientValue = {
	nutrientId: string;
	nutrient: string;
	categoryId: string;
	category: string;
	total: number;
	unitId: string;
	unit: string;
	isMacroNutrient: boolean;
	source: string;
	nutrientCategorySortOrder: number;
};

export type Match = {
	id: string;
	category: string[];
	similarity: number;
	externalId: string;
	brand: string;
	price: number;
	imageUrl: string;
	externalUrl: string;
	name: string;
	servingSizeQuantityGram: number;
	nutrientValuesPer100g: NutrientValue[];
	nutrientValuesPerServing: NutrientValue[];
};
