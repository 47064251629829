import { t } from 'i18next';

import { Recipe } from 'api/menu/GetMenuIdV1';
import SelectSimple from 'components/desktop/_general/Select/SelectSimple/SelectSimple';
import { Menu, Status } from 'types/Menu/Menu';
import { StatusLight } from 'types/Status/StatusLight';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';

interface IProps {
	handleSetStatus: (status: Status) => void;
	handleSetSystemStatus: (status: SystemStatus) => void;
	object: Recipe | Menu;
	statusList: Status[];
	disabled?: boolean;
	errorKey?: string;
}

const systemStatusList: StatusLight[] = [
	{
		id: '0',
		name: 'New',
	},
	{
		id: '1',
		name: 'Published',
	},
];

const StatusEditor = (props: IProps) => {
	const handleStatusChange = (id: string): void => {
		const status: Status | undefined = props.statusList.find((e: Status) => {
			return e.id === id;
		});
		if (status) props.handleSetStatus(status);
	};

	const handleSystemStatusChange = (id: string): void => {
		const status: StatusLight | undefined = systemStatusList.find((e: StatusLight) => {
			return e.id === id;
		});
		if (status) props.handleSetSystemStatus(status.name as SystemStatus);
	};

	const getSystemStatusIdFromMenu = (): StatusLight | undefined => {
		return systemStatusList.find((e: StatusLight) => {
			if ('systemStatus' in props.object) {
				return e.name === props.object.systemStatus;
			}
		});
	};

	const renderContent = () => {
		return (
			<>
				<div className="form-group">
					<label>{t('recipe:STATUS')}</label>
					<SelectSimple
						options={props.statusList.map((status: Status) => ({
							id: status.id,
							text: status.name!,
						}))}
						currentValue={props.object.status?.id ?? null}
						handleValueChange={handleStatusChange}
						disabled={props.disabled}
						errorKey={props.errorKey || 'Status'}
					/>
				</div>
				{'systemStatus' in props.object && (
					<div className="form-group">
						<label>{t('recipe:SYSTEM_STATUS')}</label>
						<SelectSimple
							options={systemStatusList.map((status: StatusLight) => ({
								id: status.id,
								text: t('systemStatus:' + status.name),
							}))}
							currentValue={getSystemStatusIdFromMenu()?.id ?? null}
							handleValueChange={handleSystemStatusChange}
							disabled={props.disabled}
							errorKey="SystemStatus"
						/>
					</div>
				)}
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default StatusEditor;
