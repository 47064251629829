import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMicrochipAi } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';

interface IProps {
	setDisplayOverlay: (input: boolean) => void;
}

const BtnGenerateImage = (props: IProps) => {
	return (
		<div>
			<label className="form-label">{t('_general:GENERATE_PICTURE')}</label>
			<button
				type="button"
				className="btn btn-primary"
				style={{ width: '100%' }}
				onClick={() => props.setDisplayOverlay(true)}
			>
				<FontAwesomeIcon icon={faMicrochipAi as IconProp} />
				<span style={{ paddingLeft: '6px' }}>{t('_general:GENERATE_PICTURE')}</span>
			</button>
		</div>
	);
};

export default BtnGenerateImage;
