import { faEmptySet } from '@fortawesome/pro-duotone-svg-icons';
import { faArrowRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from 'react-i18next';

import * as apiGetNutrient from 'api/nutrient/GetNutrientV1';
import IngredientSearch from 'components/desktop/Recipe/RecipeTabs/Components/RecipeErpView/IngredientSearch/IngredientSearch';
import 'components/desktop/Recipe/RecipeTabs/Components/RecipeErpView/RecipeErpView.scss';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import { ImageWithFallback } from 'components/desktop/_general/ImageWithFallback/ImageWithFallback';
import COLOR from 'enums/COLOR';
import { ValueScope } from 'enums/valueScope';
import * as Match from 'types/Recipe/Detail/Product';
import { SegmentIngredient } from 'types/Recipe/SegmentIngredient';
import Style from 'types/_general/Style';

type IProps = {
	nutrients: apiGetNutrient.Nutrient[];
	ingredient: SegmentIngredient;
	product: Match.Type | null;
	valueScope: ValueScope;
	selectProduct: (input: { ingredientId: string; product: Match.Type }) => void;
	deSelectProduct: (ingredientId: string) => void;
	selectedColumns: string[];
};

const ProductAccordionItem = (props: IProps) => {
	const { t } = useTranslation();

	return (
		<>
			<Accordion.Header>
				<RenderIf condition={Boolean(props.product)}>
					<ImageWithFallback
						src={props.product?.imageUrl}
						className="image-with-fallback"
						style={{
							...style.image,
							objectFit: 'contain',
						}}
					/>
				</RenderIf>
				<RenderIf condition={!props.product}>
					<div
						style={{
							...style.image,
							backgroundColor: '#eee',
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
						}}
					>
						<FontAwesomeIcon icon={faEmptySet} size={'lg'} style={{ opacity: '0.2' }} />
					</div>
				</RenderIf>
				{props.ingredient.ingredient}
				<FontAwesomeIcon
					icon={faArrowRight}
					style={{ marginRight: '7px', marginLeft: '7px' }}
				/>
				{props.product ? (
					<span style={{ color: COLOR.PRIMARY }}>{Match.toString(props.product)}</span>
				) : (
					<span style={{ color: 'grey' }}>{t('_general:NO_PRODUCT_ASSIGNED')}</span>
				)}
			</Accordion.Header>
			<Accordion.Body style={{ border: '5px solid #e0ebf5', borderTop: '0' }}>
				<IngredientSearch {...props} />
			</Accordion.Body>
		</>
	);
};

export default ProductAccordionItem;

const style: Style = {
	image: {
		width: '44px',
		height: '44px',
		marginTop: '-12px',
		marginBottom: '-12px',
		marginLeft: '-16px',
		marginRight: '8px',
		border: '1px solid #ccc',
	},
};
