import { ReducerBuilder } from 'reducers/ReducerBuilder';
import { payloadReducer } from 'reducers/reducerUtils';
import * as SeasonList from 'types/_general/Store/SeasonList';

const rb = new ReducerBuilder<SeasonList.Type>();
export const createSeasonLoadedAction = rb.createActionForReducer(
	payloadReducer((x) => x),
	'Seasons/LoadedFromBackend'
);

export const seasonsReducer = rb.createReducer();
