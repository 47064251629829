import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as apiGet from 'api/timeUnit/GetTimeUnitIdV1';
import TimeUnit from 'classes/StandingData/TimeUnit/TimeUnit';
import TimeUnitLanguageInput from 'components/desktop/StandingData/TimeUnit/LanguageInput/TimeUnitLanguageInput';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import clone from 'functions/clone';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import { RootState } from 'reducers/rootReducer';

const TimeUnitDetail = () => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
	const [selectedCultureCode, setSelectedCultureCode] = useState<string>('');

	const [timeUnit, setTimeUnit] = useState<TimeUnit>(new TimeUnit());
	const [id, setId] = useState<string | null>(null);

	useEffect(() => {
		initialize();
		setSelectedCultureCode(reduxCultureCode);
	}, []);

	const initialize = async (): Promise<void> => {
		const id: string | null = getIdFromUrl();
		setId(id);
		if (id !== null && id !== 'new') {
			getTimeUnit(id);
		}
	};

	const getTimeUnit = async (id: string): Promise<void> => {
		setIsLoading(true);
		try {
			const response = await apiGet.callApi(id);
			response.do((x) => setTimeUnit(new TimeUnit(x)));
		} finally {
			setIsLoading(false);
		}
	};

	const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.target.name;
		const value = e.target.value;
		switch (name) {
			case 'descriptionSingularTranslations':
				timeUnit.nameSingularTranslations[selectedCultureCode] = value;
				break;
			case 'descriptionPluralTranslations':
				timeUnit.namePluralTranslations[selectedCultureCode] = value;
				break;
			case 'shortDescriptionSingularTranslations':
				timeUnit.shortNameSingularTranslations[selectedCultureCode] = value;
				break;
			case 'shortDescriptionPluralTranslations':
				timeUnit.shortNamePluralTranslations[selectedCultureCode] = value;
				break;
		}
		setTimeUnit(clone(timeUnit));
	};

	const handleTranslate = async () => {
		setIsLoading(true);
		await timeUnit.callApiTranslation(reduxCultureCode, selectedCultureCode);
		setTimeUnit(clone(timeUnit));
		setIsLoading(false);
	};

	const handleChangeFactorToSecond = (e: React.ChangeEvent<HTMLInputElement>) => {
		timeUnit.factorToSecond = Number(e.currentTarget.value);
		setTimeUnit(clone(timeUnit));
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string) => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSaveTimeUnit = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			if (id !== null && id !== 'new') {
				await timeUnit.callApiPut();
			} else {
				await timeUnit.callApiPost();
				if (!timeUnit.id) return;
				setId(timeUnit.id);
				setIdToUrl(timeUnit.id);
			}
		} catch {
			setIsLoadingSave(false);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={isLoading} />
				<div className="row">
					<div className="d-flex flex-row justify-content-between mb-3">
						<div>
							{timeUnit.id ? (
								<>
									<h1>{t('standingData:EDIT_TIMEUNIT')}</h1>
								</>
							) : (
								<>
									<h1>{t('standingData:NEW_TIMEUNIT')}</h1>
								</>
							)}
						</div>
						<div>
							<BtnSave
								handleSave={() => handleSaveTimeUnit()}
								isLoading={isLoadingSave}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4 col-xl-3 col-lg-3 col-sm-4 col-12">
						<label>{t('_general:FACTOR_TO_SECOND')}</label>
						<input
							value={timeUnit.factorToSecond}
							type="number"
							onChange={handleChangeFactorToSecond}
							className="form-control"
							name="factorToSecond"
							id="factorToSecond"
							error-key={'FactorToSecond'}
						/>
					</div>
					<div className="col-mg-7 col-xl-8 col-sm-7 col-12">
						<LanguageSwitch
							handleSelectLanguage={handleSelectLanguage}
							selectedLanguage={selectedCultureCode}
							translatable={true}
							onTranslateClick={handleTranslate}
							entryLanguage={reduxCultureCode}
							content={
								<TimeUnitLanguageInput
									timeUnit={timeUnit}
									selectedLanguage={selectedCultureCode}
									handleValueChange={handleInputValueChange}
								/>
							}
						/>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default TimeUnitDetail;
