import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
	id: string;
	onPlusClick: (id: string) => void;
}

const InputPlusButton = (props: IProps) => {
	return (
		<span
			className="input-group-text input-label-field-button btn-primary"
			id="basic-addon1"
			onClick={() => props.onPlusClick(props.id)}
		>
			<FontAwesomeIcon icon={faPlus} />
		</span>
	);
};

export default InputPlusButton;
