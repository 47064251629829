import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faUtensils } from '@fortawesome/pro-duotone-svg-icons';
import { faDollarSign } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Recipe, Status } from 'api/menu/GetMenuIdV1';
import * as apiPostCalculate from 'api/menuPlanner/PostMenuPlannerCalculateV1';
import StatusEditor from 'components/desktop/Menu/Sidebar/StatusEditor/StatusEditor';
import TagEditor from 'components/desktop/Menu/Sidebar/TagEditor/TagEditor';
import FoldableCard from 'components/desktop/_general/Card/FoldableCard/FoldableCard';
import LabeledCheckbox from 'components/desktop/_general/Checkbox/LabeledCheckbox';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import ListItem from 'components/desktop/_general/ListItem/ListItem';
import NutriScoreSelect from 'components/desktop/_general/NutriScoreSelect/NutriScoreSelect';
import NutritionDiagram from 'components/desktop/_general/NutritionDiagram/NutritionDiagram';
import ENTITLEMENTS from 'enums/entitlements';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import PERMISSIONS from 'enums/permissions';
import { ValueScope } from 'enums/valueScope';
import { getNumberString, getPriceString } from 'functions/numberToString';
import { Optional } from 'functions/promiseExtensions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { getValueScopeLabel } from 'functions/valueScopeConversion';
import { RootState } from 'reducers/rootReducer';
import { AllergenLight } from 'types/Allergen/AllergenLight';
import { Menu } from 'types/Menu/Menu';
import { SeasonLight } from 'types/Season/SeasonLight';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';
import { TagLight } from 'types/Tag/TagLight';
import * as UserSettings from 'types/User/UserProfile/UserSettings';

interface IProps {
	displayMenu: boolean;
	menu: Menu;
	selectedRecipe: Recipe;
	setDisplayMenu: (input: boolean) => void;
	handleSetHasNutriScore: (selectedRecipeId: string, input: boolean) => void;
	handleSetNutriScoreCategory: (selectedRecipeId: string, input: NutriScoreCategory) => void;
	handleSetStatus: (status: Status) => void;
	handleSetTags: (tags: TagLight[]) => void;
	handleSetSystemStatus: (status: SystemStatus) => void;
	statusList: Status[];
	tagList: TagLight[];
	calculation: Optional<apiPostCalculate.Data>;
}

const MenuSidebar = (props: IProps) => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const reduxCurrency: string = useSelector((state: RootState) => state.currency);
	const menuEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITEMENU]);
	const recipeEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITERECIPE]);

	const reduxUserSetting: UserSettings.Type = useSelector(
		(state: RootState) => state.userSettings
	);
	const displayRecipe = (): boolean => {
		if (props.selectedRecipe && !props.displayMenu) {
			return true;
		} else {
			return false;
		}
	};

	const getValueScope = (): string => {
		const valueScope = reduxUserSetting['Recipe.List.ValueScope'] as ValueScope;
		return getValueScopeLabel(valueScope ?? null, null, true);
	};

	const renderCalories = (calories: number) => {
		return (
			<p>
				<span id="icon" style={{ marginRight: '10px' }}>
					<FontAwesomeIcon icon={faUtensils as IconProp} />
				</span>
				<b>{` ${getNumberString(calories, reduxCultureCode, true)}`}</b>
				{` ${t('_general:CALORIES')}${getValueScope()}`}
			</p>
		);
	};

	const getCalories = (): JSX.Element => {
		if (displayRecipe()) {
			return renderPrice(props.selectedRecipe.price);
		} else {
			return props.calculation
				.keep((x) => x.calorieSum)
				.map(renderCalories)
				.getOrDefault(<></>);
		}
	};
	const getCosts = (): JSX.Element => {
		if (displayRecipe()) {
			return renderPrice(props.selectedRecipe.price);
		} else {
			return props.calculation
				.map((x) => x.priceSum)
				.map(renderPrice)
				.getOrDefault(<></>);
		}
	};

	const getAllergens = (): JSX.Element => {
		if (displayRecipe()) {
			if (props.selectedRecipe.allergens.length) {
				return (
					<div className="overflow-auto">
						{props.selectedRecipe.allergens.map(
							(allergen: AllergenLight, i: number) => (
								<ListItem
									iconSvgUrl={allergen.iconSvgUrl}
									key={i}
									text={allergen.name}
									textColor={
										allergen.logic === 'Positive' ? '#e35359' : undefined
									}
								/>
							)
						)}
					</div>
				);
			} else {
				return <span className="grey">{t('_general:NO_ALLERGENS')}</span>;
			}
		} else {
			return props.calculation
				.flatMap((x) => x.allergens)
				.map((allergen: any, i: number) => (
					<ListItem
						iconSvgUrl={allergen.iconSvgUrl}
						key={i}
						text={allergen.name}
						textColor={allergen.logic === 'Positive' ? '#e35359' : undefined}
					/>
				))
				.wrap(
					(x) => <div className="overflow-auto">{x}</div>,
					() => <span className="grey">{t('_general:NO_ALLERGENS')}</span>
				);
		}
	};

	const getSeasons = (): JSX.Element => {
		if (displayRecipe()) {
			if (props.selectedRecipe.seasons.length) {
				return (
					<div className="overlow-auto">
						{props.selectedRecipe.seasons
							.filter((x) => x.iconSvgUrl != null)
							.map((season: SeasonLight, i: number) => (
								<ListItem
									key={i}
									text={season.name}
									iconSvgUrl={season.iconSvgUrl}
								/>
							))}
					</div>
				);
			} else {
				return <span className="grey">{t('_general:NO_SEASONS')}</span>;
			}
		} else {
			// season: SeasonMappingDto
			return props.calculation
				.flatMap((x) => x.seasons)
				.map((season: any, i: number) => (
					<ListItem key={i} text={season.name} iconSvgUrl={season.iconSvgUrl} />
				))
				.wrap(
					(x) => <div className="overflow-auto">{x}</div>,
					() => <span className="grey">{t('_general:NO_SEASONS')}</span>
				);
		}
	};

	const getMacroNutrientChartData = (): { label: string; value: number }[] => {
		if (displayRecipe()) {
			if (props.selectedRecipe.nutrientValues) {
				const macroWeight = props.selectedRecipe.nutrientValues
					.filter((n) => n.isMacroNutrient)
					.reduce((p, c) => p + c.total, 0);
				const macros = props.selectedRecipe.nutrientValues
					.filter((n) => n.isMacroNutrient)
					.map((n) => {
						return {
							label: n.nutrient,
							value: n.total / macroWeight,
						};
					});
				return macros;
			}
			return [];
		} else {
			if (props.calculation.hasValue()) {
				const macroWeight = props.calculation
					.flatMap((x) => x.nutrientValues)
					.filter((n) => n.isMacroNutrient)
					.reduce((p, c) => p + c.total, 0);
				const macros = props.calculation
					.flatMap((x) => x.nutrientValues)
					.filter((n) => n.isMacroNutrient)
					.map((n) => {
						return {
							label: n.nutrient,
							value: n.total / macroWeight,
						};
					});
				return macros;
			}
			return [];
		}
	};

	const renderPrice = (input: number | null): JSX.Element => {
		if (input) {
			return (
				<p>
					<span id="icon" style={{ marginRight: '10px' }}>
						<FontAwesomeIcon icon={faDollarSign as IconProp} />
					</span>
					<b>{getPriceString(input, reduxCultureCode)}</b>
					{` ${reduxCurrency} ${getValueScope()}`}
				</p>
			);
		} else {
			return <span className="grey">{t('recipe:NO_PRICE')}</span>;
		}
	};

	const renderContent = () => {
		return (
			<>
				<LabeledCheckbox
					label={t('menu:DISPLAY_MENU_DETAILS')}
					checked={props.displayMenu}
					setChecked={props.setDisplayMenu}
					disabled={props.selectedRecipe.id ? false : true}
				/>
				<FoldableCard
					defaultOpen={true}
					title={t('recipe:STATUS')}
					content={
						<>
							<div className="card-body">
								<StatusEditor
									disabled={displayRecipe() ? !recipeEditor : !menuEditor}
									handleSetStatus={props.handleSetStatus}
									errorKey="$.statusId"
									handleSetSystemStatus={props.handleSetSystemStatus}
									object={displayRecipe() ? props.selectedRecipe : props.menu}
									statusList={props.statusList}
								/>
							</div>
						</>
					}
					defaultLocked={displayRecipe()}
					hasLock={false}
					unlockable={!displayRecipe() && menuEditor}
				/>
				<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>
					<FoldableCard
						defaultOpen={true}
						title={t('_general:COSTS')}
						content={getCosts()}
						defaultLocked={displayRecipe()}
						hasLock={displayRecipe()}
						unlockable={false}
					/>
				</RenderIf>
				<FoldableCard
					defaultOpen={true}
					title={t('_general:CALORIES')}
					content={getCalories()}
					defaultLocked={displayRecipe()}
					hasLock={displayRecipe()}
					unlockable={false}
				/>

				<FoldableCard
					defaultOpen={true}
					title={t('_general:TAGS')}
					content={
						<>
							<div className="card-body">
								<TagEditor
									handleSetTags={props.handleSetTags}
									object={displayRecipe() ? props.selectedRecipe : props.menu}
									tagList={props.tagList}
									disabled={displayRecipe() ? !recipeEditor : !menuEditor}
								/>
							</div>
						</>
					}
					defaultLocked={displayRecipe()}
					hasLock={false}
					unlockable={!displayRecipe() && menuEditor}
				/>
				<FoldableCard
					content={getAllergens()}
					defaultOpen={true}
					info={'_general:ALLERGEN_INFO_TOOLTIP'}
					title={t('_general:ALLERGENS')}
					defaultLocked={displayRecipe()}
					hasLock={displayRecipe()}
					unlockable={false}
				/>
				<RenderIf entitlements={[ENTITLEMENTS.SEASON]}>
					<FoldableCard
						content={getSeasons()}
						defaultOpen={true}
						title={t('_general:SEASONS')}
						defaultLocked={displayRecipe()}
						hasLock={displayRecipe()}
						unlockable={false}
					/>
				</RenderIf>
				{displayRecipe() && (
					<RenderIf entitlements={[ENTITLEMENTS.NUTRI_SCORE]}>
						<FoldableCard
							defaultOpen={true}
							title={t('_general:NUTRI_SCORE')}
							content={
								<NutriScoreSelect
									hasNutriScore={props.selectedRecipe.hasNutriScore}
									nutriScoreCategory={props.selectedRecipe.nutriScoreCategory}
									setHasNutriScore={(flag: boolean) =>
										props.handleSetHasNutriScore(props.selectedRecipe.id, flag)
									}
									setNutriScoreCategory={(category: NutriScoreCategory) =>
										props.handleSetNutriScoreCategory(
											props.selectedRecipe.id,
											category
										)
									}
									disabled={!recipeEditor}
								/>
							}
							defaultLocked={true}
							hasLock={displayRecipe()}
							unlockable={false}
						/>
					</RenderIf>
				)}
				<FoldableCard
					defaultOpen={true}
					title={t('_general:NUTRI_DIAGRAM')}
					content={<NutritionDiagram data={getMacroNutrientChartData()} />}
					defaultLocked={displayRecipe()}
					hasLock={displayRecipe()}
					unlockable={false}
				/>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default MenuSidebar;
