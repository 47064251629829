import axios from 'axios';

import { MENUPLANNERITEMTYPE } from 'enums/MENUPLANNERITEMTYPE';
import { AllergenLogic } from 'enums/allergenLogic';
import { SearchLogic } from 'enums/searchLogic';
import { Optional } from 'functions/promiseExtensions';
import { AllergenLogicMap } from 'types/Allergen/AllergenLogicMap';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';

export function callApi(request: Request): Promise<Optional<ResponseData[]>> {
	const url = '/backend-service/MenuPlanner/GetAutoFill';
	return axios.post(url, request).toOptionalMapped((x) => x.data);
}

export interface Request {
	accessories?: SearchLogicMap | null;
	allergenIds?: AllergenLogicMap | [];
	caloriesLimit?: number | null;
	caloriesRange?: Range | null;
	carbonDioxideLabelIds?: string[];
	carbonDioxideRange?: Range | null;
	categoryIds?: string[];
	ingredients?: SearchLogicMap | null;
	numberOfItems?: number | null;
	nutriScoreLabelIds?: string[];
	nutrientRanges?: NutrientRange[] | null;
	pinnedItems?: PinnedItem[];
	priceLimit?: number | null;
	priceRange?: Range | null;
	seasonIds?: string[];
	statusIds?: string[];
	systemStatus?: SystemStatus[] | null;
	tagIds?: string[];
}

export interface Response {
	data: ResponseData[];
}

export type ResponseData = {
	day: number;
	id: string;
	images: Image[];
	titleTranslations: Translation;
	type: MENUPLANNERITEMTYPE;
	price: number;
	calories: number;
	tags: Tag[];
	allergens: Allergen[];
};

export type Allergen = {
	id: string;
	iconSvgUrl: string;
	display: boolean;
	name: string;
	logic: AllergenLogic;
	source: string;
};

export type Tag = {
	id: string;
	iconSvgUrl: string;
	display: boolean;
	name: string;
	tagCategory: TagCategory;
	source: string;
};

export type TagCategory = {
	id: string;
	name: string;
};

export type Range = {
	minimum: number | null;
	maximum: number | null;
};

export type Image = {
	availableSizes: number[];
	id: string;
	url: string;
};

export type Translation = {
	[key: string]: string;
};

export type SearchLogicMap = {
	[key: string]: SearchLogic;
};

export type NutrientRange = {
	id: string;
	maximum: number | null;
	minimum: number | null;
};

export type PinnedItem = {
	day: number;
	id: string;
	type: MENUPLANNERITEMTYPE;
};
