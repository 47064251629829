import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import Calculation from 'classes/Recipe/Detail/Calculation/Calculation';
import AllergenView from 'components/desktop/Recipe/RecipeTabs/AllergenView/AllergenView';
import DefaultView from 'components/desktop/Recipe/RecipeTabs/DefaultView/DefaultView';
import PriceNutrientCo2View from 'components/desktop/Recipe/RecipeTabs/IncorporationSharePriceNutrientCo2View/IncorporationSharePriceNutrientCo2View';
import NutritionFactsLabelView from 'components/desktop/Recipe/RecipeTabs/NutritionFactsLabelView/NutritionFactsLabelView';
import 'components/desktop/Recipe/RecipeTabs/RecipeTabs.scss';
import ScalingPriceNutrientCo2View from 'components/desktop/Recipe/RecipeTabs/ScalingPriceNutrientCo2View/ScalingPriceNutrientCo2View';
import SeasonalityView from 'components/desktop/Recipe/RecipeTabs/SeasonalityView/SeasonalityView';
import SegmentMediaView from 'components/desktop/Recipe/RecipeTabs/SegmentMediaView/SegmentMediaView';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import { SpinnerWhenLoading } from 'components/desktop/_general/Loading/Loading';
import Tabs from 'components/desktop/_general/Tabs/Tabs';
import { Tab } from 'components/desktop/_general/Tabs/Tabs';
import ENTITLEMENTS from 'enums/entitlements';
import { asBoolean } from 'functions/coercions';
import { areEntitlementsInUserEntitlements } from 'functions/tokenFunctions';
import { RefreshType } from 'hooks/useRefresh';
import { RootState } from 'reducers/rootReducer';
import { SegmentMediaCollection } from 'types/Media/SegmentMedia/SegmentMediaCollection';
import { CalculatedNutrient } from 'types/NutrientValue/CalculatedNutrientValues';
import { IncorporationShareType } from 'types/Recipe/IngredientIncorporationShare';
import { IncorporationShare, Recipe } from 'types/Recipe/Recipe';
import { Segment } from 'types/Recipe/Segment';
import { NutriScore } from 'types/_general/NutriScore';

interface IProps {
	readOnly: boolean;
	refresh: RefreshType;
	selectedLanguage: string;
	handleUploadSegmentMedia: (index: number, files: File[], video: boolean) => void;
	handleDeleteSegmentMedia: (index: number, id: string, video: boolean) => void;
	handleChangeScaledParts: (scaledParts: number) => void;
	calculation: Calculation | null;
	scaledCalculation: Calculation | null;
	handleIncorporationShareChange: (shares: IncorporationShare[]) => void;
	recipe: Recipe;
	segmentMedia: SegmentMediaCollection;
	segments: Segment[];
	loadingStatus: {
		isSegmentsLoadingDone: boolean;
		isScaledSegmentsLoadingDone: boolean;
	};
	scaledSegments: Segment[];
	incorporationShares: IncorporationShareType[];
	nutrients: CalculatedNutrient[];
	nutriScore: NutriScore | null;
	cultureCode: string;
	setRecipe: Dispatch<SetStateAction<Recipe>>;
}

const RecipeRendering = (props: IProps) => {
	const { t } = useTranslation();
	const quantityMethod: string = useSelector((state: RootState) => state.quantityMethod);
	const reduxAllowedEntryLanguages: string[] = useSelector(
		(state: RootState) => state.allowedEntryLanguages
	);

	const getNameOfPartsPricesTab = (): string => {
		const output: string[] = [];
		output.push(t('recipe:PORTIONS'));
		if (areEntitlementsInUserEntitlements([ENTITLEMENTS.PRICE]))
			output.push(t('recipe:PRICES'));
		output.push(t('recipe:NUTRIENTS'));
		if (areEntitlementsInUserEntitlements([ENTITLEMENTS.CARBONDIOXIDE]))
			output.push(t('recipe:CO2'));
		return output.join(', ');
	};

	const tabs: Tab[] = [
		{
			name: t('recipe:STEPS'),
			content: (
				<SpinnerWhenLoading isLoading={!props.loadingStatus.isSegmentsLoadingDone}>
					<DefaultView
						refresh={props.refresh}
						calculation={props.calculation}
						quantityMethod={quantityMethod}
						incorporationShares={props.incorporationShares}
						segments={props.segments}
					/>
				</SpinnerWhenLoading>
			),
			ai: true,
		},
		{
			name: t('recipe:SCALING'),
			display: areEntitlementsInUserEntitlements([ENTITLEMENTS.SCALING]),
			content: (
				<SpinnerWhenLoading isLoading={!props.loadingStatus.isScaledSegmentsLoadingDone}>
					<RenderIf condition={asBoolean(props.scaledCalculation)}>
						<ScalingPriceNutrientCo2View
							scaledParts={props.recipe.scaledParts ?? 1}
							setScaledParts={props.handleChangeScaledParts}
							refresh={props.refresh}
							incorporationShares={props.incorporationShares}
							quantityMethod={quantityMethod}
							segments={props.scaledSegments}
							calculation={props.scaledCalculation ?? new Calculation()}
						/>
					</RenderIf>
				</SpinnerWhenLoading>
			),
		},
		{
			name: getNameOfPartsPricesTab(),
			content: (
				<SpinnerWhenLoading isLoading={!props.loadingStatus.isSegmentsLoadingDone}>
					<PriceNutrientCo2View
						refresh={props.refresh}
						calculation={props.calculation}
						segments={props.segments}
						quantityMethod={quantityMethod}
						handleIncorporationShareChange={props.handleIncorporationShareChange}
						incorporationShares={props.incorporationShares}
					/>
				</SpinnerWhenLoading>
			),
		},
		{
			name: t('recipe:ALLERGENS'),
			content: (
				<AllergenView
					segments={props.segments}
					allergensPerIngredient={
						props.calculation ? props.calculation.allergensPerIngredient.items : []
					}
				/>
			),
		},
		{
			name: t('_general:SEASONS'),
			content: (
				<SeasonalityView
					segments={props.segments}
					seasonsPerIngredient={
						props.calculation ? props.calculation.seasonsPerIngredient.items : []
					}
				/>
			),
			display: areEntitlementsInUserEntitlements([ENTITLEMENTS.SEASON]),
		},
		{
			name: t('_general:LABELS'),
			content: (
				<NutritionFactsLabelView
					segments={props.segments}
					calculation={props.calculation}
					recipe={props.recipe}
					nutrients={props.nutrients}
					nutriScore={props.nutriScore}
				/>
			),
			display: areEntitlementsInUserEntitlements([ENTITLEMENTS.SEASON]),
		},
		{
			name: t('recipe:SEGMENT_MEDIA'),
			content: (
				<SegmentMediaView
					segments={props.segments}
					readOnly={props.readOnly}
					segmentMedia={props.segmentMedia}
					handleDeleteSegmentMedia={props.handleDeleteSegmentMedia}
					handleUploadMedia={props.handleUploadSegmentMedia}
					setRecipe={props.setRecipe}
					cultureCode={props.cultureCode}
				/>
			),
		},
	];

	return (
		<>
			<RenderIf condition={!reduxAllowedEntryLanguages.includes(props.selectedLanguage)}>
				<fieldset>
					<FontAwesomeIcon icon={faCircleInfo as IconProp} />{' '}
					<b>{t('recipe:ANALYSIS_LANGUAGE_DISCLAIMER')}</b>
				</fieldset>
			</RenderIf>
			<br />
			<Tabs
				tabs={tabs.filter((e: Tab) => {
					if (e.display === undefined || e.display === true) {
						return e;
					}
				})}
			/>
		</>
	);
};

export default RecipeRendering;
