import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMicrochipAi, faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { clone } from 'lodash';
import { useEffect } from 'react';
import { Dispatch, SetStateAction } from 'react';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import Allergen from 'classes/MenuPlan/Detail/Allergen';
import Column from 'classes/MenuPlan/Detail/Column';
import Item from 'classes/MenuPlan/Detail/Item';
import Menu from 'classes/MenuPlan/Detail/Menu';
import MenuPlan, { Check } from 'classes/MenuPlan/Detail/MenuPlan';
import Recipe from 'classes/MenuPlan/Detail/Recipe';
import Row from 'classes/MenuPlan/Detail/Row';
import Tag from 'classes/MenuPlan/Detail/Tag';
import DefaultValueComponent from 'components/desktop/MenuPlan/Detail/DefaultValueComponent';
import 'components/desktop/MenuPlan/Detail/RowInformationComponent.scss';
import PriceCaloriesRendering from 'components/desktop/MenuPlan/PriceCaloriesRendering/PriceCaloriesRendering';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import IconComponent from 'components/desktop/_general/Icon/IconComponent';
import COLOR from 'enums/COLOR';
import PERMISSIONS from 'enums/permissions';
import { mapToIExclusionLogicSelectItem } from 'functions/mappers/ISelectItem/mapToIExclusionLogicSelectItem';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { RootState } from 'reducers/rootReducer';
import { AccessoryLight } from 'types/Accessory/AccessoryLight';
import { AllergenLight } from 'types/Allergen/AllergenLight';
import { IngredientLight } from 'types/Ingredient/IngredientLight';
import * as Search from 'types/MenuPlan/AutoFillSearch';
import { SeasonLight } from 'types/Season/SeasonLight';
import { GetAllStatusDto, mapToToggleItemList } from 'types/Status/GetAllStatusDto';
import { TagLight } from 'types/Tag/TagLight';
import { Co2Label } from 'types/_general/Co2Label';
import { NutriScoreLabel } from 'types/_general/NutriScoreLabel';

interface IProps {
	accessories: AccessoryLight[];
	allergens: AllergenLight[];
	co2Labels: Co2Label[];
	currency: string;
	ingredients: IngredientLight[];
	menuPlan: MenuPlan;
	nutriScoreLabels: NutriScoreLabel[];
	row: Row;
	rowHeight: number;
	rowIndex: number;
	search: Search.Type;
	seasons: SeasonLight[];
	selectedItem: Recipe | Menu | Row | Item | Column | null;
	setMenuPlan: (input: MenuPlan) => void;
	setSearch: Dispatch<SetStateAction<Search.Type>>;
	setSelectedItem: (input: Row | null) => void;
	status: GetAllStatusDto[];
	tags: TagLight[];
}

const RowInformationComponent = (props: IProps) => {
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const [showButtons, setShowButtons] = useState<boolean>(false);
	const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);
	const [autoFillCheck, setAutoFillCheck] = useState<Check | null>(null);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const menuPlanEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITEMENUPLAN]);

	useEffect(() => {
		if (displayOverlay) {
			checkAutoFill();
		}
	}, [props.search]);

	const handleSelectRow = (): void => {
		if (props.selectedItem?.id === props.row.id) {
			props.setSelectedItem(null);
		} else {
			props.setSelectedItem(props.row);
		}
	};

	const handleClick = async (): Promise<void> => {
		await checkAutoFill();
		setDisplayOverlay(true);
	};

	const checkAutoFill = async (): Promise<void> => {
		setIsLoading(true);
		const check: Check = await props.menuPlan.checkAutoFillOfRow(props.rowIndex, props.search);
		setAutoFillCheck(check);
		setIsLoading(false);
	};

	const handleAutoFill = async () => {
		await props.menuPlan.rowList.all[props.rowIndex].itemList.callApiAutoFill(
			Search.mapToApiAutoFill({
				...props.search,
				pinnedItems: props.menuPlan.rowList.all[props.rowIndex].itemList.all,
				numberOfItems: props.menuPlan.getDurationDays() ?? 7,
				priceLimit: props.menuPlan.priceLimit,
				caloriesLimit: props.menuPlan.caloriesLimit,
			})
		);
		await props.menuPlan.recalculateColumnsAndRows();
		props.setMenuPlan(clone(props.menuPlan));
		setDisplayOverlay(false);
	};

	const onMouseOver = (): void => {
		if (menuPlanEditor) setShowButtons(true);
	};

	const onMouseLeave = (): void => {
		setShowButtons(false);
	};

	const handleRemoveRow = async (): Promise<void> => {
		props.menuPlan.removeRow(props.row);
		await props.menuPlan.recalculateColumnsAndRows();
		props.setMenuPlan(clone(props.menuPlan));
	};

	return (
		<>
			<div
				className="d-flex flex-column"
				onClick={handleSelectRow}
				onMouseOver={onMouseOver}
				onMouseLeave={onMouseLeave}
				style={{
					backgroundColor: '#ccc',
					width: '200px',
					height: props.rowHeight + 'px',
					position: 'relative',
					border:
						props.selectedItem?.id === props.row.id
							? `3px solid ${COLOR.PRIMARY}`
							: undefined,
					cursor: 'pointer',
				}}
			>
				<div style={{ margin: '10px' }}>
					<div>
						<strong>{props.row.name?.[reduxCultureCode] ?? ''}</strong>
						<hr style={{ margin: '0.5em 0' }} />
						<PriceCaloriesRendering
							costs={props.row.priceSum ?? 0}
							costsTotal={props.row.priceSumTotal ?? 0}
							calories={props.row.caloriesSum ?? 0}
							constraintStatusCosts={props.row.getConstraintStatusPrice()}
							constraintStatusCalories={props.row.getConstraintStatusCalories()}
						/>
						<hr />
					</div>
					<div>
						<div className="d-flex flex-row flex-wrap" style={{ gap: '5px' }}>
							{props.row.tagList.getVisibleTags().map((e: Tag, i: number) => {
								return (
									<IconComponent
										key={i}
										icon={{
											iconSvgUrl: e.iconSvgUrl ?? null,
											name: e.name ?? '',
										}}
									/>
								);
							})}
							{props.row.allergenList
								.getVisibleAllergens()
								.map((e: Allergen, i: number) => {
									return (
										<IconComponent
											key={i}
											icon={{
												iconSvgUrl: e.iconSvgUrl ?? null,
												name: e.name ?? '',
											}}
										/>
									);
								})}
						</div>
					</div>
				</div>
				<div
					className="recipe-buttons"
					style={{
						opacity: showButtons ? '1' : '0',
					}}
				>
					<div className="text-center" style={{ margin: '5px' }}>
						<RenderIf
							condition={Boolean(
								props.menuPlan.getCountOfEmptySpotsInRow(props.rowIndex)
							)}
						>
							<button
								type="button"
								className="btn btn-outline-primary"
								onClick={handleClick}
							>
								<FontAwesomeIcon icon={faMicrochipAi as IconProp} />
							</button>
						</RenderIf>
						<button
							type="button"
							className="btn btn-outline-primary"
							onClick={handleRemoveRow}
						>
							<FontAwesomeIcon icon={faTrash as IconProp} />
						</button>
					</div>
				</div>
			</div>
			<DefaultValueComponent
				accessories={mapToIExclusionLogicSelectItem(props.accessories)}
				allergens={props.allergens}
				autoFillCheck={autoFillCheck}
				co2Labels={props.co2Labels}
				currency={props.currency}
				displayOverlay={displayOverlay}
				handleAutoFill={handleAutoFill}
				ingredients={mapToIExclusionLogicSelectItem(props.ingredients)}
				isLoading={isLoading}
				menuPlan={props.menuPlan}
				numberOfItems={props.menuPlan.getDurationDays() ?? 7}
				nutriScoreLabels={props.nutriScoreLabels}
				rowIndex={props.rowIndex}
				search={props.search}
				seasons={props.seasons}
				setDisplayOverlay={setDisplayOverlay}
				setMenuPlan={props.setMenuPlan}
				setSearch={props.setSearch}
				status={mapToToggleItemList(props.status)}
				tags={props.tags}
			/>
		</>
	);
};

export default RowInformationComponent;
