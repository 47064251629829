import { faLock, faUnlock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import Co2FootprintDetail from 'components/desktop/_general/Co2Footprint/Co2FootprintDetail';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import { Characteristic } from 'enums/characteristic';
import { ValueScope } from 'enums/valueScope';
import { getValueScopeLabel } from 'functions/valueScopeConversion';
import { ISelectItem } from 'interfaces/ISelectItem';
import { Co2Footprint, defaultCo2Footprint } from 'types/Co2Footprint/Co2Footprint';

interface IProps {
	footprints: Co2Footprint[];
	setFootprints: (footprints: Co2Footprint[]) => void;
	hasLock?: boolean;
	lockToggleFunction?: () => void;
	isLocked?: boolean;
	disabled?: boolean;
	characteristic: Characteristic | null;
}

const Co2Footprints = (props: IProps) => {
	const { t } = useTranslation();

	const addNewFootprint = () => {
		var footprintsCpy = [...props.footprints];
		footprintsCpy.push(defaultCo2Footprint());
		props.setFootprints(footprintsCpy);
	};

	const handleValueChange = (input: number, index: number) => {
		const footprintsCpy = [...props.footprints];
		footprintsCpy[index].value = input;
		props.setFootprints(footprintsCpy);
	};

	const handleDelete = (_e: any, index: number) => {
		var footprintsCpy = [...props.footprints];
		footprintsCpy.splice(index, 1);
		props.setFootprints(footprintsCpy);
	};

	const handleChangeOriginLocation = (item: ISelectItem | null, index: number) => {
		var footprintsCpy = [...props.footprints];
		if (item) {
			footprintsCpy[index].originLocationId = item.id;
			footprintsCpy[index].origin = item.name;
		} else {
			footprintsCpy[index].originLocationId = '';
			footprintsCpy[index].origin = '';
		}
		props.setFootprints(footprintsCpy);
	};

	const handleChangeProductionMethod = (item: ISelectItem | null, index: number) => {
		var footprintsCpy = [...props.footprints];
		if (item) {
			footprintsCpy[index].productionMethodId = item.id;
			footprintsCpy[index].productionMethod = item.name;
		} else {
			footprintsCpy[index].productionMethodId = '';
			footprintsCpy[index].productionMethod = '';
		}
		props.setFootprints(footprintsCpy);
	};

	const renderContent = () => {
		return (
			<fieldset className="margin-top-25" style={{ minHeight: '4em' }}>
				<div className="d-flex justify-content-between">
					<legend>
						{t('_general:CO2_FOOTPRINTS')}{' '}
						{getValueScopeLabel(ValueScope.HundredGrams, props.characteristic)}
						<RenderIf condition={!props.disabled}>
							<>
								{props.hasLock && (
									<span
										className="lock input-label-field-button"
										id="basic-addon1"
										onClick={props.lockToggleFunction}
										style={{ marginLeft: '10px' }}
									>
										{props.isLocked ? (
											<FontAwesomeIcon icon={faLock} className="icon" />
										) : (
											<FontAwesomeIcon icon={faUnlock} className="icon" />
										)}
									</span>
								)}
							</>
						</RenderIf>
					</legend>
					<div>
						<RenderIf condition={!props.isLocked && !props.disabled}>
							<button
								disabled={props.isLocked}
								type="button"
								onClick={addNewFootprint}
								className="btn btn-outline-primary"
								style={{ marginBottom: '18px', whiteSpace: 'nowrap' }}
							>
								{t('_general:NEW_CO2_FOOTPRINT')}
							</button>
						</RenderIf>
					</div>
				</div>
				<div className="row gy-4">
					{props.footprints.map((element, index) => (
						<Co2FootprintDetail
							disabled={props.isLocked || props.disabled}
							footprint={element}
							handleChangeOriginLocation={handleChangeOriginLocation}
							handleChangeProductionMethod={handleChangeProductionMethod}
							handleDelete={handleDelete}
							handleValueChange={handleValueChange}
							index={index}
							key={index}
						/>
					))}
				</div>
			</fieldset>
		);
	};

	return <>{renderContent()}</>;
};

export default Co2Footprints;
