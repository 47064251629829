import { t } from 'i18next';

import RoundedInput from 'components/desktop/_general/Input/RoundedInput/RoundedInput';
import PERMISSIONS from 'enums/permissions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { Recipe } from 'types/Recipe/Recipe';

interface IProps {
	recipe: Recipe;
	handleSetRecipeAttribute: (key: keyof Recipe, value: number | null) => void;
}

const PartsPersonsEditor = (props: IProps) => {
	const recipeEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITERECIPE]);

	const handleValueChange = (id: string, value: number): void => {
		const valueIsZero = value === 0;

		if (id === 'partsInput') {
			props.handleSetRecipeAttribute('parts', valueIsZero ? null : value);
		} else if (id === 'personsInput') {
			props.handleSetRecipeAttribute('persons', valueIsZero ? null : value);
		}
	};

	return (
		<>
			<div>
				<label>{t('recipe:PARTS_COUNT')}</label>
				<RoundedInput
					disabled={!recipeEditor}
					error-key="Parts"
					handleChange={(input) => handleValueChange('partsInput', input)}
					id="partsInput"
					value={props.recipe.parts ?? 0}
				/>
			</div>
			<div>
				<label>{t('recipe:PERSONS_COUNT')}</label>
				<RoundedInput
					disabled={!recipeEditor}
					error-key="Persons"
					handleChange={(input) => handleValueChange('personsInput', input)}
					id="personsInput"
					value={props.recipe.persons ?? 0}
				/>
			</div>
		</>
	);
};

export default PartsPersonsEditor;
