import { useDispatch, useStore } from 'react-redux';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import { AnyAction, Dispatch, Store } from 'redux';
import accessoryRoutes from 'routing/AccessoryRoutes';
import baseIngredientRoutes from 'routing/BaseIngredientRoutes';
import dashboardRoutes from 'routing/DashboardRoutes';
import ingredientRoutes from 'routing/IngredientRoutes';
import masterDataRoutes from 'routing/MasterData/index';
import menuPlanRoutes from 'routing/MenuPlanRoutes';
import menuRoutes from 'routing/MenuRoutes';
import productRoutes from 'routing/ProductRoutes';
import recipeRoutes from 'routing/RecipeRoutes';
import specialRoutes from 'routing/SpecialRoutes';
import userAccountRoutes from 'routing/UserAccountRoutes';

import { dissoc } from 'functions/objectExtensions';
import { createLoaderFromResources } from 'reducers/LoaderDispatcher';
import * as rootReducer from 'reducers/rootReducer';

const extractQueryParams = (url: string) => {
	const urlObj = new URL(url);
	const queryParams = new URLSearchParams(urlObj.search);
	const queryObj: { [key: string]: string } = {};

	queryParams.forEach((value, key) => {
		queryObj[key] = value;
	});
	return queryObj;
};

export const wrapLoaderRoute = (
	props: {
		loader?: any;
		resources?: string[];
	},
	store: Store<rootReducer.RootState, AnyAction>,
	dispatch: Dispatch<AnyAction>
) => {
	const resourcesLoader = createLoaderFromResources(store, dispatch, props.resources || []);

	const loader = props.loader
		? async ({ request, params }: any) => {
				const queryParams = extractQueryParams(request.url);
				await resourcesLoader({ ...params, ...queryParams });
				return await props.loader(store, dispatch, { ...params, ...queryParams });
		  }
		: props.resources
		? async ({ request, params }: any) => {
				const queryParams = extractQueryParams(request.url);
				return await resourcesLoader({ ...params, ...queryParams });
		  }
		: null;

	return dissoc(
		{
			...props,
			loader: loader,
		},
		'loader',
		'resources'
	);
};

const standardRoutes = [
	{ path: '/', Component: () => <Navigate replace to="/login" /> },
	{ path: '*', Component: () => <Navigate to="/dashboard" /> },
];

const router = (store: Store<rootReducer.RootState, AnyAction>, dispatch: Dispatch<AnyAction>) => {
	const routes = [
		...standardRoutes,
		...dashboardRoutes,
		...masterDataRoutes,
		...accessoryRoutes,
		...baseIngredientRoutes,
		...ingredientRoutes,
		...menuPlanRoutes,
		...menuRoutes,
		...recipeRoutes,
		...userAccountRoutes,
		...specialRoutes,
		...productRoutes,
	].map((x: any) => wrapLoaderRoute(x, store, dispatch));

	return createBrowserRouter(routes);
};

const Router = () => {
	const store = useStore<rootReducer.RootState>();
	const dispatch = useDispatch();
	return <RouterProvider router={router(store, dispatch)} />;
};

export default Router;
