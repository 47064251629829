import { faCircleInfo } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import ErpNutrientTableRow from 'components/desktop/Recipe/RecipeTabs/Components/RecipeErpView/IngredientProductMappingComponent/ErpNutrientTableRow';
import { ValueScope } from 'enums/valueScope';
import {
	CalculatedNutrient,
	NutrientCategoryGroup,
} from 'types/NutrientValue/CalculatedNutrientValues';
import * as Product from 'types/Recipe/Detail/Product';
import * as ProductList from 'types/Recipe/Detail/ProductList';

interface IProps {
	productMatches: Product.Type[];
	nutrients: CalculatedNutrient[];
	valueScope: ValueScope;
	matchLeftId: string | null;
	matchRightId: string | null;
	setMatchLeftId: (input: string | null) => void;
	setMatchRightId: (input: string | null) => void;
}

const ErpNutrients = (props: IProps) => {
	const { t } = useTranslation();

	const getNutrientCategoryGroups = (): NutrientCategoryGroup[] => {
		return props.nutrients
			.groupBy((x) => x.category)
			.map((k: string, nutrients: CalculatedNutrient[]) => {
				return {
					name: k,
					sortOrder: nutrients[0].nutrientCategorySortOrder,
					nutrients: nutrients.orderBy((x) => x.nutrient),
				};
			})
			.orderBy((x) => [x.sortOrder, x.name]);
	};

	const getMatchFromValue = (input: string): null | string => {
		if (input === 'default') return null;
		return input;
	};

	if (!props.matchLeftId && !props.matchRightId) return <></>;

	return (
		<fieldset className="margin-top-25">
			<div className="d-flex justify-content-between" style={{ gap: '10px' }}>
				<div className="d-flex flex-gap-10">
					<legend style={{ width: 'auto' }}>{t('_general:NUTRIENTS')}</legend>
					<FontAwesomeIcon
						style={{ marginTop: 4 }}
						title={t('recipe:INFO_CALCULATION_ON_ENTRYLANGUAGE')}
						icon={faCircleInfo}
					></FontAwesomeIcon>
				</div>

				<div
					className="d-flex"
					style={{ gap: '10px', marginBottom: '10px', width: '300px' }}
				>
					<select
						className="form-select form-select-sm"
						onChange={(e) =>
							props.setMatchLeftId(getMatchFromValue(e.currentTarget.value))
						}
						value={props.matchLeftId ?? 'default'}
					>
						<option value="default">AIR</option>;
						{props.productMatches.map((e, i) => {
							return (
								<option key={i} value={e.id}>
									{`${e.name} (${e.brand})`}
								</option>
							);
						})}
					</select>
					<select
						className="form-select form-select-sm"
						onChange={(e) => props.setMatchRightId(e.currentTarget.value)}
						value={props.matchRightId ?? 'default'}
					>
						<option value="default">AIR</option>;
						{props.productMatches.map((e, i) => {
							return (
								<option key={i} value={e.id}>
									{`${e.name} (${e.brand})`}
								</option>
							);
						})}
					</select>
				</div>
			</div>
			{getNutrientCategoryGroups().map((group: NutrientCategoryGroup, i: number) => (
				<div key={i}>
					<div className="table-header">{group.name}</div>
					<div className="scrollable-table-wrapper" style={{ height: 'auto' }}>
						<table className="align-middle table table-striped table-hover table-sm">
							<thead className="recipe-nutrients-thead">
								<tr>
									<th>
										<label>{t('recipe:NUTRIENT')}</label>
									</th>
									<th className="text-end">
										<label>{t('_general:SOURCE')} (L)</label>
									</th>
									<th className="text-end">
										<label>{t('_general:VALUE')} (L)</label>
									</th>
									<th className="text-end">
										<label>{t('_general:SOURCE')} (R)</label>
									</th>
									<th className="text-end">
										<label>{t('_general:VALUE')} (R)</label>
									</th>
									<th className="text-end" style={{ display: 'none' }}>
										<label>{t('_general:ERROR') + ' (%)'}</label>
									</th>
								</tr>
							</thead>
							<tbody className="recipe-nutrients-tbody">
								{group.nutrients.map((nutrient: CalculatedNutrient, i: number) => {
									return (
										<ErpNutrientTableRow
											nutrient={nutrient}
											servingSizeQuantityGram={ProductList.getServingSize(
												props.productMatches,
												props.matchLeftId,
												props.matchRightId
											)}
											key={i}
											props={props}
										/>
									);
								})}
							</tbody>
						</table>
					</div>
				</div>
			))}
		</fieldset>
	);
};

export default ErpNutrients;
