export function base64ToFormData(base64: string, fileName: string): FormData {
	const formData = new FormData();
	const file = base64ToFile(base64, fileName);

	formData.append('Media.File', file, fileName);

	return formData;
}

function base64ToFile(base64: string, fileName: string): File {
	const byteString = atob(base64.split(',')[1]);
	const ab = new ArrayBuffer(byteString.length);
	const ia = new Uint8Array(ab);
	const mimeType = extractMimeType(base64);

	for (let i = 0; i < byteString.length; i++) {
		ia[i] = byteString.charCodeAt(i);
	}

	return new File([ab], fileName, { type: mimeType ?? 'image/jpeg' });
}

function extractMimeType(base64: string): string | null {
	const mimeTypeMatch = base64.match(/^data:(.*?);base64,/);
	return mimeTypeMatch ? mimeTypeMatch[1] : null;
}
