import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMicrochipAi } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface IProps {
	onClick: () => void;
}

const InputAiButton = (props: IProps) => {
	return (
		<span
			className="input-group-text select-button btn-primary"
			id="basic-addon1"
			onClick={props.onClick}
		>
			<FontAwesomeIcon icon={faMicrochipAi as IconProp} />
		</span>
	);
};

export default InputAiButton;
