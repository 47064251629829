import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertResult } from 'sweetalert2';

import OriginLocation from 'classes/StandingData/OriginLocation/Search/OriginLocation';
import OriginLocationList from 'classes/StandingData/OriginLocation/Search/OriginLocationList';
import Search from 'classes/StandingData/OriginLocation/Search/Search';
import OriginLocationDetailComponent from 'components/desktop/StandingData/OriginLocation/Detail/OriginLocationDetailComponent';
import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import BtnNew from 'components/desktop/_general/Button/BtnNew/BtnNew';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import AdvancedSearchInput from 'components/desktop/_general/Search/AdvancedSearchInput';
import StandingDataList, {
	Row,
} from 'components/desktop/_general/StandingDataList/StandingDataList';
import clone from 'functions/clone';
import { QueryParams, getQueryParams } from 'functions/getQueryParams';
import useEffectWhenMounted from 'hooks/useEffectWhenMounted';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	archive?: boolean;
}

const maxNumberOfButtons = 5;

const OriginLocationManagementComponent = (props: IProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [originLocationList, setOriginLocationList] = useState<OriginLocationList>(
		new OriginLocationList()
	);
	const [search, setSearch] = useState<Search>(new Search());
	const [searchTerm, setSearchTerm] = useState<string>(search.searchTerm.value ?? '');

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);

	useEffect(() => {
		initialize();
	}, []);

	useEffectWhenMounted(() => {
		initialize();
	}, [reduxCultureCode]);

	const initialize = async (): Promise<void> => {
		if (props.archive !== undefined && props.archive == true) {
			search.showOnlyDeleted = true;
		}
		if (window.location.href.includes('?')) {
			const queryParams: QueryParams = getQueryParams();
			search.mapFromUrl(queryParams);
			if (search.id.value) setDisplayOverlay(true);
			await getOriginLocations();
		} else {
			await getOriginLocations();
		}
		setSearch(clone(search));
	};

	const getOriginLocations = async () => {
		setIsLoading(true);
		const response = await originLocationList.callApi(search.mapToRequest());
		response.do((x) => {
			originLocationList.mapFromApi(x);
			setOriginLocationList(clone(originLocationList));
		});
		setIsLoading(false);
	};

	const getSuggestedOriginLocation = async (): Promise<ISelectItem[]> => {
		if (searchTerm.length >= 1) {
			await originLocationList.search(searchTerm, props.archive ?? false);
		} else {
			originLocationList.filtered = [];
		}

		setOriginLocationList(clone(originLocationList));
		return originLocationList.filtered;
	};

	const handleChangeUrl = () => {
		const url: string | null = search.mapToUrl();
		if (url) navigate(url);
		getOriginLocations();
	};

	const handleSearch = (input?: string) => {
		search.setSearchTerm(input ?? searchTerm);
		handleChangeUrl();
	};

	const handleChangePageIndex = (index: number) => {
		search.pageIndex.value = index;
		handleChangeUrl();
	};

	const handleChangeId = (id: string | null) => {
		search.id.value = id;
		handleChangeUrl();
	};

	const handleOverlayOpen = (input: string): void => {
		setDisplayOverlay(true);
		handleChangeId(input);
	};

	const handleOverlayClose = (): void => {
		setDisplayOverlay(false);
		handleChangeId(null);
	};

	const handleArchiveLocation = async (originLocation: OriginLocation): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:ARCHIVE_TITLE'),
			text: t('standingData:ORIGIN_ARCHIVE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#ff0004',
			confirmButtonColor: '#ff0004',
			confirmButtonText: t('_general:ARCHIVE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await originLocation.callApiDelete();
				await Swal.fire({
					title: t('standingData:ORIGIN_ARCHIVE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getOriginLocations();
			} finally {
				setIsLoading(false);
			}
		}
	};

	const handleRestoreLocation = async (originLocation: OriginLocation): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:RESTORE_TITLE'),
			text: t('standingData:ORIGIN_RESTORE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#00cc07',
			confirmButtonColor: '#00cc07',
			confirmButtonText: t('_general:RESTORE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await originLocation.callApiRestore();
				await Swal.fire({
					title: t('standingData:ORIGIN_RESTORE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getOriginLocations();
			} finally {
				setIsLoading(false);
			}
		}
	};

	const handleChangeIdToNew = (): void => {
		handleChangeId('new');
		setDisplayOverlay(true);
	};

	const getButtonDataArchive = (input: OriginLocation): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleArchiveLocation(input),
			};
		}
	};

	const getButtonDataEdit = (id: string): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleOverlayOpen(id),
			};
		}
	};

	const getButtonDataRestore = (input: OriginLocation): BtnData | undefined => {
		if (props.archive) {
			return {
				onClick: () => handleRestoreLocation(input),
			};
		}
	};

	const renderButtons = (input: OriginLocation): JSX.Element => {
		return (
			<BtnGroup
				edit={getButtonDataEdit(input.id)}
				archive={getButtonDataArchive(input)}
				restore={getButtonDataRestore(input)}
			/>
		);
	};

	const renderListRows = (): Row[] => {
		return originLocationList.all.map((origin: OriginLocation) => {
			return {
				id: origin.id,
				jsx: (
					<div
						className="d-flex flex-row justify-content-start align-items-center"
						style={{ gap: '2em' }}
					>
						<div className="d-flex flex-row" style={{ gap: '0.8em' }}>
							<div>{origin.code}</div>
						</div>
						<div className="flex-fill">{origin.name}</div>
						<div>{renderButtons(origin)}</div>
					</div>
				),
			};
		});
	};

	const renderContent = () => {
		return (
			<>
				<main className="container">
					<div className="d-flex flex-row justify-content-between">
						{!props.archive ? (
							<>
								<h1>{t('standingData:ORIGIN_MANAGEMENT')}</h1>
								<div className="d-flex flex-gap-25">
									<BtnNew
										onClick={handleChangeIdToNew}
										name={t('standingData:CREATE_ORIGIN')}
									/>
								</div>
							</>
						) : (
							<h1>{t('standingData:ORIGIN_ARCHIVE')}</h1>
						)}
					</div>
					<br />
					<div className="row">
						<div className="col-lg-12">
							<div className="input-group mb-3">
								<AdvancedSearchInput
									debounceMilliSeconds={350}
									getSuggestedItems={getSuggestedOriginLocation}
									handleSearch={handleSearch}
									searchTerm={searchTerm}
									setSearchTerm={setSearchTerm}
									triggerSearchLetterAmount={1}
								/>
							</div>
						</div>
					</div>
					<br />
					<LoadingAnimation isLoading={isLoading} />
					<p>{originLocationList.totalCount + ' ' + t('_general:ORIGINS')}</p>
					<StandingDataList
						archive={props.archive}
						displayOverlay={displayOverlay}
						handleOverlayClose={handleOverlayClose}
						handleOverlayOpen={handleOverlayOpen}
						overlay={<OriginLocationDetailComponent />}
						rows={renderListRows()}
					/>
					<br />
					<Pagination
						countOfAllListItems={originLocationList.totalCount}
						listItemAmount={search.getPageSize()}
						maxNumberOfButtons={maxNumberOfButtons}
						pageIndex={search.getPageIndex()}
						setPageIndex={handleChangePageIndex}
					/>
				</main>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default OriginLocationManagementComponent;
