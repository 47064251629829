import { ReducerBuilder } from 'reducers/ReducerBuilder';
import { payloadReducer } from 'reducers/reducerUtils';
import * as UnitList from 'types/_general/Store/UnitList';

const rb = new ReducerBuilder<UnitList.Type>();

export const createUnitListLoadedAction = rb.createActionForReducer(
	payloadReducer((x) => x),
	'Units/LoadedFromBackend'
);

export const unitListReducer = rb.createReducer();
