import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { faCircleXmark } from '@fortawesome/pro-solid-svg-icons';
import { faSpinner } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { useState } from 'react';

import { ResponseCarbonDioxideLabel } from 'api/carbonDioxideLabel/GetCarbonDioxideLabelStartingWithV1';
import { ResponseNutriScoreLabel } from 'api/nutriScoreLabel/GetNutriScoreLabelGroupedStartingWithV1';
import MenuPlan, { Check } from 'classes/MenuPlan/Detail/MenuPlan';
import AllergenMultiSearchSelect from 'components/desktop/_general/AllergenMultiSearchSelect';
import Co2Select from 'components/desktop/_general/Co2Select/Co2Select';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import ExclusionLogicMultiSearchSelect from 'components/desktop/_general/MultiSearchSelect/ExclusionLogicMultiSearchSelect';
import MultiSearchSelect from 'components/desktop/_general/MultiSearchSelect/MultiSearchSelect';
import NutriScoreDiagramSelect from 'components/desktop/_general/NutriScoreSelect/NutriScoreDiagramSelect';
import RangeDoubleLabel from 'components/desktop/_general/RangeDoubleLabel/RangeDoubleLabelNew';
import MenuPlanStatusSelect from 'components/desktop/_general/StatusSelect/MenuPlanStatusSelect';
import COLOR from 'enums/COLOR';
import { ValueScope } from 'enums/valueScope';
import { getValueScope } from 'functions/getValueScope';
import { IAllergenSelectItem } from 'interfaces/IAllergenSelectItem';
import { IExclusionLogicSelectItem } from 'interfaces/IExclusionLogicSelectItem';
import { ISelectItem } from 'interfaces/ISelectItem';
import * as Search from 'types/MenuPlan/AutoFillSearch';
import { Co2Label } from 'types/_general/Co2Label';
import { NutriScoreLabel } from 'types/_general/NutriScoreLabel';
import * as SliderWithId from 'types/_general/Slider/SliderWithId';
import { ToggleItem } from 'types/_general/ToggleItem';

interface IProps {
	accessories: IExclusionLogicSelectItem[];
	allergens: IAllergenSelectItem[];
	closeOverlay: () => void;
	co2Labels: Co2Label[];
	currency: string;
	handleAutoFill: () => Promise<void>;
	handleChangeAccessories: (input: IExclusionLogicSelectItem[]) => void;
	handleChangeAllergens: (input: IAllergenSelectItem[]) => void;
	handleChangeCalories: (left: number | null, right: number | null, _id?: string) => void;
	handleChangeCarbonDioxide: (left: number | null, right: number | null, _id?: string) => void;
	handleChangeCo2: (items: ResponseCarbonDioxideLabel[]) => void;
	handleChangeIngredients: (input: IExclusionLogicSelectItem[]) => void;
	handleChangeNutriScore: (items: ResponseNutriScoreLabel[]) => void;
	handleChangeNutrients: (left: number | null, right: number | null, id?: string) => void;
	handleChangePrice: (left: number | null, right: number | null, _id?: string) => void;
	handleChangeSeasons: (input: ISelectItem[]) => void;
	handleChangeStatus: (input: ToggleItem) => void;
	handleChangeTags: (input: ISelectItem[]) => void;
	handleResetSearch: () => void;
	handleResetStatus: () => void;
	ingredients: IExclusionLogicSelectItem[];
	isLoading: boolean;
	menuPlan: MenuPlan;
	nutriScoreLabels: NutriScoreLabel[];
	rowIndex: number;
	search: Search.Type;
	seasons: ISelectItem[];
	setSearch: (input: Search.Type) => void;
	status: ToggleItem[];
	tags: ISelectItem[];
	autoFillCheck: Check | null;
}

const AutoFillSearch = (props: IProps) => {
	const [suggestedTags, setSuggestedTags] = useState<ISelectItem[]>(props.tags);
	const [suggestedSeasons, setSuggestedSeasons] = useState<ISelectItem[]>(props.seasons);
	const [suggestedAllergens, setSuggestedAllergens] = useState<IAllergenSelectItem[]>(
		props.allergens
	);
	const [suggestedIngredients, setSuggestedIngredients] = useState<IExclusionLogicSelectItem[]>(
		props.ingredients
	);
	const [suggestedAccessories, setSuggestedAccessories] = useState<IExclusionLogicSelectItem[]>(
		props.accessories
	);

	const handleSearchTags = (searchTerm: string): void => {
		const filtered = props.tags.filter((e: ISelectItem) =>
			e.name.toLowerCase().startsWith(searchTerm.toLowerCase())
		);
		setSuggestedTags(filtered);
	};

	const handleSearchSeasons = (searchTerm: string): void => {
		const filtered = props.seasons.filter((e: ISelectItem) =>
			e.name.toLowerCase().startsWith(searchTerm.toLowerCase())
		);
		setSuggestedSeasons(filtered);
	};

	const handleSearchAllergens = (searchTerm: string): void => {
		const filtered = props.allergens.filter((e: ISelectItem) =>
			e.name.toLowerCase().startsWith(searchTerm.toLowerCase())
		);

		setSuggestedAllergens(filtered);
	};
	const handleSearchIngredients = (searchTerm: string): void => {
		const filtered = props.ingredients.filter((e: IExclusionLogicSelectItem) =>
			e.name.toLowerCase().startsWith(searchTerm.toLowerCase())
		);
		setSuggestedIngredients(filtered);
	};

	const handleSearchAccessories = (searchTerm: string): void => {
		const filtered = props.accessories.filter((e: IExclusionLogicSelectItem) =>
			e.name.toLowerCase().startsWith(searchTerm.toLowerCase())
		);
		setSuggestedAccessories(filtered);
	};

	const renderCol1 = (): JSX.Element => {
		return (
			<>
				<ExclusionLogicMultiSearchSelect
					className="mb-2"
					label={t('_general:INGREDIENTS')}
					suggestedItems={suggestedIngredients}
					displaySavedItems="top"
					savedItems={props.search.ingredients}
					setSavedItems={props.handleChangeIngredients}
					handleChangeSearchTerm={handleSearchIngredients}
					triggerSearchLetterAmount={0}
					errorKey="Ingredients"
				/>
				<ExclusionLogicMultiSearchSelect
					className="mb-2"
					label={t('_general:ACCESSORIES')}
					suggestedItems={suggestedAccessories}
					displaySavedItems="top"
					savedItems={props.search.accessories}
					setSavedItems={props.handleChangeAccessories}
					handleChangeSearchTerm={handleSearchAccessories}
					triggerSearchLetterAmount={0}
					errorKey="Accessories"
				/>
				<AllergenMultiSearchSelect
					className="mb-2"
					label={t('_general:ALLERGENS')}
					suggestedItems={suggestedAllergens}
					displaySavedItems="top"
					savedItems={props.search.allergens}
					setSavedItems={props.handleChangeAllergens}
					handleChangeSearchTerm={handleSearchAllergens}
					triggerSearchLetterAmount={0}
					errorKey="Allergens"
				/>
				<MultiSearchSelect
					className="mb-2"
					label={t('_general:SEASONS')}
					suggestedItems={suggestedSeasons}
					displaySavedItems="top"
					savedItems={props.search.seasons}
					setSavedItems={props.handleChangeSeasons}
					handleChangeSearchTerm={handleSearchSeasons}
					triggerSearchLetterAmount={0}
					errorKey="Seasons"
				/>
				<MultiSearchSelect
					className="mb-2"
					label={t('_general:TAGS')}
					suggestedItems={suggestedTags}
					displaySavedItems="top"
					savedItems={props.search.tags}
					setSavedItems={props.handleChangeTags}
					handleChangeSearchTerm={handleSearchTags}
					triggerSearchLetterAmount={0}
					errorKey="Tags"
				/>
			</>
		);
	};

	const renderCol2 = (): JSX.Element => {
		return (
			<>
				<label className="mb-2">
					{t('_general:NUTRITION_FACTS') + ' ' + getValueScope(ValueScope.Person)}
				</label>
				<RenderIf condition={props.search.sliderCalories.max !== null}>
					<RangeDoubleLabel
						className="mb-2"
						label={`${t('_general:CALORIES')} (kcal)`}
						min={props.search.sliderCalories.min}
						max={props.search.sliderCalories.max}
						minArea={props.search.sliderCalories.minArea}
						maxArea={props.search.sliderCalories.maxArea}
						left={props.search.sliderCalories.left}
						right={props.search.sliderCalories.right}
						handleChange={props.handleChangeCalories}
					/>
				</RenderIf>
				{props.search.sliderNutrient.map((e: SliderWithId.Type, i: number) => {
					return (
						<div key={i}>
							<RangeDoubleLabel
								id={e.id}
								className="mb-2"
								label={`${e.name} (${e.unit})`}
								min={e.min ?? 0}
								max={e.max ?? 0}
								minArea={e.minArea ?? 0}
								maxArea={e.maxArea ?? 0}
								left={e.left ?? undefined}
								right={e.right ?? undefined}
								handleChange={props.handleChangeNutrients}
							/>
						</div>
					);
				})}
				<label className="mb-2">
					{t('_general:COSTS') + ' ' + getValueScope(ValueScope.Person)}
				</label>
				<RenderIf condition={props.search.sliderCalories.max !== null}>
					<RangeDoubleLabel
						className="mb-2"
						label={`${t('_general:COSTS')} (${props.currency})`}
						min={props.search.sliderPrice.min ?? 0}
						max={props.search.sliderPrice.max ?? 0}
						minArea={props.search.sliderPrice.minArea ?? 0}
						maxArea={props.search.sliderPrice.maxArea ?? 0}
						left={props.search.sliderPrice.left ?? undefined}
						right={props.search.sliderPrice.right ?? undefined}
						handleChange={props.handleChangePrice}
					/>
				</RenderIf>
				<RenderIf condition={props.search.sliderCalories.max !== null}>
					<RangeDoubleLabel
						className="mb-2"
						label={`${
							t('_general:CARBON_DIOXIDE') + ' ' + getValueScope(ValueScope.Person)
						}`}
						min={props.search.sliderCarbonDioxide.min ?? 0}
						max={props.search.sliderCarbonDioxide.max ?? 0}
						minArea={props.search.sliderCarbonDioxide.minArea ?? 0}
						maxArea={props.search.sliderCarbonDioxide.maxArea ?? 0}
						left={props.search.sliderCarbonDioxide.left ?? undefined}
						right={props.search.sliderCarbonDioxide.right ?? undefined}
						handleChange={props.handleChangeCarbonDioxide}
					/>
				</RenderIf>
			</>
		);
	};

	const renderCol3 = (): JSX.Element => {
		return (
			<div className="d-flex flex-column" style={{ gap: '10px' }}>
				<div>
					<MenuPlanStatusSelect
						toggleList={props.search.statusToggleList}
						handleChange={props.handleChangeStatus}
						handleReset={props.handleResetStatus}
					/>
				</div>
				<div>
					<Co2Select
						carbonDioxideLabels={props.co2Labels}
						savedItems={props.search.carbonDioxideLabels}
						setSavedItems={props.handleChangeCo2}
					/>
				</div>
				<div>
					<NutriScoreDiagramSelect
						nutriScoreLabels={props.nutriScoreLabels}
						savedItems={props.search.nutriScoreLabels}
						setSavedItems={props.handleChangeNutriScore}
					/>
				</div>
			</div>
		);
	};

	const renderStatusIcon = (): JSX.Element => {
		return (
			<span style={{ marginRight: '5px' }}>
				<RenderIf condition={props.autoFillCheck?.okay && !props.isLoading}>
					<FontAwesomeIcon
						style={{ color: COLOR.PRIMARY }}
						icon={faCircleCheck as IconProp}
						size="lg"
					/>
				</RenderIf>
				<RenderIf condition={!props.autoFillCheck?.okay && !props.isLoading}>
					<FontAwesomeIcon
						style={{ color: COLOR.DANGER }}
						icon={faCircleXmark as IconProp}
						size="lg"
					/>
				</RenderIf>
				<RenderIf condition={props.isLoading}>
					<FontAwesomeIcon
						style={{ color: COLOR.SECONDARY }}
						icon={faSpinner as IconProp}
						size="lg"
						spin
						pulse
					/>
				</RenderIf>
			</span>
		);
	};

	const renderAutoFillMessage = (): JSX.Element => {
		return (
			<div className="d-flex justify-content-between" style={{ gap: '10px' }}>
				<div>
					{renderStatusIcon()}
					{t('menuPlan:NEEDED')}:{' '}
					<span
						style={{ color: props.autoFillCheck?.okay ? COLOR.PRIMARY : COLOR.DANGER }}
					>
						{props.autoFillCheck?.shouldHave} {t('_general:RECIPES')}/
						{t('_general:MENUES')}
					</span>
				</div>
				<RenderIf condition={props.autoFillCheck?.okay && !props.isLoading}>
					<span style={{ color: 'grey' }}>
						{props.search.count.recipes +
							' ' +
							t('_general:RECIPES') +
							', ' +
							props.search.count.menues +
							' ' +
							t('_general:MENUES')}
					</span>
				</RenderIf>
				<RenderIf condition={!props.autoFillCheck?.okay}>
					<div>
						<span style={{ color: COLOR.DANGER }}>
							{t('menuPlan:NO_ITEMS_AVAILABLE')}
						</span>
					</div>
				</RenderIf>
			</div>
		);
	};

	return (
		<>
			{renderAutoFillMessage()}
			<hr />
			<div className="row">
				<div className="col-lg-4">{renderCol1()}</div>
				<div className="col-lg-6">{renderCol2()}</div>
				<div className="col-lg-2">{renderCol3()}</div>
			</div>
			<div className="row">
				<div className="col-lg-12" style={{ textAlign: 'end' }}>
					<button className="btn btn-primary" onClick={props.handleAutoFill}>
						{t('menuPlan:GENERATE')}
					</button>
					<button
						className="btn btn-primary"
						onClick={props.handleResetSearch}
						style={{ marginLeft: '5px' }}
					>
						{t('_general:RESET')}
					</button>
				</div>
			</div>
		</>
	);
};

export default AutoFillSearch;
