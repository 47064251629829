import * as apiPost from 'api/validatorResults/PostValidatorResultsListV1';
import { AirUrlParamsClass } from 'hooks/useAirNavigation';

const defaultPageSize = 20;
const defaultPageIndex = 0;

export type Type = {
	showOnlyUnacknowledged: boolean | null;
	pageIndex: number;
	pageSize: number;
};

export function create(): Type {
	return {
		showOnlyUnacknowledged: true,
		pageIndex: defaultPageIndex,
		pageSize: defaultPageSize,
	};
}

export function mapToApi(obj: Type): apiPost.Request {
	return {
		pageIndex: obj.pageIndex,
		pageSize: obj.pageSize,
		type: 'Recipe',
		showOnlyUnacknowledged: true,
	};
}

function parseSearchOnlyUnacknowledge(input: string): boolean | null {
	if (input === 'true') return true;
	if (input === 'false') return false;
	return null;
}

export function getFromUrl(p: AirUrlParamsClass): Type {
	const params = p.params;
	return {
		showOnlyUnacknowledged: params.sou ? parseSearchOnlyUnacknowledge(params.sou) : null,
		pageIndex: params.pi ? Number(params.pi) : defaultPageIndex,
		pageSize: params.ps ? Number(params.ps) : defaultPageSize,
	};
}
