import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { faMicrochipAi } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { useEffect, useState } from 'react';

import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import ImageWithFallback from 'components/desktop/_general/Image/ImageWithFallback';
import { SpinnerWhenLoading } from 'components/desktop/_general/Loading/Loading';
import Overlay from 'components/desktop/_general/Overlay/Overlay';

interface IProps {
	description: string;
	displayOverlay: boolean;
	handleAddImage: (input: string, index: number) => void;
	handleGenerate: (nrOfImages: number, description: string) => Promise<string[]>;
	index: number;
	setDisplayOverlay: (input: boolean) => void;
}

const GenerateSegmentImageOverlay = (props: IProps) => {
	const [nrOfImages, setNrOfImages] = useState<number>(3);
	const [description, setDescription] = useState<string>(props.description);
	const [images, setImages] = useState<string[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	useEffect(() => {
		setDescription(props.description);
	}, [props.description]);

	const onNrOfImagesChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setNrOfImages(Number(e.currentTarget.value));
	};

	const onDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
		setDescription(e.currentTarget.value);
	};

	const handleAddImage = (input: string) => {
		props.setDisplayOverlay(false);
		props.handleAddImage(input, props.index);
	};

	const handleGenerate = async () => {
		setIsLoading(true);
		const result = await props.handleGenerate(nrOfImages, description);
		setImages(result);
		setIsLoading(false);
	};

	const getButtonDataAdd = (input: string): BtnData | undefined => {
		return {
			onClick: () => handleAddImage(input),
		};
	};

	const getSource = (input: string): string => {
		return `data:image/jpeg;base64,${input}`;
	};

	const renderButtons = (input: string): JSX.Element => {
		return <BtnGroup add={getButtonDataAdd(input)} />;
	};

	return (
		<Overlay
			containerStyle={{ maxHeight: '100vh' }}
			contentStyle={{ maxHeight: '100vh', width: '800px' }}
			displayOverlay={props.displayOverlay}
			handleClose={() => props.setDisplayOverlay(false)}
		>
			<SpinnerWhenLoading isLoading={isLoading}>
				<>
					<div className="row">
						<div className="col-lg-12">
							<label>{t('_general:DESCRIPTION')}</label>
							<input
								type="text"
								onChange={onDescriptionChange}
								className="form-control"
								value={description}
							/>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-12">
							<label>{t('_general:NUMBER_OF_IMAGES')}</label>
							<input
								type="number"
								onChange={onNrOfImagesChange}
								className="form-control"
								value={nrOfImages}
							/>
						</div>
					</div>
					<br />
					<div className="row justify-content-center">
						{images.length > 0 ? (
							<>
								{images.map((x, i) => {
									return (
										<div className="col-lg-4 margin-top-10" key={i}>
											<ImageWithFallback src={getSource(x)} />
											<div
												className="ai-image-buttons text-center"
												style={{ zIndex: 3000 }}
											>
												{renderButtons(getSource(x))}
											</div>
										</div>
									);
								})}
							</>
						) : (
							<div className="col-lg-4">
								<div
									className="img-thumbnail img-thumbnail-custom"
									style={{ maxHeight: '326px' }}
								>
									<div
										className="picture-big-placeholder d-flex justify-content-center align-items-center"
										style={{ maxHeight: '326px', width: 'auto' }}
									>
										<FontAwesomeIcon
											size="5x"
											icon={faCamera}
											style={{ color: 'white' }}
										/>
									</div>
								</div>
							</div>
						)}
					</div>
					<br />
					<div className="row justify-content-end">
						<div className="col-lg-3 d-flex justify-content-end">
							<button
								type="button"
								className="btn btn-primary"
								onClick={handleGenerate}
							>
								<FontAwesomeIcon icon={faMicrochipAi as IconProp} />
								<span style={{ paddingLeft: '6px' }}>
									{t('_general:GENERATE_PICTURE')}
								</span>
							</button>
						</div>
					</div>
				</>
			</SpinnerWhenLoading>
		</Overlay>
	);
};

export default GenerateSegmentImageOverlay;
