import AccuracyMeter from 'components/desktop/Recipe/RecipeTabs/Components/RecipeErpView/IngredientProductMappingComponent/AccuracyMeter';
import { ValueScope } from 'enums/valueScope';
import useCultureCode from 'hooks/redux/useCultureCode';
import { CalculatedNutrient } from 'types/NutrientValue/CalculatedNutrientValues';
import * as Product from 'types/Recipe/Detail/Product';
import * as ProductList from 'types/Recipe/Detail/ProductList';
import Style from 'types/_general/Style';

interface IProps {
	productMatches: Product.Type[];
	valueScope: ValueScope;
	matchLeftId: string | null;
	matchRightId: string | null;
}

const ErpNutrientTableRow = ({
	nutrient,
	servingSizeQuantityGram,
	props,
}: {
	nutrient: CalculatedNutrient;
	servingSizeQuantityGram: number;
	props: IProps;
}) => {
	const cultureCode = useCultureCode();
	if (nutrient.value >= 0) {
		return (
			<tr>
				<td>
					{`${nutrient.nutrient} `}
					<span className="nutrient-table-unit">{`(${nutrient.unit})`}</span>
				</td>

				<td className="text-end no-wrap" style={style.td}>
					{Product.getSource(nutrient, props.matchLeftId)}
				</td>
				<td className="text-end no-wrap" style={style.td}>
					{Product.getValueString(
						nutrient,
						servingSizeQuantityGram,
						ProductList.getFromId(props.productMatches, props.matchLeftId),
						cultureCode,
						props.valueScope
					)}
				</td>
				<td className="text-end no-wrap" style={style.td}>
					{Product.getSource(nutrient, props.matchRightId)}
				</td>

				<td className="text-end no-wrap" style={style.td}>
					{Product.getValueString(
						nutrient,
						servingSizeQuantityGram,
						ProductList.getFromId(props.productMatches, props.matchRightId),
						cultureCode,
						props.valueScope
					)}
				</td>
				<td style={style.tdAccuracy}>
					<AccuracyMeter
						value={Product.getValue(
							nutrient,
							servingSizeQuantityGram,
							ProductList.getFromId(props.productMatches, props.matchLeftId),
							props.valueScope
						)}
						mean={Product.getValue(
							nutrient,
							servingSizeQuantityGram,
							ProductList.getFromId(props.productMatches, props.matchRightId),
							props.valueScope
						)}
					/>
				</td>
			</tr>
		);
	}
};

const style: Style = {
	td: {
		width: '85px',
		minWidth: '85px',
	},
	tdAccuracy: {
		width: '80px',
		minWidth: '80px',
		display: 'none',
	},
};
export default ErpNutrientTableRow;
