import { ReducerBuilder } from 'reducers/ReducerBuilder';
import { payloadReducer } from 'reducers/reducerUtils';
import * as Co2LabelList from 'types/_general/Store/Co2LabelList';

const rb = new ReducerBuilder<Co2LabelList.Type>();
export const createCo2LabelsLoadedAction = rb.createActionForReducer(
	payloadReducer((x) => x),
	'Co2Labels/LoadedFromBackend'
);

export const co2LabelsReducer = rb.createReducer();
