import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import InputLockButton from 'components/desktop/_general/Input/InputExtensions/InputLockButton/InputLockButton';
import InputTrashButton from 'components/desktop/_general/Input/InputExtensions/InputTrashButton/InputTrashButton';
import RoundedInput from 'components/desktop/_general/Input/RoundedInput/RoundedInput';
import SuggestionSearchInput from 'components/desktop/_general/Input/SuggestionSearchInput/SuggestionSearchInput';
import { Characteristic } from 'enums/characteristic';
import PERMISSIONS from 'enums/permissions';
import { ValueScope } from 'enums/valueScope';
import { Optional } from 'functions/promiseExtensions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { getValueScopeLabel } from 'functions/valueScopeConversion';
import { ISelectItem } from 'interfaces/ISelectItem';
import { Ingredient } from 'types/Ingredient/Ingredient';
import { Nutrient } from 'types/Nutrient/Nutrient';
import { NutrientCategoryGroup } from 'types/Nutrient/NutrientCategoryGroup';
import { NutrientValue } from 'types/NutrientValue/NutrientValue';

interface IProps {
	characteristic: Characteristic | null;
	nutrients: Nutrient[];
	ingredient: Ingredient;
	setIngredient: (input: Ingredient) => void;
}

const IngredientNutrients = (props: IProps) => {
	const { t } = useTranslation();
	const ingredientEditor: boolean = arePermissionsInUserPermissions([
		PERMISSIONS.WRITEINGREDIENT,
	]);

	const [suggestedItems, setSuggestedItems] = useState<ISelectItem[]>([]);

	let nutrientsOfBaseIngredient: string[] = [];
	if (props.ingredient.baseIngredient.nutrientValues) {
		nutrientsOfBaseIngredient = props.ingredient.baseIngredient.nutrientValues.map(
			(x: NutrientValue) => x.id
		);
	}

	const nutrientsOfIngredient: string[] = props.ingredient.nutrientValues.map(
		(x: NutrientValue) => x.id
	);

	const concatNutrients = (): NutrientValue[] => {
		let result: NutrientValue[] = [];
		const baseIngredientNutrientValues = props.ingredient.baseIngredient.nutrientValues;
		const ingredientNutrientValues = props.ingredient.nutrientValues;

		for (const nutrientValue of ingredientNutrientValues) {
			const index = baseIngredientNutrientValues.findIndex((x) => x.id == nutrientValue.id);
			if (index >= 0) {
				baseIngredientNutrientValues.splice(index, 1);
			}
		}

		result = baseIngredientNutrientValues.concat(ingredientNutrientValues);

		return result;
	};

	const handleNutrientValueChange = (nutrientId: string, nutrientValue: number): void => {
		const ingredientCpy: Ingredient = { ...props.ingredient };
		const index = ingredientCpy.nutrientValues.findIndex((x) => x.id == nutrientId);
		ingredientCpy.nutrientValues[index].value = nutrientValue;
		props.setIngredient(ingredientCpy);
	};

	const getNutrientFromPropsWithValueAndSource = (
		id: string,
		value: number,
		source: string | null
	): Optional<Nutrient> => {
		const nutrient = getNutrientFromProps(id);
		return Optional.Maybe(nutrient).map((n) => {
			return { ...n, value, source };
		});
	};

	const getNutrientCategoryGroups = (): NutrientCategoryGroup[] => {
		return concatNutrients()
			.keepOptional((x) => getNutrientFromPropsWithValueAndSource(x.id, x.value, x.source))
			.groupBy((x) => x.nutrientCategory.id)
			.map((k: string, b: Nutrient[]) => {
				return {
					id: k,
					name: b[0].nutrientCategory.name,
					sortOrder: b[0].nutrientCategory.sortOrder,
					nutrients: b.orderBy((x) => x.sortOrder),
				};
			})
			.orderBy((x) => [x.sortOrder, x.name]);
	};

	const getNutrientFromProps = (nutrientId: string): Nutrient | null => {
		const nutrient: Nutrient | undefined = props.nutrients.find((nutrient: Nutrient) => {
			return nutrient.id === nutrientId;
		});
		return nutrient ? nutrient : null;
	};

	const addNutrientToIngredient = (savedItem: ISelectItem): void => {
		const nutrient = getNutrientFromProps(savedItem.id);
		const ingredientCpy: Ingredient = { ...props.ingredient };
		if (nutrient) {
			ingredientCpy.nutrientValues.push({
				id: nutrient.id,
				value: 0,
				source: nutrient.source,
			});
			props.setIngredient(ingredientCpy);
		}
	};

	const removeNutrientFromIngredient = (nutrientId: string): void => {
		const ingredient: Ingredient = { ...props.ingredient };
		ingredient.nutrientValues = ingredient.nutrientValues.filter((x) => x.id !== nutrientId);
		props.setIngredient(ingredient);
	};

	const onLockClick = (nutrientId: string, isLocked: boolean): number => {
		const ingredientCpy: Ingredient = { ...props.ingredient };

		if (isLocked) {
			ingredientCpy.nutrientValues = props.ingredient.nutrientValues.filter(
				(x) => x.id !== nutrientId
			);
			props.setIngredient(ingredientCpy);
			const index = props.ingredient.baseIngredient.nutrientValues.findIndex(
				(x) => x.id == nutrientId
			);
			return props.ingredient.baseIngredient.nutrientValues[index].value;
		} else {
			const index = props.ingredient.baseIngredient.nutrientValues.findIndex(
				(x) => x.id == nutrientId
			);
			ingredientCpy.nutrientValues.push(ingredientCpy.baseIngredient.nutrientValues[index]);
			ingredientCpy.baseIngredient.nutrientValues.splice(index, 1); // nutrientValue needs to be removed from ingredient.baseIngredient.nutrientValues otherwise it will be duplicated on concat because it is existing in ingredient.nutrientValues and ingredient.baseIngredient.nutrientValues
			props.setIngredient(ingredientCpy);
			return ingredientCpy.baseIngredient.nutrientValues[index].value;
		}
	};

	const calculateSuggestedItems = (searchTerm: string): ISelectItem[] => {
		const matchingNutrients: Nutrient[] = props.nutrients.filter((nutrient: Nutrient) => {
			const inBaseIngredient: boolean = nutrientsOfBaseIngredient.includes(nutrient.id);
			const inIngredient: boolean = nutrientsOfIngredient.includes(nutrient.id);
			if (inBaseIngredient || inIngredient) return false;
			if (searchTerm) {
				const name = nutrient.name.toLowerCase();
				searchTerm = searchTerm.toLowerCase();
				return name.startsWith(searchTerm);
			} else {
				return true;
			}
		});

		return matchingNutrients.map((nutrient: Nutrient) => {
			return {
				id: nutrient.id,
				name: `${nutrient.name} (${nutrient.nutrientCategory.name})`,
			};
		});
	};

	const handleChangeSearchTerm = (input: string): void => {
		setSuggestedItems(calculateSuggestedItems(input));
	};

	const renderInputNutrientValues = (nutrient: Nutrient): JSX.Element => {
		const inBaseIngredient: boolean = nutrientsOfBaseIngredient.includes(nutrient.id);
		const inIngredient: boolean = nutrientsOfIngredient.includes(nutrient.id);
		return (
			<>
				<label>{`${nutrient.name} (${nutrient.unitShortNameSingular})`}</label>
				<div className="input-group">
					<RoundedInput
						disabled={!ingredientEditor || (inBaseIngredient && !inIngredient)}
						error-key="NutrientValues"
						handleChange={(input) => handleNutrientValueChange(nutrient.id, input)}
						name={nutrient.name}
						value={nutrient.value}
					/>
					<RenderIf condition={!inBaseIngredient && inIngredient}>
						<InputTrashButton
							id={nutrient.id}
							onTrashCanClick={removeNutrientFromIngredient}
						/>
					</RenderIf>
					<RenderIf condition={inBaseIngredient && !inIngredient}>
						<InputLockButton
							isLocked={inBaseIngredient && !inIngredient}
							onLockClick={(isLocked) => onLockClick(nutrient.id, isLocked)}
						/>
					</RenderIf>
				</div>
			</>
		);
	};

	return (
		<>
			<fieldset className="margin-top-50">
				<legend>{t('_general:NUTRIENTS')}</legend>{' '}
				{getValueScopeLabel(ValueScope.HundredGrams, props.characteristic)}
				<br />
				{ingredientEditor && (
					<>
						<label className="input-label">{t('_general:ADD_NUTRIENT')}</label>
						<div className="input-group mb-3">
							<SuggestionSearchInput
								handleChangeSearchTerm={handleChangeSearchTerm}
								setSavedItem={addNutrientToIngredient}
								triggerSearchLetterAmount={0}
								suggestedItems={suggestedItems}
							/>
						</div>
					</>
				)}
				<div className="row">
					{getNutrientCategoryGroups().map((group: NutrientCategoryGroup, i: number) => (
						<div className="col-6" key={i} style={{ paddingTop: '24px' }}>
							<fieldset
								className="fieldset-white"
								style={{
									height: '700px',
									overflowY: 'auto',
								}}
							>
								<legend>{group.name}</legend>
								{group.nutrients.map((nutrient: Nutrient, i: number) => (
									<div key={i}>{renderInputNutrientValues(nutrient)}</div>
								))}
							</fieldset>
						</div>
					))}
				</div>
			</fieldset>
		</>
	);
};

export default IngredientNutrients;
