import { useAllergens } from 'context/Store';
import { useCategories } from 'context/Store';
import { useNutrientList } from 'context/Store';
import { useSeasons } from 'context/Store';
import { useTagList } from 'context/Store';
import { useUnitList } from 'context/Store';

export default function useBaseIngredientData() {
	const allergens = useAllergens();
	const seasons = useSeasons();
	const tags = useTagList();
	const units = useUnitList();
	const nutrients = useNutrientList();
	const categories = useCategories();

	return {
		allergens,
		seasons,
		tags,
		units,
		nutrients,
		categories,
	};
}
