import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import * as apiGetAllergenStartingWith from 'api/allergen/GetAllergenStartingWithV1';
import * as apiGetBaseIngredientId from 'api/baseIngredient/GetBaseIngredientIdV1';
import * as apiGetBaseIngredientStartingWith from 'api/baseIngredient/GetBaseIngredientStartingWithV1';
import * as apiGetIngredientIdV1 from 'api/ingredient/GetIngredientIdV1';
import * as apiPostIngredientV1 from 'api/ingredient/PostIngredientV1';
import * as apiPutIngredientIdV1 from 'api/ingredient/PutIngredientIdV1';
import { Category as CategoryTree } from 'api/ingredientCategory/GetIngredientCategoryTreeViewStartingWithV1';
import * as apiGetNutrientV1 from 'api/nutrient/GetNutrientV1';
import * as apiGetSeasonStartingWith from 'api/season/GetSeasonStartingWithV1';
import * as apiGetTagsStartingWith from 'api/tag/GetTagStartingWithV1';
import * as apiPostTranslation from 'api/translation/PostTranslationV1';
import * as apiGetUnitByType from 'api/unit/GetUnitByTypeV1';
import 'components/desktop/Ingredient/IngredientComponent.scss';
import * as handlers from 'components/desktop/Ingredient/IngredientComponentHandlers';
import IngredientProducts from 'components/desktop/Ingredient/IngredientProducts/IngredientProducts';
import IngredientLanguageInput from 'components/desktop/Ingredient/LanguageInput/IngredientLanguageInput';
import IngredientNutrients from 'components/desktop/Ingredient/LanguageInput/IngredientNutrients';
import IngredientSidebar from 'components/desktop/Ingredient/Sidebar/IngredientSidebar';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import Co2Display from 'components/desktop/_general/Co2Display/Co2Display';
import Co2Footprints from 'components/desktop/_general/Co2Footprint/Co2Footprints';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import { showConfirmationDialog } from 'components/desktop/_general/ConfirmationDialog/ConfirmationDialog';
import IconComponent from 'components/desktop/_general/Icon/IconComponent';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import NutriScoreDiagram from 'components/desktop/_general/NutriScoreDiagram/NutriScoreDiagram';
import PictureBig from 'components/desktop/_general/PictureBig/PictureBig';
import PictureUpload from 'components/desktop/_general/PictureUpload/PictureUpload';
import SeasonalityBar from 'components/desktop/_general/SeasonalityBar/SeasonalityBar';
import SynonymsComponent from 'components/desktop/_general/Synonyms/Synonyms';
import * as ProductUrlParams from 'enums/Product/Search/UrlParams';
import { EAlertType } from 'enums/alertType';
import { Characteristic } from 'enums/characteristic';
import ENTITLEMENTS from 'enums/entitlements';
import { MEDIACOLLECTION } from 'enums/mediaCollection';
import PERMISSIONS from 'enums/permissions';
import { UnitType } from 'enums/unitType';
import { fireAlert } from 'functions/fireAlert';
import { getPicturePath } from 'functions/getPicturePath';
import getSeasonalityCode from 'functions/getSeasonalityCode';
import { mapToISelectItem } from 'functions/mappers/ISelectItem/mapToISelectItem';
import { mapToIngredient } from 'functions/mappers/Ingredient/mapToIngredient';
import { mapToSaveIngredientDto } from 'functions/mappers/Ingredient/mapToSaveIngredientDto';
import { getPriceString } from 'functions/numberToString';
import { Optional } from 'functions/promiseExtensions';
import scrollToTop from 'functions/scrollToTop';
import {
	areEntitlementsInUserEntitlements,
	arePermissionsInUserPermissions,
} from 'functions/tokenFunctions';
import { uploadMedia } from 'functions/uploadMedia';
import { hide, show } from 'functions/validation/inputValidation';
import { useApi } from 'hooks/useApi';
import { useWarnOnLeave } from 'hooks/useWarnOnLeave';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';
import { AllergenLight } from 'types/Allergen/AllergenLight';
import * as BaseIngredient from 'types/BaseIngredient/Search/BaseIngredient';
import * as BaseIngredientList from 'types/BaseIngredient/Search/BaseIngredientList';
import { Co2Footprint } from 'types/Co2Footprint/Co2Footprint';
import {
	Ingredient,
	BaseIngredient as IngredientBaseIngredient,
	defaultIngredient,
} from 'types/Ingredient/Ingredient';
import { SaveIngredientDto } from 'types/Ingredient/SaveIngredientDto';
import { Language, defaultLanguage } from 'types/Language/Language';
import { Nutrient } from 'types/Nutrient/Nutrient';
import { NutrientValue } from 'types/NutrientValue/NutrientValue';
import * as IngredientProduct from 'types/Product/IngredientProduct';
import { SeasonLight } from 'types/Season/SeasonLight';
import { TagLight } from 'types/Tag/TagLight';
import { PostTranslationDto } from 'types/Translation/PostTranslationDto';
import { UnitLight } from 'types/Unit/UnitLight';
import { UnitWeight } from 'types/UnitWeight/UnitWeight';

interface IProps {
	id?: string;
}

const IngredientComponent = (props: IProps) => {
	const { t } = useTranslation();
	const reduxLanguages: Language[] = useSelector((state: RootState) => state.languages);
	const reduxCurrency: string = useSelector((state: RootState) => state.currency);
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const [allergens, setAllergens] = useState<AllergenLight[]>([]);
	const [seasons, setSeasons] = useState<SeasonLight[]>([]);
	const [tags, setTags] = useState<TagLight[]>([]);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [selectedLanguage, setSelectedLanguage] = useState<Language>(defaultLanguage());
	const [baseIngredients, setBaseIngredients] = useState<BaseIngredientList.Type>([]);
	const [nutrients, setNutrients] = useState<Nutrient[]>([]);
	const [units, setUnits] = useState<UnitLight[]>([]);
	const [ingredient, setIngredient] = useState<Ingredient>(defaultIngredient());
	const { setObjectCachedAsync } = useWarnOnLeave(ingredient, handleSave);
	const [categoryTree] = useApi<CategoryTree[], string>([], handlers.getCategoryTree, '');
	const navigate = useNavigate();

	const ingredientEditor: boolean = arePermissionsInUserPermissions([
		PERMISSIONS.WRITEINGREDIENT,
	]);

	const setIngredientWrapper = (newIngredient: Ingredient) => {
		if (newIngredient.density !== 0 && ingredient.density == 0) {
			hide('Density');
		} else if (newIngredient.density === 0) {
			show('Density');
		}
		return setIngredient(newIngredient);
	};

	useEffect(() => {
		if (reduxCultureCode) {
			initialize();
			const language: Language | undefined = reduxLanguages.find((e: Language) => {
				return e.cultureCode === reduxCultureCode;
			});
			if (language) setSelectedLanguage(language);
		}
	}, [reduxCultureCode, reduxLanguages]);

	const initialize = async (): Promise<void> => {
		scrollToTop();
		setIsLoading(true);
		if (props.id) {
			const ingredient = await getIngredient();
			if (ingredient.hasValue()) {
				setObjectCachedAsync(ingredient.get());
				await getUnitsForBaseIngredientCharacteristic(
					ingredient.get().characteristic ??
						ingredient.get().baseIngredient.characteristic
				);
			}
		} else {
			setObjectCachedAsync(ingredient);
			await getUnitsForBaseIngredientCharacteristic();
		}
		await getActiveNutrients();
		await getTags();
		await getSeasons();
		await getAllergens();
		await getBaseIngredients();
		setIsLoading(false);
	};

	const getTags = async () => {
		const response = await apiGetTagsStartingWith.callApi('', false);
		setTags(response.getOrDefault([]));
	};

	const getAllergens = async (): Promise<void> => {
		const response = await apiGetAllergenStartingWith.callApi('', false);
		setAllergens(response.getOrDefault([]));
	};

	const getAllergensStartingWith = async (searchTerm: string) => {
		const filteredAllergens: AllergenLight[] = allergens.filter((e: AllergenLight) =>
			e.name.toLowerCase().startsWith(searchTerm.toLowerCase())
		);
		return filteredAllergens;
	};

	const getBaseIngredients = async (): Promise<void> => {
		const baseIngredients = await apiGetBaseIngredientStartingWith.callApi('', false);
		baseIngredients.do((x) => setBaseIngredients(x));
	};

	const getSeasons = async (): Promise<void> => {
		if (!areEntitlementsInUserEntitlements([ENTITLEMENTS.SEASON])) return;
		const seasons = await apiGetSeasonStartingWith.callApi('', false);
		seasons.do((x) => setSeasons(x));
	};

	const getSeasonsStartingWith = async (searchTerm: string): Promise<ISelectItem[]> => {
		const filteredSeasons: SeasonLight[] = seasons.filter((e: SeasonLight) =>
			e.name.toLowerCase().startsWith(searchTerm.toLowerCase())
		);

		return mapToISelectItem(filteredSeasons);
	};

	const getBaseIngredientsStartingWith = async (searchTerm: string): Promise<ISelectItem[]> => {
		const filteredBaseIngredients: BaseIngredientList.Type = baseIngredients.filter(
			(e: BaseIngredient.Type) =>
				e.description.toLowerCase().startsWith(searchTerm.toLowerCase())
		);

		return mapToISelectItem(filteredBaseIngredients);
	};

	const getBaseIngredientById = async (
		id: string,
		optionalIngredient?: Ingredient
	): Promise<void> => {
		let ingredientCpy = { ...ingredient };

		if (optionalIngredient) {
			ingredientCpy = optionalIngredient;
		}

		const response = await apiGetBaseIngredientId.callApi(id);
		if (response.hasValue()) {
			const baseIngredient: IngredientBaseIngredient = {
				allergens: response.get().allergens,
				calories: response.get().calories,
				carbonDioxide: response.get().carbonDioxide,
				categories: response.get().categories,
				characteristic: response.get().characteristic,
				density: response.get().density,
				hasNutriScore: response.get().hasNutriScore,
				id: response.get().id,
				name: response.get().nameTranslations[reduxCultureCode],
				nutriScoreCategory: response.get().nutriScoreCategory,
				nutriScoreCategoryIsSetByUser: response.get().nutriScoreCategoryIsSetByUser,
				nutrientValues: response.get().nutrientValues,
				scalingFactor: response.get().scalingFactor,
				seasons: response.get().seasons,
				source: response.get().source,
				tags: response.get().tags,
				unitWeights: response.get().unitWeights,
			};
			ingredientCpy.baseIngredient = baseIngredient;
			getUnitsForBaseIngredientCharacteristic(baseIngredient.characteristic);
			setIngredient(ingredientCpy);
		}
	};

	const getUnitsForBaseIngredientCharacteristic = async (
		characteristic?: Characteristic | null
	) => {
		const getAndSetUnits = async (unitTypes: UnitType[]) => {
			const units: UnitLight[] = [];
			for (const unitType of unitTypes) {
				const response = await apiGetUnitByType.callApi(unitType);
				Array.prototype.push.apply(units, response.getOrDefault([]));
			}

			setUnits(units);
		};

		characteristic =
			characteristic ??
			ingredient.characteristic ??
			ingredient.baseIngredient.characteristic ??
			Characteristic.SolidNonQuantifiable;

		switch (characteristic) {
			case Characteristic.Fluid:
				await getAndSetUnits([UnitType.Container]);
				break;
			case Characteristic.SolidQuantifiable:
				await getAndSetUnits([UnitType.Quantifier]);
				break;
			case Characteristic.SolidNonQuantifiable:
			default:
				await getAndSetUnits([UnitType.Quantifier, UnitType.Container]);
				break;
		}
	};

	const getActiveNutrients = async () => {
		const response = await apiGetNutrientV1.callApi();
		response.do((x) => setNutrients([...x]));
	};

	const getIngredient = async (): Promise<Optional<Ingredient>> => {
		if (!props.id) return Optional.Just(defaultIngredient());
		const data = await apiGetIngredientIdV1.callApi(props.id);
		return data.map((x) => {
			const newIngredient: Ingredient = mapToIngredient(x);
			setIngredient(newIngredient);
			return newIngredient;
		});
	};

	const apiPost = async (
		dto: SaveIngredientDto,
		suppressNavigation?: boolean
	): Promise<Optional<any>> => {
		const response = await apiPostIngredientV1.callApi(dto);
		response.do(async (id) => {
			await setObjectCachedAsync(ingredient);
			if (!suppressNavigation) {
				navigate(`/ingredient/detail/${id}`);
			}
		});
		setIsLoading(false);
		return response;
	};

	const apiPut = async (saveIngredientDto: SaveIngredientDto): Promise<Optional<any>> => {
		const response = await apiPutIngredientIdV1.callApi(props.id ?? '', saveIngredientDto);
		response.do((x) => {
			const ingredientCpy: Ingredient = mapToIngredient(x);
			setIngredient(ingredientCpy);
			setObjectCachedAsync(ingredientCpy);
		});
		setIsLoading(false);
		return response;
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string) => {
		setSelectedLanguage(
			reduxLanguages[
				reduxLanguages.findIndex((element) => element.cultureCode == selectedLanguage)
			]
		);
	};

	const handleCharacteristicLockClick = async (isLocked: boolean) => {
		const ingredientCpy: Ingredient = { ...ingredient };
		if (isLocked) {
			ingredientCpy.characteristic = null;
		} else {
			ingredientCpy.characteristic = ingredient.baseIngredient.characteristic;
		}
		await getUnitsForBaseIngredientCharacteristic(
			ingredientCpy.characteristic ?? ingredientCpy.baseIngredient.characteristic
		);
		setIngredient(ingredientCpy);
	};

	const handleUploadMedia = async (input: FormData | null) => {
		let ingredientCpy = { ...ingredient };
		setIsLoading(true);
		if (input) {
			ingredientCpy = await uploadMedia<Ingredient>(
				ingredientCpy,
				input,
				MEDIACOLLECTION.Ingredient
			);
		}
		setIsLoading(false);
		setIngredient(ingredientCpy);
	};

	type NewType = Optional<any>;

	async function handleSave(suppressNavigation?: boolean): Promise<NewType> {
		setIsLoading(true);

		const ingredientCpy: Ingredient = { ...ingredient };

		if (props.id) {
			return apiPut(mapToSaveIngredientDto(ingredientCpy));
		} else {
			return apiPost(mapToSaveIngredientDto(ingredientCpy), suppressNavigation);
		}
	}

	const handleInputValueChange = (key: keyof Ingredient, value: string) => {
		var ingredientCpy = { ...ingredient };
		ingredientCpy[key][selectedLanguage.cultureCode] = value;
		setIngredient(ingredientCpy);
	};

	const handleTranslate = async () => {
		await handleTranslateInput('nameSingularTranslations');
		await handleTranslateInput('namePluralTranslations');
	};

	const handleTranslateInput = async (name: string) => {
		const ingredientCpy = { ...ingredient };
		const text: string = ingredientCpy[name][reduxCultureCode];

		if (!text) {
			return;
		}

		const translationDto = {
			fromCultureCode: reduxCultureCode,
			toCultureCode: selectedLanguage.cultureCode,
			text: text,
		} as PostTranslationDto;

		const response = await apiPostTranslation.callApi(translationDto);
		ingredientCpy[name][selectedLanguage.cultureCode] = response.getOrDefault('');
		setIngredient(ingredientCpy);
	};

	const getIcons = (): Array<AllergenLight | TagLight> => {
		const output: Array<AllergenLight | TagLight> = [];

		const tags: TagLight[] = ingredient.isTagOverridden
			? ingredient.tags
			: ingredient.baseIngredient.tags;
		for (const tag of tags) {
			if (tag.iconSvgUrl && tag.display) {
				output.push(tag);
			}
		}

		const allergens: AllergenLight[] = ingredient.isAllergenOverridden
			? ingredient.allergens
			: ingredient.baseIngredient.allergens;
		for (const allergen of allergens) {
			if (allergen.iconSvgUrl && allergen.display) {
				output.push(allergen);
			}
		}
		return output;
	};

	const handleSetFormData = (input: FormData | null) => {
		handleUploadMedia(input);
	};

	const handleSetCo2Footprints = (input: Co2Footprint[]) => {
		const ingredientCpy = { ...ingredient };
		ingredientCpy.carbonDioxide = input;
		setIngredient(ingredientCpy);
	};

	const shouldRecalculate = async () => {
		const answer = await showConfirmationDialog(
			t('alerts:ALERT_RECALCULATE'),
			t('alerts:ALERT_RECALCULATE_OK'),
			t('alerts:ALERT_RECALCULATE_NO')
		);

		return answer.isConfirmed;
	};

	const handleCharacteristicChange = async (checked: boolean, elementId: string) => {
		let ingredientCpy: Ingredient = { ...ingredient };
		let recalculate: boolean = false;

		switch (elementId) {
			case Characteristic.Fluid:
				if (ingredient.density === 0) {
					fireAlert(
						EAlertType.danger,
						t('alerts:ALERT_INVALID_INPUT_DATA'),
						t('alerts:ALERT_DENSIITY_NOT_VALID')
					);
					break;
				}
				recalculate = await shouldRecalculate();

				if (checked) {
					ingredientCpy.characteristic = Characteristic.Fluid;
					await getUnitsForBaseIngredientCharacteristic(elementId);
					if (recalculate) {
						handleRecalculateNutrients(Characteristic.Fluid, ingredient.nutrientValues)
							.do((x) => {
								ingredientCpy.nutrientValues = x.nutrientValue;
								ingredientCpy.calories = x.calories;
								ingredientCpy.unitWeights = x.unitWeights;
								ingredientCpy.carbonDioxide = x.carbonDioxide;
							})
							.orElseDo(() => {
								ingredientCpy.characteristic = ingredient.characteristic;
							});
					}
				}
				break;
			case Characteristic.SolidNonQuantifiable:
				if (checked) {
					await getUnitsForBaseIngredientCharacteristic(elementId);
					if (ingredient.characteristic == Characteristic.Fluid) {
						recalculate = await shouldRecalculate();
						ingredientCpy = setToSolidNonQuantifiable(ingredientCpy, recalculate);
					} else {
						ingredientCpy = setToSolidNonQuantifiable(ingredientCpy, false);
					}
				}
				break;
			case Characteristic.SolidQuantifiable:
				if (checked) {
					await getUnitsForBaseIngredientCharacteristic(elementId);
					ingredientCpy.characteristic = Characteristic.SolidQuantifiable;
					if (ingredient.characteristic == Characteristic.Fluid) {
						recalculate = await shouldRecalculate();
						if (recalculate) {
							handleRecalculateNutrients(
								Characteristic.SolidQuantifiable,
								ingredient.nutrientValues
							)
								.do((x) => {
									ingredientCpy.nutrientValues = x.nutrientValue;
									ingredientCpy.calories = x.calories;
									ingredientCpy.unitWeights = x.unitWeights;
									ingredientCpy.carbonDioxide = x.carbonDioxide;
								})
								.orElseDo(() => {
									ingredientCpy.characteristic = ingredient.characteristic;
								});
						}
					}
				}
				break;
		}
		setIngredient(ingredientCpy);
	};

	const handleRecalculateNutrients = (
		characteristic: Characteristic,
		nutrientValues: NutrientValue[]
	): Optional<{
		nutrientValue: NutrientValue[];
		calories: number | null;
		unitWeights: UnitWeight[];
		carbonDioxide: Co2Footprint[];
		price: number | null;
	}> => {
		const result: NutrientValue[] = [];

		const density = ingredient.density || ingredient.baseIngredient.density;
		const factor = characteristic === Characteristic.Fluid ? density : 1 / density;

		for (const nutrientValue of nutrientValues) {
			result.push({
				...nutrientValue,
				value: nutrientValue.value * factor,
			});
		}

		for (const nutrientValue of ingredient.baseIngredient.nutrientValues) {
			if (!result.includes(nutrientValue)) {
				result.push({
					...nutrientValue,
					value: nutrientValue.value * factor,
				});
			}
		}

		const calories = ingredient.calories
			? ingredient.calories * factor
			: ingredient.baseIngredient.calories
			? ingredient.baseIngredient.calories * factor
			: null;

		const unitWeights = ingredient.unitWeights.map((x) => ({
			...x,
			weight: x.weight * factor,
		}));
		const carbonDioxide = ingredient.carbonDioxide.map((x) => ({
			...x,
			value: x.value * factor,
		}));

		const price = ingredient.price && ingredient.price * factor;
		fireAlert(
			EAlertType.success,
			t('alerts:ALERT_RECALCULATE_AUTOMATIC_TITLE'),
			t('alerts:ALERT_RECALCULATE_AUTOMATIC_SUCCESS')
		);

		return Optional.Just({
			nutrientValue: result,
			calories,
			unitWeights,
			carbonDioxide: carbonDioxide,
			price,
		});
	};

	const handleAddProduct = async (): Promise<void> => {
		const response = await handleSave(true);
		if (response.hasValue()) {
			navigate(`/product/search?${ProductUrlParams.UrlParams.IngredientId}=${ingredient.id}`);
		}
	};

	const setToSolidNonQuantifiable = (ingredientCpy: Ingredient, recalculate: boolean) => {
		ingredientCpy.characteristic = Characteristic.SolidNonQuantifiable;
		if (ingredient.characteristic == Characteristic.Fluid) {
			if (recalculate) {
				handleRecalculateNutrients(
					Characteristic.SolidNonQuantifiable,
					ingredient.nutrientValues
				).do((x) => {
					ingredientCpy.nutrientValues = x.nutrientValue;
					ingredientCpy.calories = x.calories;
					ingredientCpy.unitWeights = x.unitWeights;
					ingredientCpy.carbonDioxide = x.carbonDioxide;
					ingredientCpy.price = x.price;
				});
			}
		}
		return ingredientCpy;
	};

	const setIngredientProducts = (products: IngredientProduct.Type[]) => {
		const ingredientCpy = { ...ingredient };
		ingredientCpy.products = products;
		setIngredient(ingredientCpy);
	};

	const getFootprints = (): Co2Footprint[] => {
		if (ingredient.isCarbonDioxideOverridden) {
			return ingredient.carbonDioxide;
		}
		return ingredient.baseIngredient.carbonDioxide;
	};

	const lockToggleFunction = (): void => {
		const ingredientCpy: Ingredient = { ...ingredient };
		if (ingredient.isCarbonDioxideOverridden) {
			ingredientCpy.isCarbonDioxideOverridden = false;
			ingredientCpy.carbonDioxide = [];
		} else {
			ingredientCpy.isCarbonDioxideOverridden = true;
			ingredientCpy.carbonDioxide = ingredientCpy.baseIngredient.carbonDioxide;
		}
		setIngredient(ingredientCpy);
	};

	const getTitle = (): string => {
		if (!props.id) return t('ingredient:NEW_INGREDIENT');
		if (!ingredientEditor) return t('ingredient:VIEW_INGREDIENT');
		return t('ingredient:EDIT_INGREDIENT');
	};

	const getPriceLabel = (): JSX.Element => {
		if (ingredient.characteristic) {
			if (ingredient.characteristic === Characteristic.Fluid) {
				return <label>{`${t('_general:PER')} 100ml`}</label>;
			}
			return <label>{`${t('_general:PER')} 100g`}</label>;
		}
		if (ingredient.baseIngredient.characteristic === Characteristic.Fluid) {
			return <label>{`${t('_general:PER')} 100ml`}</label>;
		}
		return <label>{`${t('_general:PER')} 100g`}</label>;
	};

	return (
		<>
			<LoadingAnimation isLoading={isLoading} />
			<div className="row">
				<div className="d-flex flex-row justify-content-between">
					<div>
						<h1>{getTitle()}</h1>
					</div>
					<div>
						<RenderIf condition={ingredientEditor}>
							<BtnSave handleSave={() => handleSave()} isLoading={isLoading} />
						</RenderIf>
					</div>
				</div>
			</div>
			<br />
			<div className="row">
				<div className="col-md-4 col-xl-3 col-sm-12 col-sx-12 sidebar">
					<PictureBig path={getPicturePath(ingredient.image, 376)} />
					<div className="margin-top-5">
						{ingredientEditor && <PictureUpload setFormData={handleSetFormData} />}
					</div>
					<IngredientSidebar
						categoryTree={categoryTree}
						cultureCode={selectedLanguage.cultureCode}
						handleChangeCategory={handlers.createCategoriesHandler(setIngredient)}
						ingredient={ingredient}
						tags={tags}
						setIngredient={setIngredientWrapper}
						getBaseIngredientsStartingWith={getBaseIngredientsStartingWith}
						handleBaseIngredientChange={getBaseIngredientById}
						getSeasonsStartingWith={getSeasonsStartingWith}
						getAllergensStartingWith={getAllergensStartingWith}
						seasons={seasons}
						nutrients={nutrients}
						units={units}
						handleCharacteristicChange={handleCharacteristicChange}
						handleCharacteristicLockClick={handleCharacteristicLockClick}
					/>
				</div>
				<div className="col-md-8 col-xl-9 col-sm-12 col-12">
					<div className="row">
						<div className="d-flex flex-gap-10 justify-content-end align-items-center">
							{getIcons().map((icon: AllergenLight | TagLight, i: number) => (
								<IconComponent icon={icon} key={i} />
							))}
						</div>
					</div>
					<div className="row">
						<div className="d-flex justify-content-between flex-wrap flex-gap-10 mb-4">
							<div className="d-flex flex-gap-25">
								<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>
									<div>
										{getPriceLabel()}
										<div className="form-control">
											<b>{`${reduxCurrency} ${getPriceString(
												ingredient.price ?? 0,
												reduxCultureCode
											)}`}</b>
										</div>
									</div>
								</RenderIf>
								<RenderIf entitlements={[ENTITLEMENTS.CARBONDIOXIDE]}>
									<Co2Display
										value={ingredient.carbonDioxideValue}
										color={ingredient.carbonDioxideLabel?.color || '#000'}
										fluid={
											ingredient.characteristic ??
											ingredient.baseIngredient.characteristic
										}
										valueScopeInfo={null}
									/>
								</RenderIf>
								<RenderIf entitlements={[ENTITLEMENTS.SEASON]}>
									<div>
										<SeasonalityBar
											seasonalityCode={getSeasonalityCode(
												ingredient.isSeasonOverridden
													? ingredient.seasons
													: ingredient.baseIngredient.seasons
											)}
										/>
									</div>
								</RenderIf>
							</div>
							<RenderIf
								condition={Boolean(
									ingredient.hasNutriScore && ingredient.nutriScore
								)}
								entitlements={[ENTITLEMENTS.NUTRI_SCORE]}
							>
								<div className="d-flex flex-gap-25">
									{ingredient.nutriScore && (
										<NutriScoreDiagram nutriScore={ingredient.nutriScore} />
									)}
								</div>
							</RenderIf>
						</div>
						<LanguageSwitch
							handleSelectLanguage={handleSelectLanguage}
							selectedLanguage={selectedLanguage.cultureCode}
							translatable={true}
							onTranslateClick={handleTranslate}
							entryLanguage={reduxCultureCode}
							content={
								<>
									<IngredientLanguageInput
										handleValueChange={handleInputValueChange}
										ingredient={ingredient}
										onTranslateClick={handleTranslateInput}
										selectedLanguage={selectedLanguage.cultureCode}
									/>
									<br />
									<SynonymsComponent
										input={ingredient}
										setInput={setIngredient}
										cultureCode={selectedLanguage.cultureCode}
										disabled={!ingredientEditor}
									/>
								</>
							}
						/>
						<br />
						<IngredientProducts
							disabled={!ingredientEditor}
							handleAddProduct={handleAddProduct}
							products={ingredient.products}
							setProducts={setIngredientProducts}
						/>
						<br />
						<RenderIf entitlements={[ENTITLEMENTS.CARBONDIOXIDE]}>
							<Co2Footprints
								disabled={!ingredientEditor}
								characteristic={ingredient.characteristic}
								footprints={getFootprints()}
								hasLock={true}
								isLocked={!ingredient.isCarbonDioxideOverridden}
								lockToggleFunction={lockToggleFunction}
								setFootprints={handleSetCo2Footprints}
							/>
						</RenderIf>
						<IngredientNutrients
							characteristic={
								ingredient.characteristic ??
								ingredient.baseIngredient.characteristic
							}
							nutrients={nutrients}
							ingredient={ingredient}
							setIngredient={setIngredient}
						/>
					</div>
				</div>
			</div>
		</>
	);
};

export default IngredientComponent;
