import { useTranslation } from 'react-i18next';

import { ValueScope } from 'enums/valueScope';

interface IProps {
	valueScope: ValueScope;
	setValueScope: (input: ValueScope) => void;
}

const ValueScopeButtons = (props: IProps) => {
	const { t } = useTranslation();
	return (
		<div className="d-flex" style={{ gap: '10px' }}>
			<div className="form-check">
				<input
					className="form-check-input"
					type="radio"
					id="value-scope-100g"
					checked={props.valueScope === ValueScope.HundredGrams}
					onChange={() => {
						props.setValueScope(ValueScope.HundredGrams);
					}}
				/>
				<label className="form-check-label" htmlFor="value-scope-100g">
					{t('measurments:PER_100_G')}
				</label>
			</div>
			<div className="form-check">
				<input
					className="form-check-input"
					type="radio"
					id="value-scope-parts"
					checked={props.valueScope === ValueScope.Parts}
					onChange={() => {
						props.setValueScope(ValueScope.Parts);
					}}
				/>
				<label className="form-check-label" htmlFor="value-scope-parts">
					{t('measurments:PER_PART')}
				</label>
			</div>
		</div>
	);
};

export default ValueScopeButtons;
