import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { setReduxCultureCode } from 'features/CultureCodeFeature';
import { getLanguageString } from 'functions/getLanguageString';
import { RootState } from 'reducers/rootReducer';
import { Language } from 'types/Language/Language';

const NavigationLanguageSwitch = () => {
	const { i18n } = useTranslation();

	const reduxLanguages: Language[] = useSelector((state: RootState) => state.languages);
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const reduxAllowedUiLanguages: string[] = useSelector(
		(state: RootState) => state.allowedUiLanguages
	);
	const dispatch = useDispatch();

	const getClassName = (cultureCode: string): string => {
		if (cultureCode === reduxCultureCode) {
			return 'dropdown-item active';
		}
		return 'dropdown-item ';
	};

	const changeLanguage = async (cultureCode: string) => {
		const language: string = getLanguageString(cultureCode);
		localStorage.setItem('cultureCode', cultureCode);
		i18n.changeLanguage(language);
		dispatch(setReduxCultureCode(cultureCode));
	};

	if (reduxCultureCode) {
		return (
			<li
				className="nav-item dropdown"
				style={{ listStyle: 'none', padding: '0px', color: 'grey' }}
			>
				<div
					className="nav-link dropdown-toggle"
					id="navbarDropdown"
					role="button"
					data-bs-toggle="dropdown"
					aria-expanded="false"
				>
					{reduxCultureCode + ' '}
				</div>
				<ul className="dropdown-menu" aria-labelledby="navbarDropdown">
					{reduxLanguages
						.filter((x) => reduxAllowedUiLanguages.includes(x.cultureCode))
						.map((e: Language, i: number) => (
							<li key={i}>
								<div
									onClick={() => changeLanguage(e.cultureCode)}
									className={getClassName(e.cultureCode)}
								>
									{e.cultureCode}
								</div>
							</li>
						))}
				</ul>
			</li>
		);
	} else {
		return <></>;
	}
};

export default NavigationLanguageSwitch;
