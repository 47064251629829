import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import InputTranslateButton from 'components/desktop/_general/Input/InputExtensions/InputTranslateButton/InputTranslateButton';
import SimpleInput from 'components/desktop/_general/Input/SimpleInput/SimpleInput';
import PERMISSIONS from 'enums/permissions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { RootState } from 'reducers/rootReducer';
import { Language } from 'types/Language/Language';

interface IProps {
	text: string;
	handleValueChange: (input: { cultureCode: string; value: string }) => void;
	onTranslateClick: (name: string) => void;
	selectedLanguage: string;
}

const BaseIngredientLanguageInput = (props: IProps) => {
	const { t } = useTranslation();
	const baseIngredientEditor: boolean = arePermissionsInUserPermissions([
		PERMISSIONS.WRITEBASEINGREDIENT,
	]);
	const reduxLanguages: Language[] = useSelector((state: RootState) => state.languages);
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const handleValueChange = (value: string): void => {
		props.handleValueChange({ cultureCode: props.selectedLanguage, value: value });
	};

	const isTranslatable = (): boolean => {
		if (!baseIngredientEditor) return false;
		if (reduxLanguages && reduxCultureCode !== undefined) {
			if (reduxCultureCode === props.selectedLanguage) {
				return false;
			} else {
				return true;
			}
		}
		return false;
	};

	const renderContent = () => {
		return (
			<>
				<div className="row">
					<div className="col-12">
						<label>{t('_general:NAME')}</label>
						<div className="input-group">
							<SimpleInput
								disabled={!baseIngredientEditor}
								error-key="NameTranslations"
								handleChange={handleValueChange}
								value={props.text}
							/>
							<RenderIf condition={isTranslatable()}>
								<InputTranslateButton
									name="nameTranslations"
									onClick={props.onTranslateClick}
								/>
							</RenderIf>
						</div>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default BaseIngredientLanguageInput;
