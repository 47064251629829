import RangeDouble from 'components/desktop/_general/RangeDouble/RangeDoubleNew';
import * as Slider from 'types/Product/Search/Slider';

interface IProps {
	min: number | null;
	max: number | null;
	left?: number | null;
	right?: number | null;
	minArea: number | null;
	maxArea: number | null;
	handleChange: (left: number | null, right: number | null, id?: string) => void;
	label: string;
	id?: string;
	className?: string;
}

const RangeDoubleLabel = (props: IProps) => {
	const createChangeHandler = () => {
		return (left: number | null, right: number | null, _id?: string) => {
			props.handleChange(left, right, props.id);
		};
	};

	const getAreaMax = (): number => {
		return Slider.getMaxRoundUp(props.maxArea ?? 0);
	};

	const getAreaMin = (): number => {
		return Slider.getMinRoundDown(props.minArea ?? 0);
	};

	const getDefaultControlLeft = (): number => {
		return Slider.getMinRoundDown(props.left ?? props.min ?? 0);
	};

	const getDefaultControlRight = (): number => {
		return Slider.getMaxRoundUp(props.right ?? props.max ?? 0);
	};

	const getTotalMax = (): number => {
		return Slider.getMaxRoundUp(props.max ?? 0);
	};

	const getTotalMin = (): number => {
		return Slider.getMinRoundDown(props.min ?? 0);
	};

	return (
		<div className={props.className ? 'row ' + props.className : 'row'}>
			<div className="col-3 font-sm">{props.label}</div>
			<div className="col-9">
				<RangeDouble
					id={props.id}
					areaMax={getAreaMax()}
					areaMin={getAreaMin()}
					defaultControlLeft={getDefaultControlLeft()}
					defaultControlRight={getDefaultControlRight()}
					handleChange={createChangeHandler()}
					totalMax={getTotalMax()}
					totalMin={getTotalMin()}
				/>
			</div>
		</div>
	);
};

export default RangeDoubleLabel;
