import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import SuggestionSearchInput from 'components/desktop/_general/Input/SuggestionSearchInput/SuggestionSearchInput';
import { ISelectItem } from 'interfaces/ISelectItem';
import { BaseIngredientCategoryLight } from 'types/BaseIngredientCategory/BaseIngredientCategoryLight';
import { BaseIngredientCategoryPoint } from 'types/BaseIngredientCategoryPoints/BaseIngredientCategoryPoint';

interface IProps {
	baseIngredientCategories: BaseIngredientCategoryLight[];
	baseIngredientCategoryPoint: BaseIngredientCategoryPoint;
	setBaseIngredientCategoryPoint: (input: BaseIngredientCategoryPoint) => void;
}

const BaseIngredientSelect = (props: IProps) => {
	const { t } = useTranslation();

	const [suggestedItems, setSuggestedItems] = useState<ISelectItem[]>([]);

	const calculateSuggestedItems = (searchTerm: string): ISelectItem[] => {
		const matchingCategories: BaseIngredientCategoryLight[] = props.baseIngredientCategories
			.filter((x) => x.name)
			.filter((category: BaseIngredientCategoryLight) => {
				const current: boolean =
					props.baseIngredientCategoryPoint.baseIngredientCategoryId === category.id;
				if (current) return false;
				if (searchTerm) {
					const name = category.name.toLowerCase();
					searchTerm = searchTerm.toLowerCase();
					return name.startsWith(searchTerm);
				} else {
					return true;
				}
			});

		return matchingCategories.map((category: BaseIngredientCategoryLight) => {
			return {
				id: category.id,
				name: category.name + ' (' + category.nutriScoreCategory + ')',
			};
		});
	};

	const handleChangeSearchTerm = (input: string): void => {
		setSuggestedItems(calculateSuggestedItems(input));
	};

	const changeCategory = (savedItem: ISelectItem): void => {
		const input: BaseIngredientCategoryPoint = { ...props.baseIngredientCategoryPoint };
		input.baseIngredientCategoryId = savedItem.id;
		input.baseIngredientCategoryName = savedItem.name;
		props.setBaseIngredientCategoryPoint(input);
	};

	return (
		<>
			<label className="input-label">{t('standingData:CHANGE_CATEGORY')}</label>
			<div className="input-group mb-3">
				<SuggestionSearchInput
					className="form-control"
					placeholderText={
						props.baseIngredientCategoryPoint.baseIngredientCategoryName ?? undefined
					}
					errorKey="$.baseIngredientCategoryId"
					placeholderIsBlack={true}
					handleChangeSearchTerm={handleChangeSearchTerm}
					setSavedItem={changeCategory}
					triggerSearchLetterAmount={0}
					suggestedItems={suggestedItems}
				/>
			</div>
		</>
	);
};

export default BaseIngredientSelect;
