import * as Criterion from 'types/Recipe/Detail/Criterion';

export type Type = {
	matchLeftId: string | null;
	matchRightId: string | null;
	search: string;
	criterion: Criterion.Type;
	nrOfMatches: number;
};

export function create(input: { search: string; ingredientId: string | null }): Type {
	return {
		matchLeftId: null,
		matchRightId: null,
		search: input.search ?? '',
		criterion: Criterion.create(input.ingredientId),
		nrOfMatches: 5,
	};
}

export function setSearch(obj: Type, input: string): Type {
	return {
		...obj,
		search: input,
	};
}

export function setMatchLeftId(obj: Type, id: string | null): Type {
	if (obj.matchLeftId === id) {
		return { ...obj, matchLeftId: null };
	}
	return { ...obj, matchLeftId: id };
}

export function setMatchRightId(obj: Type, id: string | null): Type {
	if (obj.matchRightId === id) {
		return { ...obj, matchRightId: null };
	}
	return { ...obj, matchRightId: id };
}

export function setCriterion(obj: Type, input: Criterion.Type): Type {
	return { ...obj, criterion: input };
}

export function setNrOfMatches(state: Type, input: number): Type {
	return {
		...state,
		nrOfMatches: input,
	};
}
