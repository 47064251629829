import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertResult } from 'sweetalert2';

import AccessoryCategory from 'classes/StandingData/AccessoryCategory/Search/AccessoryCategory';
import AccessoryCategoryList from 'classes/StandingData/AccessoryCategory/Search/AccessoryCategoryList';
import Search from 'classes/StandingData/AccessoryCategory/Search/Search';
import AccessoryCategoryDetailComponent from 'components/desktop/StandingData/AccessoryCategory/Detail/AccessoryCategoryDetailComponent';
import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import BtnNew from 'components/desktop/_general/Button/BtnNew/BtnNew';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import AdvancedSearchInput from 'components/desktop/_general/Search/AdvancedSearchInput';
import StandingDataList, {
	Row,
} from 'components/desktop/_general/StandingDataList/StandingDataList';
import clone from 'functions/clone';
import { QueryParams, getQueryParams } from 'functions/getQueryParams';
import useEffectWhenMounted from 'hooks/useEffectWhenMounted';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	archive?: boolean;
}

const maxNumberOfButtons = 5;

const AccessoryCategoryManagementComponent = (props: IProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);

	const [accessoryCategoryList, setAccessoryCategoryList] = useState<AccessoryCategoryList>(
		new AccessoryCategoryList()
	);
	const [search, setSearch] = useState<Search>(new Search());
	const [searchTerm, setSearchTerm] = useState<string>(search.searchTerm.value ?? '');

	useEffect(() => {
		initialize();
	}, []);

	useEffectWhenMounted(() => {
		initialize();
	}, [reduxCultureCode]);

	const initialize = async (): Promise<void> => {
		if (props.archive !== undefined && props.archive == true) {
			search.showOnlyDeleted = true;
		}
		if (window.location.href.includes('?')) {
			const queryParams: QueryParams = getQueryParams();
			search.mapFromUrl(queryParams);
			if (search.id.value) setDisplayOverlay(true);
			await getAccessoryCategories();
		} else {
			await getAccessoryCategories();
		}
		setSearch(clone(search));
	};

	const handleRestoreAccessoryCategory = async (
		accessoryCategory: AccessoryCategory
	): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:RESTORE_TITLE'),
			text: t('standingData:ACCESSORYCATEGORY_RESTORE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#00cc07',
			confirmButtonColor: '#00cc07',
			confirmButtonText: t('_general:RESTORE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await accessoryCategory.callApiRestore();
				await Swal.fire({
					title: t('standingData:ACCESSORYCATEGORY_RESTORE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getAccessoryCategories();
			} catch {
				setIsLoading(false);
			}
		}
	};

	const handleArchiveAccessoryCategory = async (
		accessoryCategory: AccessoryCategory
	): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:ARCHIVE_TITLE'),
			text: t('standingData:ACCESSORYCATEGORY_ARCHIVE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#ff0004',
			confirmButtonColor: '#ff0004',
			confirmButtonText: t('_general:ARCHIVE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await accessoryCategory.callApiDelete();
				await Swal.fire({
					title: t('standingData:ACCESSORYCATEGORY_ARCHIVE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getAccessoryCategories();
			} catch {
				setIsLoading(false);
			}
		}
	};

	const getAccessoryCategories = async () => {
		setIsLoading(true);
		try {
			const response = await accessoryCategoryList.callApi(search.mapToRequest());
			if (response.hasValue()) {
				accessoryCategoryList.mapFromApi(response.get());
				setAccessoryCategoryList(clone(accessoryCategoryList));
			}
		} finally {
			setIsLoading(false);
		}
	};

	const handleChangeId = (id: string | null) => {
		search.id.value = id;
		handleChangeUrl();
	};

	const handleOverlayOpen = (input: string): void => {
		setDisplayOverlay(true);
		handleChangeId(input);
	};

	const handleOverlayClose = (): void => {
		setDisplayOverlay(false);
		handleChangeId(null);
	};

	const handleChangeUrl = () => {
		const url: string | null = search.mapToUrl();
		if (url) navigate(url);
		getAccessoryCategories();
	};

	const handleSearch = (input?: string) => {
		search.setSearchTerm(input ?? searchTerm);
		handleChangeUrl();
	};

	const handleChangePageIndex = (index: number) => {
		search.pageIndex.value = index;
		handleChangeUrl();
	};

	const handleChangeIdToNew = (): void => {
		handleChangeId('new');
		setDisplayOverlay(true);
	};

	const getSuggestedAccessoryCategories = async (): Promise<ISelectItem[]> => {
		if (searchTerm.length >= 1) {
			await accessoryCategoryList.search(searchTerm, props.archive ?? false);
		} else {
			accessoryCategoryList.filtered = [];
		}
		setAccessoryCategoryList(clone(accessoryCategoryList));
		return accessoryCategoryList.filtered;
	};

	const getButtonDataEdit = (id: string): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleOverlayOpen(id),
			};
		}
	};

	const getButtonDataArchive = (input: AccessoryCategory): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleArchiveAccessoryCategory(input),
			};
		}
	};

	const getButtonDataRestore = (input: AccessoryCategory): BtnData | undefined => {
		if (props.archive) {
			return {
				onClick: () => handleRestoreAccessoryCategory(input),
			};
		}
	};

	const renderButtons = (input: AccessoryCategory): JSX.Element => {
		return (
			<BtnGroup
				edit={getButtonDataEdit(input.id)}
				archive={getButtonDataArchive(input)}
				restore={getButtonDataRestore(input)}
			/>
		);
	};

	const renderListRows = (): Row[] => {
		return accessoryCategoryList.all.map((category: AccessoryCategory) => {
			return {
				id: category.id,
				jsx: (
					<div
						className="d-flex flex-row justify-content-start align-items-center"
						style={{ gap: '2em' }}
					>
						{category.parentName ? (
							<>
								<div className="d-flex flex-row" style={{ width: '300px' }}>
									{category.name}
								</div>
								<div className="flex-fill">
									<span className="grey">{t('standingData:PARENT')}: </span>
									{category.parentName}
								</div>
							</>
						) : (
							<div className="flex-fill">{category.name}</div>
						)}
						<div>{renderButtons(category)}</div>
					</div>
				),
			};
		});
	};

	const renderContent = (): JSX.Element => {
		return (
			<main className="container">
				<div className="d-flex flex-row justify-content-between">
					{!props.archive ? (
						<>
							<h1>{t('standingData:ACCESSORYCATEGORY_MANAGEMENT')}</h1>
							<div className="d-flex flex-gap-25">
								<BtnNew
									onClick={handleChangeIdToNew}
									name={t('standingData:CREATE_ACCESSORYCATEGORY')}
								/>
							</div>
						</>
					) : (
						<h1>{t('standingData:ACCESSORYCATEGORY_ARCHIVE')}</h1>
					)}
				</div>
				<br />
				<div className="row">
					<div className="col-lg-12">
						<div className="input-group mb-3">
							<AdvancedSearchInput
								debounceMilliSeconds={1}
								getSuggestedItems={getSuggestedAccessoryCategories}
								handleSearch={handleSearch}
								searchTerm={searchTerm}
								setSearchTerm={setSearchTerm}
								triggerSearchLetterAmount={1}
							/>
						</div>
					</div>
				</div>
				<br />
				<LoadingAnimation isLoading={isLoading} />
				<p>{accessoryCategoryList.totalCount + ' ' + t('_general:ACCESSORYCATEGORIES')}</p>
				<StandingDataList
					archive={props.archive}
					displayOverlay={displayOverlay}
					rows={renderListRows()}
					overlay={<AccessoryCategoryDetailComponent />}
					handleOverlayOpen={handleOverlayOpen}
					handleOverlayClose={handleOverlayClose}
				/>
				<br />
				<Pagination
					countOfAllListItems={accessoryCategoryList.totalCount}
					pageIndex={search.getPageIndex()}
					setPageIndex={handleChangePageIndex}
					listItemAmount={search.getPageSize()}
					maxNumberOfButtons={maxNumberOfButtons}
				/>
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default AccessoryCategoryManagementComponent;
