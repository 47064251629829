import { faCamera } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import ImageWithFallback from 'components/desktop/_general/Image/ImageWithFallback';
import * as Product from 'types/Product/Search/Product';

interface IProps {
	handleAddToIngredient: (id: string) => Promise<void>;
	product: Product.Type;
}

const SearchResultListRow = (props: IProps) => {
	const getButtonDataAdd = (): BtnData | undefined => {
		return {
			onClick: () => props.handleAddToIngredient(props.product.id),
		};
	};

	const getButtons = (): JSX.Element => {
		return <BtnGroup add={getButtonDataAdd()} />;
	};

	const getPicture = (): JSX.Element => {
		if (props.product.imageUrl) {
			return <ImageWithFallback src={props.product.imageUrl} />;
		} else {
			return (
				<div className="card-picture-placeholder d-flex justify-content-center align-items-center">
					<FontAwesomeIcon size="3x" icon={faCamera} />
				</div>
			);
		}
	};

	return (
		<div className="product-table-item">
			{getPicture()}
			<div id="column-first">
				<div id="title">
					<strong>{props.product.nameTranslation}</strong>
				</div>
			</div>
			<div id="column-last">
				<div>{getButtons()}</div>
			</div>
		</div>
	);
};

export default SearchResultListRow;
