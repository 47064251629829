import { useSelector } from 'react-redux';

import ENTITLEMENTS from 'enums/entitlements';
import { Optional } from 'functions/promiseExtensions';
import { areEntitlementsInUserEntitlements } from 'functions/tokenFunctions';
import { RootState } from 'reducers/rootReducer';
import * as SeasonList from 'types/_general/Store/SeasonList';

const EmptyArray = [] as any;
export function useSeasons(): SeasonList.Type {
	if (!areEntitlementsInUserEntitlements([ENTITLEMENTS.SEASON])) return EmptyArray;
	const seasons = useSelector((state: RootState) => state.seasons);
	return Optional.Maybe(seasons).getOrDefault(EmptyArray);
}

export function useAllergens() {
	return useSelector((state: RootState) =>
		Optional.Maybe(state.allergens).getOrDefault(EmptyArray)
	);
}

export function useTagList() {
	return useSelector((state: RootState) =>
		Optional.Maybe(state.tagList).getOrDefault(EmptyArray)
	);
}

export function useMenu() {
	return useSelector((state: RootState) => (state.menu ? state.menu : null));
}

export function useStatusList() {
	return useSelector((state: RootState) =>
		Optional.Maybe(state.statusList).getOrDefault(EmptyArray)
	);
}

export function useCo2Labels() {
	return useSelector((state: RootState) =>
		Optional.Maybe(state.co2Labels).getOrDefault(EmptyArray)
	);
}

export function useProductRanges() {
	return useSelector((state: RootState) =>
		Optional.Maybe(state.productRanges).getOrDefault(EmptyArray)
	);
}

export function useCategories() {
	return useSelector((state: RootState) =>
		Optional.Maybe(state.categories).getOrDefault(EmptyArray)
	);
}
export function useNutrientList() {
	return useSelector((state: RootState) =>
		Optional.Maybe(state.nutrientList).getOrDefault(EmptyArray)
	);
}

export function useUnitList() {
	return useSelector((state: RootState) =>
		Optional.Maybe(state.unitList).getOrDefault(EmptyArray)
	);
}
