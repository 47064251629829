import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as apiGet from 'api/channel/GetChannelIdV1';
import Channel from 'classes/StandingData/Channel/Channel';
import ChannelLanguageInput from 'components/desktop/StandingData/Channel/LanguageInput/ChannelLanguageInput';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import PictureUpload from 'components/desktop/_general/PictureUpload/PictureUpload';
import SvgBig from 'components/desktop/_general/SvgBig/SvgBig';
import clone from 'functions/clone';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import { RootState } from 'reducers/rootReducer';

const ChannelDetailComponent = () => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
	const [selectedCultureCode, setSelectedCultureCode] = useState<string>('');

	const [channel, setChannel] = useState<Channel>(new Channel());
	const [id, setId] = useState<string | null>(null);

	useEffect(() => {
		initialize();
		setSelectedCultureCode(reduxCultureCode);
	}, []);

	const initialize = async (): Promise<void> => {
		const id: string | null = getIdFromUrl();
		setId(id);
		if (id !== null && id !== 'new') {
			getChannel(id);
		}
	};

	const getChannel = async (id: string): Promise<void> => {
		setIsLoading(true);
		const response = await apiGet.callApi(id);
		response.hasValue() && setChannel(new Channel(response.get()));
		setIsLoading(false);
	};

	const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		channel.nameTranslations[selectedCultureCode] = e.currentTarget.value;
		setChannel(clone(channel));
	};

	const handleTranslate = async () => {
		setIsLoading(true);
		await channel.callApiTranslation(reduxCultureCode, selectedCultureCode);
		setChannel(clone(channel));
		setIsLoading(false);
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string) => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSaveChannel = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			if (id !== null && id !== 'new') {
				await channel.callApiPut();
			} else {
				await channel.callApiPost();
				if (!channel.id) return;
				setId(channel.id);
				setIdToUrl(channel.id);
			}
		} catch {
			setIsLoadingSave(false);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const setPictureUpload = async (input: FormData | null): Promise<void> => {
		if (input) {
			await channel.callApiUploadMedia(input);
			setChannel(clone(channel));
		}
	};

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={isLoading} />
				<div className="row">
					<div className="d-flex flex-row justify-content-between mb-3">
						<div>
							{channel.id ? (
								<>
									<h1>{t('standingData:EDIT_CHANNEL')}</h1>
								</>
							) : (
								<>
									<h1>{t('standingData:NEW_CHANNEL')}</h1>
								</>
							)}
						</div>
						<div>
							<BtnSave
								handleSave={() => handleSaveChannel()}
								isLoading={isLoadingSave}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4 col-xl-3 col-lg-3 col-sm-4 col-12">
						<SvgBig url={channel.iconSvg?.url ?? null} width={120} height={120} />
						<PictureUpload setFormData={setPictureUpload} accept="image/svg+xml" />
					</div>
					<div className="col-mg-8 col-xl-9 col-sm-8 col-12">
						<LanguageSwitch
							handleSelectLanguage={handleSelectLanguage}
							selectedLanguage={selectedCultureCode}
							translatable={true}
							onTranslateClick={handleTranslate}
							entryLanguage={reduxCultureCode}
							content={
								<ChannelLanguageInput
									channel={channel}
									handleValueChange={handleInputValueChange}
									selectedLanguage={selectedCultureCode}
								/>
							}
						/>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default ChannelDetailComponent;
