import { t } from 'i18next';

import RoundedInput from 'components/desktop/_general/Input/RoundedInput/RoundedInput';
import PERMISSIONS from 'enums/permissions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { Recipe } from 'types/Recipe/Recipe';

interface IProps {
	recipe: Recipe;
	handleSetRecipeAttribute: (key: keyof Recipe, value: number | null) => void;
}

const RecipeScalingEditor = (props: IProps) => {
	const recipeEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITERECIPE]);

	const handleValueChange = (id: string, value: number): void => {
		if (id === 'scaledPartsInput') {
			props.handleSetRecipeAttribute('scaledParts', value > 0 ? value : null);
		} else if (id === 'scaledPersonsInput') {
			props.handleSetRecipeAttribute('scaledPersons', value > 0 ? value : null);
		}
	};

	return (
		<>
			<div>
				<label>{t('recipe:SCALED_PARTS_COUNT')}</label>
				<RoundedInput
					disabled={!recipeEditor}
					error-key="ScaledParts"
					id="scaledPartsInput"
					value={props.recipe.scaledParts ?? 0}
					handleChange={(input) => handleValueChange('scaledPartsInput', input)}
				/>
			</div>
			<div>
				<label>{t('recipe:SCALED_PARTS_PERSONS')}</label>
				<RoundedInput
					disabled={!recipeEditor}
					error-key="ScaledPersons"
					id="scaledPersonsInput"
					value={props.recipe.scaledPersons ?? 0}
					handleChange={(input) => handleValueChange('scaledPersonsInput', input)}
				/>
			</div>
		</>
	);
};

export default RecipeScalingEditor;
