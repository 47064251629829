import { useMemo } from 'react';

import { IncorporationShare } from 'api/recipe/GetRecipeIdV1';
import * as recipeHandlers from 'components/desktop/Recipe/RecipeComponentHandlers';
import { IngredientsProductMappingComponent } from 'components/desktop/Recipe/RecipeTabs/Components/RecipeErpView/IngredientProductMappingComponent/IngredientsProductMappingComponent2';
import useRecipeErpViewData from 'components/desktop/Recipe/RecipeTabs/Components/RecipeErpView/useRecipeErpViewData';
import useRecipeErpViewState from 'components/desktop/Recipe/RecipeTabs/Components/RecipeErpView/useRecipeErpViewState';
import Co2Display from 'components/desktop/_general/Co2Display/Co2Display';
import NutriScoreDiagram from 'components/desktop/_general/NutriScoreDiagram/NutriScoreDiagram';
import NutritionDiagram from 'components/desktop/_general/NutritionDiagram/NutritionDiagram';
import NutritionFactsLabelWrapper from 'components/desktop/_general/NutritionFactsLabel/RecipeWrapper';
import PriceDisplay from 'components/desktop/_general/PriceDisplay';
import { IngredientType } from 'enums/ingredientType';
import { ValueScopeInfo } from 'functions/valueScopeConversion';
import useCurrency from 'hooks/redux/useCurrency';
import { useApi } from 'hooks/useApi';
import { Recipe } from 'types/Recipe/Recipe';
import { Segment } from 'types/Recipe/Segment';
import * as SegmentList from 'types/Recipe/SegmentList';

type IncorporationShareWithIndex = IncorporationShare & { index: number };

type IProps = {
	segments: Segment[];
	recipe: Recipe;
	incorporationShares: IncorporationShareWithIndex[];
};

export const RecipeErpView = (props: IProps) => {
	const { state, handlers } = useRecipeErpViewState();
	const data = useRecipeErpViewData();

	const currency: string = useCurrency();

	const segments = useMemo((): Segment[] => {
		return props.segments.map((segment) => ({
			...segment,
			ingredients: segment.ingredients.map((i) => ({
				...i,
				productId:
					i.ingredientId && state.productMappings[i.ingredientId]
						? state.productMappings[i.ingredientId].externalId
						: null,
			})),
		}));
	}, [state.productMappings, props.segments]);

	const [recipeCalculation] = useApi(
		null,
		(segments: Segment[], incorporationShares: IncorporationShareWithIndex[]) => {
			return recipeHandlers.calculate(segments, incorporationShares);
		},
		SegmentList.mergeWithProductMappings(segments, state.productMappings),
		props.incorporationShares
	);

	const [nutriScore] = useApi(
		null,
		async (segments: Segment[], hasNutriScore: boolean) => {
			if (hasNutriScore) return await recipeHandlers.getNutriScore(segments);
			else return null;
		},
		segments,
		props.recipe.hasNutriScore,
		props.recipe.nutriScoreCategory
	);

	const nutrients = recipeCalculation?.nutrientValues.items ?? [];

	const getMacroNutrientChartData = (): { label: string; value: number }[] => {
		if (recipeCalculation && nutrients) {
			const macroWeight = nutrients
				.filter((n) => n.isMacroNutrient)
				.reduce((p, c) => p + c.total, 0);
			const macros = nutrients
				.filter((n) => n.isMacroNutrient)
				.map((n) => {
					return { label: n.nutrient, value: n.total / macroWeight };
				});
			return macros;
		}
		return [];
	};

	const getValueScopeInfo = (): ValueScopeInfo => {
		return {
			valueScope: state.valueScope,
			persons: props.recipe.persons,
			parts: props.recipe.parts,
			weight:
				recipeCalculation?.weight ?? props.recipe.weight ?? props.recipe.ingredientWeight,
		};
	};

	return (
		<div className="margin-top-25">
			<div className="row">
				<div className="col-lg-9">
					<fieldset>
						<IngredientsProductMappingComponent
							calculation={recipeCalculation}
							setSelectedColumns={handlers.setSelectedColumns}
							setValueScope={handlers.setValueScope}
							selectedColumns={state.selectedColumns}
							valueScope={state.valueScope}
							nutritionSources={data.nutritionSources}
							deSelectProduct={handlers.deSelectProduct}
							ingredients={props.segments
								.flatMap((x) => x.ingredients)
								.filter((e) => e.type === IngredientType.Ingredient)}
							nutriScore={nutriScore}
							productMappings={state.productMappings}
							recipe={props.recipe}
							selectProduct={handlers.selectProduct}
						/>
					</fieldset>
				</div>
				<div className="col-lg-3">
					<fieldset className="d-flex flex-column" style={{ gap: '15px' }}>
						<NutritionFactsLabelWrapper
							calculation={recipeCalculation}
							recipe={props.recipe}
						/>
						<div>
							<NutriScoreDiagram
								nutriScore={nutriScore ?? props.recipe.nutriScore ?? null}
							/>
						</div>
						<div>
							<NutritionDiagram data={getMacroNutrientChartData()} />
						</div>
						<div>
							<Co2Display
								value={
									recipeCalculation?.carbonDioxideValue ??
									props.recipe.carbonDioxideValue
								}
								color={
									recipeCalculation?.carbonDioxideLabel?.color ??
									props.recipe.carbonDioxideLabel?.color ??
									'#000'
								}
								valueScopeInfo={getValueScopeInfo()}
							/>
						</div>
						<PriceDisplay
							value={recipeCalculation?.price ?? 0}
							currency={currency}
							valueScopeInfo={getValueScopeInfo()}
						/>
					</fieldset>
				</div>
			</div>
		</div>
	);
};
