import { t } from 'i18next';
import { useSelector } from 'react-redux';

import FoldableCard from 'components/desktop/_general/Card/FoldableCard/FoldableCard';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import InputTranslateButton from 'components/desktop/_general/Input/InputExtensions/InputTranslateButton/InputTranslateButton';
import SimpleInput from 'components/desktop/_general/Input/SimpleInput/SimpleInput';
import TranslatableTextArea from 'components/desktop/_general/TranslatableTextArea/TranslatableTextArea';
import PERMISSIONS from 'enums/permissions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { RootState } from 'reducers/rootReducer';
import { Language } from 'types/Language/Language';
import { Recipe } from 'types/Recipe/Recipe';

interface IProps {
	recipe: Recipe;
	selectedLanguage: string;
	entryLanguage: string;
	handleValueChange: (key: keyof Recipe, value: string) => void;
	onTranslateClick: (name: string) => void;
}

const RecipeLanguageInput = (props: IProps) => {
	const recipeEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITERECIPE]);

	const reduxLanguages: Language[] = useSelector((state: RootState) => state.languages);
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const createHandleValueChangeFunction = (key: keyof Recipe): ((value: string) => void) => {
		return function (value: string) {
			props.handleValueChange(key, value);
		};
	};

	const isTranslatable = (): boolean => {
		if (!recipeEditor) return false;

		if (props.entryLanguage === props.selectedLanguage) {
			return false;
		}

		return true;
	};

	if (reduxCultureCode !== undefined && reduxLanguages !== undefined) {
		return (
			<>
				<div className="recipe-language-input-rows">
					<div className="row">
						<div className="col-12">
							<label>{t('recipe:RECIPE_NAME')}</label>
							<div className="input-group">
								<SimpleInput
									value={
										props.recipe.titleTranslations[props.selectedLanguage] || ''
									}
									handleChange={createHandleValueChangeFunction(
										'titleTranslations'
									)}
									disabled={!recipeEditor}
									error-key="TitleTranslations"
								/>
								<RenderIf condition={isTranslatable()}>
									<InputTranslateButton
										name="titleTranslations"
										onClick={props.onTranslateClick}
									/>
								</RenderIf>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-12">
							<FoldableCard
								defaultOpen={props.recipe.isSeoActive}
								title={t('recipe:SEO')}
								content={
									<>
										<TranslatableTextArea
											value={
												props.recipe.seoTranslations[
													props.selectedLanguage
												] || ''
											}
											name="seoTranslations"
											rows={4}
											handleValueChange={createHandleValueChangeFunction(
												'seoTranslations'
											)}
											translatable={isTranslatable()}
											onTranslateClick={props.onTranslateClick}
											disabled={!recipeEditor}
											errorKey="SeoTranslations"
										/>
									</>
								}
							/>
						</div>
					</div>
				</div>
			</>
		);
	} else {
		return <></>;
	}
};

export default RecipeLanguageInput;
