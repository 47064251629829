import { Dispatch, SetStateAction, useState } from 'react';

import * as handlers from 'components/desktop/Recipe/RecipeComponentHandlers';
import GenerateSegmentImageOverlay from 'components/desktop/Recipe/RecipeTabs/SegmentMediaView/GenerateSegmentImageOverlay';
import SegmentMediaManagement from 'components/desktop/_general/MediaManagement/SegmentMediaManagement';
import { SegmentMedia } from 'types/Media/SegmentMedia/SegmentMedia';
import { Recipe } from 'types/Recipe/Recipe';

interface IProps {
	cultureCode: string;
	handleDeleteSegmentMedia: (index: number, id: string, video: boolean) => void;
	handleUploadMedia: (index: number, files: File[], video: boolean) => void;
	images: SegmentMedia[];
	index: number;
	instruction: string;
	readOnly: boolean;
	setRecipe: Dispatch<SetStateAction<Recipe>>;
	videos: SegmentMedia[];
}

const SegmentImageView = (props: IProps) => {
	const [displayGenerateImageOverlay, setDisplayGenerateImageOverlay] = useState<boolean>(false);

	const handleGenerateImages = async (nrOfImages: number, description: string) => {
		return await handlers.generateAiImages(nrOfImages, description, props.cultureCode);
	};
	const handleUploadMedia = (input: File[], video: boolean): void => {
		props.handleUploadMedia(props.index, input, video);
	};

	const handleDeleteImage = (id: string): void => {
		props.handleDeleteSegmentMedia(props.index, id, false);
	};

	const handleDeleteVideo = (id: string): void => {
		props.handleDeleteSegmentMedia(props.index, id, true);
	};

	return (
		<div style={{ paddingLeft: '10px', height: '100%' }}>
			<GenerateSegmentImageOverlay
				description={props.instruction}
				displayOverlay={displayGenerateImageOverlay}
				handleAddImage={handlers.createUploadAiSegmentFileHandler(props.setRecipe)}
				handleGenerate={handleGenerateImages}
				index={props.index}
				setDisplayOverlay={setDisplayGenerateImageOverlay}
			/>
			<SegmentMediaManagement
				handleDeleteImage={handleDeleteImage}
				handleDeleteVideo={handleDeleteVideo}
				images={props.images}
				readOnly={props.readOnly}
				setDisplayGenerateImageOverlay={setDisplayGenerateImageOverlay}
				uploadMedia={handleUploadMedia}
				videos={props.videos}
			/>
		</div>
	);
};

export default SegmentImageView;
