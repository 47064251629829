import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import InputTrashButton from 'components/desktop/_general/Input/InputExtensions/InputTrashButton/InputTrashButton';
import RoundedInput from 'components/desktop/_general/Input/RoundedInput/RoundedInput';
import SuggestionSearchInput from 'components/desktop/_general/Input/SuggestionSearchInput/SuggestionSearchInput';
import { Characteristic } from 'enums/characteristic';
import PERMISSIONS from 'enums/permissions';
import { ValueScope } from 'enums/valueScope';
import { Optional } from 'functions/promiseExtensions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { getValueScopeLabel } from 'functions/valueScopeConversion';
import { ISelectItem } from 'interfaces/ISelectItem';
import * as NutrientValue from 'types/BaseIngredient/Detail/NutrientValue';
import * as NutrientValueList from 'types/BaseIngredient/Detail/NutrientValueList';
import { Nutrient } from 'types/Nutrient/Nutrient';
import { NutrientCategoryGroup } from 'types/Nutrient/NutrientCategoryGroup';

interface IProps {
	characteristic: Characteristic | null;
	allNutrients: Nutrient[];
	nutrients: NutrientValueList.Type;
	setNutrients: (input: NutrientValueList.Type) => void;
}

const BaseIngredientNutrients = (props: IProps) => {
	const { t } = useTranslation();
	const baseIngredientEditor: boolean = arePermissionsInUserPermissions([
		PERMISSIONS.WRITEBASEINGREDIENT,
	]);

	const [suggestedItems, setSuggestedItems] = useState<ISelectItem[]>([]);

	const handleNutrientValueChange = (nutrientId: string, nutrientValue: number): void => {
		const nutrients: NutrientValueList.Type = [...props.nutrients];
		const nutrient: NutrientValue.Type | undefined = nutrients.find((e) => e.id === nutrientId);
		if (nutrient) {
			nutrient.value = nutrientValue;
		} else {
			const newNutrient: NutrientValue.Type | undefined = props.allNutrients.find(
				(e) => e.id === nutrientId
			);
			if (newNutrient) {
				nutrients.push({ ...newNutrient, value: nutrientValue });
			}
		}
		props.setNutrients(nutrients);
	};

	const getNutrientFromPropsWithValueAndSource = (
		id: string,
		value: number,
		source: string | null
	): Optional<Nutrient> => {
		const nutrient = getNutrientFromProps(id);
		return Optional.Maybe(nutrient).map((n) => {
			return { ...n, value, source };
		});
	};

	const getNutrientCategoryGroups = (): NutrientCategoryGroup[] => {
		return props.nutrients
			.keepOptional((x) => getNutrientFromPropsWithValueAndSource(x.id, x.value, x.source))
			.groupBy((x) => x.nutrientCategory.id)
			.map((k: string, nutrients: Nutrient[]) => {
				return {
					id: k,
					name: nutrients[0].nutrientCategory.name,
					sortOrder: nutrients[0].nutrientCategory.sortOrder,
					nutrients: nutrients.orderBy((x) => x.sortOrder),
				};
			})
			.orderBy((x) => [x.sortOrder, x.name]);
	};

	const getNutrientFromProps = (nutrientId: string): Nutrient | null => {
		const nutrient: Nutrient | undefined = props.allNutrients.find((nutrient: Nutrient) => {
			return nutrient.id === nutrientId;
		});
		return nutrient ? nutrient : null;
	};

	const addNutrientValue = (savedItem: ISelectItem): void => {
		handleNutrientValueChange(savedItem.id, 0);
	};

	const removeNutrientFromBaseIngredient = (nutrientId: string): void => {
		props.setNutrients(props.nutrients.filter((x) => x.id !== nutrientId));
	};

	const calculateSuggestedItems = (searchTerm: string): ISelectItem[] => {
		const matchingNutrients: Nutrient[] = props.allNutrients.filter((nutrient: Nutrient) => {
			const inBaseIngredient: boolean = props.nutrients
				.map((e) => e.id)
				.includes(nutrient.id);
			if (inBaseIngredient) return false;
			if (searchTerm) {
				const name = nutrient.name.toLowerCase();
				searchTerm = searchTerm.toLowerCase();
				return name.startsWith(searchTerm);
			} else {
				return true;
			}
		});

		return matchingNutrients.map((nutrient: Nutrient) => {
			return {
				id: nutrient.id,
				name: `${nutrient.name} (${nutrient.nutrientCategory.name})`,
			};
		});
	};

	const handleChangeSearchTerm = (input: string): void => {
		setSuggestedItems(calculateSuggestedItems(input));
	};

	const renderInputNutrientValues = (nutrient: Nutrient): JSX.Element => {
		return (
			<>
				<label>{`${nutrient.name} (${nutrient.unitShortNameSingular})`}</label>
				<div className="input-group">
					<RoundedInput
						disabled={!baseIngredientEditor}
						error-key="NutrientValues"
						handleChange={(input) => handleNutrientValueChange(nutrient.id, input)}
						title={nutrient.source ?? undefined}
						id={nutrient.id}
						value={nutrient.value}
					/>
					<InputTrashButton
						id={nutrient.id}
						onTrashCanClick={removeNutrientFromBaseIngredient}
					/>
				</div>
			</>
		);
	};

	return (
		<>
			<fieldset>
				<legend>{t('_general:NUTRIENTS')}</legend>{' '}
				{getValueScopeLabel(ValueScope.HundredGrams, props.characteristic)}
				<br />
				{baseIngredientEditor && (
					<>
						<label className="input-label">{t('_general:ADD_NUTRIENT')}</label>
						<div className="input-group mb-3">
							<SuggestionSearchInput
								className="form-control"
								handleChangeSearchTerm={handleChangeSearchTerm}
								setSavedItem={addNutrientValue}
								triggerSearchLetterAmount={0}
								suggestedItems={suggestedItems}
							/>
						</div>
					</>
				)}
				<div className="row">
					{getNutrientCategoryGroups().map((group: NutrientCategoryGroup, i: number) => (
						<div className="col-6" key={i} style={{ paddingTop: '24px' }}>
							<fieldset
								className="fieldset-white"
								style={{
									height: '700px',
									overflowY: 'auto',
								}}
							>
								<legend>{group.name}</legend>
								{group.nutrients.map((nutrient: Nutrient, i: number) => (
									<div key={i}>{renderInputNutrientValues(nutrient)}</div>
								))}
							</fieldset>
						</div>
					))}
				</div>
			</fieldset>
		</>
	);
};

export default BaseIngredientNutrients;
