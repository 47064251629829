import * as Product from 'types/Recipe/Detail/Product';

export type Type = Product.Type[];

export function create(): Type {
	return [];
}

export function getFromId(obj: Type, id: string | null): Product.Type | null {
	if (!id) return null;
	const output: Product.Type | undefined = obj.find((e) => e.id === id);
	if (!output) return null;
	return output;
}

export function getServingSize(obj: Type, matchLeftId: string | null, matchRightId: string | null) {
	if (matchLeftId) {
		const product = getFromId(obj, matchLeftId);
		if (product) return product.servingSizeQuantityGram ?? 100;
	}
	if (matchRightId) {
		const product = getFromId(obj, matchRightId);
		if (product) return product.servingSizeQuantityGram ?? 100;
	}

	return 100;
}
