import axios from 'axios';

import { NutriScoreCategoryIngredientCategory } from 'enums/NutriScoreCategory/NutriScoreCategoryIngredientCategory';
import { ProductType } from 'enums/productType';
import { Optional } from 'functions/promiseExtensions';

export function callApi(id: string): Promise<Optional<ResponseData>> {
	const url: string = `/backend-service/Ingredient/${id}`;
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	allergens: Allergen[];
	baseIngredient: BaseIngredient;
	calories: number | null;
	carbonDioxide: CarbonDioxide[];
	carbonDioxideLabel: CarbonDioxideLabel | null;
	carbonDioxideValue: number;
	categories: Category[];
	characteristic: Characteristic | null;
	density: number | null;
	hasNutriScore: boolean;
	id: string;
	image: Image | null;
	isAllergenOverridden: boolean;
	isCarbonDioxideOverridden: boolean;
	isScalingFactorOverridden: boolean;
	isSeasonOverridden: boolean;
	isTagOverridden: boolean;
	isUnitWeightOverridden: boolean;
	namePluralTranslations: Translation;
	nameSingularTranslations: Translation;
	nutriScore: NutriScore | null;
	nutriScoreCategory: NutriScoreCategory | null;
	nutriScoreCategoryIsSetByUser: boolean;
	nutrientValues: NutrientValue[];
	price: number | null;
	products: Product[];
	scalingFactor: number | null;
	seasons: Season[];
	source: string | null;
	synonyms: Synonyms;
	tags: Tag[];
	unitWeights: UnitWeight[];
};

export type Product = {
	id: string;
	name: string;
	description: string;
	externalId: string | null;
	brand: string | null;
	producer: string | null;
	isActive: boolean;
	isCarbonDioxideOverridden: boolean;
	carbonDioxideValue: number | null;
	imageUrl: string | null;
	quantity: string | null;
	type: ProductType;
	price: number | null;
};

export type BaseIngredientUnitWeight = {
	id: string;
	name: string;
	weight: number;
	source: string | null;
};

export type BaseIngredientSeason = {
	id: string;
	iconSvgUrl: string | null;
	name: string;
	display: boolean;
	associatedMonthsFlag: number;
	sortOrder: number;
	source: string | null;
};

export type BaseIngredientNutrientValues = {
	id: string;
	value: number;
	source: string | null;
};

export type BaseIngredientTag = {
	id: string;
	iconSvgUrl: string | null;
	display: boolean;
	name: string;
	tagCategory: BaseIngredientTagCategory;
	source: string | null;
};

export type BaseIngredientTagCategory = {
	id: string;
	name: string;
};

export type BaseIngredientAllergen = {
	id: string;
	iconSvgUrl: string | null;
	name: string;
	display: boolean;
	logic: AllergenLogic;
	source: string | null;
};

export type BaseIngredientCategory = {
	id: string;
	name: string;
	parentName: string;
	nutriScoreCategory: string;
};

export type BaseIngredientCarbonDioxide = {
	originLocationId: string;
	origin: string;
	productionMethodId: string;
	productionMethod: string;
	value: number;
	source: string | null;
};

export type BaseIngredient = {
	allergens: BaseIngredientAllergen[];
	calories: number | null;
	carbonDioxide: BaseIngredientCarbonDioxide[];
	categories: BaseIngredientCategory[];
	characteristic: Characteristic;
	density: number;
	hasNutriScore: boolean;
	id: string | null;
	name: string;
	nutriScoreCategory: NutriScoreCategory | null;
	nutriScoreCategoryIsSetByUser: boolean;
	nutrientValues: BaseIngredientNutrientValues[];
	scalingFactor: number;
	seasons: BaseIngredientSeason[];
	source: string | null;
	tags: BaseIngredientTag[];
	unitWeights: BaseIngredientUnitWeight[];
};

export type Image = {
	id: string;
	url: string;
	availableSizes: number[];
};

export type NutriScore = {
	label: string;
	color: string;
};

export type CarbonDioxideLabel = {
	label: string;
	color: string;
};

export type Translation = {
	[key: string]: string;
};

export type UnitWeight = {
	id: string;
	name: string;
	weight: number;
	source: string | null;
};

export type NutrientValue = {
	id: string;
	value: number;
	source: string | null;
};

export type Category = {
	id: string;
	name: string;
	nutriScoreCategory: NutriScoreCategoryIngredientCategory | null;
	source: string | null;
};

export type Synonyms = {
	[key: string]: Synonym[];
};

export type Synonym = {
	id: string | null;
	nameSingular: string;
	namePlural: string;
};

export type IconData = {
	display: boolean;
	name: string;
	iconSvgUrl: string | null;
};

export type Tag = IconData & {
	id: string;
	tagCategory: TagCategory;
	source: string | null;
};

export type TagCategory = {
	id: string;
	name: string;
};

export type Allergen = IconData & {
	id: string;
	logic: AllergenLogic;
	source: string | null;
};

export type Season = {
	id: string;
	iconSvgUrl: string | null;
	name: string;
	display: boolean;
	associatedMonthsFlag: number;
	sortOrder: number;
	source: string | null;
};

export type CarbonDioxide = {
	productionMethodId: string;
	originLocationId: string;
	origin: string;
	productionMethod: string;
	value: number;
	source: string | null;
};

export enum AllergenLogic {
	Positive = 'Positive',
	Negative = 'Negative',
	MayContain = 'MayContain',
}

export enum Characteristic {
	Fluid = 'Fluid',
	SolidQuantifiable = 'SolidQuantifiable',
	SolidNonQuantifiable = 'SolidNonQuantifiable',
}

export enum NutriScoreCategory {
	StandardFood = 'StandardFood',
	Cheese = 'Cheese',
	Beverage = 'Beverage',
	AddedFat = 'AddedFat',
	Water = 'Water',
}
