import axios from 'axios';

import * as Get from 'api/menu/GetMenuIdV1';
import { Optional } from 'functions/promiseExtensions';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';

export function callApi(id: string, putObject: Request): Promise<Optional<Get.ResponseData>> {
	const url: string = `/backend-service/Menu/${id}`;
	return axios.put<Get.ResponseData>(url, putObject).toOptionalMapped((x) => x.data);
}

export interface Request {
	nameTranslations: Translation;
	recipes: string[];
	statusId: string | null;
	systemStatus: SystemStatus;
	tags: string[];
}

export type Translation = {
	[key: string]: string;
};
