import axios from 'axios';

import { AllergenLogic } from 'enums/allergenLogic';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { Optional } from 'functions/promiseExtensions';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';

export function callApi(id: string): Promise<Optional<ResponseData>> {
	const url: string = `/backend-service/Recipe/${id}`;
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	accessoryProducts: Product[];
	activityTimes: ActivityTime[];
	allergens: Allergen[];
	calories: number | null;
	carbonDioxide: CarbonDioxide[];
	carbonDioxideLabel: CarbonDioxideLabel | null;
	carbonDioxideValue: number;
	categories: Category[];
	entryLanguage: EntryLanguage;
	hasNutriScore: boolean;
	id: string;
	image: Image | null;
	images: Image[];
	incorporationShares: IncorporationShare[];
	ingredientCalories: number;
	ingredientProducts: Product[];
	ingredientWeight: number | null;
	ingredientsTranslations: Translation;
	isSeoActive: boolean;
	notesTranslations: Translation;
	nutriScore: NutriScore;
	nutriScoreCategory: NutriScoreCategory;
	nutrientValues: NutrientValue[];
	parts: number | null;
	persons: number | null;
	price: number | null;
	scaledParts: number | null;
	scaledPersons: number | null;
	seasons: Season[];
	segmentMedia: SegmentMediaCollection;
	seoTranslations: Translation;
	shortDescriptionTranslations: Translation;
	status: Status;
	stepsTranslations: Translation;
	systemStatus: SystemStatus;
	tags: Tag[];
	titleTranslations: Translation;
	videos: Video[];
	weight: number | null;
};

export type SegmentMediaCollection = {
	[key: string]: {
		images: SegmentMedia[];
		videos: SegmentMedia[];
	};
};

export type SegmentMedia = {
	availableSizes: number[];
	id: string;
	url: string;
};

export type Product = {
	brand: string | null;
	externalId: string | null;
	externalUrl: string | null;
	id: string;
	imageUrl: string | null;
	nameTranslations: Translation;
	price: number | null;
	producer: string | null;
	quantity: string | null;
};

export type Category = {
	id: string;
	name: string;
};

export type Translation = {
	[key: string]: string;
};

export type EntryLanguage = {
	id: string | null;
	description: string;
};

export type Status = {
	id: string;
	name: string;
	order: number;
	color: string;
};

export type Image = {
	id: string;
	url: string;
	availableSizes: number[];
	channels: ChannelItem[];
};

export type Video = {
	id: string;
	url: string;
	availableSizes: number[];
	channels: ChannelItem[];
};

export type ChannelItem = {
	id: string;
	name: string;
	iconSvgUrl: string | null;
};

export type NutriScore = {
	label: string;
	color: string;
};

export type Tag = {
	id: string;
	iconSvgUrl: string | null;
	display: boolean;
	name: string;
	tagCategory: TagCategory;
	source: string | null;
};

export type TagCategory = {
	id: string;
	name: string;
};

export type ActivityTime = {
	id: string;
	name: string;
	timeUnit: TimeUnit;
	timeValue: number;
};

export type TimeUnit = {
	id: string;
	description: string;
};

export type Season = {
	id: string;
	iconSvgUrl: string | null;
	name: string;
	display: boolean;
	associatedMonthsFlag: number;
	sortOrder: number | null;
	source: string | null;
};

export type Allergen = {
	id: string;
	iconSvgUrl: string | null;
	name: string;
	display: boolean;
	logic: AllergenLogic;
	source: string | null;
};

export type Categories = {
	id: string;
	name: string;
};

export type CarbonDioxide = {
	productionMethodId: string;
	originLocationId: string;
	origin: string;
	productionMethod: string;
	value: number;
	source: string | null;
};

export type CarbonDioxideLabel = {
	label: string;
	color: string;
};

export type IconSvg = {
	id: string;
	url: string | null;
	availableSizes: number[] | null;
};

export type NutrientValue = {
	category: string;
	categoryId: string;
	isMacroNutrient: boolean;
	nutrient: string;
	nutrientId: string;
	total: number;
	unit: string;
	unitId: string;
	source: string | null;
	quantity: string;
	nutrientCategorySortOrder: number;
	value: number;
};

export type IncorporationShare = {
	segmentIndex: number;
	ingredientIndex: number;
	incorporationShare: number;
	unitId: string | null;
	ingredientId: string;
	quantity: number | null;
};
