import { t } from 'i18next';
import { useSelector } from 'react-redux';

import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import InputTranslateButton from 'components/desktop/_general/Input/InputExtensions/InputTranslateButton/InputTranslateButton';
import SimpleInput from 'components/desktop/_general/Input/SimpleInput/SimpleInput';
import PERMISSIONS from 'enums/permissions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { RootState } from 'reducers/rootReducer';
import { Ingredient } from 'types/Ingredient/Ingredient';
import { Language } from 'types/Language/Language';

interface IProps {
	onTranslateClick: (name: string) => void;
	ingredient: Ingredient;
	selectedLanguage: string;
	handleValueChange: (key: keyof Ingredient, value: string) => void;
}

const IngredientLanguageInput = (props: IProps) => {
	const ingredientEditor: boolean = arePermissionsInUserPermissions([
		PERMISSIONS.WRITEINGREDIENT,
	]);

	const reduxLanguages: Language[] = useSelector((state: RootState) => state.languages);
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const createHandleValueChangeFunction = (key: keyof Ingredient): ((value: string) => void) => {
		return function (value: string) {
			props.handleValueChange(key, value);
		};
	};

	const isTranslatable = (): boolean => {
		if (!ingredientEditor) return false;
		if (reduxLanguages && reduxCultureCode !== undefined) {
			if (reduxCultureCode === props.selectedLanguage) {
				return false;
			} else {
				return true;
			}
		}
		return false;
	};

	const renderContent = () => {
		return (
			<>
				<br />
				<b>{t('_general:SINGULAR')}</b>
				<div className="row">
					<label>{t('_general:NAME')}</label>
					<div className="input-group">
						<SimpleInput
							value={
								props.ingredient.nameSingularTranslations[props.selectedLanguage] ||
								''
							}
							handleChange={createHandleValueChangeFunction(
								'nameSingularTranslations'
							)}
							disabled={!ingredientEditor}
							error-key="NameSingularTranslations"
						/>
						<RenderIf condition={isTranslatable()}>
							<InputTranslateButton
								name="nameSingularTranslations"
								onClick={props.onTranslateClick}
							/>
						</RenderIf>
					</div>
				</div>
				<br />
				<b>{t('_general:PLURAL')}</b>
				<div className="row">
					<label>{t('_general:NAME')}</label>
					<div className="input-group">
						<SimpleInput
							value={
								props.ingredient.namePluralTranslations[props.selectedLanguage] ||
								''
							}
							handleChange={createHandleValueChangeFunction('namePluralTranslations')}
							disabled={!ingredientEditor}
							error-key="NamePluralTranslations"
						/>
						<RenderIf condition={isTranslatable()}>
							<InputTranslateButton
								name="namePluralTranslations"
								onClick={props.onTranslateClick}
							/>
						</RenderIf>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default IngredientLanguageInput;
