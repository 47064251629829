import * as MatchNutrientValue from 'types/Recipe/Detail/ProductNutrientValue';

export type Type = MatchNutrientValue.Type[];

export function create(): Type {
	return [];
}

export function getById(obj: Type, id: string): MatchNutrientValue.Type | undefined {
	return obj.find((e) => e.nutrientId.toLowerCase() === id.toLowerCase());
}

export function getByIds(obj: Type, ids: string[]): MatchNutrientValue.Type {
	let value: MatchNutrientValue.Type | undefined = undefined;
	for (const id of ids) {
		if (value) continue;
		value = getById(obj, id);
	}
	if (value) return value;
	return MatchNutrientValue.create();
}

export function getByIdString(obj: Type, id: string): string {
	const nutrient: MatchNutrientValue.Type | undefined = getById(obj, id);
	if (!nutrient) return 'undefined';
	return Math.round(nutrient.total) + nutrient.unit;
}

export function getTotalByIds(obj: Type, ids: string[], parts: number): number {
	const nutrientValue: MatchNutrientValue.Type = getByIds(obj, ids);
	if (!nutrientValue.total) return 0;
	return nutrientValue.total / parts;
}

export function getUnitByIds(obj: Type, ids: string[]): string {
	const nutrientValue: MatchNutrientValue.Type = getByIds(obj, ids);
	return nutrientValue.unit;
}

export function calculateDailyValueById(
	obj: Type,
	ids: string[],
	parts: number,
	dailyValues: number[]
): number {
	const nutrientValue: MatchNutrientValue.Type = getByIds(obj, ids);
	const dailyValue = dailyValues
		.zip(ids)
		.filter(([_dailyValue, id]) =>
			obj.some((y) => y.nutrientId.toLowerCase() === id.toLowerCase())
		)
		.map(([dailyValue, _id]) => dailyValue)
		.firstOrDefault();
	if (!nutrientValue || !dailyValue) return 0;
	return (nutrientValue.total / parts / dailyValue) * 100;
}
