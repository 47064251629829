import { t } from 'i18next';

import SegmentImageCarousel from 'components/desktop/_general/ImageCarousel/SegmentImageCarousel';
import Tabs, { Tab } from 'components/desktop/_general/Tabs/Tabs';
import SegmentVideoCarousel from 'components/desktop/_general/VideoCarousel/SegmentVideoCarousel';
import { SegmentMedia } from 'types/Media/SegmentMedia/SegmentMedia';

interface IProps {
	handleDeleteImage: (id: string) => void;
	handleDeleteVideo: (id: string) => void;
	images: SegmentMedia[];
	readOnly: boolean;
	setDisplayGenerateImageOverlay: (input: boolean) => void;
	uploadMedia: (input: File[], video: boolean) => void;
	videos: SegmentMedia[];
}

const SegmentMediaManagement = (props: IProps) => {
	const tabs: Tab[] = [
		{
			name: t('_general:IMAGES'),
			content: (
				<SegmentImageCarousel
					handleDeleteImage={props.handleDeleteImage}
					images={props.images}
					readOnly={props.readOnly}
					uploadImage={props.uploadMedia}
					setDisplayGenerateImageOverlay={props.setDisplayGenerateImageOverlay}
				/>
			),
		},
		{
			name: t('_general:VIDEOS'),
			content: (
				<SegmentVideoCarousel
					handleDeleteVideo={props.handleDeleteVideo}
					readOnly={props.readOnly}
					uploadVideo={props.uploadMedia}
					videos={props.videos}
				/>
			),
		},
	];

	return (
		<fieldset style={{ overflowX: 'auto' }}>
			<Tabs
				tabs={tabs.filter((t: Tab) => {
					if (t.display === undefined || t.display === true) {
						return t;
					}
				})}
			/>
		</fieldset>
	);
};

export default SegmentMediaManagement;
