import { useStatusList, useTagList } from 'context/Store';
import { t } from 'i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Recipe } from 'api/menu/GetMenuIdV1';
import * as apiPostCalculate from 'api/menuPlanner/PostMenuPlannerCalculateV1';
import MenuLanguageInput from 'components/desktop/Menu/LanguageInput/MenuLanguageInput';
import 'components/desktop/Menu/MenuComponent.scss';
import MenuNutrients from 'components/desktop/Menu/MenuNutrients/MenuNutrients';
import RecipeList from 'components/desktop/Menu/RecipeList/RecipeList';
import MenuSidebar from 'components/desktop/Menu/Sidebar/MenuSidebar';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import { RenderIf, RenderOptional } from 'components/desktop/_general/Conditional/RenderIf';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import PERMISSIONS from 'enums/permissions';
import { Optional } from 'functions/promiseExtensions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { useIsHandlerLoaded } from 'hooks/useIsLoading';
import { useReaction } from 'hooks/useReaction';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { useMenuHandlers } from 'reducers/MenuReducer';
import { RootState } from 'reducers/rootReducer';
import {
	Menu,
	MenuEditType,
	MenuTranslationPayload,
	Type,
	calculateNutrientsAsync,
} from 'types/Menu/Menu';

interface IProps {
	copy?: boolean;
	id?: string;
	menu: Type;
}

function useMenuLocalState(translationHandler: (payload: MenuTranslationPayload) => void) {
	const navigate = useNavigate();

	const reduxCultureCode = useSelector((state: RootState) => state.cultureCode);

	const [selectedCultureCode, setSelectedCultureCode] = useState<string>(reduxCultureCode);
	const [displayMenu, setDisplayMenu] = useState<boolean>(true);
	const [selectedRecipe, setSelectedRecipe] = useState<Recipe>({} as Recipe);

	const handleSetDisplayMenu = (input: boolean) => {
		if (input === true) {
			setSelectedRecipe({} as Recipe);
			setDisplayMenu(input);
		} else {
			setDisplayMenu(input);
		}
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string) => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSetSelectedRecipe = (index: number, menu: Type) => {
		let selectedRecipeCpy = { ...selectedRecipe };
		selectedRecipeCpy = menu.recipes[index];
		setSelectedRecipe(selectedRecipeCpy);
		setDisplayMenu(false);
	};

	const handleAddRecipe = async (menu: Type): Promise<void> => {
		if (menu.editType === MenuEditType.COPY) {
			navigate(`/recipe/search/menuCopy/${menu.copiedFrom}`);
		} else if (menu.editType === MenuEditType.EDIT) {
			navigate(`/recipe/search/menu/${menu.id}`);
		} else {
			navigate('/recipe/search/menu/');
		}
	};

	const handleTranslate = async () => {
		await translationHandler({
			name: 'nameTranslations',
			reduxCultureCode: reduxCultureCode,
			selectedCultureCode: selectedCultureCode,
		});
		await translationHandler({
			name: 'shortDescriptionTranslations',
			reduxCultureCode: reduxCultureCode,
			selectedCultureCode: selectedCultureCode,
		});
	};

	return {
		localState: { selectedCultureCode, displayMenu, selectedRecipe, reduxCultureCode },
		localHandlers: {
			handleSetDisplayMenu,
			handleSelectLanguage,
			handleSetSelectedRecipe,
			handleAddRecipe,
			handleTranslate,
		},
	};
}

const useTitle = (props: IProps, menuEditor: boolean): string => {
	if (!props.id) return t('menu:MENU_NEW');
	if (!menuEditor) return t('menu:MENU_VIEW');
	return t('menu:MENU_EDIT');
};

const Nutrients = (props: {
	displayMenu: boolean;
	selectedRecipe: Recipe;
	menu: Type;
	calculation: Optional<apiPostCalculate.Data>;
}) => {
	if (props.displayMenu) {
		return (
			<RenderOptional optional={props.calculation}>
				{(x) => (
					<MenuNutrients
						displayMenu={props.displayMenu}
						object={props.menu}
						nutrients={x.nutrientValues}
					/>
				)}
			</RenderOptional>
		);
	} else {
		if (!props.selectedRecipe.nutrientValues.isEmpty()) {
			return (
				<MenuNutrients
					displayMenu={props.displayMenu}
					object={props.selectedRecipe}
					nutrients={props.selectedRecipe.nutrientValues}
				/>
			);
		}
	}
};

const MenuComponent = (props: IProps) => {
	useScrollToTop();

	const handlers = useMenuHandlers();

	const statusList = useStatusList();
	const tagListWithUndefinedIconUrls = useTagList();

	const menuEditor = arePermissionsInUserPermissions([PERMISSIONS.WRITEMENU]);
	const title = useTitle(props, menuEditor);

	const [calculation] = useReaction(
		Optional.None<apiPostCalculate.Data>(),
		() => calculateNutrientsAsync(props.menu),
		props.menu.recipes
	);

	const { localState, localHandlers } = useMenuLocalState(handlers.handleTranslate);

	const handleSave = async () => {
		if (props.menu.editType === MenuEditType.EDIT) {
			await handlers.saveExistingMenu();
		} else {
			await handlers.createNewMenu(props.menu);
		}
	};

	const [isRunning, handleSaveOrCreate] = useIsHandlerLoaded(handleSave);

	const tagListWithNullableUrls = tagListWithUndefinedIconUrls.map((x) => ({
		id: x.id,
		display: x.display,
		iconSvgUrl: x.iconSvgUrl || null,
		name: x.name,
		tagCategory: x.tagCategory,
	}));

	return (
		<>
			<LoadingAnimation isLoading={!isRunning} />
			<div className="row">
				<div className="d-flex flex-row justify-content-between">
					<div>
						<h1>{title}</h1>
					</div>
					<div>
						<RenderIf condition={menuEditor}>
							<BtnSave
								handleSave={() => handleSaveOrCreate()}
								isLoading={!isRunning}
							/>
						</RenderIf>
					</div>
				</div>
			</div>
			<br />
			<div className="row">
				<div className="col-md-4 col-xl-3 col-sm-12 col-12 sidebar">
					<MenuSidebar
						displayMenu={localState.displayMenu}
						calculation={calculation}
						menu={props.menu}
						selectedRecipe={localState.selectedRecipe}
						setDisplayMenu={localHandlers.handleSetDisplayMenu}
						handleSetHasNutriScore={(selectedRecipeId: string, input: boolean) =>
							handlers.handleSetHasNutriScore({ selectedRecipeId, input })
						}
						handleSetNutriScoreCategory={(
							selectedRecipeId: string,
							input: NutriScoreCategory
						) => handlers.handleSetNutriScoreCategory({ selectedRecipeId, input })}
						handleSetStatus={handlers.handleSetStatus}
						handleSetSystemStatus={handlers.handleSetSystemStatus}
						handleSetTags={handlers.handleSetTags}
						statusList={statusList}
						tagList={tagListWithNullableUrls}
					/>
				</div>
				<div className="col-md-8 col-xl-9 col-sm-12 col-12">
					<LanguageSwitch
						handleSelectLanguage={localHandlers.handleSelectLanguage}
						selectedLanguage={localState.selectedCultureCode}
						translatable={true}
						onTranslateClick={() => localHandlers.handleTranslate()}
						entryLanguage={localState.reduxCultureCode}
						content={
							<MenuLanguageInput
								onTranslateClick={(name: string) =>
									handlers.handleTranslate({
										name,
										reduxCultureCode: localState.reduxCultureCode,
										selectedCultureCode: localState.selectedCultureCode,
									})
								}
								handleValueChange={(key: keyof Menu, value: string) =>
									handlers.handleValueChangeAction({
										selectedCultureCode: localState.selectedCultureCode,
										value: value,
										key: key,
									})
								}
								menu={props.menu}
								selectedLanguage={localState.selectedCultureCode}
							/>
						}
					/>
					<br />
					<RecipeList
						addNewRecipe={() => localHandlers.handleAddRecipe(props.menu)}
						recipes={props.menu.recipes}
						setRecipes={(t: Recipe[]) => handlers.handleSetRecipes(t)}
						setSelectedRecipe={(i) =>
							localHandlers.handleSetSelectedRecipe(i, props.menu)
						}
						selectedRecipe={props.menu.recipes.findIndex(
							(x) => x == localState.selectedRecipe
						)}
					/>
					<br />
					<Nutrients
						calculation={calculation}
						displayMenu={localState.displayMenu}
						selectedRecipe={localState.selectedRecipe}
						menu={props.menu}
					/>
				</div>
			</div>
		</>
	);
};

export default MenuComponent;
