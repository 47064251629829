import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as apiGet from 'api/recipeCategory/GetRecipeCategoryIdV1';
import RecipeCategory from 'classes/StandingData/RecipeCategory/RecipeCategory';
import RecipeCategoryLanguageInput from 'components/desktop/StandingData/RecipeCategory/LanguageInput/RecipeCategoryLanguageInput';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import clone from 'functions/clone';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import { RootState } from 'reducers/rootReducer';

const RecipeCategoryDetailComponent = () => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
	const [selectedCultureCode, setSelectedCultureCode] = useState<string>('');

	const [id, setId] = useState<string | null>(null);
	const [recipeCategory, setRecipeCategory] = useState<RecipeCategory>(new RecipeCategory());

	useEffect(() => {
		initialize();
		setSelectedCultureCode(reduxCultureCode);
	}, []);

	const initialize = async (): Promise<void> => {
		const id: string | null = getIdFromUrl();
		setId(id);
		if (id !== null && id !== 'new') {
			getRecipeCategory(id);
		}
	};

	const getRecipeCategory = async (id: string): Promise<void> => {
		setIsLoading(true);
		try {
			const response = await apiGet.callApi(id);
			response.do((x) => setRecipeCategory(new RecipeCategory(x)));
		} finally {
			setIsLoading(false);
		}
	};

	const handleTranslate = async () => {
		setIsLoading(true);
		await recipeCategory.callApiTranslation(reduxCultureCode, selectedCultureCode);
		setRecipeCategory(clone(recipeCategory));
		setIsLoading(false);
	};

	const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name = e.currentTarget.name;
		const value = e.currentTarget.value;
		switch (name) {
			case 'nameTranslations':
				recipeCategory.nameTranslations[selectedCultureCode] = value;
				break;
			case 'sortOrder':
				recipeCategory.sortOrder = Number(value);
				break;
		}
		setRecipeCategory(clone(recipeCategory));
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string): void => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSaveRecipeCategory = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			if (id !== null && id !== 'new') {
				await recipeCategory.callApiPut();
			} else {
				await recipeCategory.callApiPost();
				if (!recipeCategory.id) return;
				setId(recipeCategory.id);
				setIdToUrl(recipeCategory.id);
			}
		} catch {
			setIsLoadingSave(false);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={isLoading} />
				<div className="row">
					<div
						className="d-flex flex-row justify-content-between mb-3"
						style={{ gap: '70px' }}
					>
						<div>
							{' '}
							{recipeCategory.id ? (
								<>
									<h1>{t('standingData:EDIT_RECIPECATEGORY')}</h1>
								</>
							) : (
								<>
									<h1>{t('standingData:NEW_RECIPECATEGORY')}</h1>
								</>
							)}
						</div>
						<div>
							<BtnSave
								handleSave={() => handleSaveRecipeCategory()}
								isLoading={isLoadingSave}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4 col-xl-3 col-lg-3 col-sm-4 col-12">
						<label>{t('standingData:SORT_ORDER')}</label>
						<input
							className={'form-control'}
							id="sortOrder"
							value={String(recipeCategory.sortOrder)}
							type="number"
							onChange={handleValueChange}
							name="sortOrder"
							error-key={'SortOrder'}
						/>
					</div>
					<div className="col-mg-8 col-xl-9 col-sm-8 col-12">
						<LanguageSwitch
							handleSelectLanguage={handleSelectLanguage}
							selectedLanguage={selectedCultureCode}
							translatable={true}
							onTranslateClick={handleTranslate}
							entryLanguage={reduxCultureCode}
							content={
								<RecipeCategoryLanguageInput
									recipeCategory={recipeCategory}
									handleValueChange={handleValueChange}
									selectedLanguage={selectedCultureCode}
								/>
							}
						/>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default RecipeCategoryDetailComponent;
