import { faLink } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import * as apiGetIngredientIdV1 from 'api/ingredient/GetIngredientIdV1';
import * as apiGetMatchProduct from 'api/match/GetMatchProduct';
import * as apiGetNutrient from 'api/nutrient/GetNutrientV1';
import { useNutrients } from 'components/desktop/Recipe/RecipeTabs/Components/RecipeErpView/IngredientProductMappingComponent/ColumnSelectionOverlay';
import ErpNutrients from 'components/desktop/Recipe/RecipeTabs/Components/RecipeErpView/IngredientProductMappingComponent/ErpNutrients';
import IngredientSearchButtons from 'components/desktop/Recipe/RecipeTabs/Components/RecipeErpView/IngredientSearch/IngredientSearchButtons2';
import * as State from 'components/desktop/Recipe/RecipeTabs/Components/RecipeErpView/IngredientSearch/IngredientSearchState';
import useIngredientSearchState from 'components/desktop/Recipe/RecipeTabs/Components/RecipeErpView/IngredientSearch/useIngredientSearchState';
import 'components/desktop/Recipe/RecipeTabs/Components/RecipeErpView/RecipeErpView.scss';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import { ImageWithFallback } from 'components/desktop/_general/ImageWithFallback/ImageWithFallback';
import { ValueScope } from 'enums/valueScope';
import { mapToIngredient } from 'functions/mappers/Ingredient/mapToIngredient';
import { Optional } from 'functions/promiseExtensions';
import useCurrency from 'hooks/redux/useCurrency';
import { useReaction } from 'hooks/useReaction';
import { Ingredient } from 'types/Ingredient/Ingredient';
import * as NutrientValueList from 'types/NutrientValue/NutrientValueList';
import * as Criterion from 'types/Recipe/Detail/Criterion';
import * as Product from 'types/Recipe/Detail/Product';
import * as MatchNutrientValueList from 'types/Recipe/Detail/ProductNutrientValueList';
import { SegmentIngredient } from 'types/Recipe/SegmentIngredient';
import * as Nutrient from 'types/_general/Store/Nutrient';
import * as NutrientList from 'types/_general/Store/NutrientList';

const pictureHeight: string = '59px';
const pictureWidth: string = '60px';

type IProps = {
	nutrients: apiGetNutrient.Nutrient[];
	ingredient: SegmentIngredient;
	product: Product.Type | null;
	valueScope: ValueScope;
	selectedColumns: string[];
	selectProduct: (input: { ingredientId: string; product: Product.Type }) => void;
	deSelectProduct: (ingredientId: string) => void;
};

const IngredientSearch = (props: IProps) => {
	const { t } = useTranslation();
	const currency: string = useCurrency();
	const nutrients = useNutrients();

	const { state, handlers } = useIngredientSearchState(
		State.create({
			search: props.ingredient.ingredient || '',
			ingredientId: props.ingredient.ingredientId,
		})
	);

	const [productMatches] = useReaction<Product.Type[], Criterion.Type>(
		[],
		async (criterion: any) => await apiGetMatchProduct.callApi(criterion),
		state.criterion
	);

	const visitSourcePage = (match: Product.Type | null) => {
		if (!match) return;
		if (!match.externalUrl) return;
		window.open(match.externalUrl, '_blank');
	};

	const [ingredient] = useReaction<Optional<Ingredient>, string>(
		Optional.None(),
		async () => {
			if (props.ingredient.ingredientId) {
				const data = await apiGetIngredientIdV1.callApi(props.ingredient.ingredientId!);
				return data.map((x) => mapToIngredient(x));
			}
			return Optional.Empty as any;
		},
		props.ingredient.ingredientId!
	);

	return (
		<>
			<div className="d-flex">
				<div className="input-group input-group-sm mb-3">
					<input
						className="form-control"
						onChange={(t) => {
							handlers.setSearch(t.currentTarget.value);
						}}
						placeholder={t('_general:INPUT_PLACEHOLDER_SEARCHTERM')}
						type="text"
						value={state.search}
					/>
					<input
						className="form-control"
						onChange={(t) => {
							handlers.setNrOfMatches(Number(t.currentTarget.value ?? 5));
						}}
						type="number"
						style={{ maxWidth: '100px' }}
						value={state.nrOfMatches}
						title={t('_general:AMOUNT_OF_SEARCHRESULTS')}
					/>

					<button
						className="btn btn-outline-primary advancedSearch btn-200"
						type="button"
						onClick={() => {
							props.deSelectProduct(props.ingredient.ingredientId!);
							handlers.setMatchRightId(null);
							handlers.setCriterion({
								cultureCode: 'en-US',
								nrOfMatches: state.nrOfMatches,
								itemName: state.search,
								ingredientId: null,
							});
						}}
					>
						{t('_general:BTN_SEARCH')}
					</button>
				</div>
			</div>

			<RenderIf condition={!productMatches.length}>
				<div style={{ fontStyle: 'italic' }}>{t('recipe:NO_PRODUCTS_FOUND')}</div>
			</RenderIf>
			<RenderIf condition={Boolean(productMatches.length)}>
				<div className="table-responsive">
					<table className="table table-sm table-bordered " style={{ marginBottom: '0' }}>
						<thead>
							<tr>
								<th style={getTdWidth(40)}></th>
								<th>{t('_general:IMAGE')}</th>
								<th style={getTdWidth(160)}>{t('_general:BRAND')}</th>
								<th style={getTdWidth(220)}>{t('_general:PRODUCT_NAME')}</th>
								<th style={getTdWidth(120)}>{t('recipe:PRICE')}</th>
								<th>GTIN</th>
								{props.selectedColumns.map((e, i) => {
									return (
										<th key={i}>
											{Nutrient.toString(NutrientList.getById(nutrients, e))}
										</th>
									);
								})}
							</tr>
						</thead>
						<tbody>
							{productMatches.map((product, i) => (
								<tr
									key={i}
									className={
										props.product &&
										props.product.externalId === product.externalId
											? 'table-primary'
											: ''
									}
								>
									<td style={{ width: '65px', minWidth: '65px' }}>
										<IngredientSearchButtons
											{...props}
											index={i}
											match={product}
											matchLeftId={state.matchLeftId}
											matchRightId={state.matchRightId}
											setMatchLeftId={handlers.setMatchLeftId}
											setMatchRightId={handlers.setMatchRightId}
										/>
									</td>
									<td style={{ width: '50px', minWidth: '50px' }}>
										<ImageWithFallback
											src={product.imageUrl}
											className="image-with-fallback"
											style={{
												width: pictureWidth,
												height: pictureHeight,
												objectFit: 'contain',
											}}
											onClick={() => visitSourcePage(product)}
										/>
									</td>
									<td>
										<span
											style={{
												marginRight: product.externalUrl ? '5px' : '0',
											}}
										>
											{product.brand}
										</span>
										<RenderIf condition={Boolean(product.externalUrl)}>
											<FontAwesomeIcon
												icon={faLink}
												size={'xs'}
												onClick={() => visitSourcePage(product)}
												style={{ color: 'grey' }}
											/>
										</RenderIf>
									</td>
									<td>{product.name}</td>
									<td>{`${product.price} ${currency}`}</td>
									<td>{product.externalId}</td>
									{props.selectedColumns.map((e, i) => {
										return (
											<td
												style={{ width: '150px', minWidth: '150px' }}
												key={i}
											>
												<div
													style={{
														opacity:
															props.valueScope ===
															ValueScope.HundredGrams
																? '1'
																: '0.5',
													}}
												>
													<span
														style={{
															fontSize: '0.8em',
															color: 'grey',
															marginRight: '5px',
														}}
													>
														100g:
													</span>
													{MatchNutrientValueList.getByIdString(
														product.nutrientValuesPer100g,
														e
													)}
												</div>
												<div
													style={{
														opacity:
															props.valueScope === ValueScope.Parts
																? '1'
																: '0.5',
													}}
												>
													<span
														style={{
															fontSize: '0.8em',
															color: 'grey',
															marginRight: '5px',
														}}
													>
														{t('_general:PART')}:
													</span>
													{MatchNutrientValueList.getByIdString(
														product.nutrientValuesPerServing,
														e
													)}
												</div>
											</td>
										);
									})}
								</tr>
							))}
						</tbody>
					</table>
				</div>
			</RenderIf>

			{ingredient.hasValue() && productMatches && (
				<ErpNutrients
					matchLeftId={state.matchLeftId}
					matchRightId={state.matchRightId}
					productMatches={productMatches}
					setMatchLeftId={handlers.setMatchLeftId}
					setMatchRightId={handlers.setMatchRightId}
					valueScope={props.valueScope}
					nutrients={NutrientValueList.mergeTwoArrays(
						ingredient.get().nutrientValues,
						ingredient.get().baseIngredient.nutrientValues
					).map((x) => ({
						...x,
						category: props.nutrients.find((y) => y.id === x.id)!.nutrientCategory.name,
						total: props.nutrients.find((y) => y.id === x.id)!.value,
						isMacroNutrient: props.nutrients.find((y) => y.id === x.id)!
							.isMacroNutrient,
						unit: props.nutrients.find((y) => y.id === x.id)!.unitShortNameSingular,
						nutrientCategorySortOrder: props.nutrients.find((y) => y.id === x.id)!
							.nutrientCategory.sortOrder,
						nutrient: props.nutrients.find((y) => y.id === x.id)!.name,
					}))}
				/>
			)}
		</>
	);
};
export default IngredientSearch;

function getTdWidth(input: number): React.CSSProperties {
	return {
		width: `${input}px`,
		minWidth: `${input}px`,
	};
}
