import { Dispatch, SetStateAction, useState } from 'react';

import * as handlers from 'components/desktop/Recipe/RecipeComponentHandlers';
import MediaManagement from 'components/desktop/_general/MediaManagement/MediaManagement';
import GenerateImageOverlay from 'components/desktop/_general/Overlay/GenerateImageOverlay/GenerateImageOverlay';
import { ChannelLight } from 'types/Channel/ChannelLight';
import { Recipe } from 'types/Recipe/Recipe';

interface IProps {
	cultureCode: string;
	readOnly: boolean;
	recipe: Recipe;
	setRecipe: Dispatch<SetStateAction<Recipe>>;
	updateChannels: (mediaId: string, channels: ChannelLight[]) => void;
}

const RecipeMediaComponent = (props: IProps) => {
	const [displayGenerateImageOverlay, setDisplayGenerateImageOverlay] = useState<boolean>(false);

	const handleGenerateAiImages = async (nrOfImages: number, description: string) => {
		const response = await handlers.generateAiImages(
			nrOfImages,
			description.length > 0
				? description
				: props.recipe.titleTranslations[props.cultureCode],
			props.cultureCode
		);
		return response;
	};

	return (
		<div className="margin-top-25">
			<GenerateImageOverlay
				description={props.recipe.titleTranslations[props.cultureCode]}
				displayOverlay={displayGenerateImageOverlay}
				handleAddImage={handlers.createUploadAiFileHandler(props.setRecipe)}
				handleGenerate={handleGenerateAiImages}
				setDisplayOverlay={setDisplayGenerateImageOverlay}
			/>
			<MediaManagement
				handleDeleteImage={handlers.createDeleteImageHandler(props.setRecipe)}
				handleDeleteVideo={handlers.createDeleteVideoHandler(props.setRecipe)}
				images={props.recipe.images}
				mainImage={props.recipe.image}
				readOnly={props.readOnly}
				setDisplayGenerateImageOverlay={setDisplayGenerateImageOverlay}
				setMainImage={handlers.createSetImageHandler(props.setRecipe)}
				updateChannels={props.updateChannels}
				uploadMedia={handlers.createSetFormDataHandler(props.setRecipe)}
				videos={props.recipe.videos}
			/>
		</div>
	);
};

export default RecipeMediaComponent;
