import { faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as apiGetOriginStartingWith from 'api/originLocation/GetOriginLocationStartingWithV1';
import * as apiGetStartingWith from 'api/productionMethod/GetProductionMethodStartingWithV1';
import RoundedInput from 'components/desktop/_general/Input/RoundedInput/RoundedInput';
import SingleSearchSelect from 'components/desktop/_general/Select/SingleSearchSelect/SingleSearchSelect';
import { mapToISelectItem } from 'functions/mappers/ISelectItem/mapToISelectItem';
import { ISelectItem } from 'interfaces/ISelectItem';
import { Co2Footprint } from 'types/Co2Footprint/Co2Footprint';

interface IProps {
	footprint: Co2Footprint;
	handleDelete: (e: any, index: number) => void;
	handleValueChange: (input: number, index: number) => void;
	handleChangeOriginLocation: (item: ISelectItem | null, index: number) => void;
	handleChangeProductionMethod: (item: ISelectItem | null, index: number) => void;
	index: number;
	disabled?: boolean;
}

const Co2FootprintDetail = (props: IProps) => {
	const { t } = useTranslation();

	const [suggestedOriginLocations, setSuggestedOriginLocations] = useState<ISelectItem[]>([]);
	const [suggestedProductionMethods, setSuggestedProductionMethods] = useState<ISelectItem[]>([]);
	const [selectedOriginLocation, setSelectedOriginLocation] = useState<ISelectItem | null>(null);
	const [selectedProductionMethod, setSelectedProductionMethod] = useState<ISelectItem | null>(
		null
	);

	useEffect(() => {
		initialize();
	}, [props.footprint]);

	const getTitle = () => {
		if (props.footprint.source) {
			return props.footprint.source;
		}
	};

	const initialize = () => {
		if (props.footprint.originLocationId && props.footprint.originLocationId != '') {
			setSelectedOriginLocation({
				id: props.footprint.originLocationId,
				name: props.footprint.origin,
			} as ISelectItem);
		}
		if (props.footprint.productionMethodId && props.footprint.productionMethodId != ' ') {
			setSelectedProductionMethod({
				id: props.footprint.productionMethodId,
				name: props.footprint.productionMethod,
			} as ISelectItem);
		}
	};

	const getOriginLocationsStartingWith = async (searchTerm: string): Promise<void> => {
		const response = await apiGetOriginStartingWith.callApi(searchTerm, false);
		response.do((x) => setSuggestedOriginLocations(mapToISelectItem(x)));
	};

	const getProductionMethodStartingWith = async (searchTerm: string): Promise<void> => {
		const response = await apiGetStartingWith.callApi(searchTerm, false);
		response.do((x) => setSuggestedProductionMethods(mapToISelectItem(x)));
	};

	const handleChangeOriginLocation = (item: ISelectItem | null) => {
		props.handleChangeOriginLocation(item, props.index);
		setSelectedOriginLocation(item);
	};

	const handleChangeProductionMethod = (item: ISelectItem | null) => {
		props.handleChangeProductionMethod(item, props.index);
		setSelectedProductionMethod(item);
	};

	const handleValueChange = (input: number) => {
		props.handleValueChange(input, props.index);
	};

	const renderContent = () => {
		return (
			<>
				<div className="col-3" key={props.index}>
					<fieldset className="fieldset-white" title={getTitle()}>
						{!props.disabled && (
							<div className="text-end">
								{!props.disabled && (
									<FontAwesomeIcon
										className="trash-can"
										icon={faTrashCan}
										onClick={(e: any) => props.handleDelete(e, props.index)}
									/>
								)}
							</div>
						)}
						<div>
							<div className="form-group">
								<SingleSearchSelect
									debounceMilliSeconds={350}
									handleChangeSearchTerm={getOriginLocationsStartingWith}
									label={t('_general:ORIGIN')}
									selectedItem={selectedOriginLocation}
									setSavedItem={handleChangeOriginLocation}
									suggestedItems={suggestedOriginLocations}
									triggerSearchLetterAmount={0}
									hideField={props.disabled}
									errorKey={'CarbonDioxide[' + props.index + '].OriginLocation'}
								/>
								<div className="mt-2"></div>
								<SingleSearchSelect
									debounceMilliSeconds={350}
									handleChangeSearchTerm={getProductionMethodStartingWith}
									label={t('_general:PRODUCTION_METHOD')}
									selectedItem={selectedProductionMethod}
									setSavedItem={handleChangeProductionMethod}
									suggestedItems={suggestedProductionMethods}
									triggerSearchLetterAmount={0}
									hideField={props.disabled}
									errorKey={'CarbonDioxide[' + props.index + '].ProductionMethod'}
								/>
								<div className="mt-2"></div>
								<label>{t('_general:VALUE_GRAMM')}</label>
								<RoundedInput
									disabled={props.disabled}
									error-key={`CarbonDioxide[${props.index}].Value`}
									handleChange={handleValueChange}
									name="value"
									value={props.footprint.value}
								/>
							</div>
						</div>
					</fieldset>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default Co2FootprintDetail;
