import Swal from 'sweetalert2';

import { EAlertType } from 'enums/alertType';

let blockAdditionalAlerts = false;
export const fireAlert = async (
	type: EAlertType,
	title: string,
	text: string,
	milliseconds?: number
) => {
	if (blockAdditionalAlerts) {
		return;
	}
	try {
		blockAdditionalAlerts = true;
		if (!milliseconds) {
			milliseconds = 5000;
		}

		let color = '#fff';
		switch (type) {
			case 0:
				color = '#d1e7dd';
				break;
			case 1:
				color = '#fff3cd';
				break;
			case 2:
				color = '#f8d7da';
				break;
		}

		const toast = Swal.mixin({
			background: color,
			customClass: {
				confirmButton: 'btn btn-primary',
				cancelButton: 'btn btn-danger',
				closeButton: `alert-${type}`,
				popup: `alert alert-${type}`,
			},
			position: 'top-end',
			showCloseButton: true,
			showConfirmButton: false,
			html: text,
			timer: milliseconds,
			title: title,
			toast: true,
		});
		await toast.fire();
	} finally {
		blockAdditionalAlerts = false;
	}
};
