import * as Product from 'types/Recipe/Detail/Product';
import * as ProductNutrientValueList from 'types/Recipe/Detail/ProductNutrientValueList';

export type Type = {
	[key: string]: Product.Type;
};

export function create(): Type {
	return {};
}

export function calculatePrice(obj: Type): number {
	let output: number = 0;
	for (const value of Object.values(obj)) {
		if (value.price) {
			output = output + value.price;
		}
	}
	return output;
}

export function getTotalByIds(obj: Type, ids: string[], parts: number): number {
	let output: number = 0;
	for (const value of Object.values(obj)) {
		const total: number = ProductNutrientValueList.getTotalByIds(
			value.nutrientValuesPerServing,
			ids,
			parts
		);
		output = output + total;
	}
	return output;
}

export function getUnitByIds(obj: Type, ids: string[]): string {
	for (const value of Object.values(obj)) {
		const unit: string = ProductNutrientValueList.getUnitByIds(
			value.nutrientValuesPerServing,
			ids
		);
		if (unit) return unit;
	}
	return '';
}

export function calculateDailyValueById(
	obj: Type,
	ids: string[],
	parts: number,
	dailyValues: number[]
): number {
	let output: number = 0;
	for (const value of Object.values(obj)) {
		const total: number = ProductNutrientValueList.calculateDailyValueById(
			value.nutrientValuesPerServing,
			ids,
			parts,
			dailyValues
		);
		output = output + total;
	}
	return output;
}

export function removeId(obj: Type, id: string) {
	const output = { ...obj };
	delete output[id];
	return output;
}
