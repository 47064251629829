import * as ErpNutritionSourceList from 'types/Recipe/Detail/ErpNutritionSourceList';

export default function useRecipeErpViewData() {
	const nutritionSources = useColumns();

	return {
		nutritionSources,
	};
}

function useColumns(): ErpNutritionSourceList.Type {
	return [
		{
			id: '1',
			name: 'AIR',
			fullName: 'AIR GoldenDB',
		},
		{
			id: '2',
			name: 'SNDB',
			fullName: 'SNDB V6.4 (13.06.2022)',
		},
		{
			id: '3',
			name: 'Sysco',
			fullName: 'Sysco',
		},
	];
}
