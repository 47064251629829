import Ingredient from 'classes/Ingredient/Search/Ingredient';
import IngredientList from 'classes/Ingredient/Search/IngredientList';
import 'components/desktop/Ingredient/Search/SearchResultList.scss';
import SearchResultListRow from 'components/desktop/Ingredient/Search/SearchResultListRow';

interface IProps {
	archive: boolean;
	handleArchiveIngredient: (input: Ingredient) => Promise<void>;
	handleRestoreIngredient: (input: Ingredient) => Promise<void>;
	ingredientList: IngredientList;
}

const SearchResultList = (props: IProps) => {
	return (
		<div className="row-gy-4">
			<div className="col-lg-12">
				{props.ingredientList.all.map((e: Ingredient, i: number) => {
					return (
						<div key={i}>
							<SearchResultListRow
								archive={props.archive}
								handleArchiveIngredient={props.handleArchiveIngredient}
								handleRestoreIngredient={props.handleRestoreIngredient}
								ingredient={e}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default SearchResultList;
