import UrlParams from 'enums/Product/Search/UrlParams';
import { AirUrlParamsClass } from 'hooks/useAirNavigation';

export type Type = string[];

export function create(): Type {
	return [];
}

export function addOrRemove(obj: Type, input: string): Type {
	if (obj.includes(input)) {
		return obj.filter((e) => e !== input);
	}
	return [...obj, input];
}

export function addOrRemoveMultiple(obj: Type, input: string[]): Type {
	let output: string[] = [...obj];
	for (const i of input) {
		output = addOrRemove(output, i);
	}
	return output;
}

export function mapToUrl(
	obj: Type,
	params: AirUrlParamsClass,
	urlParam: UrlParams
): AirUrlParamsClass {
	if (!obj.length) return params.delete(urlParam);
	return params.add(urlParam, obj.join(';'));
}
