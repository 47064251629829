import * as apiGetRecipeIdV1 from 'api/recipe/GetRecipeIdV1';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { ActivityTime } from 'types/ActivityTime/ActivityTime';
import { AllergenMappingDto } from 'types/Allergen/AllergenMappingDto';
import { Language } from 'types/Language/Language';
import { ChannelMedia } from 'types/Media/ChannelMedia';
import { SegmentMediaCollection } from 'types/Media/SegmentMedia/SegmentMediaCollection';
import { Video } from 'types/Media/Video';
import { EntryLanguage } from 'types/Recipe/EntryLanguage';
import { SeasonMappingDto } from 'types/Season/SeasonMappingDto';
import { StatusLight } from 'types/Status/StatusLight';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';
import { TagMappingDto } from 'types/Tag/TagMappingDto';
import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';
import { NutriScore, defaultNutriScore } from 'types/_general/NutriScore';

export type Recipe = {
	accessoryProducts: RecipeProduct[];
	activityTimes: ActivityTime[];
	allergens: AllergenMappingDto[];
	calories: number | null;
	carbonDioxide: CarbonDioxide[];
	carbonDioxideLabel: CarbonDioxideLabel | null;
	carbonDioxideValue: number | null;
	categories: Category[];
	entryLanguage: EntryLanguage;
	hasNutriScore: boolean;
	id: string | null;
	image: ChannelMedia | null;
	images: ChannelMedia[];
	incorporationShares: IncorporationShare[];
	ingredientCalories: number | null;
	ingredientProducts: RecipeProduct[];
	ingredientWeight: number | null;
	ingredientsTranslations: LocalizedTranslation<string>;
	isSeoActive: boolean;
	notesTranslations: LocalizedTranslation<string>;
	nutriScore: NutriScore;
	nutriScoreCategory: NutriScoreCategory;
	parts: number | null;
	persons: number | null;
	price: number | null;
	scaledParts: number | null;
	scaledPersons: number | null;
	seasons: SeasonMappingDto[];
	segmentMedia: SegmentMediaCollection;
	seoTranslations: LocalizedTranslation<string>;
	shortDescriptionTranslations: LocalizedTranslation<string>;
	status: StatusLight | null;
	stepsTranslations: LocalizedTranslation<string>;
	systemStatus: SystemStatus;
	tags: TagMappingDto[];
	titleTranslations: LocalizedTranslation<string>;
	videos: Video[];
	weight: number | null;
};

export type RecipeProduct = {
	brand: string | null;
	externalId: string | null;
	externalUrl: string | null;
	id: string;
	imageUrl: string | null;
	nameTranslations: LocalizedTranslation<string>;
	price: number | null;
	producer: string | null;
	quantity: string | null;
};

export type Category = {
	id: string;
	name: string;
};

export type CarbonDioxide = {
	productionMethodId: string;
	originLocationId: string;
	origin: string;
	productionMethod: string;
	value: number;
	source: string | null;
};

export type IncorporationShare = {
	segmentIndex: number;
	ingredientIndex: number;
	incorporationShare: number;
	unitId: string | null;
	ingredientId: string;
	quantity: number | null;
	index: number;
};

export type CarbonDioxideLabel = {
	label: string;
	color: string;
};

export const defaultRecipe = (initialStatus: StatusLight, language: Language): Recipe => {
	return {
		accessoryProducts: [],
		activityTimes: [],
		allergens: [],
		calories: null,
		carbonDioxide: [],
		carbonDioxideLabel: null,
		carbonDioxideValue: 0,
		categories: [],
		entryLanguage: { description: language.name, id: language.id },
		hasNutriScore: true,
		id: null,
		image: null,
		images: [],
		incorporationShares: [],
		ingredientCalories: 0,
		ingredientProducts: [],
		ingredientWeight: null,
		ingredientsTranslations: {} as LocalizedTranslation<string>,
		isSeoActive: false,
		notesTranslations: {} as LocalizedTranslation<string>,
		nutriScore: defaultNutriScore(),
		nutriScoreCategory: NutriScoreCategory.StandardFood,
		parts: 1,
		persons: 1,
		price: null,
		scaledParts: 1,
		scaledPersons: 1,
		seasons: [],
		segmentMedia: {} as SegmentMediaCollection,
		seoTranslations: {} as LocalizedTranslation<string>,
		shortDescriptionTranslations: {} as LocalizedTranslation<string>,
		status: initialStatus,
		stepsTranslations: {} as LocalizedTranslation<string>,
		systemStatus: 'New',
		tags: [],
		titleTranslations: {} as LocalizedTranslation<string>,
		videos: [],
		weight: null,
	};
};

export const mapToRecipe = (recipe: apiGetRecipeIdV1.ResponseData): Recipe => {
	return {
		accessoryProducts: recipe.accessoryProducts,
		activityTimes: recipe.activityTimes,
		allergens: recipe.allergens,
		calories: recipe.calories,
		carbonDioxide: recipe.carbonDioxide,
		carbonDioxideLabel: recipe.carbonDioxideLabel,
		carbonDioxideValue: recipe.carbonDioxideValue,
		categories: recipe.categories,
		entryLanguage: recipe.entryLanguage,
		hasNutriScore: recipe.hasNutriScore,
		id: recipe.id,
		image: recipe.image,
		images: recipe.images,
		incorporationShares: recipe.incorporationShares.map((x, i) => ({ ...x, index: i })),
		ingredientCalories: recipe.ingredientCalories,
		ingredientProducts: recipe.ingredientProducts,
		ingredientWeight: recipe.ingredientWeight,
		ingredientsTranslations: recipe.ingredientsTranslations,
		isSeoActive: recipe.isSeoActive,
		notesTranslations: recipe.notesTranslations,
		nutriScore: recipe.nutriScore,
		nutriScoreCategory: recipe.nutriScoreCategory ?? NutriScoreCategory.StandardFood,
		parts: recipe.parts,
		persons: recipe.persons,
		price: recipe.price,
		scaledParts: recipe.scaledParts ?? recipe.parts,
		scaledPersons: recipe.scaledPersons ?? recipe.persons,
		seasons: recipe.seasons,
		segmentMedia: recipe.segmentMedia,
		seoTranslations: recipe.seoTranslations,
		shortDescriptionTranslations: recipe.shortDescriptionTranslations,
		status: recipe.status,
		stepsTranslations: recipe.stepsTranslations,
		systemStatus: recipe.systemStatus,
		tags: recipe.tags,
		titleTranslations: recipe.titleTranslations,
		videos: recipe.videos,
		weight: recipe.weight,
	};
};
