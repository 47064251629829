import { ReducerBuilder } from 'reducers/ReducerBuilder';
import { payloadReducer } from 'reducers/reducerUtils';
import * as ProductRanges from 'types/_general/Store/ProductRanges';

const rb = new ReducerBuilder<ProductRanges.Type>();
export const createProductRangesReducerLoadedAction = rb.createActionForReducer(
	payloadReducer((x) => x),
	'Products/LoadedFromBackend'
);

export const productRangesReducer = rb.createReducer();
