import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertResult } from 'sweetalert2';

import * as apiSearch from 'api/tag/PostTagSearchV1';
import Search from 'classes/StandingData/Tag/Search/Search';
import Tag from 'classes/StandingData/Tag/Search/Tag';
import TagList from 'classes/StandingData/Tag/Search/TagList';
import TagDetailComponent from 'components/desktop/StandingData/Tag/Detail/TagDetailComponent';
import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import BtnNew from 'components/desktop/_general/Button/BtnNew/BtnNew';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import AdvancedSearchInput from 'components/desktop/_general/Search/AdvancedSearchInput';
import StandingDataList, {
	Row,
} from 'components/desktop/_general/StandingDataList/StandingDataList';
import SvgBig from 'components/desktop/_general/SvgBig/SvgBig';
import clone from 'functions/clone';
import { QueryParams, getQueryParams } from 'functions/getQueryParams';
import useEffectWhenMounted from 'hooks/useEffectWhenMounted';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	archive?: boolean;
}

const maxNumberOfButtons = 5;

const TagManagementComponent = (props: IProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [tagList, setTagList] = useState<TagList>(new TagList());
	const [search, setSearch] = useState<Search>(new Search());
	const [searchTerm, setSearchTerm] = useState<string>(search.searchTerm.value ?? '');

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);

	useEffect(() => {
		initialize();
	}, []);

	useEffectWhenMounted(() => {
		initialize();
	}, [reduxCultureCode]);

	const initialize = async (): Promise<void> => {
		if (props.archive !== undefined && props.archive == true) {
			search.showOnlyDeleted = true;
		}
		if (window.location.href.includes('?')) {
			const queryParams: QueryParams = getQueryParams();
			search.mapFromUrl(queryParams);
			if (search.id.value) setDisplayOverlay(true);
			await getTags();
		} else {
			await getTags();
		}
		setSearch(clone(search));
	};

	const getTags = async () => {
		setIsLoading(true);
		try {
			const response: apiSearch.ResponseData | null = await tagList.callApi(
				search.mapToRequest()
			);
			if (response) {
				tagList.mapFromApi(response);
				setTagList(clone(tagList));
			}
		} finally {
			setIsLoading(false);
		}
	};

	const getSuggestedTags = async (): Promise<ISelectItem[]> => {
		if (searchTerm.length >= 1) {
			await tagList.search(searchTerm, props.archive ?? false);
		} else {
			tagList.filtered = [];
		}

		setTagList(clone(tagList));
		return tagList.filtered;
	};

	const handleChangeUrl = () => {
		const url: string | null = search.mapToUrl();
		if (url) navigate(url);
		getTags();
	};

	const handleSearch = (input?: string) => {
		search.setSearchTerm(input ?? searchTerm);
		handleChangeUrl();
	};

	const handleChangePageIndex = (index: number) => {
		search.pageIndex.value = index;
		handleChangeUrl();
	};

	const handleChangeId = (id: string | null) => {
		search.id.value = id;
		handleChangeUrl();
	};

	const handleOverlayOpen = (input: string): void => {
		setDisplayOverlay(true);
		handleChangeId(input);
	};

	const handleOverlayClose = (): void => {
		setDisplayOverlay(false);
		handleChangeId(null);
	};

	const handleArchiveTag = async (tag: Tag): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:ARCHIVE_TITLE'),
			text: t('standingData:TAG_ARCHIVE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#ff0004',
			confirmButtonColor: '#ff0004',
			confirmButtonText: t('_general:ARCHIVE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await tag.callApiDelete();
				await Swal.fire({
					title: t('standingData:TAG_ARCHIVE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getTags();
			} finally {
				setIsLoading(false);
			}
		}
	};

	const handleRestoreTag = async (tag: Tag): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('standingData:RESTORE_TITLE'),
			text: t('standingData:TAG_RESTORE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#00cc07',
			confirmButtonColor: '#00cc07',
			confirmButtonText: t('_general:RESTORE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await tag.callApiRestore();
				await Swal.fire({
					title: t('standingData:TAG_RESTORE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getTags();
			} finally {
				setIsLoading(false);
			}
		}
	};

	const handleChangeIdToNew = (): void => {
		handleChangeId('new');
		setDisplayOverlay(true);
	};

	const getYesOrNo = (input: boolean): string => {
		if (input) {
			return t('_general:YES');
		} else {
			return t('_general:NO');
		}
	};

	const getButtonDataArchive = (input: Tag): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleArchiveTag(input),
			};
		}
	};

	const getButtonDataEdit = (id: string): BtnData | undefined => {
		if (!props.archive) {
			return {
				onClick: () => handleOverlayOpen(id),
			};
		}
	};

	const getButtonDataRestore = (input: Tag): BtnData | undefined => {
		if (props.archive) {
			return {
				onClick: () => handleRestoreTag(input),
			};
		}
	};

	const renderButtons = (input: Tag): JSX.Element => {
		return (
			<BtnGroup
				edit={getButtonDataEdit(input.id)}
				archive={getButtonDataArchive(input)}
				restore={getButtonDataRestore(input)}
			/>
		);
	};

	const renderListRows = (): Row[] => {
		return tagList.all.map((tag: Tag) => {
			return {
				id: tag.id,
				jsx: (
					<div
						className="d-flex flex-row justify-content-start align-items-center"
						style={{ gap: '2em' }}
					>
						<div className="d-flex flex-row" style={{ gap: '0.8em' }}>
							<SvgBig url={tag.iconSvgUrl} width={56} height={56} />
						</div>
						<div className="flex-fill">{tag.name}</div>
						<div>
							<span className="grey">{t('standingData:DISPLAY')}: </span>
							{getYesOrNo(tag.display)}
						</div>
						<div>{renderButtons(tag)}</div>
					</div>
				),
			};
		});
	};

	const renderContent = () => {
		return (
			<>
				<main className="container">
					<div className="d-flex flex-row justify-content-between">
						{!props.archive ? (
							<>
								<h1>{t('standingData:TAG_MANAGEMENT')}</h1>
								<div className="d-flex flex-gap-25">
									<BtnNew
										onClick={handleChangeIdToNew}
										name={t('standingData:CREATE_TAG')}
									/>
								</div>
							</>
						) : (
							<h1>{t('standingData:TAG_ARCHIVE')}</h1>
						)}
					</div>
					<br />
					<div className="row">
						<div className="col-lg-12">
							<div className="input-group mb-3">
								<AdvancedSearchInput
									debounceMilliSeconds={350}
									getSuggestedItems={getSuggestedTags}
									handleSearch={handleSearch}
									searchTerm={searchTerm}
									setSearchTerm={setSearchTerm}
									triggerSearchLetterAmount={1}
								/>
							</div>
						</div>
					</div>
					<br />
					<LoadingAnimation isLoading={isLoading} />
					<p>{tagList.totalCount + ' ' + t('_general:TAGS')}</p>
					<StandingDataList
						archive={props.archive}
						displayOverlay={displayOverlay}
						rows={renderListRows()}
						overlay={<TagDetailComponent />}
						handleOverlayOpen={handleOverlayOpen}
						handleOverlayClose={handleOverlayClose}
					/>
					<br />
					<Pagination
						countOfAllListItems={tagList.totalCount}
						pageIndex={search.getPageIndex()}
						setPageIndex={handleChangePageIndex}
						listItemAmount={search.getPageSize()}
						maxNumberOfButtons={maxNumberOfButtons}
					/>
				</main>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default TagManagementComponent;
