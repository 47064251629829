interface IProps {
	value: number | null;
	mean: number | null;
}

const color = {
	perfect: '#20B2AA',
	good: '#66CDAA',
	middle: '#FFD700',
	okay: '#FF8C00',
	bad: '#FF4500',
	veryBad: '#FF0000',
	unknown: '#C5C5C5',
};
const AccuracyMeter = ({ value, mean }: IProps) => {
	const getColor = () => {
		const overlap: number | null = calculateProcentualOverlap();
		if (overlap === null) return color.unknown;
		if (overlap < 20) return color.perfect;
		if (overlap < 40) return color.good;
		if (overlap < 60) return color.middle;
		if (overlap < 80) return color.okay;
		return color.bad;
	};

	const calculateProcentualOverlap = (): number | null => {
		if (mean === null || value === null) return null;

		const absMean = Math.abs(Math.round((mean + Number.EPSILON) * 1) / 1);
		const absValue = Math.abs(Math.round((value + Number.EPSILON) * 1) / 1);

		// Case where both values are zero, no error
		if (absMean === 0 && absValue === 0) return 0;

		// Case where one value is zero, and the other is not
		if (absMean === 0 || absValue === 0) {
			return 100; // If one is zero, and the other is non-zero, the error is 100%
		}

		// Normal SMAPE calculation for non-zero values
		const numerator = Math.abs(mean - value);
		const denominator = (absMean + absValue) / 2;

		const smape = (numerator / denominator) * 100;

		return Math.round(smape);
	};

	const getComparisonPercent = (): string => {
		const overlap: number | null = calculateProcentualOverlap();
		if (overlap !== null) return overlap + '%';
		return '–';
	};

	return (
		<div className="d-flex align-items-center justify-content-end" style={{ gap: '3px' }}>
			<span style={{ fontSize: '0.6em', marginRight: '2px', color: 'grey' }}>
				{getComparisonPercent()}
			</span>
			<Block color={getColor()} />
		</div>
	);
};

export default AccuracyMeter;

const Block = (props: { color: string }) => {
	const width: string = '15px';
	const height: string = '12px';

	return <div style={{ width: width, height: height, backgroundColor: props.color }}></div>;
};
