import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ResponseCarbonDioxideLabel } from 'api/carbonDioxideLabel/GetCarbonDioxideLabelStartingWithV1';
import Search from 'classes/Accessory/Search/Search';
import CharacteristicToggleSelect from 'components/desktop/_general/CharacteristicSelect/CharacteristicToggleSelect';
import Co2Select from 'components/desktop/_general/Co2Select/Co2Select';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import MultiSearchSelect from 'components/desktop/_general/MultiSearchSelect/MultiSearchSelect';
import RangeDoubleLabel from 'components/desktop/_general/RangeDoubleLabel/RangeDoubleLabelNew';
import ENTITLEMENTS from 'enums/entitlements';
import clone from 'functions/clone';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	advancedSearch: Search;
	handleChangeUrl: () => void;
	handleReset: () => void;
	renderRanges: boolean;
	disabled: boolean;
	setAdvancedSearch: (input: Search) => void;
}

const AdvancedSearch = (props: IProps) => {
	const { t } = useTranslation();

	const reduxCurrency = useSelector((state: RootState) => state.currency);

	const handleChangeTags = (items: ISelectItem[]) => {
		props.advancedSearch.selectTag.pushToSaved(items);
		props.handleChangeUrl();
	};

	const handleChangeCarbonDioxideLabels = (items: ResponseCarbonDioxideLabel[]) => {
		props.advancedSearch.selectCo2Label.pushToSaved(items);
		props.handleChangeUrl();
	};

	const handleChangeCategories = (items: ISelectItem[]) => {
		props.advancedSearch.selectCategory.pushToSaved(items);
		props.handleChangeUrl();
	};

	const getSavedTags = (): ISelectItem[] => {
		return props.advancedSearch.selectTag.saved;
	};

	const getSavedCategories = (): ISelectItem[] => {
		return props.advancedSearch.selectCategory.saved;
	};

	const getSuggestedTags = (): ISelectItem[] => {
		return props.advancedSearch.selectTag.suggested.filtered;
	};

	const getSuggestedCategories = (): ISelectItem[] => {
		return props.advancedSearch.selectCategory.suggested.filtered;
	};

	const setSuggestedTags = async (input: string): Promise<void> => {
		await props.advancedSearch.selectTag.search(input);
		props.setAdvancedSearch(clone(props.advancedSearch));
	};

	const setSuggestedCategories = async (input: string): Promise<void> => {
		await props.advancedSearch.selectCategory.search(input);
		props.setAdvancedSearch(clone(props.advancedSearch));
	};

	const handleChangeCarbonDioxide = (
		left: number | null,
		right: number | null,
		_id?: string
	): void => {
		props.advancedSearch.sliderCo2.adjustLeftRight(left, right, props.handleChangeUrl);
	};

	const handleChangePrice = (left: number | null, right: number | null, _id?: string): void => {
		props.advancedSearch.sliderPrice.adjustLeftRight(left, right, props.handleChangeUrl);
	};

	const handleChangeCharacteristics = (): void => {
		props.handleChangeUrl();
	};

	const renderCol1 = (): JSX.Element => {
		return (
			<>
				<MultiSearchSelect
					className="mb-2"
					displaySavedItems="top"
					disabled={props.disabled}
					handleChangeSearchTerm={setSuggestedTags}
					label={t('_general:TAGS')}
					savedItems={getSavedTags()}
					setSavedItems={handleChangeTags}
					suggestedItems={getSuggestedTags()}
					triggerSearchLetterAmount={0}
					errorKey="Tags"
				/>
				<MultiSearchSelect
					className="mb-2"
					displaySavedItems="top"
					disabled={props.disabled}
					handleChangeSearchTerm={setSuggestedCategories}
					label={t('_general:CATEGORIES')}
					savedItems={getSavedCategories()}
					setSavedItems={handleChangeCategories}
					suggestedItems={getSuggestedCategories()}
					triggerSearchLetterAmount={0}
					errorKey="Categories"
				/>
			</>
		);
	};

	const renderCol2 = (): JSX.Element => {
		if (props.renderRanges) {
			return (
				<>
					<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>
						<label className="mb-2 mt-2">{`${t('_general:COSTS')} ${t(
							'_general:TOTAL'
						)}`}</label>
						<RangeDoubleLabel
							className="mb-2"
							label={`${t('_general:COSTS')} (${reduxCurrency})`}
							min={props.advancedSearch.sliderPrice.getMinRoundDown()}
							max={props.advancedSearch.sliderPrice.getMaxRoundUp()}
							minArea={props.advancedSearch.sliderPrice.minArea ?? 0}
							maxArea={props.advancedSearch.sliderPrice.maxArea ?? 0}
							left={props.advancedSearch.sliderPrice.left ?? undefined}
							right={props.advancedSearch.sliderPrice.right ?? undefined}
							handleChange={handleChangePrice}
						/>
					</RenderIf>
					<RenderIf entitlements={[ENTITLEMENTS.CARBONDIOXIDE]}>
						<label className="mb-2 mt-2">{t('_general:CARBON_DIOXIDE')}</label>
						<RangeDoubleLabel
							className="mb-2"
							label={t('_general:CARBON_DIOXIDE') + ' (g)'}
							min={props.advancedSearch.sliderCo2.getMinRoundDown()}
							max={props.advancedSearch.sliderCo2.getMaxRoundUp()}
							minArea={props.advancedSearch.sliderCo2.minArea ?? 0}
							maxArea={props.advancedSearch.sliderCo2.maxArea ?? 0}
							left={props.advancedSearch.sliderCo2.left ?? undefined}
							right={props.advancedSearch.sliderCo2.right ?? undefined}
							handleChange={handleChangeCarbonDioxide}
						/>
					</RenderIf>
				</>
			);
		}
		return <></>;
	};

	const renderCol3 = (): JSX.Element => {
		return (
			<div className="d-flex flex-column" style={{ gap: '10px' }}>
				<RenderIf entitlements={[ENTITLEMENTS.CARBONDIOXIDE]}>
					<Co2Select
						carbonDioxideLabels={props.advancedSearch.selectCo2Label.suggested.all}
						savedItems={props.advancedSearch.selectCo2Label.saved}
						setSavedItems={handleChangeCarbonDioxideLabels}
					/>
				</RenderIf>
				<CharacteristicToggleSelect
					characteristics={props.advancedSearch.toggleListCharacteristic}
					handleChange={handleChangeCharacteristics}
				/>
			</div>
		);
	};

	const renderContent = () => {
		return (
			<>
				<div className="row">
					<div className="col-lg-4">{renderCol1()}</div>
					<div className="col-lg-6">{renderCol2()}</div>
					<RenderIf entitlements={[ENTITLEMENTS.CARBONDIOXIDE]}>
						<div className="col-lg-2">{renderCol3()}</div>
					</RenderIf>
				</div>
				<div className="row">
					<div className="col-lg-12" style={{ textAlign: 'end' }}>
						<button className="btn btn-primary" onClick={props.handleReset}>
							{t('_general:RESET')}
						</button>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default AdvancedSearch;
