import { faCameraSlash } from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

export const ImageWithFallback = (props: {
	className?: string;
	style?: React.CSSProperties;
	src: string | undefined | null;
	onClick?: () => void;
}) => {
	const [hasError, setError] = useState(false);
	const onError = () => {
		setError(true);
	};
	if (hasError || !props.src) {
		return (
			<div
				className="d-flex justify-content-center align-items-center"
				style={{
					...{
						backgroundColor: '#ddd',
						padding: '2px',
					},
					...props.style,
				}}
			>
				<div>
					<FontAwesomeIcon size="2x" icon={faCameraSlash} color="white" />
				</div>
			</div>
		);
	}

	return (
		<img
			alt="thumbnail"
			onError={onError}
			className={props.className}
			style={{ backgroundColor: 'white', ...props.style }}
			src={props.src}
			onClick={props.onClick}
		/>
	);
};
