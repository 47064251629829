import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faMicrochipAi } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import * as api from 'api/aiFunctions/PostAiFunctionsAnalyzeRecipeFromUrlV1';
import { hasNewValue } from 'components/desktop/Recipe/RecipeImportOverlay/RecipeImportOverlayHandlers';
import { getPreviewString } from 'components/desktop/Recipe/RecipeImportOverlay/RecipeImportOverlayHandlers';
import { hasValue } from 'components/desktop/Recipe/RecipeImportOverlay/RecipeImportOverlayHandlers';
import LoadingSpinner from 'components/desktop/_general/Loading/LoadingSpinner';
import Overlay from 'components/desktop/_general/Overlay/Overlay';
import { Recipe } from 'types/Recipe/Recipe';

interface IProps {
	handleToggleOverlay: () => void;
	displayOverlay: boolean;
	recipe: Recipe;
	setRecipe: (input: Recipe) => void;
	selectedCultureCode: string;
}

const RecipeImportOverlay = (props: IProps) => {
	const { t } = useTranslation();
	const [url, setUrl] = useState<string>('');
	const [apiRecipe, setApiRecipe] = useState<api.ResponseData | undefined>(undefined);
	const [form, setForm] = useState<FormItem[]>(defaultForm());
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const initializeForm = (recipe: Recipe, apiRecipe: api.ResponseData): void => {
		const form: FormItem[] = defaultForm();
		for (const i of form) {
			if (!hasNewValue(recipe, apiRecipe, i.key)) continue;
			i.checked = true;
		}
		setForm(form);
	};

	const handleChangeUrl = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const value = e.target.value;
		setUrl(value);
	};

	const handleAnalyseClick = async (): Promise<void> => {
		setIsLoading(true);
		const response = await api.callApi({
			recipeUrl: url,
		});
		setIsLoading(false);
		response.do((x) => {
			setApiRecipe(x);
			initializeForm(props.recipe, x);
		});
	};

	const handleImportClick = async (): Promise<void> => {
		if (!apiRecipe) return;
		let recipe: Recipe = {} as Recipe;
		for (const i of form) {
			if (!i.checked) continue;
			const key = i.key;
			recipe = {
				...recipe,
				[key]: apiRecipe[key],
			};
		}
		props.setRecipe({
			...props.recipe,
			...recipe,
		});
		handleCancelClick();
	};

	const handleCancelClick = (): void => {
		setApiRecipe(undefined);
		props.handleToggleOverlay();
	};

	const handleKeyDownInputField = (e: React.KeyboardEvent<HTMLDivElement>): void => {
		if (e.key === 'Enter') handleAnalyseClick();
	};

	const renderUrlInput = (): JSX.Element => {
		return (
			<>
				<p>{t('recipe:RECIPE_URL_MESSAGE')}</p>
				<div className="col-auto">
					<div className="input-group mb-2">
						<div className="input-group-prepend">
							<div className="input-group-text">URL</div>
						</div>
						<input
							error-key={'RecipeUrl'}
							style={{ width: '350px' }}
							type="text"
							value={url}
							onChange={handleChangeUrl}
							onKeyDown={handleKeyDownInputField}
							className="form-control"
							id="inlineFormInputGroup"
							placeholder="URL"
						/>
					</div>
				</div>
				<button
					type="button"
					style={{ marginRight: '10px' }}
					className="btn btn-primary"
					onClick={handleAnalyseClick}
				>
					<FontAwesomeIcon icon={faMicrochipAi as IconProp} />
					<span style={{ paddingLeft: '6px' }}>{t('_general:ANALYSE')}</span>
				</button>
				{renderCancelButton()}
			</>
		);
	};

	const renderCancelButton = (): JSX.Element => {
		return (
			<button type="button" className="btn btn-outline-primary" onClick={handleCancelClick}>
				{t('_general:CANCEL')}
			</button>
		);
	};

	const renderImportDisplay = (recipe: api.ResponseData): JSX.Element => {
		return (
			<>
				{renderImportDisplayTable(recipe)}
				<button
					type="button"
					style={{ marginRight: '10px' }}
					className="btn btn-primary"
					onClick={handleImportClick}
				>
					{t('_general:IMPORT')}
				</button>
				{renderCancelButton()}
			</>
		);
	};

	const isChecked = (recipe: Recipe, apiRecipe: api.ResponseData): boolean => {
		let output: boolean = true;
		for (const i of form) {
			if (!hasValue(apiRecipe, i.key)) continue;
			if (!hasNewValue(recipe, apiRecipe, i.key)) continue;
			if (!i.checked) output = false;
		}
		return output;
	};

	const handleToggleAll = (recipe: Recipe, apiRecipe: api.ResponseData) => {
		const newForm = form.map((e) => {
			if (!hasValue(apiRecipe, e.key)) return e;
			if (!hasNewValue(recipe, apiRecipe, e.key)) return e;
			return {
				...e,
				checked: !isChecked(recipe, apiRecipe),
			};
		});
		setForm(newForm);
	};

	const renderImportDisplayTable = (apiRecipe: api.ResponseData): JSX.Element => {
		return (
			<>
				<p>{t('recipe:RECIPE_IMPORT_MESSAGE')}</p>
				<table className="table table-bordered">
					<thead>
						<tr>
							<th>{t('_general:FIELD')}</th>
							<th>
								<input
									className="form-check-input"
									type="checkbox"
									checked={isChecked(props.recipe, apiRecipe)}
									onClick={() => handleToggleAll(props.recipe, apiRecipe)}
									readOnly={true}
								/>
								{' ' + t('_general:ACTION')}
							</th>
							<th>{t('_general:PREVIEW')}</th>
						</tr>
					</thead>
					<tbody>
						{form.map((e: FormItem, i: number) => {
							return (
								<tr key={i}>
									<td>{t(e.translationKey)}</td>
									<td>
										{renderActionField(
											e.checked,
											e.key,
											props.recipe,
											apiRecipe
										)}
									</td>
									<td>{getPreviewString(apiRecipe, e.key)}</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			</>
		);
	};

	const renderActionField = (
		checked: boolean,
		key: keyof Recipe & keyof api.ResponseData,
		recipe: Recipe,
		apiRecipe: api.ResponseData
	): JSX.Element => {
		if (!hasValue(apiRecipe, key)) return <label>{t('_general:NOT_SPECIFIED')}</label>;
		if (!hasNewValue(recipe, apiRecipe, key))
			return <label className="text-success">{t('_general:IDENTICAL')}</label>;
		return (
			<div className="form-check">
				<input
					className="form-check-input"
					type="checkbox"
					id={key}
					checked={checked}
					onChange={() => handleChangeChecked(key)}
				/>
				<label className="form-check-label" htmlFor={key}>
					{hasValue(recipe, key) ? (
						<span className="text-danger">{t('_general:REPLACE')}</span>
					) : (
						<span className="text-warning">{t('_general:IMPORT')}</span>
					)}
				</label>
			</div>
		);
	};

	const handleChangeChecked = (key: keyof Recipe & keyof api.ResponseData) => {
		const formCopy: FormItem | undefined = form.find((e: FormItem) => {
			return e.key === key;
		});
		if (formCopy) {
			formCopy.checked = !formCopy.checked;
		}
		setForm([...form]);
	};

	const renderIsLoading = (): JSX.Element => {
		return (
			<div style={{ padding: '40px 100px' }}>
				<LoadingSpinner />
			</div>
		);
	};

	const renderDisplayAccordingToState = (): JSX.Element => {
		if (isLoading) return renderIsLoading();
		if (apiRecipe === undefined) return renderUrlInput();
		return renderImportDisplay(apiRecipe);
	};

	return (
		<Overlay
			displayOverlay={props.displayOverlay}
			containerStyle={{ overflow: 'scroll' }}
			handleClose={handleCancelClick}
		>
			{renderDisplayAccordingToState()}
		</Overlay>
	);
};

type FormItem = {
	translationKey: string;
	key: keyof Recipe & keyof api.ResponseData;
	checked: boolean;
	hasValue?: (
		recipe: Recipe | api.ResponseData,
		key: keyof Recipe & keyof api.ResponseData
	) => boolean;
};

function defaultForm(): FormItem[] {
	return [
		{
			translationKey: 'recipe:ENTRYLANGUAGE',
			key: 'entryLanguage',
			checked: false,
		},
		{
			translationKey: 'recipe:RECIPE_NAME',
			key: 'titleTranslations',
			checked: false,
		},
		{
			translationKey: 'recipe:SHORT_DESCRIPTION',
			key: 'shortDescriptionTranslations',
			checked: false,
		},
		{
			translationKey: 'recipe:TIP',
			key: 'notesTranslations',
			checked: false,
		},
		{
			translationKey: '_general:INGREDIENTS',
			key: 'ingredientsTranslations',
			checked: false,
		},
		{
			translationKey: 'recipe:STEPS',
			key: 'stepsTranslations',
			checked: false,
		},
		{
			translationKey: 'recipe:SEO',
			key: 'seoTranslations',
			checked: false,
		},
		{
			translationKey: '_general:CALORIES',
			key: 'calories',
			checked: false,
		},
		{
			translationKey: 'recipe:PERSONS_COUNT',
			key: 'persons',
			checked: false,
		},
		{
			translationKey: 'recipe:PARTS_COUNT',
			key: 'parts',
			checked: false,
		},
		{
			translationKey: '_general:NUTRISCORE',
			key: 'nutriScore',
			checked: false,
		},
		{
			translationKey: 'recipe:TIMES',
			key: 'activityTimes',
			checked: false,
		},
		{
			translationKey: '_general:TAGS',
			key: 'tags',
			checked: false,
		},
		{
			translationKey: '_general:CATEGORIES',
			key: 'categories',
			checked: false,
		},
	];
}

export default RecipeImportOverlay;
