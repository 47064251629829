import { InputHTMLAttributes } from 'react';

interface IProps extends InputHTMLAttributes<any> {
	value: string;
	handleChange: (input: string) => void;
}

const SimpleInput = (props: IProps) => {
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		props.handleChange(e.currentTarget.value);
	};

	return (
		<>
			<input
				className="form-control"
				name={props.name}
				onChange={handleChange}
				type="text"
				value={props.value}
			/>
		</>
	);
};

export default SimpleInput;
