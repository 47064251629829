import * as React from 'react';
import { useEffect } from 'react';

import DesktopContainer from 'containers/desktop/index';
import { logout } from 'functions/tokenFunctions';

const App: React.FC = () => {
	useEffect((): void => {
		window.addEventListener('storage', localStorageTransfer);
	}, []);

	const localStorageTransfer = (event: StorageEvent): void => {
		if (event.key === null && event.newValue === null) {
			logout();
		}
	};

	return <DesktopContainer />;
};

export default App;
