import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as apiGet from 'api/allergen/GetAllergenIdV1';
import Allergen, { SvgKeys } from 'classes/StandingData/Allergen/Allergen';
import AllergenLanguageInput from 'components/desktop/StandingData/Allergen/LanguageInput/AllergenLanguageInput';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import PictureUpload from 'components/desktop/_general/PictureUpload/PictureUpload';
import SvgBig from 'components/desktop/_general/SvgBig/SvgBig';
import Toggle from 'components/desktop/_general/Toggle/Toggle';
import clone from 'functions/clone';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import { RootState } from 'reducers/rootReducer';

const AllergenDetail = () => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
	const [selectedCultureCode, setSelectedCultureCode] = useState<string>('');

	const [allergen, setAllergen] = useState<Allergen>(new Allergen());
	const [id, setId] = useState<string | null>(null);

	useEffect(() => {
		initialize();
		setSelectedCultureCode(reduxCultureCode);
	}, []);

	const initialize = async (): Promise<void> => {
		const id: string | null = getIdFromUrl();
		setId(id);
		if (id !== null && id !== 'new') {
			getAllergen(id);
		}
	};

	const getAllergen = async (id: string): Promise<void> => {
		setIsLoading(true);
		const response = await apiGet.callApi(id);
		response.hasValue() && setAllergen(new Allergen(response.get()));
		setIsLoading(false);
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string) => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSaveAllergen = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			if (id !== null && id !== 'new') {
				await allergen.callApiPut();
			} else {
				await allergen.callApiPost();
				if (!allergen.id) return;
				setId(allergen.id);
				setIdToUrl(allergen.id);
			}
		} catch {
			setIsLoadingSave(false);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const handleInputValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name: string = e.currentTarget.name;
		switch (name) {
			case 'positiveNameTranslations':
				allergen.positiveNameTranslations[selectedCultureCode] = e.currentTarget.value;
				break;
			case 'negativeNameTranslations':
				allergen.negativeNameTranslations[selectedCultureCode] = e.currentTarget.value;
				break;
			case 'mayContainNameTranslations':
				allergen.mayContainNameTranslations[selectedCultureCode] = e.currentTarget.value;
				break;
		}
		setAllergen(clone(allergen));
	};

	const handleTranslate = async () => {
		setIsLoading(true);
		await allergen.callApiTranslation(reduxCultureCode, selectedCultureCode);
		setAllergen(clone(allergen));
		setIsLoading(false);
	};

	const handleToggle = (input: boolean): void => {
		allergen.display = input;
		setAllergen(clone(allergen));
	};

	const createSetPictureUpload = (key: SvgKeys): ((input: FormData | null) => Promise<void>) => {
		return async function (input: FormData | null) {
			if (input) {
				await allergen.callApiUploadMedia(input, key);
				setAllergen(clone(allergen));
			}
		};
	};

	return (
		<>
			<LoadingAnimation isLoading={isLoading} />
			<div className="row">
				<div className="d-flex flex-row justify-content-between mb-3">
					<div>
						{allergen.id ? (
							<>
								<h1>{t('standingData:EDIT_ALLERGEN')}</h1>
							</>
						) : (
							<>
								<h1>{t('standingData:NEW_ALLERGEN')}</h1>
							</>
						)}
					</div>
					<div>
						<BtnSave
							handleSave={() => handleSaveAllergen()}
							isLoading={isLoadingSave}
						/>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-md-4 col-xl-3 col-lg-3 col-sm-4 col-12">
					<SvgBig url={allergen.positiveIconSvg?.url ?? null} width={120} height={120} />
					<PictureUpload
						setFormData={createSetPictureUpload('positiveIconSvg')}
						accept="image/svg+xml"
						label={t('standingData:UPLOAD_POSITIVE_ICON')}
					/>
					<SvgBig url={allergen.negativeIconSvg?.url ?? null} width={120} height={120} />
					<PictureUpload
						setFormData={createSetPictureUpload('negativeIconSvg')}
						accept="image/svg+xml"
						label={t('standingData:UPLOAD_NEGATIVE_ICON')}
					/>
					<SvgBig
						url={allergen.mayContainIconSvg?.url ?? null}
						width={120}
						height={120}
					/>
					<PictureUpload
						setFormData={createSetPictureUpload('mayContainIconSvg')}
						accept="image/svg+xml"
						label={t('standingData:UPLOAD_MAYCONTAIN_ICON')}
					/>
					<Toggle
						checked={allergen.display ?? false}
						handleToggle={handleToggle}
						elementId="display"
						label="standingData:DISPLAY"
						errorKey="Display"
					/>
				</div>
				<div className="col-md-8 col-xl-9 col-sm-8 col-12">
					<LanguageSwitch
						handleSelectLanguage={handleSelectLanguage}
						selectedLanguage={selectedCultureCode}
						translatable={true}
						onTranslateClick={handleTranslate}
						entryLanguage={reduxCultureCode}
						content={
							<AllergenLanguageInput
								allergen={allergen}
								selectedLanguage={selectedCultureCode}
								handleValueChange={handleInputValueChange}
							/>
						}
					/>
				</div>
			</div>
		</>
	);
};

export default AllergenDetail;
