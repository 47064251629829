import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as apiGet from 'api/accessoryCategory/GetAccessoryCategoryIdV1';
import AccessoryCategory from 'classes/StandingData/AccessoryCategory/AccessoryCategory';
import AccessoryCategoryList from 'classes/StandingData/AccessoryCategory/Search/AccessoryCategoryList';
import AccessoryCategoryLanguageInput from 'components/desktop/StandingData/AccessoryCategory/LanguageInput/AccessoryCategoryLanguageInput';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import SelectSimple, {
	SelectOption,
} from 'components/desktop/_general/Select/SelectSimple/SelectSimple';
import clone from 'functions/clone';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';

const AccessoryCategoryDetailComponent = () => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const [parentList, setParentList] = useState<AccessoryCategoryList>(
		new AccessoryCategoryList()
	);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
	const [selectedCultureCode, setSelectedCultureCode] = useState<string>('');

	const [id, setId] = useState<string | null>(null);
	const [accessoryCategory, setAccessoryCategory] = useState<AccessoryCategory>(
		new AccessoryCategory()
	);

	useEffect(() => {
		initialize();
		setSelectedCultureCode(reduxCultureCode);
	}, []);

	const initialize = async (): Promise<void> => {
		const id: string | null = getIdFromUrl();
		setId(id);
		await getParents();
		if (id !== null && id !== 'new') {
			getAccessoryCategory(id);
		}
	};

	const getParents = async (): Promise<void> => {
		await parentList.search('', false);
		setParentList(clone(parentList));
	};

	const getAccessoryCategory = async (id: string): Promise<void> => {
		setIsLoading(true);
		try {
			const response = await apiGet.callApi(id);
			response.hasValue() && setAccessoryCategory(new AccessoryCategory(response.get()));
		} finally {
			setIsLoading(false);
		}
	};

	const handleTranslate = async () => {
		setIsLoading(true);
		await accessoryCategory.callApiTranslation(reduxCultureCode, selectedCultureCode);
		setAccessoryCategory(clone(accessoryCategory));
		setIsLoading(false);
	};

	const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
		accessoryCategory.nameTranslations[selectedCultureCode] = e.target.value;
		setAccessoryCategory(clone(accessoryCategory));
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string): void => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSaveAccessoryCategory = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			if (id !== null && id !== 'new') {
				await accessoryCategory.callApiPut();
			} else {
				await accessoryCategory.callApiPost();
				if (!accessoryCategory.id) return;
				setId(accessoryCategory.id);
				setIdToUrl(accessoryCategory.id);
			}
		} catch {
			setIsLoadingSave(false);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const getParentOptions = (): SelectOption[] => {
		const output: SelectOption[] = [];
		output.push({ id: '', text: t('standingData:NOTHING_SELECTED') });
		parentList.filtered.forEach((parent: ISelectItem) => {
			output.push({ id: parent.id, text: parent.name });
		});
		return output;
	};

	const handleParentChange = (id: string) => {
		const parent = parentList.filtered.find((x) => x.id == id);
		if (parent) {
			accessoryCategory.parent = { id: parent.id, name: parent.name };
		} else {
			accessoryCategory.parent = null;
		}
		setAccessoryCategory(clone(accessoryCategory));
	};

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={isLoading} />
				<div className="row">
					<div
						className="d-flex flex-row justify-content-between mb-3"
						style={{ gap: '70px' }}
					>
						<div>
							{' '}
							{accessoryCategory.id ? (
								<>
									<h1>{t('standingData:EDIT_ACCESSORYCATEGORY')}</h1>
								</>
							) : (
								<>
									<h1>{t('standingData:NEW_ACCESSORYCATEGORY')}</h1>
								</>
							)}
						</div>
						<div>
							<BtnSave
								handleSave={() => handleSaveAccessoryCategory()}
								isLoading={isLoadingSave}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-5 col-xl-4 col-lg-4 col-sm-5 col-12">
						<label>{t('standingData:PARENT')}:</label>
						<SelectSimple
							options={getParentOptions()}
							currentValue={accessoryCategory.parent?.id ?? null}
							handleValueChange={handleParentChange}
							errorKey="Parent"
						/>
					</div>
					<div className="col-mg-7 col-xl-8 col-sm-7 col-12">
						<LanguageSwitch
							handleSelectLanguage={handleSelectLanguage}
							selectedLanguage={selectedCultureCode}
							translatable={true}
							onTranslateClick={handleTranslate}
							entryLanguage={reduxCultureCode}
							content={
								<AccessoryCategoryLanguageInput
									accessoryCategory={accessoryCategory}
									handleValueChange={handleChangeName}
									selectedLanguage={selectedCultureCode}
								/>
							}
						/>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default AccessoryCategoryDetailComponent;
