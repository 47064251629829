import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(request: Request): Promise<Optional<ResponseData>> {
	const url: string = '/backend-service/Recipe/Calculate';
	return axios.post(url, request).toOptionalMapped((x) => x.data);
}

export type Request = {
	ingredients: Ingredients[];
	accessories: Accessories[];
};

export type Ingredients = {
	ingredientIndex: number;
	quantity: number | null;
	maxQuantity: number | null;
	unitId: string | null;
	ingredientId: string;
	productId: string | null;
	incorporationShare: number | null;
};

export type Accessories = {
	accessoryIndex: number;
	quantity: number | null;
	maxQuantity: number | null;
	unitId: string | null;
	accessoryId: string;
};

export type Response = {
	data: ResponseData; // required
};

export type ResponseData = {
	calories: number;
	weight: number;
	price: number | null;
	carbonDioxideValue: number | null;
	carbonDioxideLabel: CarbonDioxideLabel;
	ingredientNames: IngredientNames[];
	accessoryNames: AccessoryNames[];
	nutrientValues: NutrientValues[];
	nutrientValuesPerIngredient: NutrientValuesPerIngredient[];
	pricePerIngredient: PricePerIngredient[];
	pricePerAccessory: PricePerAccessory[];
	carbonDioxideValuePerIngredient: CarbonDioxideValuePerIngredient[];
	carbonDioxideValuePerAccessory: CarbonDioxideValuePerAccessory[];
	allergens: Allergens[];
	allergensPerIngredient: AllergensPerIngredient[];
	seasons: Seasons[];
	seasonsPerIngredient: SeasonsPerIngredient[];
};

export type CarbonDioxideLabel = {
	label: string;
	color: string;
};

export type IngredientNames = {
	ingredientIndex: number;
	ingredientNames: Translation;
};

export type Translation = {
	[key: string]: string;
};

export type AccessoryNames = {
	accessoryIndex: number;
	accessoryNames: Translation;
};

export type NutrientValues = {
	nutrientId: string;
	nutrient: string;
	category: string;
	total: number;
	unit: string;
	isMacroNutrient: boolean;
	nutrientCategorySortOrder: number;
};

export type NutrientValuesPerIngredient = {
	ingredientIndex: number;
	ingredientId: string;
	nutrientValues: NutrientValues[];
};

export type PricePerIngredient = {
	ingredientIndex: number;
	ingredientId: string;
	price: number;
};

export type PricePerAccessory = {
	accessoryIndex: number;
	accessoryId: string;
	price: number;
};

export type CarbonDioxideValuePerIngredient = {
	ingredientIndex: number;
	ingredientId: string;
	value: number;
};

export type CarbonDioxideValuePerAccessory = {
	accessoryIndex: number;
	accessoryId: string;
	value: number;
};

export type Allergens = {
	allergen: string;
	logic: 'Positive' | 'Negative' | 'MayContain';
	iconSvgUrl: string | null;
};

export type AllergensPerIngredient = {
	ingredientIndex: number;
	ingredientId: string;
	allergens: Allergens[];
};

export type Seasons = {
	season: string;
	associatedMonthsFlag: number | null;
	iconSvgUrl: string | null;
};

export type SeasonsPerIngredient = {
	ingredientIndex: number;
	ingredientId: string;
	seasons: Seasons[];
};
