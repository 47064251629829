import { ReducerBuilder } from 'reducers/ReducerBuilder';
import { payloadReducer } from 'reducers/reducerUtils';
import * as AllergenList from 'types/_general/Store/AllergenList';

const rb = new ReducerBuilder<AllergenList.Type>();

export const createAllergenLoadedAction = rb.createActionForReducer<AllergenList.Type>(
	payloadReducer((x) => x),
	'Allergens/LoadedFromBackend'
);

export const allergensReducer = rb.createReducer();
