import * as Rounding from 'functions/rounding/number';

export type Type = {
	isActive: boolean;
	value: string | null;
};

export function create(): Type {
	return {
		isActive: false,
		value: null,
	};
}

export function setValue(state: Type, input: string): Type {
	return { ...state, value: input };
}

export function setIsActive(state: Type, input: boolean): Type {
	return { ...state, isActive: input };
}

export function getValue(state: Type, backupValue: number): number {
	if (typeof Number(state.value) === 'number' && Number(state.value) >= 0)
		return Number(state.value);
	return backupValue;
}

export function getDisplayValue(state: Type, input: number): string | number {
	if (state.isActive) return state.value ?? input;
	return Rounding.getRoundedNumber(input);
}

export function onFocus(state: Type, input: string): Type {
	return { ...state, value: input };
}

export function onBlur(
	state: Type,
	input: { value: number; callback: (input: number) => void }
): Type {
	input.callback(input.value);
	return { ...state, isActive: false, value: null };
}
