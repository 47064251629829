import axios from 'axios';

import { AllergenLogic } from 'enums/allergenLogic';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { Optional } from 'functions/promiseExtensions';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';

export function callApi(id: string): Promise<Optional<ResponseData>> {
	const url: string = `/backend-service/Menu/${id}`;
	return axios.get(url).toOptionalMapped((x) => x.data);
}

export interface Response {
	data: ResponseData;
}

export type ResponseData = {
	allergens: Allergen[];
	calories: number | null;
	carbonDioxideLabel: CarbonDioxideLabel;
	carbonDioxideValue: number | null;
	id: string;
	nameTranslations: Translation;
	shortDescriptionTranslations: Translation;
	nutrientValues: NutrientValue[];
	price: number | null;
	recipes: Recipe[];
	seasons: Season[];
	status: Status;
	systemStatus: SystemStatus;
	tags: Tag[];
	weight: number | null;
};

export type Status = {
	color: string | null;
	id: string;
	name: string | null;
	order: number;
};

export type CarbonDioxideLabel = {
	color: string | null;
	label: string | null;
};

export type Translation = {
	[key: string]: string;
};

export type Tag = {
	display: boolean;
	iconSvgUrl: string | null;
	id: string;
	name: string;
	tagCategory: TagCategory;
	source: string | null;
};

export type TagCategory = {
	id: string;
	name: string;
};

export type Season = {
	associatedMonthsFlag: number;
	display: boolean;
	iconSvgUrl: string | null;
	id: string;
	name: string;
	source: string | null;
};

export type Allergen = {
	display: boolean;
	iconSvgUrl: string | null;
	id: string;
	logic: AllergenLogic;
	name: string;
	source: string | null;
};

export type NutrientValue = {
	category: string;
	categoryId: string;
	isMacroNutrient: boolean;
	nutrient: string;
	nutrientId: string;
	total: number;
	value: number;
	unit: string;
	unitId: string;
	nutrientCategorySortOrder: number;
	source: string | null;
};

export type Image = {
	availableSizes: number[];
	url: string;
};

export type PrepareTime = {
	timeUnit: string;
	timeValue: number;
};

export type NutriScore = {
	color: string;
	label: string;
};

export type Recipe = {
	allergens: Allergen[];
	calories: number | null;
	carbonDioxideLabel: CarbonDioxideLabel | null;
	carbonDioxideValue: number;
	hasNutriScore: boolean;
	id: string;
	image: Image | null;
	images: Image[];
	nutriScore: NutriScore;
	nutriScoreCategory: NutriScoreCategory;
	nutrientValues: NutrientValue[];
	parts: number | null;
	persons: number | null;
	prepareTime: PrepareTime;
	price: number | null;
	seasons: Season[];
	status: Status | null;
	tags: Tag[];
	titleTranslations: Translation;
	weight: number | null;
};
