import BaseIngredient from 'classes/BaseIngredient/Search/BaseIngredient';
import BaseIngredientList from 'classes/BaseIngredient/Search/BaseIngredientList';
import 'components/desktop/BaseIngredient/Search/SearchResultList.scss';
import SearchResultListRow from 'components/desktop/BaseIngredient/Search/SearchResultListRow';

interface IProps {
	archive: boolean;
	baseIngredientList: BaseIngredientList;
	handleArchiveBaseIngredient: (input: BaseIngredient) => Promise<void>;
	handleRestoreBaseIngredient: (input: BaseIngredient) => Promise<void>;
}

const SearchResultList = (props: IProps) => {
	return (
		<div className="row-gy-4">
			<div className="col-lg-12">
				{props.baseIngredientList.all.map((e: BaseIngredient, i: number) => {
					return (
						<div key={i}>
							<SearchResultListRow
								archive={props.archive}
								baseIngredient={e}
								handleArchiveBaseIngredient={props.handleArchiveBaseIngredient}
								handleRestoreBaseIngredient={props.handleRestoreBaseIngredient}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default SearchResultList;
