import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export function callApi(request: Request): Promise<Optional<ResponseData>> {
	const url = '/ai-service/suggest/activity_times/';
	return axios.post(url, request).toOptionalMapped((x) => x.data.result);
}

export type Request = {
	recipeSteps: string;
	cultureCode: string;
	nrOfTries?: number;
	openAiModel?: string;
};

export type Response = {
	result: ResponseData;
};

export type ResponseData = {
	activityTimes: ActivityTime[];
};

export type ActivityTime = {
	id: string;
	name: string;
	timeUnit: TimeUnit;
	timeValue: number;
};

export type TimeUnit = {
	id: string;
	description: string;
};
