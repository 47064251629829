import Menu from 'classes/Menu/Search/Menu';
import MenuList from 'classes/Menu/Search/MenuList';
import SearchResultListRow from 'components/desktop/Menu/Search/SearchResultListRow';

interface IProps {
	archive: boolean;
	handleArchiveMenu: (input: Menu) => Promise<void>;
	handleRestoreMenu: (input: Menu) => Promise<void>;
	menuList: MenuList;
}

const SearchResultListComponent = (props: IProps) => {
	return (
		<div className="d-flex flex-column" style={{ gap: '20px' }}>
			{props.menuList.all.map((e: Menu, i: number) => {
				return (
					<div key={i}>
						<SearchResultListRow
							archive={props.archive}
							handleArchiveMenu={props.handleArchiveMenu}
							handleRestoreMenu={props.handleRestoreMenu}
							menu={e}
						/>
					</div>
				);
			})}
		</div>
	);
};

export default SearchResultListComponent;
