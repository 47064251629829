import { faMinus, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faL } from '@fortawesome/pro-solid-svg-icons';
import { faR } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';

import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import * as Match from 'types/Recipe/Detail/Product';
import { SegmentIngredient } from 'types/Recipe/SegmentIngredient';
import Style from 'types/_general/Style';

interface IProps {
	ingredient: SegmentIngredient;
	product: Match.Type | null;
	selectProduct: (input: { ingredientId: string; product: Match.Type }) => void;
	deSelectProduct: (ingredientId: string) => void;
	index: number;
	setMatchLeftId: (input: string | null) => void;
	setMatchRightId: (input: string | null) => void;
	matchLeftId: string | null;
	matchRightId: string | null;
	match: Match.Type | null;
}

const IngredientSearchButtons = (props: IProps) => {
	const { t } = useTranslation();

	const isSelected = (): boolean => {
		if (!props.product) return false;
		if (!props.match) return false;
		return props.product.externalId === props.match.externalId;
	};

	const select = () => {
		props.selectProduct({
			ingredientId: props.ingredient.ingredientId!,
			product: props.match ?? Match.create(),
		});
	};

	const deSelect = () => {
		props.deSelectProduct(props.ingredient.ingredientId!);
	};

	const setMatchLeft = () => {
		if (!props.match) return;
		props.setMatchLeftId(props.match.id);
	};

	const setMatchRight = () => {
		if (!props.match) return;
		props.setMatchRightId(props.match.id);
	};

	return (
		<div className="d-flex flex-column">
			<RenderIf condition={!isSelected()}>
				<button
					type="button"
					className="btn btn-outline-secondary"
					style={style.button}
					onClick={select}
				>
					<FontAwesomeIcon icon={faPlus} />
				</button>
			</RenderIf>
			<RenderIf condition={isSelected()}>
				<button
					type="button"
					className="btn btn-secondary"
					style={style.button}
					onClick={deSelect}
				>
					<FontAwesomeIcon icon={faMinus} />
				</button>
			</RenderIf>
			<div className="btn-group">
				<button
					type="button"
					className={[
						'btn',
						props.match?.id !== props.matchLeftId
							? 'btn-outline-secondary'
							: 'btn-secondary',
					].join(' ')}
					style={{
						...style.button,
						marginTop: '-1px',
					}}
					onClick={setMatchLeft}
					title={t('_general:COMPARISON_LEFT')}
				>
					<FontAwesomeIcon icon={faL} />
				</button>
				<button
					type="button"
					className={[
						'btn',
						props.match?.id !== props.matchRightId
							? 'btn-outline-secondary'
							: 'btn-secondary',
					].join(' ')}
					style={{
						...style.button,
						marginTop: '-1px',
					}}
					onClick={setMatchRight}
					title={t('_general:COMPARISON_RIGHT')}
				>
					<FontAwesomeIcon icon={faR} />
				</button>
			</div>
		</div>
	);
};

export default IngredientSearchButtons;

const style: Style = {
	button: {
		padding: '0',
		height: '30px',
	},
};
