import { protectRoutes } from 'components/desktop/_general/ProtectedRoute/ProtectedRoute';
import Search from 'containers/desktop/ERP/Product/Search/ProductSearchContainer';
import PERMISSIONS from 'enums/permissions';

const routes = [
	{
		path: '/product/search',
		element: <Search />,
		permissions: [PERMISSIONS.READPRODUCT],
		resources: ['co2Labels', 'productRanges'],
	},
];

export default protectRoutes(routes);
