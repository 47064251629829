import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import * as apiGet from 'api/carbonDioxideLabel/GetCarbonDioxideLabelIdV1';
import CarbonDioxideLabel from 'classes/StandingData/CarbonDioxideLabel/CarbonDioxideLabel';
import CarbonDioxideLabelLanguageInput from 'components/desktop/StandingData/CarbonDioxideLabel/LanguageInput/CarbonDioxideLabelLanguageInput';
import BtnSave from 'components/desktop/_general/Button/BtnSave/BtnSave';
import RoundedInput from 'components/desktop/_general/Input/RoundedInput/RoundedInput';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import clone from 'functions/clone';
import { getIdFromUrl, setIdToUrl } from 'functions/url';
import { RootState } from 'reducers/rootReducer';

const CarbonDioxideLabelDetailComponent = () => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isLoadingSave, setIsLoadingSave] = useState<boolean>(false);
	const [selectedCultureCode, setSelectedCultureCode] = useState<string>('');

	const [id, setId] = useState<string | null>(null);
	const [carbonDioxideLabel, setCarbonDioxideLabel] = useState<CarbonDioxideLabel>(
		new CarbonDioxideLabel()
	);

	useEffect(() => {
		initialize();
		setSelectedCultureCode(reduxCultureCode);
	}, []);

	const initialize = async (): Promise<void> => {
		const id: string | null = getIdFromUrl();
		setId(id);
		if (id !== null && id !== 'new') {
			getCarbonDioxideLabel(id);
		}
	};

	const getCarbonDioxideLabel = async (id: string): Promise<void> => {
		setIsLoading(true);
		const response = await apiGet.callApi(id);
		response.hasValue() && setCarbonDioxideLabel(new CarbonDioxideLabel(response.get()));
		setIsLoading(false);
	};

	const handleTranslate = async () => {
		setIsLoading(true);
		await carbonDioxideLabel.callApiTranslation(reduxCultureCode, selectedCultureCode);
		setCarbonDioxideLabel(clone(carbonDioxideLabel));
		setIsLoading(false);
	};

	const handleMaxValueChange = (input: number) => {
		carbonDioxideLabel.maxValue = input;
		setCarbonDioxideLabel(clone(carbonDioxideLabel));
	};

	const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const name: string = e.currentTarget.name;
		switch (name) {
			case 'color':
				carbonDioxideLabel.color = e.target.value;
				break;
			case 'labelTranslations':
				carbonDioxideLabel.labelTranslations[selectedCultureCode] = e.target.value;
				break;
		}
		setCarbonDioxideLabel(clone(carbonDioxideLabel));
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string): void => {
		setSelectedCultureCode(selectedLanguage);
	};

	const handleSaveCarbonDioxideLabel = async (): Promise<void> => {
		setIsLoadingSave(true);
		try {
			if (id !== null && id !== 'new') {
				await carbonDioxideLabel.callApiPut();
			} else {
				await carbonDioxideLabel.callApiPost();
				if (!carbonDioxideLabel.id) return;
				setId(carbonDioxideLabel.id);
				setIdToUrl(carbonDioxideLabel.id);
			}
		} catch {
			setIsLoadingSave(false);
		} finally {
			setIsLoadingSave(false);
		}
	};

	const renderContent = () => {
		return (
			<>
				<LoadingAnimation isLoading={isLoading} />
				<div className="row">
					<div
						className="d-flex flex-row justify-content-between mb-3"
						style={{ gap: '70px' }}
					>
						<div>
							{' '}
							{carbonDioxideLabel.id ? (
								<>
									<h1>{t('standingData:EDIT_CARBONDIOXIDELABEL')}</h1>
								</>
							) : (
								<>
									<h1>{t('standingData:NEW_CARBONDIOXIDELABEL')}</h1>
								</>
							)}
						</div>
						<div>
							<BtnSave
								handleSave={() => handleSaveCarbonDioxideLabel()}
								isLoading={isLoadingSave}
							/>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-md-4 col-xl-3 col-lg-3 col-sm-4 col-12">
						<label className="form-control-label" htmlFor="color">
							{t('standingData:COLOR')}
						</label>
						<input
							id="color"
							type="text"
							className="form-control"
							onChange={handleValueChange}
							value={carbonDioxideLabel.color}
							placeholder={'#2d5776'}
							style={{ marginBottom: '0.5em' }}
							error-key={'Color'}
							name="color"
						/>
						<input
							type="color"
							onChange={handleValueChange}
							value={carbonDioxideLabel.color}
							name="color"
						/>
						<hr />
						<label className="form-control-label" htmlFor="maxValue">
							{t('standingData:MAX_VALUE')}
						</label>
						<RoundedInput
							error-key="MaxValue"
							handleChange={handleMaxValueChange}
							name="maxValue"
							value={carbonDioxideLabel.maxValue ?? 0}
						/>
					</div>
					<div className="col-mg-8 col-xl-9 col-sm-8 col-12">
						<LanguageSwitch
							handleSelectLanguage={handleSelectLanguage}
							selectedLanguage={selectedCultureCode}
							translatable={true}
							onTranslateClick={handleTranslate}
							entryLanguage={reduxCultureCode}
							content={
								<CarbonDioxideLabelLanguageInput
									carbonDioxideLabel={carbonDioxideLabel}
									handleValueChange={handleValueChange}
									selectedLanguage={selectedCultureCode}
								/>
							}
						/>
					</div>
				</div>
			</>
		);
	};

	return <>{renderContent()}</>;
};

export default CarbonDioxideLabelDetailComponent;
