import { useTranslation } from 'react-i18next';

import 'components/desktop/BaseIngredient/BaseIngredientComponent.scss';
import * as BaseIngredientState from 'components/desktop/BaseIngredient/BaseIngredientState';
import BaseIngredientNutrients from 'components/desktop/BaseIngredient/LanguageInput/BaseIngredientNutrients';
import useBaseIngredientData from 'components/desktop/BaseIngredient/useBaseIngredientData';
import FoldableCard from 'components/desktop/_general/Card/FoldableCard/FoldableCard';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import RoundedInput from 'components/desktop/_general/Input/RoundedInput/RoundedInput';
import TreeInput from 'components/desktop/_general/Input/TreeInput/TreeInput';
import NutriScoreSelect from 'components/desktop/_general/NutriScoreSelect/NutriScoreSelect';
import NutritionDiagram from 'components/desktop/_general/NutritionDiagram/NutritionDiagram';
import { Characteristic } from 'enums/characteristic';
import ENTITLEMENTS from 'enums/entitlements';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import PERMISSIONS from 'enums/permissions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import * as NutrientValueList from 'types/BaseIngredient/Detail/NutrientValueList';
import * as CategoryList from 'types/_general/Store/CategoryList';

interface IProps {
	state: BaseIngredientState.Type;
	setCalories: (input: number) => void;
	setCategories: (input: CategoryList.Type) => void;
	setHasNutriScore: (input: boolean) => void;
	setNutriScoreCategory: (input: NutriScoreCategory) => void;
	setNutriScoreCategoryIsSetByUser: (input: boolean) => void;
	setNutrientValues: (input: NutrientValueList.Type) => void;
}

const TabNutrients = ({ state, ...props }: IProps) => {
	const { t } = useTranslation();
	const data = useBaseIngredientData();

	const baseIngredientEditor: boolean = arePermissionsInUserPermissions([
		PERMISSIONS.WRITEBASEINGREDIENT,
	]);

	const getLabelCalories = (): string => {
		if (state.characteristic == Characteristic.Fluid) {
			return `${t('_general:CALORIES')} (${t('measurments:CAL_PER_100_MILLILITERS')})`;
		} else {
			return `${t('_general:CALORIES')} (${t('measurments:CAL_PER_100_GRAMMS')})`;
		}
	};

	const getLabelCategories = (): string => {
		if (!baseIngredientEditor) {
			if (state.categorieIds.length === 0) {
				return t('baseIngredient:NO_CATEGORIES');
			}
			return '';
		}
		return t('baseIngredient:CATEGORIES');
	};

	const getMacroNutrientChartData = (): { label: string; value: number }[] => {
		if (data.nutrients) {
			const macroWeight = data.nutrients
				.filter((n) => n.isMacroNutrient)
				.reduce(
					(p, c) => p + (state.nutrientValues.find((x) => x.id == c.id)?.value ?? 0),
					0
				);

			const macros = data.nutrients
				.filter((n) => n.isMacroNutrient)
				.map((n) => {
					return {
						label: n.name,
						value:
							(state.nutrientValues.find((x) => x.id == n.id)?.value ?? 0) /
							macroWeight,
					};
				});

			return macros;
		}
		return [];
	};

	return (
		<div className="row mt-4">
			<div className="col-md-4 col-xl-3 col-sm-12 col-12 sidebar">
				<FoldableCard
					defaultOpen={true}
					title={t('baseIngredient:CATEGORIES')}
					content={
						<>
							<div className="card-body">
								<TreeInput
									input={data.categories}
									setSelected={props.setCategories}
									selected={CategoryList.getFromBaseIngredientCategories(
										data.categories,
										state.categorieIds
									)}
									errorKey={'Categories'}
									disabled={!baseIngredientEditor}
									label={getLabelCategories()}
									noObjectsFoundText={t('_general:NO_CATEGORIES')}
								/>
							</div>
						</>
					}
				/>
				<FoldableCard
					defaultOpen={true}
					title={t('_general:CALORIES')}
					content={
						<>
							<div className="card-body">
								<label>{getLabelCalories()}</label>
								<RoundedInput
									error-key="Calories"
									disabled={!baseIngredientEditor}
									handleChange={props.setCalories}
									name="calories"
									value={state.calories}
								/>
							</div>
						</>
					}
				/>
				<RenderIf entitlements={[ENTITLEMENTS.NUTRI_SCORE]}>
					<FoldableCard
						disabled={!baseIngredientEditor}
						defaultOpen={true}
						hasLock={true}
						defaultLocked={
							!(state.nutriScoreCategoryIsSetByUser === null
								? true
								: state.nutriScoreCategoryIsSetByUser)
						}
						handleLockClick={props.setNutriScoreCategoryIsSetByUser}
						title={t('_general:NUTRI_SCORE')}
						content={
							<NutriScoreSelect
								hasNutriScore={state.hasNutriScore ?? false}
								nutriScoreCategory={state.nutriScoreCategory}
								setHasNutriScore={props.setHasNutriScore}
								setNutriScoreCategory={props.setNutriScoreCategory}
								canImplyNutriScoreMethod={true}
								disabled={!baseIngredientEditor}
							></NutriScoreSelect>
						}
					/>
				</RenderIf>

				<FoldableCard
					defaultOpen={true}
					title={t('_general:NUTRI_DIAGRAM')}
					content={
						<NutritionDiagram data={getMacroNutrientChartData()}></NutritionDiagram>
					}
				></FoldableCard>
			</div>

			<div className="col-md-8 col-xl-9 col-sm-12 col-12">
				<BaseIngredientNutrients
					characteristic={state.characteristic}
					allNutrients={data.nutrients}
					nutrients={state.nutrientValues}
					setNutrients={props.setNutrientValues}
				/>
			</div>
		</div>
	);
};

export default TabNutrients;
