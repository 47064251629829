import { ReducerBuilder } from 'reducers/ReducerBuilder';
import { payloadReducer } from 'reducers/reducerUtils';
import * as StatusList from 'types/_general/Store/StatusList';

const rb = new ReducerBuilder<StatusList.Type>();
export const createStatusLustLoadedAction = rb.createActionForReducer(
	payloadReducer((x) => x),
	'Status/LoadedFromBackend'
);

export const statusReducer = rb.createReducer();
