import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { Category as CategoryTree } from 'api/accessoryCategory/GetAccessoryCategoryTreeViewStartingWithV1';
import * as apiGetCategoryTree from 'api/accessoryCategory/GetAccessoryCategoryTreeViewStartingWithV1';
import { getSelectedCategoriesTree } from 'components/desktop/Accessory/AccessoryComponentHandlers';
import UnitWeightEditor from 'components/desktop/Accessory/UnitWeightEditor/UnitWeightEditor';
import FoldableCard from 'components/desktop/_general/Card/FoldableCard/FoldableCard';
import CharacteristicRadioSelect from 'components/desktop/_general/CharacteristicSelect/CharacteristicRadioSelect';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import RoundedInput from 'components/desktop/_general/Input/RoundedInput/RoundedInput';
import TreeInput from 'components/desktop/_general/Input/TreeInput/TreeInput';
import TagEditor from 'components/desktop/_general/TagEditor/TagEditor';
import ENTITLEMENTS from 'enums/entitlements';
import PERMISSIONS from 'enums/permissions';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import { RootState } from 'reducers/rootReducer';
import { Accessory, UnitWeights } from 'types/Accessory/Accessory';
import { TagLight } from 'types/Tag/TagLight';
import { UnitLight } from 'types/Unit/UnitLight';

interface IProps {
	accessory: Accessory;
	categoryTree: CategoryTree[];
	cultureCode: string;
	handleChangeCategory: (tree: apiGetCategoryTree.Category[]) => void;
	handleCharacteristicChange: (checked: boolean, elementId: string) => void;
	setAccessory: (accessory: Accessory) => void;
	tags: TagLight[];
	units: UnitLight[];
}

const AccessorySidebar = (props: IProps) => {
	const { t } = useTranslation();
	const accessoryEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITEACCESSORY]);

	const reduxCurrency: string = useSelector((state: RootState) => state.currency);

	const handleChangeDensity = (input: number) => {
		const accessoryCpy = { ...props.accessory };
		accessoryCpy.density = input;
		props.setAccessory(accessoryCpy);
	};

	const handleAddUnitWeight = (items: UnitWeights[]) => {
		const accessoryCpy: Accessory = { ...props.accessory };
		accessoryCpy.unitWeights = [];
		for (const uw of items) {
			accessoryCpy.unitWeights.push(uw);
		}
		props.setAccessory(accessoryCpy);
	};

	const getLabelPrice = (): string => {
		return `${reduxCurrency} ${t('_general:PER')} 100g`;
	};

	const changeAccessoryPrice = (value: number): void => {
		const accessoryCpy: Accessory = { ...props.accessory };
		accessoryCpy.price = value;
		props.setAccessory(accessoryCpy);
	};

	const handleAddTag = (items: TagLight[]) => {
		var accessoryCpy: Accessory = { ...props.accessory };
		accessoryCpy.tags = [];
		for (const i of items) {
			accessoryCpy.tags.push({
				id: i.id,
				iconSvgUrl: i.iconSvgUrl ?? null,
				display: i.display,
				name: i.name,
				tagCategory: i.tagCategory,
				source: null,
			});
		}
		props.setAccessory(accessoryCpy);
	};

	const getLabelCategories = (): string => {
		if (!accessoryEditor) {
			if (props.accessory.categories) {
				if (props.accessory.categories.length === 0) {
					return t('_general:NO_CATEGORIES');
				}
			}
			return '';
		}
		return t('_general:CATEGORIES');
	};

	return (
		<>
			<FoldableCard
				defaultOpen={true}
				title={t('standingData:WEIGHT')}
				hasLock={true}
				defaultLocked={false}
				content={
					<div className="card-body">
						<UnitWeightEditor
							units={props.units}
							setUnitWeights={handleAddUnitWeight}
							accessory={props.accessory}
							setAccessory={props.setAccessory}
						/>
					</div>
				}
				unlockable={accessoryEditor}
			/>
			<FoldableCard
				defaultOpen={true}
				title={t('_general:CHARACTERISTICS')}
				content={
					<>
						<div className="card-body">
							<CharacteristicRadioSelect
								checked={props.accessory.characteristic}
								disabled={!accessoryEditor}
								handleCharacteristicChange={props.handleCharacteristicChange}
							/>
						</div>
					</>
				}
			/>
			<FoldableCard
				defaultOpen={true}
				title={t('_general:DENSITY')}
				content={
					<>
						<div className="card-body">
							<label>{`${t('_general:DENSITY')} (${t(
								'measurments:GRAMMS_PER_QUBIC_CM'
							)})`}</label>
							<RoundedInput
								disabled={!accessoryEditor}
								error-key="Density"
								handleChange={handleChangeDensity}
								name="density"
								value={props.accessory.density ?? 0}
							/>
						</div>
					</>
				}
			/>
			<FoldableCard
				defaultOpen={true}
				title={t('_general:CATEGORIES')}
				content={
					<>
						<div className="card-body">
							<TreeInput
								input={props.categoryTree}
								setSelected={props.handleChangeCategory}
								selected={getSelectedCategoriesTree(
									props.accessory,
									props.categoryTree
								)}
								errorKey={'Categories'}
								disabled={!accessoryEditor}
								label={getLabelCategories()}
								noObjectsFoundText={t('_general:NO_CATEGORIES')}
							/>
						</div>
					</>
				}
			/>
			<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>
				<FoldableCard
					defaultOpen={true}
					title={t('_general:COSTS')}
					content={
						<>
							<div className="card-body">
								<label>{getLabelPrice()}</label>
								<RoundedInput
									disabled={!accessoryEditor}
									error-key="Price"
									handleChange={changeAccessoryPrice}
									value={props.accessory.price ?? 0}
									name="price"
								/>
							</div>
						</>
					}
				/>
			</RenderIf>
			<FoldableCard
				defaultOpen={true}
				defaultLocked={false}
				hasLock={true}
				title={t('_general:TAGS')}
				content={
					<>
						<div className="card-body">
							<TagEditor
								entityId={props.accessory.id}
								allTags={props.tags}
								tags={props.accessory.tags}
								setTags={handleAddTag}
								cultureCode={props.cultureCode}
								description={
									props.accessory.nameSingularTranslations[props.cultureCode]
								}
								disabled={!accessoryEditor}
							/>
						</div>
					</>
				}
				unlockable={accessoryEditor}
			/>
		</>
	);
};

export default AccessorySidebar;
