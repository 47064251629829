import { protectRoutes } from 'components/desktop/_general/ProtectedRoute/ProtectedRoute';
import Archive from 'containers/desktop/Menu/Archive/MenuArchive';
import Detail from 'containers/desktop/Menu/Detail/MenuDetail';
import New from 'containers/desktop/Menu/New/MenuNew';
import Search from 'containers/desktop/Menu/Search/MenuSearch';
import PERMISSIONS from 'enums/permissions';

const MenuPlanIdParamsName = 'menuPlanId';
const MenuPlanRowIndexParamsName = 'menuPlanRowIndex';
const MenuPlanDayParamsName = 'menuPlanDay';

const routes = [
	{
		path: '/menu/detail/:id',
		element: <Detail />,
		permissions: [PERMISSIONS.READMENU],
		resources: ['tagList', 'statusList', 'menu/:id'],
	},
	{
		path: '/menu/search',
		element: <Search />,
		permissions: [PERMISSIONS.READMENU],
	},
	{
		path: `/menu/search/menuPlan/:${MenuPlanIdParamsName}/:${MenuPlanRowIndexParamsName}/:${MenuPlanDayParamsName}`,
		element: <Search />,
		permissions: [PERMISSIONS.READMENU],
	},
	{
		path: '/menu/archive',
		element: <Archive />,
		permissions: [PERMISSIONS.WRITEMENU],
	},
	{
		path: '/menu/copy/:id',
		element: <Detail />,
		permissions: [PERMISSIONS.WRITEMENU],
		resources: ['tagList', 'statusList', 'menuCopy/:id'],
	},
	{
		path: '/menu/new',
		element: <New />,
		permissions: [PERMISSIONS.WRITEMENU],
		resources: ['tagList', 'statusList', 'menuNew'],
	},
];

export default protectRoutes(routes);
