export type Type = {
	id: string;
	name: string;
	sortOrder: number;
};

export function create() {
	return {
		id: '',
		name: '',
		sortOrder: 0,
	};
}
