import { faSquare } from '@fortawesome/free-solid-svg-icons';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Swal, { SweetAlertResult } from 'sweetalert2';

import * as apiDelete from 'api/nutriScoreNutrientPoints/DeleteNutriScoreNutrientPointsDeletePointsForNutrientAndMethodV1';
import * as apiGet from 'api/nutriScoreNutrientPoints/GetNutriScoreNutrientPointsListPointsGroupedByNutrientAndMethodV1';
import * as apiGetNutrients from 'api/nutrient/GetNutrientV1';
import NutriScoreNutrientDetail from 'components/desktop/StandingData/NutriScoreNutrients/NutriScoreNutrientDetail';
import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import BtnNew from 'components/desktop/_general/Button/BtnNew/BtnNew';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import AdvancedSearchInput from 'components/desktop/_general/Search/AdvancedSearchInput';
import StandingDataList from 'components/desktop/_general/StandingDataList/StandingDataList';
import { Row } from 'components/desktop/_general/StandingDataList/StandingDataList';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { mapToISelectItem } from 'functions/mappers/ISelectItem/mapToISelectItem';
import { mapAdvancedSearchToUrl } from 'functions/mappers/NutriScoreNutrientLight/URL/mapAdvancedSearchToUrl';
import { mapStateToAdvancedSearch } from 'functions/mappers/NutriScoreNutrientLight/URL/mapStateToAdvancedSearch';
import { mapUrlToAdvancedSearch } from 'functions/mappers/NutriScoreNutrientLight/URL/mapUrlToAdvancedSearch';
import { mapFromRequest } from 'functions/mappers/NutriScoreNutrientLight/mapFromRequest';
import useEffectWhenMounted from 'hooks/useEffectWhenMounted';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';
import { NutriScoreNutrientsAdvancedSearch } from 'types/NutriScoreNutrient/AdvancedSearch/NutrientAdvancedSearch';
import { defaultNutriScoreNutrientsAdvancedSearch } from 'types/NutriScoreNutrient/AdvancedSearch/NutrientAdvancedSearch';
import { NutriScoreNutrientLight } from 'types/NutriScoreNutrient/NutriScoreNutrientLight';
import { Nutrient } from 'types/Nutrient/Nutrient';

const maxNumberOfButtons = 5;

const NutriScoreNutrientManagement = () => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [nutriscoreNutrients, setNutriScoreNutrients] = useState<NutriScoreNutrientLight[]>([]);
	const [nutrients, setNutrients] = useState<Nutrient[]>([]);
	const [advancedSearch, setAdvancedSearch] = useState<NutriScoreNutrientsAdvancedSearch>(
		defaultNutriScoreNutrientsAdvancedSearch
	);
	const [searchTerm, setSearchTerm] = useState<string>(advancedSearch.searchTerm ?? '');

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);
	const [totalCount, setTotalCount] = useState<number>(0);

	useEffect(() => {
		initialize();
	}, []);

	useEffectWhenMounted(() => {
		initialize();
	}, [reduxCultureCode]);

	const initialize = async (): Promise<void> => {
		setIsLoading(true);
		await getActiveNutrients();
		try {
			if (window.location.href.includes('?')) {
				let advancedSearchCpy: NutriScoreNutrientsAdvancedSearch =
					defaultNutriScoreNutrientsAdvancedSearch();
				if (window.history.state.searchTerm !== undefined) {
					advancedSearchCpy = mapStateToAdvancedSearch(window.history.state);
					setAdvancedSearch(advancedSearchCpy);
					await getNutriScoreNutrients(advancedSearchCpy);
				} else {
					const params = new URL(String(window.location)).searchParams;
					advancedSearchCpy = mapUrlToAdvancedSearch(params);
					setAdvancedSearch(advancedSearchCpy);
					await getNutriScoreNutrients(advancedSearchCpy);
				}
				if (advancedSearchCpy.id !== null) {
					setDisplayOverlay(true);
				}
			} else {
				window.history.pushState(advancedSearch, '');
				await getNutriScoreNutrients();
			}
		} finally {
			setIsLoading(false);
		}
	};

	const mapToNutriscoreCategoryId = (nutriScoreNutrientLight: NutriScoreNutrientLight): string =>
		`${nutriScoreNutrientLight.nutrientId}:${nutriScoreNutrientLight.pointType}`;

	const getNutriScoreNutrients = async (advancedSearch?: NutriScoreNutrientsAdvancedSearch) => {
		const searchTerm = advancedSearch?.searchTerm ?? '';
		const pageIndex = advancedSearch?.pageIndex ?? 0;
		const pageSize = advancedSearch?.pageSize ?? 12;
		const response = await apiGet.apiCall(searchTerm, pageIndex, pageSize);
		response.do((x) => {
			setNutriScoreNutrients(mapFromRequest(x.data));
			setTotalCount(x.totalCount);
		});
	};

	const getSuggestedNutriScoreNutrients = async (): Promise<ISelectItem[]> => {
		let output: apiGetNutrients.Nutrient[] = [];
		output = nutrients.filter((e: apiGetNutrients.Nutrient) => {
			if (e.name.toLowerCase().startsWith(searchTerm.toLowerCase())) {
				return e;
			}
		});
		return mapToISelectItem(output);
	};

	const handleChangeUrl = (advancedSearch: NutriScoreNutrientsAdvancedSearch) => {
		setAdvancedSearch(advancedSearch);
		window.history.replaceState(advancedSearch, '', mapAdvancedSearchToUrl(advancedSearch));
		getNutriScoreNutrients(advancedSearch);
	};

	const handleSearch = (input?: string) => {
		var advancedSearchCpy = { ...advancedSearch };
		advancedSearchCpy.searchTerm = input ?? searchTerm;
		advancedSearchCpy.pageIndex = 0;
		handleChangeUrl(advancedSearchCpy);
	};

	const handleChangePageIndex = (index: number) => {
		var advancedSearchCpy = { ...advancedSearch };
		advancedSearchCpy.pageIndex = index;
		handleChangeUrl(advancedSearchCpy);
	};

	const handleChangeId = (id: string | null) => {
		var advancedSearchCpy = { ...advancedSearch };
		advancedSearchCpy.id = id;
		handleChangeUrl(advancedSearchCpy);
	};

	const handleOverlayOpen = (input: string): void => {
		setDisplayOverlay(true);
		handleChangeId(input);
	};

	const handleOverlayClose = (): void => {
		setDisplayOverlay(false);
		handleChangeId(null);
	};

	const getActiveNutrients = async () => {
		const response = await apiGetNutrients.callApi();
		response.do((x) => setNutrients([...x]));
	};

	const handleDelete = async (id: string, pointType: NutriScoreCategory): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			cancelButtonText: t('_general:CANCEL'),
			confirmButtonColor: '#ff0004',
			confirmButtonText: t('_general:DELETE'),
			icon: 'warning',
			iconColor: '#ff0004',
			showCancelButton: true,
			text: t('standingData:NUTRISCORENUTRIENT_DELETION_DIALOG'),
			title: t('standingData:ARCHIVE_TITLE'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await apiDelete.apiCall(id, pointType);
				Swal.fire({
					confirmButtonColor: '#00cc07',
					title: t('standingData:NUTRISCORENUTRIENT_DELETION_CONFIRMATION'),
				});
				await initialize();
			} finally {
				setIsLoading(false);
			}
		}
	};

	const getButtonDataArchive = (
		id: string,
		pointType: NutriScoreCategory
	): BtnData | undefined => {
		return {
			onClick: () => handleDelete(id, pointType),
		};
	};

	const getButtonDataEdit = (id: string): BtnData | undefined => {
		return {
			onClick: () => handleOverlayOpen(id),
		};
	};

	const renderButtons = (nutriScoreNutrientLight: NutriScoreNutrientLight): JSX.Element => {
		return (
			<BtnGroup
				edit={getButtonDataEdit(mapToNutriscoreCategoryId(nutriScoreNutrientLight))}
				archive={getButtonDataArchive(
					nutriScoreNutrientLight.nutrientId,
					nutriScoreNutrientLight.pointType
				)}
			/>
		);
	};

	const renderProtein = (input: boolean): JSX.Element => {
		if (input) {
			return (
				<>
					<FontAwesomeIcon icon={faSquareCheck} className="fa-1x" />{' '}
					{t('standingData:PROTEIN')}
				</>
			);
		}
		return (
			<>
				<FontAwesomeIcon icon={faSquare} className="fa-1x" /> {t('standingData:PROTEIN')}
			</>
		);
	};

	const handleChangeIdToNew = (): void => {
		handleChangeId('new');
		setDisplayOverlay(true);
	};

	const renderListRows = (): Row[] => {
		return nutriscoreNutrients.map((e: NutriScoreNutrientLight) => {
			return {
				id: mapToNutriscoreCategoryId(e),
				jsx: (
					<div
						className="d-flex flex-row justify-content-between align-items-center"
						style={{ gap: '2em' }}
					>
						<div style={{ width: '350px' }}>{e.nutrientName}</div>
						<div className="flex" style={{ width: '350px' }}>
							<span className="grey">{t('standingData:CATEGORY')}: </span>
							{e.pointType}
						</div>
						<div style={{ width: '300px' }}>
							<span className="grey">{t('standingData:AMOUNT_OF_POINTS')}: </span>
							{e.points.length}
						</div>
						<div style={{ width: '200px' }}>
							{e.isPositive ? t('_general:POSITIVE') : t('_general:NEGATIVE')}
						</div>
						<div style={{ width: '200px' }}>{renderProtein(e.isProtein)}</div>
						<div>{renderButtons(e)}</div>
					</div>
				),
			};
		});
	};

	const renderContent = () => {
		return (
			<>
				<main className="container">
					<div className="d-flex flex-row justify-content-between">
						<h1>{t('standingData:NUTRISCORE_NUTRIENT_MANAGEMENT')}</h1>
						<BtnNew
							onClick={handleChangeIdToNew}
							name={t('standingData:CREATE_NUTRISCOREPOINTS')}
						/>
					</div>
					<br />
					<div className="row">
						<div className="col-lg-12">
							<div className="input-group mb-3">
								<AdvancedSearchInput
									debounceMilliSeconds={350}
									getSuggestedItems={getSuggestedNutriScoreNutrients}
									handleSearch={handleSearch}
									searchTerm={searchTerm}
									setSearchTerm={setSearchTerm}
									triggerSearchLetterAmount={1}
								/>
							</div>
						</div>
					</div>
					<br />
					<LoadingAnimation isLoading={isLoading} />
					<p>{`${totalCount} ${t('_general:NUTRISCORENUTRIENTS')}`}</p>
					<StandingDataList
						displayOverlay={displayOverlay}
						rows={renderListRows()}
						overlay={<NutriScoreNutrientDetail />}
						handleOverlayOpen={handleOverlayOpen}
						handleOverlayClose={handleOverlayClose}
					/>
					<br />
					<Pagination
						countOfAllListItems={totalCount}
						pageIndex={advancedSearch.pageIndex}
						setPageIndex={handleChangePageIndex}
						listItemAmount={advancedSearch.pageSize}
						maxNumberOfButtons={maxNumberOfButtons}
					/>
				</main>
			</>
		);
	};

	return <>{renderContent()}</>;
};
export default NutriScoreNutrientManagement;
