export type Type = {
	cultureCode: string;
	nrOfMatches: number;
	itemName: string | null;
	ingredientId: string | null;
};

export function create(ingredientId: string | null): Type {
	return {
		cultureCode: 'en-US',
		nrOfMatches: 5,
		itemName: null,
		ingredientId: ingredientId,
	};
}

export function reset(obj: Type, itemName: string): Type {
	return {
		...create(obj.ingredientId),
		itemName: itemName,
		ingredientId: null,
	};
}
