import { Dispatch } from 'react';
import { useDispatch } from 'react-redux';
import { AnyAction } from 'redux';

import randomUUIDUnsafe from 'functions/randomUUIDUnsafe';
import { payloadReducer } from 'reducers/reducerUtils';

export type ReduxReducerFunction<T, B> = (state: T, action: B) => T;
export type ReduxReducerFunctionAsync<T, B> = (state: T, action: B) => Promise<T>;

/**
 * Redux requires you to dispatch actions to appropriate switch-sections in your
 * mega-reducer. This class marries those 2 concepts together and lets you build
 * the mega-reducer automatically, without the risk of mismatching keys.
 * In addition it offers you to create async reducers, so that you can call
 * the backend and do other calculations before calculating the final state
 *
 * Usage:
 *
 * const rb = new ReducerBuilder<MyType>(initialValue);
 *
 * export const action      = rb.createActionForReducer((t: MyType, p: payload) => {...t, name: p});
 * export const actionAsync = rb.createAsyncActionForReducer(async (t: MyType, p: payload) => {....});
 *
 * export const myReducer = rb.createReducer();
 *
 * Now mount the final reducer into your root-reducer and you're done.
 *
 * Then invoke the reducers as you wish
 * dispatch(action(myPayload))
 *
 * Note: You might optionally give your reducer an action name:
 *
 * rb.createActionForReducer((t: MyType, p: payload) => ..., 'myActionName');
 *
 * By default, it will use a random UUID for the action name but this will look odd in your redux devtools. It's recommended
 * to give an explicit name.
 */
export class ReducerBuilder<T> {
	public state: { [key: string]: ReduxReducerFunction<T, any> } = {};
	public stateAsync: { [key: string]: ReduxReducerFunctionAsync<T, any> } = {};

	public initial: T | null;

	constructor(initial?: T) {
		this.initial = initial || null;
	}

	public createActionForReducer<B>(
		f: ReduxReducerFunction<T, B>,
		key: string = randomUUIDUnsafe()
	) {
		if (this.state[key]) {
			throw Error(`Key ${key} already mapped to another reducer.`);
		}
		this.state[key] = (state: T, action: B) => f(state, action);
		const actionCreator = (payload: B) => {
			return { type: key, payload: payload };
		};
		return actionCreator;
	}

	public createAsyncActionForReducer<B>(
		asyncReducer: ReduxReducerFunctionAsync<T, B>,
		key: string = randomUUIDUnsafe()
	) {
		if (this.state[key]) {
			throw Error(`Key ${key} already mapped to another reducer.`);
		}

		const callbackKey = `${key}_${randomUUIDUnsafe()}`;
		this.state[callbackKey] = payloadReducer((x) => x);

		const actionCreator = (payload: B) => {
			return { type: key, payload: payload };
		};

		const actionCreatorWhenDone = <T>(payload: T) => {
			return { type: callbackKey, payload };
		};

		return (dispatch: Dispatch<AnyAction>) => {
			const promise = new Promise<T>((resolve) => {
				const f = async (store: T, payload: any) => {
					const result = await asyncReducer(store, payload);
					dispatch(actionCreatorWhenDone(result));
					resolve(result);
				};
				this.state[key] = (store: T, payload: any) => {
					f(store, payload);
					return store;
				};
			});

			return (payload: B) => {
				const action = actionCreator(payload);
				dispatch(action);
				return promise;
			};
		};
	}

	public createReducer(): (state: T | undefined, payload: any) => T {
		return (state: T | undefined = this.initial as any, action: any) => {
			const reducer = this.state[action.type];
			if (!reducer) return state;
			return reducer(state, action.payload);
		};
	}
}

type Decorate<T> = {
	[K in keyof T]: T[K] extends (p: infer P) => infer _R ? (p: P) => void : never;
};

type DecorateAsync<T> = {
	[K in keyof T]: T[K] extends (d: Dispatch<AnyAction>) => (p: infer P) => Promise<infer R>
		? (p: P) => Promise<R>
		: never;
};

/**
 * Remove your boilerplate by using this hook.
 *
 * All reducers build by the ReducerBuilder, require a dispatch function to work
 *
 * This hook will allow you to decorate your handlers and ignore the boilerplate of dispatching
 * any actional manually. Has decorators for both sync and async reducers
 * @returns
 */
export const useAirDecorators = () => {
	const dispatch = useDispatch();

	const decorate = <T extends { [key: string]: (...args: any[]) => any }>(
		obj: T
	): Decorate<T> => {
		const result = {} as Decorate<T>;
		for (const key in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, key)) {
				result[key] = ((p: any) => dispatch(obj[key](p))) as Decorate<T>[keyof T];
			}
		}
		return result;
	};

	// The decorateAsync function
	const decorateAsync = <
		T extends { [key: string]: (d: Dispatch<AnyAction>) => (...args: any[]) => Promise<any> },
	>(
		obj: T
	): DecorateAsync<T> => {
		const result = {} as DecorateAsync<T>;
		for (const key in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, key)) {
				result[key] = obj[key](dispatch) as DecorateAsync<T>[keyof T];
			}
		}
		return result;
	};

	const wrapNoArgs = <T>(f: (t: any) => T) => {
		return () => (f as any)(undefined);
	};

	return { decorate, decorateAsync, wrapNoArgs };
};
