import { Dispatch, SetStateAction } from 'react';

import { HighlightingSegment } from 'components/desktop/Recipe/RecipeTabs/Components/HighlightingSegment/HighlightingSegment';
import SegmentImageView from 'components/desktop/Recipe/RecipeTabs/Components/SegmentImageView/SegmentImageView';
import { SegmentMediaCollection } from 'types/Media/SegmentMedia/SegmentMediaCollection';
import { Recipe } from 'types/Recipe/Recipe';
import { Segment } from 'types/Recipe/Segment';

interface IProps {
	cultureCode: string;
	handleDeleteSegmentMedia: (index: number, id: string, video: boolean) => void;
	handleUploadMedia: (index: number, files: File[], video: boolean) => void;
	readOnly: boolean;
	segmentMedia: SegmentMediaCollection;
	segments: Segment[];
	setRecipe: Dispatch<SetStateAction<Recipe>>;
}

const SegmentMediaView = (props: IProps) => {
	return (
		<>
			<div style={{ padding: '10px' }} />
			{props.segments.map((x, i) => (
				<>
					<div style={{ padding: '10px' }} />
					<div
						className="segment-image-colspan"
						key={i}
						style={{ width: '100%', height: '100%' }}
					>
						<div className="col-4">
							<HighlightingSegment key={i} segment={x} />
						</div>
						<div className="col-8">
							<SegmentImageView
								handleDeleteSegmentMedia={props.handleDeleteSegmentMedia}
								handleUploadMedia={props.handleUploadMedia}
								images={
									props.segmentMedia[String(i)]
										? props.segmentMedia[String(i)].images
										: []
								}
								index={i}
								readOnly={props.readOnly}
								videos={
									props.segmentMedia[String(i)]
										? props.segmentMedia[String(i)].videos
										: []
								}
								instruction={x.instruction}
								cultureCode={props.cultureCode}
								setRecipe={props.setRecipe}
							/>
						</div>
					</div>
				</>
			))}
		</>
	);
};

export default SegmentMediaView;
