const syscoNutrients = [
	'5eada5f3-bca1-4539-af36-03bc1eb37e90',
	'4f17db51-c60d-4bb3-62e3-08dcd33f2f72',
	'd3616cd2-9ffb-4e59-af24-0e32a768352e',
	'd2fa35b2-5bc2-4f70-b137-1489b2414851',
	'4682229e-e477-48eb-88bf-26e886abfc08',
	'd54c13c1-b300-40ce-b4ba-2ff134b87c05',
	'f8132e8e-a396-4f4a-a5aa-3b3f8c470c3f',
	'56bd566b-c32c-4cec-a302-46f6e5eb930b',
	'99579e90-5e87-4133-b1fc-62ec52abee7f',
	'bcca087b-2681-4452-b27e-64ab18f2d7cd',
	'e19af87e-d4ee-4808-be64-674fcd957424',
	'064ba852-1e62-4415-afef-70cf15502859',
	'742e88e0-1043-45af-a4a4-77bf83d14327',
	'676c2033-c984-4999-b050-7859ed47c29e',
	'63de3747-8912-4ed6-b839-7fd0b9f3fba2',
	'13e1cc77-5930-4c05-b3f6-8748f50cf429',
	'17c177a0-85e1-4b8f-b3ad-877203828624',
	'19040a77-d04c-4f4f-ac5e-8b078b43178d',
	'07df67ca-2870-4442-b81a-9520eddd16c5',
	'83eaa196-e88c-4162-8e1b-9a996c0cf6ea',
	'0fe59d54-06ba-498c-bd8c-9dea7e3c74f4',
	'00a47337-bdba-4fed-a64d-b166df6a0111',
	'94f65da0-e3bc-4c7a-a356-b26a3e2849d3',
	'0603e3f5-1507-46d9-8349-b4a6d219eff7',
	'098bf59e-a621-4143-9c52-b643be6014bd',
	'c9be9866-5d90-4ba4-8ef6-bc8ba329780e',
	'2a6441e2-a261-4a6c-a057-c477b5d5bd31',
	'b2430839-be7b-427e-8213-d40abfa3bae9',
	'286e2d14-8d5f-478f-829b-d46dd0cf2a46',
	'a352b654-e40c-42f8-a71c-d593ded5bf59',
	'79993f95-89dc-4733-92a5-d6e5e5ac0d11',
	'ee2a4a77-3489-475f-aa28-da78e0967732',
	'7bcd8ae2-89d3-4cb3-b94c-dacfe8db83b7',
	'5645d120-9ee5-4392-925e-e1db8c9b131c',
	'66fd1016-29ef-47af-b706-e81343779498',
	'59ba6660-7604-431e-8daf-ef3abe152b59',
	'd695f4fa-8433-4bac-8605-f27be1509aef',
	'e9965b64-a824-44ae-885c-f462565a69a3',
	'5cc0c4a1-1fb6-4824-950a-f547eb3f5fee',
	'1077c739-0f60-411e-8870-f97b08b407d8',
	'8c7196a5-9987-4d08-9d90-ff3c1bf61c97',
];

export default syscoNutrients;
