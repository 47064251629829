import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import Swal, { SweetAlertResult } from 'sweetalert2';

import * as apiGetAll from 'api/ingredientCategory/GetIngredientCategoryGetAllV1';
import * as apiDelete from 'api/nutriScoreIngredientCategoryPoints/DeleteNutriScoreIngredientCategoryPointsIdV1';
import * as apiGet from 'api/nutriScoreIngredientCategoryPoints/GetNutriScoreIngredientCategoryPointsV1';
import IngredientCategoryPointsDetail from 'components/desktop/StandingData/IngredientCategoryPoints/IngredientCategoryPointsDetail';
import BtnGroup, { BtnData } from 'components/desktop/_general/Button/BtnGroup/BtnGroup';
import BtnNew from 'components/desktop/_general/Button/BtnNew/BtnNew';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import AdvancedSearchInput from 'components/desktop/_general/Search/AdvancedSearchInput';
import StandingDataList from 'components/desktop/_general/StandingDataList/StandingDataList';
import { Row } from 'components/desktop/_general/StandingDataList/StandingDataList';
import { NutriScoreCategoryIngredientCategory } from 'enums/NutriScoreCategory/NutriScoreCategoryIngredientCategory';
import { mapToISelectItem } from 'functions/mappers/ISelectItem/mapToISelectItem';
import { mapFromRequest } from 'functions/mappers/IngredientCategoryPoints/mapFromRequestLight';
import { mapAdvancedSearchToUrl } from 'functions/mappers/NutriScoreNutrientLight/URL/mapAdvancedSearchToUrl';
import { mapStateToAdvancedSearch } from 'functions/mappers/NutriScoreNutrientLight/URL/mapStateToAdvancedSearch';
import { mapUrlToAdvancedSearch } from 'functions/mappers/NutriScoreNutrientLight/URL/mapUrlToAdvancedSearch';
import useEffectWhenMounted from 'hooks/useEffectWhenMounted';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';
import { IngredientCategoryPointsAdvancedSearch } from 'types/IngredientCategoryPoints/AdvancedSearch/IngredientCategoryPointsAdvancedSearch';
import { IngredientCategoryPointLight } from 'types/IngredientCategoryPoints/IngredientCategoryPointLight';
import { defaultNutriScoreNutrientsAdvancedSearch } from 'types/NutriScoreNutrient/AdvancedSearch/NutrientAdvancedSearch';

const maxNumberOfButtons = 5;

const NutriScoreIngredientCategoryManamgent = () => {
	const { t } = useTranslation();
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [points, setPoints] = useState<IngredientCategoryPointLight[]>([]);
	const [categories, setCategories] = useState<apiGetAll.ResponseCategory[]>([]);
	const [advancedSearch, setAdvancedSearch] = useState<IngredientCategoryPointsAdvancedSearch>(
		defaultNutriScoreNutrientsAdvancedSearch
	);
	const [searchTerm, setSearchTerm] = useState<string>(advancedSearch.searchTerm ?? '');

	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [displayOverlay, setDisplayOverlay] = useState<boolean>(false);
	const [totalCount, setTotalCount] = useState<number>(0);

	useEffect(() => {
		initialize();
	}, []);

	useEffectWhenMounted(() => {
		initialize();
	}, [reduxCultureCode]);

	const mapToIngredientCategoryPointLightId = (
		baseIngredientCategoryPointLight: IngredientCategoryPointLight
	): string =>
		`${baseIngredientCategoryPointLight.ingredientCategoryId}:${baseIngredientCategoryPointLight.pointType}`;

	const initialize = async (): Promise<void> => {
		setIsLoading(true);
		await getCategories();
		try {
			if (window.location.href.includes('?')) {
				let advancedSearchCpy: IngredientCategoryPointsAdvancedSearch =
					defaultNutriScoreNutrientsAdvancedSearch();
				if (window.history.state.searchTerm !== undefined) {
					advancedSearchCpy = mapStateToAdvancedSearch(window.history.state);
					setAdvancedSearch(advancedSearchCpy);
					await getNutriScoreCategories(advancedSearchCpy);
				} else {
					const params = new URL(String(window.location)).searchParams;
					advancedSearchCpy = mapUrlToAdvancedSearch(params);
					setAdvancedSearch(advancedSearchCpy);
					await getNutriScoreCategories(advancedSearchCpy);
				}
				if (advancedSearchCpy.id !== null) {
					setDisplayOverlay(true);
				}
			} else {
				window.history.pushState(advancedSearch, '');
				await getNutriScoreCategories();
			}
		} finally {
			setIsLoading(false);
		}
	};

	const getNutriScoreCategories = async (
		advancedSearch?: IngredientCategoryPointsAdvancedSearch
	) => {
		const searchTerm = advancedSearch?.searchTerm ?? '';
		const pageIndex = advancedSearch?.pageIndex ?? 0;
		const pageSize = advancedSearch?.pageSize ?? 12;
		const response = await apiGet.callApi(searchTerm, undefined, pageIndex, pageSize);
		response.do((x) => {
			setPoints(mapFromRequest(x.data));
			setTotalCount(x.totalCount);
		});
	};

	const getSuggestedNutriScoreCategories = async (): Promise<ISelectItem[]> => {
		let output: apiGetAll.ResponseCategory[] = [];
		output = categories.filter((e: apiGetAll.ResponseCategory) => {
			if (e.name.toLowerCase().startsWith(searchTerm.toLowerCase())) {
				return e;
			}
		});
		return mapToISelectItem(output);
	};

	const handleChangeUrl = (advancedSearch: IngredientCategoryPointsAdvancedSearch) => {
		setAdvancedSearch(advancedSearch);
		window.history.replaceState(advancedSearch, '', mapAdvancedSearchToUrl(advancedSearch));
		getNutriScoreCategories(advancedSearch);
	};

	const handleSearch = (input?: string) => {
		var advancedSearchCpy = { ...advancedSearch };
		advancedSearchCpy.searchTerm = input ?? searchTerm;
		advancedSearchCpy.pageIndex = 0;
		handleChangeUrl(advancedSearchCpy);
	};

	const handleChangePageIndex = (index: number) => {
		var advancedSearchCpy = { ...advancedSearch };
		advancedSearchCpy.pageIndex = index;
		handleChangeUrl(advancedSearchCpy);
	};

	const handleChangeId = (id: string | null) => {
		var advancedSearchCpy = { ...advancedSearch };
		advancedSearchCpy.id = id;
		handleChangeUrl(advancedSearchCpy);
	};

	const handleOverlayOpen = (input: string): void => {
		setDisplayOverlay(true);
		handleChangeId(input);
	};

	const handleOverlayClose = (): void => {
		setDisplayOverlay(false);
		handleChangeId(null);
	};

	const getCategories = async () => {
		const response = await apiGetAll.callApi();
		response.do((x) => setCategories([...x.ingredientCategories]));
	};

	const handleDelete = async (
		id: string,
		pointType: NutriScoreCategoryIngredientCategory
	): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			cancelButtonText: t('_general:CANCEL'),
			confirmButtonColor: '#ff0004',
			confirmButtonText: t('_general:DELETE'),
			icon: 'warning',
			iconColor: '#ff0004',
			showCancelButton: true,
			text: t('standingData:NUTRISCORENUTRIENT_DELETION_DIALOG'),
			title: t('standingData:ARCHIVE_TITLE'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await apiDelete.apiCall(id, pointType);
				Swal.fire({
					confirmButtonColor: '#00cc07',
					title: t('standingData:NUTRISCORENUTRIENT_DELETION_CONFIRMATION'),
				});
				await initialize();
			} finally {
				setIsLoading(false);
			}
		}
	};

	const getButtonDataArchive = (
		id: string,
		pointType: NutriScoreCategoryIngredientCategory
	): BtnData | undefined => {
		return {
			onClick: () => handleDelete(id, pointType),
		};
	};

	const getButtonDataEdit = (id: string): BtnData | undefined => {
		return {
			onClick: () => handleOverlayOpen(id),
		};
	};

	const renderButtons = (e: IngredientCategoryPointLight): JSX.Element => {
		return (
			<BtnGroup
				edit={getButtonDataEdit(mapToIngredientCategoryPointLightId(e))}
				archive={getButtonDataArchive(e.ingredientCategoryId, e.pointType)}
			/>
		);
	};

	const handleChangeIdToNew = (): void => {
		handleChangeId('new');
		setDisplayOverlay(true);
	};

	const renderListRows = (): Row[] => {
		return points.map((e: IngredientCategoryPointLight) => {
			return {
				id: `${e.ingredientCategoryId}:${e.pointType}`,
				jsx: (
					<div
						className="d-flex flex-row justify-content-between align-items-center"
						style={{ gap: '2em' }}
					>
						<div style={{ width: '350px' }}>{e.ingredientCategoryName}</div>
						<div className="flex" style={{ width: '350px' }}>
							<span className="grey">{t('standingData:CATEGORY')}: </span>
							{e.pointType}
						</div>
						<div style={{ width: '300px' }}>
							<span className="grey">{t('standingData:AMOUNT_OF_POINTS')}: </span>
							{e.points.length}
						</div>
						<div style={{ width: '200px' }}>
							{e.isPositive ? t('_general:POSITIVE') : t('_general:NEGATIVE')}
						</div>
						<div>{renderButtons(e)}</div>
					</div>
				),
			};
		});
	};

	const renderContent = () => {
		return (
			<>
				<main className="container">
					<div className="d-flex flex-row justify-content-between">
						<h1>{t('_general:NUTRISCORE_INGREDIENTCATEGORY')}</h1>
						<BtnNew
							onClick={handleChangeIdToNew}
							name={t('standingData:CREATE_NUTRISCOREPOINTS')}
						/>
					</div>
					<br />
					<div className="row">
						<div className="col-lg-12">
							<div className="input-group mb-3">
								<AdvancedSearchInput
									debounceMilliSeconds={350}
									getSuggestedItems={getSuggestedNutriScoreCategories}
									handleSearch={handleSearch}
									searchTerm={searchTerm}
									setSearchTerm={setSearchTerm}
									triggerSearchLetterAmount={1}
								/>
							</div>
						</div>
					</div>
					<br />
					<LoadingAnimation isLoading={isLoading} />
					<p>{`${totalCount} ${t('_general:NUTRISCORE_INGREDIENTCATEGORY')}`}</p>
					<StandingDataList
						displayOverlay={displayOverlay}
						rows={renderListRows()}
						overlay={<IngredientCategoryPointsDetail />}
						handleOverlayOpen={handleOverlayOpen}
						handleOverlayClose={handleOverlayClose}
					/>
					<br />
					<Pagination
						countOfAllListItems={totalCount}
						pageIndex={advancedSearch.pageIndex}
						setPageIndex={handleChangePageIndex}
						listItemAmount={advancedSearch.pageSize}
						maxNumberOfButtons={maxNumberOfButtons}
					/>
				</main>
			</>
		);
	};

	return <>{renderContent()}</>;
};
export default NutriScoreIngredientCategoryManamgent;
