import axios from 'axios';

import { URL_API } from 'config/config';
import { Optional } from 'functions/promiseExtensions';
import IToken from 'interfaces/IToken';

const bypassAxiosInstance = axios.create();
bypassAxiosInstance.defaults.baseURL = URL_API;

export async function callApi(accessToken: IToken, refreshToken: IToken): Promise<Optional<void>> {
	const url: string = '/backend-service/User/Logout';

	const requestBody = {
		refreshToken: refreshToken.token,
	};

	try {
		await bypassAxiosInstance.post(url, requestBody, {
			headers: {
				Authorization: `Bearer ${accessToken.token}`,
				'Content-Type': 'application/json', // optional, depending on your API
			},
		});
		return Optional.Just<void>(null as any);
	} catch (error) {
		return Optional.None as any;
	}
}
