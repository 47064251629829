import * as api from 'api/recipe/PostRecipeCalculateV1';
import AllergenList from 'classes/Recipe/Detail/Calculation/AllergenList';
import AllergensPerIngredientList from 'classes/Recipe/Detail/Calculation/AllergensPerIngredientList';
import CarbonDioxideLabel from 'classes/Recipe/Detail/Calculation/CarbonDioxideLabel';
import CarbonDioxideValuePerAccessoryList from 'classes/Recipe/Detail/Calculation/CarbonDioxideValuePerAccessoryList';
import CarbonDioxideValuePerIngredientList from 'classes/Recipe/Detail/Calculation/CarbonDioxideValuePerIngredientList';
import NutrientValue from 'classes/Recipe/Detail/Calculation/NutrientValue';
import NutrientValueList from 'classes/Recipe/Detail/Calculation/NutrientValueList';
import NutrientValuesPerIngredientList from 'classes/Recipe/Detail/Calculation/NutrientValuesPerIngredientList';
import PricePerAccessoryList from 'classes/Recipe/Detail/Calculation/PricePerAccessoryList';
import PricePerIngredientList from 'classes/Recipe/Detail/Calculation/PricePerIngredientList';
import SeasonList from 'classes/Recipe/Detail/Calculation/SeasonList';
import SeasonsPerIngredientList from 'classes/Recipe/Detail/Calculation/SeasonsPerIngredientList';
import { Nutrient } from 'types/Nutrient/Nutrient';

export default class Calculation {
	public calories: number = 0;
	public weight: number = 0;
	public price: number | null = null;
	public carbonDioxideValue: number | null = null;
	public carbonDioxideLabel: CarbonDioxideLabel = new CarbonDioxideLabel();
	public nutrientValues: NutrientValueList = new NutrientValueList();
	public nutrientValuesPerIngredient: NutrientValuesPerIngredientList =
		new NutrientValuesPerIngredientList();
	public pricePerIngredient: PricePerIngredientList = new PricePerIngredientList();
	public pricePerAccessory: PricePerAccessoryList = new PricePerAccessoryList();
	public carbonDioxideValuePerIngredient: CarbonDioxideValuePerIngredientList =
		new CarbonDioxideValuePerIngredientList();
	public carbonDioxideValuePerAccessory: CarbonDioxideValuePerAccessoryList =
		new CarbonDioxideValuePerAccessoryList();
	public allergens: AllergenList = new AllergenList();
	public allergensPerIngredient: AllergensPerIngredientList = new AllergensPerIngredientList();
	public seasons: SeasonList = new SeasonList();
	public seasonsPerIngredient: SeasonsPerIngredientList = new SeasonsPerIngredientList();
	public ingredientNames: api.IngredientNames[] = [];

	public constructor(input?: api.ResponseData) {
		if (input) this.mapFromApi(input);
	}

	private nutrientIsInString(nutrients: Nutrient[], string: string): boolean {
		if (nutrients.length !== 0) {
			for (const i of nutrients) {
				if (string.toLowerCase() === i.name.toLowerCase()) {
					return true;
				}
			}
			return false;
		} else {
			return true;
		}
	}

	public getTableColumnKeys(
		searchTerm: string,
		nutrients: Nutrient[]
	): { columnName: string; key: string }[] {
		const search: NutrientValue[] = this.search(
			this.nutrientValues.items,
			searchTerm,
			nutrients
		);

		return search.map((e: NutrientValue) => {
			return { columnName: `${e.nutrient} (${e.unit})`, key: e.nutrientId };
		});
	}

	private search(
		nutrientValues: NutrientValue[],
		searchTerm: string,
		searchNutrients: Nutrient[]
	): NutrientValue[] {
		const firstNutrient: NutrientValue = this.nutrientValues.items[0];

		return nutrientValues
			.filter((e: NutrientValue) => {
				if (
					this.nutrientIsInString(searchNutrients, e.nutrient) ||
					(e.nutrient === firstNutrient.nutrient && e.category === firstNutrient.category)
				) {
					return e;
				}
			})
			.filter((e: NutrientValue) => {
				if (
					e.nutrient.toLowerCase().includes(searchTerm.toLowerCase()) ||
					(e.nutrient === firstNutrient.nutrient && e.category === firstNutrient.category)
				) {
					return e;
				}
			});
	}

	public searchNutrientValues(
		ingredientIndex: number,
		searchTerm: string,
		nutrients: Nutrient[]
	): NutrientValue[] | undefined {
		let nutrientValues: NutrientValue[] | undefined =
			this.nutrientValuesPerIngredient.items.find(
				(x) => x.ingredientIndex === ingredientIndex
			)?.nutrientValues;
		if (nutrientValues && (searchTerm !== '' || nutrients.length !== 0)) {
			nutrientValues = this.search(nutrientValues, searchTerm, nutrients);
		}
		return nutrientValues;
	}

	private mapFromApi(input: api.ResponseData) {
		this.calories = input.calories;
		this.weight = input.weight;
		this.price = input.price;
		this.ingredientNames = input.ingredientNames;
		this.carbonDioxideValue = input.carbonDioxideValue;
		this.carbonDioxideLabel = new CarbonDioxideLabel(input.carbonDioxideLabel);
		this.nutrientValues = new NutrientValueList(input.nutrientValues);
		this.nutrientValuesPerIngredient = new NutrientValuesPerIngredientList(
			input.nutrientValuesPerIngredient
		);
		this.pricePerIngredient = new PricePerIngredientList(input.pricePerIngredient);
		this.pricePerAccessory = new PricePerAccessoryList(input.pricePerAccessory);
		this.carbonDioxideValuePerIngredient = new CarbonDioxideValuePerIngredientList(
			input.carbonDioxideValuePerIngredient
		);
		this.carbonDioxideValuePerAccessory = new CarbonDioxideValuePerAccessoryList(
			input.carbonDioxideValuePerAccessory
		);
		this.allergens = new AllergenList(input.allergens);
		this.allergensPerIngredient = new AllergensPerIngredientList(input.allergensPerIngredient);
		this.seasons = new SeasonList(input.seasons);
		this.seasonsPerIngredient = new SeasonsPerIngredientList(input.seasonsPerIngredient);
	}
}
