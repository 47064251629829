import { t } from 'i18next';

import ImageCarousel from 'components/desktop/_general/ImageCarousel/ImageCarousel';
import Tabs, { Tab } from 'components/desktop/_general/Tabs/Tabs';
import VideoCarousel from 'components/desktop/_general/VideoCarousel/VideoCarousel';
import { ChannelLight } from 'types/Channel/ChannelLight';
import { ChannelMedia } from 'types/Media/ChannelMedia';
import { Video } from 'types/Media/Video';

interface IProps {
	handleDeleteImage: (id: string) => void;
	handleDeleteVideo: (id: string) => void;
	images: ChannelMedia[];
	mainImage: ChannelMedia | null;
	readOnly: boolean;
	setDisplayGenerateImageOverlay: (input: boolean) => void;
	setMainImage: (input: ChannelMedia) => void;
	updateChannels: (mediaId: string, channels: ChannelLight[]) => void;
	uploadMedia: (input: File[], video: boolean) => void;
	videos: Video[];
}

const MediaManagement = (props: IProps) => {
	const tabs: Tab[] = [
		{
			name: t('_general:IMAGES'),
			content: (
				<ImageCarousel
					handleDeleteImage={props.handleDeleteImage}
					images={props.images}
					mainImage={props.mainImage}
					readOnly={props.readOnly}
					setDisplayGenerateImageOverlay={props.setDisplayGenerateImageOverlay}
					setMainImage={props.setMainImage}
					updateChannels={props.updateChannels}
					uploadImage={props.uploadMedia}
				/>
			),
			ai: false,
		},
		{
			name: t('_general:VIDEOS'),
			content: (
				<VideoCarousel
					uploadVideo={props.uploadMedia}
					handleDeleteVideo={props.handleDeleteVideo}
					updateChannels={props.updateChannels}
					videos={props.videos}
					readOnly={props.readOnly}
				/>
			),
			ai: false,
		},
	];

	return (
		<>
			<fieldset style={{ overflowX: 'auto' }}>
				<Tabs
					tabs={tabs.filter((t: Tab) => {
						if (t.display === undefined || t.display === true) {
							return t;
						}
					})}
				/>
			</fieldset>
		</>
	);
};

export default MediaManagement;
