export type Dispatch = (input: any) => void;

export function payloadReducer<T>(f: (payload: T) => T) {
	return (_store: T, action: T) => f(action);
}

export function stateReducer<T>(f: (state: T) => T) {
	return (_store: T, _action: T) => f(_store);
}

export function stateReducerAsync<T>(f: (_store: T) => Promise<T>) {
	return (_store: T, _action: any) => f(_store);
}
