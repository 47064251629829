import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal, { SweetAlertResult } from 'sweetalert2';

import Accessory from 'classes/Accessory/Search/Accessory';
import AccessoryList from 'classes/Accessory/Search/AccessoryList';
import Search from 'classes/Accessory/Search/Search';
import AdvancedSearch from 'components/desktop/Accessory/AdvancedSearch/AdvancedSearch';
import SearchResultList from 'components/desktop/Accessory/Search/SearchResultList';
import BtnNew from 'components/desktop/_general/Button/BtnNew/BtnNew';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import DropdownPageSize from 'components/desktop/_general/DropdownPageSize/DropdownPageSize';
import DropdownSortOrder from 'components/desktop/_general/DropdownSortOrder/DropdownSortOrder';
import LoadingAnimation from 'components/desktop/_general/Loading/LoadingAnimation';
import Pagination from 'components/desktop/_general/Pagination/Pagination';
import AdvancedSearchInput from 'components/desktop/_general/Search/AdvancedSearchInput';
import { AccessorySortOrder } from 'enums/SortOrder/AccessorySortOrder';
import PERMISSIONS from 'enums/permissions';
import clone from 'functions/clone';
import { QueryParams, getQueryParams } from 'functions/getQueryParams';
import { arePermissionsInUserPermissions } from 'functions/tokenFunctions';
import useEffectWhenMounted from 'hooks/useEffectWhenMounted';
import { ISelectItem } from 'interfaces/ISelectItem';
import { RootState } from 'reducers/rootReducer';

interface IProps {
	archive?: boolean;
}

const maxNumberOfButtons = 10;

const AccessorySearchComponent = (props: IProps) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const accessoryEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITEACCESSORY]);

	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const [isLoading, setIsLoading] = useState(false);
	const [accessoryList, setAccessoryList] = useState<AccessoryList>(new AccessoryList());
	const [search, setSearch] = useState<Search>(new Search());
	const [renderRanges, setRenderRanges] = useState<boolean>(false);
	const [searchTerm, setSearchTerm] = useState<string>(search.searchTerm.value ?? '');

	useEffect(() => {
		initialize();
	}, []);

	useEffectWhenMounted(() => {
		initialize();
	}, [reduxCultureCode]);

	const initialize = async (): Promise<void> => {
		await search.initialize();
		if (props.archive !== undefined && props.archive == true) {
			search.showOnlyDeleted = true;
		}
		if (window.location.href.includes('?')) {
			openAdvancedSearch();
			const queryParams: QueryParams = getQueryParams();
			search.mapFromUrl(queryParams);
			await getAccessories();
		} else {
			await getAccessories();
		}
		setSearch(clone(search));
	};

	const handleRestoreAccessory = async (accessory: Accessory): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('accessory:RESTORE_TITLE'),
			text: t('accessory:RESTORE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#00cc07',
			confirmButtonColor: '#00cc07',
			confirmButtonText: t('_general:RESTORE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await accessory.callApiRestore();
				await Swal.fire({
					title: t('accessory:RESTORE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getAccessories();
			} catch {
				setIsLoading(false);
			}
		}
	};

	const handleArchiveAccessory = async (accessory: Accessory): Promise<void> => {
		const result: SweetAlertResult = await Swal.fire({
			title: t('accessory:ARCHIVE_TITLE'),
			text: t('accessory:ARCHIVE_DIALOG'),
			icon: 'warning',
			showCancelButton: true,
			iconColor: '#ff0004',
			confirmButtonColor: '#ff0004',
			confirmButtonText: t('_general:ARCHIVE'),
			cancelButtonText: t('_general:CANCEL'),
		});
		if (result.value) {
			setIsLoading(true);
			try {
				await accessory.callApiDelete();
				await Swal.fire({
					title: t('accessory:ARCHIVE_CONFIRMATION'),
					confirmButtonColor: '#00cc07',
				});
				await getAccessories();
			} catch {
				setIsLoading(false);
			}
		}
	};

	const handleChangeUrl = () => {
		const url: string | null = search.mapToUrl();
		if (url) navigate(url);
		getAccessories();
	};

	const handleChangeAdvancedSearchUrl = (): void => {
		search.pageIndex.value = 0;
		handleChangeUrl();
	};

	const handleReset = () => {
		search.reset();
		setSearchTerm('');
		handleChangeUrl();
	};

	const handleSearch = (input?: string) => {
		search.setSearchTerm(input ?? searchTerm);
		handleChangeUrl();
	};

	const handleChangeSortOrder = (value: string) => {
		search.sortOrder.value = value as AccessorySortOrder;
		handleChangeUrl();
	};

	const handleChangePageSize = (value: string): void => {
		search.pageSize.value = Number(value);
		handleChangeUrl();
	};

	const handleChangePageIndex = (index: number) => {
		search.pageIndex.value = index;
		handleChangeUrl();
	};

	const getSuggestedAccessories = async (): Promise<ISelectItem[]> => {
		await accessoryList.search(searchTerm, props.archive ?? false);
		return accessoryList.filtered;
	};

	const openAdvancedSearch = () => {
		const advancedSearchButton = document.getElementById('advancedSearchButton');
		if (advancedSearchButton) advancedSearchButton.click();
	};

	const getAccessories = async (): Promise<void> => {
		setIsLoading(true);
		try {
			const response = await accessoryList.callApi(search.mapToRequest());
			if (response.hasValue()) {
				accessoryList.mapFromApi(response.get());
				setAccessoryList(clone(accessoryList));
				search.mapFromApi(response.get());
			}
		} finally {
			setIsLoading(false);
		}
	};

	const advancedSearchContainer = document.getElementById('advancedSearch');
	if (advancedSearchContainer) {
		advancedSearchContainer.addEventListener('show.bs.collapse', function () {
			setRenderRanges(true);
		});
		advancedSearchContainer.addEventListener('hidden.bs.collapse', function () {
			setRenderRanges(false);
		});
	}

	const renderContent = () => {
		return (
			<main className="container">
				<div className="d-flex justify-content-between">
					<RenderIf condition={!props.archive}>
						<h1>{t('accessory:SEARCH_ACCESSORY')}</h1>
					</RenderIf>
					<RenderIf condition={Boolean(props.archive)}>
						<h1>{t('accessory:ACCESSORY_ARCHIVE')}</h1>
					</RenderIf>
					<RenderIf condition={accessoryEditor && !props.archive}>
						<BtnNew link={'/accessory/new'} name={t('accessory:CREATE_ACCESSORY')} />
					</RenderIf>
				</div>
				<br />
				<div className="row">
					<div className="col-lg-7">
						<div className="input-group mb-3">
							<AdvancedSearchInput
								debounceMilliSeconds={350}
								getSuggestedItems={getSuggestedAccessories}
								handleSearch={handleSearch}
								searchTerm={searchTerm}
								setSearchTerm={setSearchTerm}
								triggerSearchLetterAmount={1}
							/>
						</div>
					</div>
					<div className="col-lg-2">
						<button
							id="advancedSearchButton"
							className="btn btn-primary"
							type="button"
							onClick={() => setRenderRanges(true)}
							data-bs-toggle="collapse"
							data-bs-target="#advancedSearch"
							aria-expanded="true"
							aria-controls="advancedSearch"
						>
							{t('_general:ADVANCED_FILTER')}
						</button>
					</div>
					<div className="col-lg-3 text-end">
						<div className="d-flex flex-row justify-content-end">
							<DropdownPageSize
								className="me-2"
								listFunction={handleChangePageSize}
								currentValue={String(search.getPageSize())}
							/>
							<DropdownSortOrder
								type={'accessory'}
								listFunction={handleChangeSortOrder}
								currentValue={search.getSortOrder()}
							/>
						</div>
					</div>
				</div>
				<div className={'collapse'} id="advancedSearch">
					<div className="card card-body mb-3">
						<AdvancedSearch
							disabled={accessoryList.totalCount === 0}
							advancedSearch={search}
							handleChangeUrl={handleChangeAdvancedSearchUrl}
							handleReset={handleReset}
							renderRanges={renderRanges}
							setAdvancedSearch={setSearch}
						/>
					</div>
				</div>
				<LoadingAnimation isLoading={isLoading} />
				<p>{accessoryList.totalCount + ' ' + t('_general:ACCESSORIES')}</p>
				<div className="mb-3">
					<SearchResultList
						accessoryList={accessoryList}
						archive={props.archive ?? false}
						handleArchiveAccessory={handleArchiveAccessory}
						handleRestoreAccessory={handleRestoreAccessory}
					/>
				</div>
				<Pagination
					countOfAllListItems={accessoryList.totalCount}
					pageIndex={search.getPageIndex()}
					setPageIndex={handleChangePageIndex}
					listItemAmount={search.getPageSize()}
					maxNumberOfButtons={maxNumberOfButtons}
				/>
			</main>
		);
	};

	return <>{renderContent()}</>;
};

export default AccessorySearchComponent;
