import axios from 'axios';

import { Optional } from 'functions/promiseExtensions';

export async function callApi(request: Request): Promise<Optional<ResponseData>> {
	const url = '/ai-service/generate/image/';
	const requestConfig = {
		timeout: 0,
		headers: {
			'Content-Type': 'multipart/form-data',
		},
	};

	const params: Params = {} as Params;
	params.formData = new FormData();
	params.formData.set('json_data', JSON.stringify(request));
	return axios.post(url, params.formData, requestConfig).toOptionalMapped((x) => x.data.result);
}

interface Params {
	request: Request;
	formData: FormData | null;
}

export type Request = {
	imageDescription: string | null;
	cultureCode: string;
	imageType: ImageType;
	category: string | null;
	imageSize: ImageSize;
	nrOfImages: number;
	nrOfTries: number;
	outputType: ImageOutputType;
};

export type Response = {
	result: ResponseData;
};

export type ResponseData = {
	imageList: string[];
};

export enum ImageType {
	ACCESSORY = 'accessory',
	INGREDIENT = 'ingredient',
	RECIPE = 'recipe',
}

export enum ImageSize {
	HIGH = '1024x1792',
	LARGE = '1024x1024',
	MEDIUM = '512x512',
	SMALL = '256x256',
	WIDE = '1792x1024',
}

export enum ImageOutputType {
	JSON = 'b64_json',
	URL = 'url',
}
