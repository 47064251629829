import { combineReducers } from 'redux';

import { allowedAutoCompleteLanguagesReducer } from 'features/AllowedAutoCompleteLanguagesFeature';
import { allowedEntryLanguagesReducer } from 'features/AllowedEntryLanguagesFeature';
import { allowedUiLanguagesReducer } from 'features/AllowedUiLanguagesFeature';
import { cultureCodeReducer } from 'features/CultureCodeFeature';
import { currencyReducer } from 'features/CurrencyFeature';
import { languagesReducer } from 'features/LanguagesFeature';
import { menuBarOrderReducer } from 'features/MenuBarOrderFeature';
import { quantityMethodReducer } from 'features/QuantityMethodFeature';
import { userReducer } from 'features/UserFeature';
import { userSettingsReducer } from 'features/UserSettingsFeature';
import { allergensReducer } from 'reducers/AllergensReducer';
import { categoriesReducer } from 'reducers/CategoriesReducer';
import { co2LabelsReducer } from 'reducers/Co2LabelsReducer';
import { menuReducer } from 'reducers/MenuReducer';
import { nutrientListReducer } from 'reducers/NutrientListReducer';
import { productRangesReducer } from 'reducers/ProductRangesReducer';
import { seasonsReducer } from 'reducers/SeasonsReducer';
import { statusReducer } from 'reducers/StatusListReducer';
import { tagListReducer } from 'reducers/TagListReducer';
import { unitListReducer } from 'reducers/UnitsReducer';

export const rootReducer = combineReducers({
	allergens: allergensReducer,
	allowedAutoCompleteLanguages: allowedAutoCompleteLanguagesReducer,
	allowedEntryLanguages: allowedEntryLanguagesReducer,
	allowedUiLanguages: allowedUiLanguagesReducer,
	categories: categoriesReducer,
	co2Labels: co2LabelsReducer,
	cultureCode: cultureCodeReducer,
	currency: currencyReducer,
	languages: languagesReducer,
	menu: menuReducer,
	menuBarOrder: menuBarOrderReducer,
	nutrientList: nutrientListReducer,
	productRanges: productRangesReducer,
	quantityMethod: quantityMethodReducer,
	seasons: seasonsReducer,
	statusList: statusReducer,
	tagList: tagListReducer,
	unitList: unitListReducer,
	user: userReducer,
	userSettings: userSettingsReducer,
});

export default rootReducer;

export type RootState = ReturnType<typeof rootReducer>;
