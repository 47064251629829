import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faDollar, faUtensils } from '@fortawesome/pro-duotone-svg-icons';
import { faKitchenSet } from '@fortawesome/pro-solid-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faTrash } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { t } from 'i18next';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';

import AllergenList from 'classes/MenuPlan/Detail/AllergenList';
import Column from 'classes/MenuPlan/Detail/Column';
import Item from 'classes/MenuPlan/Detail/Item';
import Menu from 'classes/MenuPlan/Detail/Menu';
import MenuPlan from 'classes/MenuPlan/Detail/MenuPlan';
import Recipe from 'classes/MenuPlan/Detail/Recipe';
import Row from 'classes/MenuPlan/Detail/Row';
import TagList from 'classes/MenuPlan/Detail/TagList';
import 'components/desktop/MenuPlan/Detail/ItemComponent.scss';
import ItemInputField from 'components/desktop/MenuPlan/Detail/ItemInputField';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import IconComponent from 'components/desktop/_general/Icon/IconComponent';
import ImageSlider from 'components/desktop/_general/ImageSlider/ImageSlider';
import ENTITLEMENTS from 'enums/entitlements';
import PERMISSIONS from 'enums/permissions';
import { getNumberString, getPriceString } from 'functions/numberToString';
import { Optional } from 'functions/promiseExtensions';
import {
	areEntitlementsInUserEntitlements,
	arePermissionsInUserPermissions,
} from 'functions/tokenFunctions';
import { RootState } from 'reducers/rootReducer';
import { AllergenLight } from 'types/Allergen/AllergenLight';
import { TagLight } from 'types/Tag/TagLight';

interface IProps {
	id?: string;
	item?: Item;
	day: number;
	rowIndex: number;
	menuPlan: MenuPlan;
	setMenuPlan: (input: MenuPlan) => void;
	row: Row;
	rowHeight: number;
	rowItemWidth: number;
	getSelectedItemFromApi: (input: Item) => Promise<void>;
	selectedItem: Recipe | Menu | Row | Item | Column | null;
	setSelectedItem: (input: Row | Item | null) => void;
	handleRemoveItem: (day: number, rowId: string) => void;
}

const ItemComponent = (props: IProps) => {
	const navigate = useNavigate();
	const menuPlanEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITEMENUPLAN]);
	const recipeReader: boolean = arePermissionsInUserPermissions([PERMISSIONS.READRECIPE]);

	const [showButtons, setShowButtons] = useState<boolean>(false);
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);
	const reduxCurrency: string = useSelector((state: RootState) => state.currency);

	const handleRemoveItem = (): void => {
		const day: number | undefined = props.item?.day;
		if (day !== undefined) {
			props.handleRemoveItem(day, props.row.id);
			setShowButtons(false);
		}
	};

	const handleSelectItem = (): void => {
		if (recipeReader) {
			if (props.item) {
				props.getSelectedItemFromApi(props.item);
			}
		}
	};

	const addItemToExistingMenuPlan = async (type: 'recipe' | 'menu'): Promise<void> => {
		const response = await props.menuPlan.callApiPut();
		response.do((x) =>
			navigate(
				`/${type}/search/menuPlan/${x.id ?? props.menuPlan.id}/${props.rowIndex}/${
					props.day
				}`
			)
		);
	};

	const addItemToNewMenuPlan = async (type: 'recipe' | 'menu'): Promise<void> => {
		const id: Optional<string> = await props.menuPlan.callApiPost();
		id.do((x) =>
			navigate(
				`/${type}/search/menuPlan/${x ?? props.menuPlan.id}/${props.rowIndex}/${props.day}`
			)
		);
	};

	const handleAddItem = async (type: 'recipe' | 'menu'): Promise<void> => {
		if (!menuPlanEditor) return;
		if (props.id) {
			addItemToExistingMenuPlan(type);
		} else {
			addItemToNewMenuPlan(type);
		}
	};

	const onMouseOver = (): void => {
		if (menuPlanEditor) setShowButtons(true);
	};

	const getIcons = (input: Item): Array<AllergenLight | TagLight> => {
		const output: Array<AllergenLight | TagLight> = [];

		const tags: TagList = input.tags;
		for (const tag of tags.saved) {
			if (tag.iconSvgUrl && tag.display) {
				output.push({
					id: tag.id,
					display: tag.display,
					iconSvgUrl: tag.iconSvgUrl,
					name: tag.name ?? ' ',
					tagCategory: {
						id: tag.tagCategory.id,
						name: tag.tagCategory.name ?? ' ',
					},
				});
			}
		}

		const allergens: AllergenList = input.allergens;
		for (const allergen of allergens.saved) {
			if (allergen.iconSvgUrl && allergen.display) {
				output.push({
					id: allergen.id,
					display: allergen.display,
					iconSvgUrl: allergen.iconSvgUrl,
					logic: allergen.logic,
					name: allergen.name ?? '',
				});
			}
		}
		return output;
	};

	const onMouseLeave = (): void => {
		setShowButtons(false);
	};
	if (props.item) {
		return (
			<div
				onClick={() => handleSelectItem()}
				onMouseOver={onMouseOver}
				onMouseLeave={onMouseLeave}
				className="item-component-recipe"
				style={{
					height: props.rowHeight + 'px',
					width: props.rowItemWidth + 'px',
					border:
						props.selectedItem?.id === props.item?.id ? '3px solid #6699cc' : undefined,
				}}
			>
				{props.item.images.all.length > 0 ? (
					<ImageSlider images={props.item.images.all} id={props.item.id} />
				) : (
					<div
						className="img-placeholder"
						style={{
							width: 'auto',
						}}
					>
						<FontAwesomeIcon icon={faKitchenSet as IconProp} className="fa-5x" />
					</div>
				)}
				<div style={{ margin: '10px' }}>
					<div className="recipe-informations">
						<strong>{props.item.title?.[reduxCultureCode]}</strong>
					</div>
					<hr style={{ marginTop: '8px', marginBottom: '5px' }} />
					{t('measurments:PER_PART')}
					<div>
						<FontAwesomeIcon
							icon={faDollar as IconProp}
							className="fa-1x"
							style={{ marginRight: '11px' }}
						/>
						{props.item.price ? (
							<>
								<b>{getPriceString(props.item.price, reduxCultureCode)} </b>
								{reduxCurrency}
							</>
						) : (
							<b>–</b>
						)}
						<br />
						<FontAwesomeIcon
							icon={faUtensils as IconProp}
							className="fa-1x"
							style={{ marginRight: '7px' }}
						/>
						{props.item.calories ? (
							<>
								<b>
									{getNumberString(props.item.calories, reduxCultureCode, true)}
								</b>
								{' ' + t('_general:CALORIES')}
							</>
						) : (
							<b>–</b>
						)}
					</div>
					<hr style={{ marginTop: '8px', marginBottom: '8px' }} />
					<div>
						{
							<>
								<div className="d-flex flex-gap-10">
									{getIcons(props.item).map(
										(icon: AllergenLight | TagLight, i: number) => (
											<IconComponent icon={icon} key={i} />
										)
									)}
								</div>
							</>
						}
					</div>
				</div>
				<div style={{ position: 'absolute', bottom: 0, margin: '10px' }}>
					<ItemInputField
						item={props.item}
						menuPlan={props.menuPlan}
						setMenuPlan={props.setMenuPlan}
					/>
				</div>
				<div
					className="recipe-buttons"
					style={{
						opacity: showButtons ? '1' : '0',
					}}
				>
					<div className="text-center" style={{ margin: '5px' }}>
						<button
							type="button"
							className="btn btn-outline-primary"
							onClick={handleRemoveItem}
						>
							<FontAwesomeIcon icon={faTrash as IconProp} />
						</button>
					</div>
				</div>
			</div>
		);
	} else {
		return (
			<RenderIf condition={menuPlanEditor}>
				<div
					className="item-component-buttons"
					style={{
						height: props.rowHeight + 'px',
						width: props.rowItemWidth + 'px',
					}}
				>
					<div
						className="button top"
						style={{
							height: areEntitlementsInUserEntitlements([ENTITLEMENTS.MENU])
								? '50%'
								: '100%',
						}}
						onClick={() => handleAddItem('recipe')}
					>
						<FontAwesomeIcon
							icon={faPlus as IconProp}
							className="fa-2x"
							style={{ marginRight: '8px' }}
						/>
						{t('_general:RECIPE')}
					</div>
					<RenderIf entitlements={[ENTITLEMENTS.MENU]}>
						<div className="button" onClick={() => handleAddItem('menu')}>
							<FontAwesomeIcon
								icon={faPlus as IconProp}
								className="fa-2x"
								style={{ marginRight: '8px' }}
							/>
							{t('_general:MENU')}
						</div>
					</RenderIf>
				</div>
			</RenderIf>
		);
	}
};

export default ItemComponent;
