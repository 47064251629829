import { useMenu } from 'context/Store';

import MenuComponent from 'components/desktop/Menu/MenuComponent';

const MenuNewContainer = () => {
	const menu = useMenu();
	const renderContent = () => {
		return <main className="container">{menu && <MenuComponent menu={menu} />}</main>;
	};

	return <>{renderContent()}</>;
};

export default MenuNewContainer;
