import { DependencyList, useEffect, useMemo, useState } from 'react';

/* eslint-disable no-redeclare */

// ESLINT does not understand overload in this context.

// export function useApi<T>(initialValue: T, f: () => Promise<T>): [T, boolean];
export function useApi<T>(initialValue: T, f: () => Promise<T>): [T, boolean];
export function useApi<T, T1>(initialValue: T, f: (t1: T1) => Promise<T>, t1: T1): [T, boolean];
export function useApi<T, T1, T2>(
	initialValue: T,
	f: (t1: T1, t2: T2) => Promise<T>,
	t1: T1,
	t2: T2
): [T, boolean];
export function useApi<T, T1, T2, T3>(
	initialValue: T,
	f: (t1: T1, t2: T2, t3: T3) => Promise<T>,
	t1: T1,
	t2: T2,
	t3: T3
): [T, boolean];

export function useApi<T, T1, T2, T3, T4>(
	initialValue: T,
	f: (t1: T1, t2: T2, t3: T3, t4: T4) => Promise<T>,
	t1: T1,
	t2: T2,
	t3: T3,
	t4: T4
): [T, boolean];

export function useApi<T, T1, T2, T3, T4, T5>(
	initialValue: T,
	f: (t1: T1, t2: T2, t3: T3, t4: T4, t5: T5) => Promise<T>,
	t1: T1,
	t2: T2,
	t3: T3,
	t4: T4,
	t5: T5
): [T, boolean];

export function useApi<T, T1, T2, T3, T4, T5, T6>(
	initialValue: T,
	f: (t1: T1, t2: T2, t3: T3, t4: T4, t5: T5, t6: T6) => Promise<T>,
	t1: T1,
	t2: T2,
	t3: T3,
	t4: T4,
	t5: T5,
	t6: T6
): [T, boolean];

export function useApi(
	initialValue: any,
	f: (...s: any) => Promise<any>,
	...s: any[]
): [any, boolean] {
	const [data, setData] = useState(initialValue);
	const [isLoaded, setIsLoaded] = useState<boolean>(false);

	useEffect(() => {
		const updateFunction = async () => {
			setIsLoaded(false);
			const t = await f(...s);
			setData(t);
			setIsLoaded(true);
		};

		updateFunction();
	}, s as DependencyList);

	return [data, isLoaded];
}

export function useMemoized<T>(t: T) {
	return useMemo(() => t, [JSON.stringify(t)]);
}

/* eslint-enable no-redeclare */
