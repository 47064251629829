import stringify from 'json-stable-stringify';

import * as api from 'api/aiFunctions/PostAiFunctionsAnalyzeRecipeFromUrlV1';
import { EntryLanguage } from 'types/Recipe/EntryLanguage';
import { Recipe } from 'types/Recipe/Recipe';

export function getPreviewString(
	recipe: Recipe | api.ResponseData,
	key: keyof Recipe & keyof api.ResponseData
): string {
	if (!recipe[key]) return '–';
	switch (key) {
		case 'entryLanguage':
			return getPreviewFromEntryLanguage(recipe[key]);
		case 'titleTranslations':
			return getPreviewFromTranslation(recipe[key]);
		case 'shortDescriptionTranslations':
			return getPreviewFromTranslation(recipe[key]);
		case 'notesTranslations':
			return getPreviewFromTranslation(recipe[key]);
		case 'ingredientsTranslations':
			return getPreviewFromTranslation(recipe[key]);
		case 'stepsTranslations':
			return getPreviewFromTranslation(recipe[key]);
		case 'seoTranslations':
			return getPreviewFromTranslation(recipe[key]);
		case 'calories':
			return getPreviewFromNumber(recipe[key]);
		case 'persons':
			return getPreviewFromNumber(recipe[key]);
		case 'parts':
			return getPreviewFromNumber(recipe[key]);
		case 'nutriScore':
			return getPreviewFromNutriScore(recipe[key]);
		case 'activityTimes':
			return getPreviewFromActivityTimes(recipe[key]);
		case 'tags':
			return getPreviewFromTags(recipe[key]);
		case 'categories':
			return getPreviewFromCategories(recipe[key]);
		default:
			return '–';
	}
}

function getPreviewFromCategories(input: api.Categories[]): string {
	if (!hasValueCategories(input)) return '-';
	const output: string[] = [];
	for (const i of input) {
		if (i.name) {
			output.push(i.name);
		}
	}
	const outputString: string = output.join(', ');
	if (outputString) return shortenString(outputString);
	return '-';
}

function getPreviewFromTags(input: api.Tags[]): string {
	if (!hasValueTags(input)) return '–';
	const output: string[] = [];
	for (const i of input) {
		if (i.name) {
			output.push(i.name);
		}
	}
	const outputString: string = output.join(', ');
	if (outputString) return shortenString(outputString);
	return '–';
}

function getPreviewFromActivityTimes(input: api.ActivityTimes[]): string {
	if (!hasValueActivityTimes(input)) return '–';
	const output: string[] = [];
	for (const i of input) {
		if (i.name) {
			output.push(i.name);
		}
	}
	const outputString: string = output.join(', ');
	if (outputString) return shortenString(outputString);
	return '–';
}

function getPreviewFromEntryLanguage(input: EntryLanguage): string {
	if (!hasValueEntryLanguage(input)) return '–';
	if (input.description) return input.description;
	return '–';
}

function getPreviewFromNutriScore(input: api.NutriScore): string {
	if (!hasValueNutriScore(input)) return '–';
	if (input.label) return input.label;
	return '–';
}

function getPreviewFromTranslation(input: api.Translation): string {
	if (!hasValueTranslation(input)) return '–';
	for (const value of Object.values(input)) {
		if (value) return shortenString(value);
	}
	return '–';
}

function getPreviewFromNumber(input: number | null): string {
	if (!hasValueNumber(input)) return '–';
	if (input === 0) return '0';
	if (input) return String(input);
	return '–';
}

export function hasNewValue(
	recipe: Recipe,
	apiRecipe: api.ResponseData,
	key: keyof Recipe & keyof api.ResponseData
): boolean {
	if (key === 'entryLanguage') {
		return recipe.entryLanguage.id !== apiRecipe.entryLanguage.id;
	}
	if (key === 'nutriScore') {
		const a: string = stringify(recipe[key]);
		const b: string = stringify(apiRecipe[key]);
		if (a === '{"color":"","label":""}' && b === 'null') return false;
		return a !== b;
	}
	const a: string = stringify(recipe[key]);
	const b: string = stringify(apiRecipe[key]);
	return a !== b;
}

export function hasValue(
	recipe: Recipe | api.ResponseData,
	key: keyof Recipe & keyof api.ResponseData
): boolean {
	if (!recipe[key]) return false;
	switch (key) {
		case 'entryLanguage':
			return hasValueEntryLanguage(recipe.entryLanguage);
		case 'titleTranslations':
			return hasValueTranslation(recipe[key]);
		case 'shortDescriptionTranslations':
			return hasValueTranslation(recipe[key]);
		case 'notesTranslations':
			return hasValueTranslation(recipe[key]);
		case 'ingredientsTranslations':
			return hasValueTranslation(recipe[key]);
		case 'stepsTranslations':
			return hasValueTranslation(recipe[key]);
		case 'seoTranslations':
			return hasValueTranslation(recipe[key]);
		case 'calories':
			return hasValueNumber(recipe[key]);
		case 'persons':
			return hasValueNumber(recipe[key]);
		case 'parts':
			return hasValueNumber(recipe[key]);
		case 'nutriScore':
			return hasValueNutriScore(recipe[key]);
		case 'activityTimes':
			return hasValueActivityTimes(recipe[key]);
		case 'tags':
			return hasValueTags(recipe[key]);
		case 'categories':
			return hasValueCategories(recipe[key]);
		default:
			return false;
	}
}

function hasValueEntryLanguage(input: EntryLanguage): boolean {
	if (!input.description || !input.id) return false;
	return true;
}

function hasValueCategories(input: api.Categories[]): boolean {
	return hasValueArray(input);
}

function hasValueTags(input: api.Tags[]): boolean {
	return hasValueArray(input);
}

function hasValueArray(input: any[]): boolean {
	if (!Array.isArray(input)) return false;
	if (!input.length) return false;
	return true;
}

function hasValueNutriScore(input: api.NutriScore): boolean {
	if (!input.color || !input.label) return false;
	return true;
}

function hasValueActivityTimes(input: api.ActivityTimes[]): boolean {
	if (!hasValueArray(input)) return false;
	for (const i of input) {
		if (!i.id || !i.name || !i.timeUnit || !i.timeValue) return false;
		if (!i.timeUnit.description) return false;
		if (!i.timeUnit.id) return false;
	}
	return true;
}

function hasValueNumber(input: number | null): boolean {
	if (input === 0) return true;
	if (!input) return false;
	return true;
}

function hasValueTranslation(input: api.Translation): boolean {
	for (const value of Object.values(input)) {
		if (value) return true;
	}
	return false;
}

function shortenString(input: string, maxLength?: number): string {
	maxLength = maxLength ?? 40;
	input = input.replaceAll('\n', ' ').replaceAll('\r', ' ');
	if (input.length > maxLength) return input.slice(0, maxLength) + '...';
	return input;
}
