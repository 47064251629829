import { t } from 'i18next';
import { useSelector } from 'react-redux';

import MenuPlan from 'classes/MenuPlan/Detail/MenuPlan';
import RowManagement from 'components/desktop/MenuPlan/Detail/RowManagement';
import DateSelect from 'components/desktop/MenuPlan/Search/DateSelect';
import { RenderIf } from 'components/desktop/_general/Conditional/RenderIf';
import InputTranslateButton from 'components/desktop/_general/Input/InputExtensions/InputTranslateButton/InputTranslateButton';
import InputFieldSimple from 'components/desktop/_general/Input/InputFields/InputFieldSimple';
import RoundedInput from 'components/desktop/_general/Input/RoundedInput/RoundedInput';
import SimpleInput from 'components/desktop/_general/Input/SimpleInput/SimpleInput';
import LanguageSwitch from 'components/desktop/_general/LanguageSwitch/LanguageSwitch';
import ENTITLEMENTS from 'enums/entitlements';
import PERMISSIONS from 'enums/permissions';
import clone from 'functions/clone';
import {
	areEntitlementsInUserEntitlements,
	arePermissionsInUserPermissions,
} from 'functions/tokenFunctions';
import { RootState } from 'reducers/rootReducer';
import { regexValidationCalories } from 'regex/validation/Calories';
import { regexValidationPrice } from 'regex/validation/Price';
import { Language } from 'types/Language/Language';

interface IProps {
	menuPlan: MenuPlan;
	selectedLanguage: Language;
	setMenuPlan: (input: MenuPlan) => void;
	setSelectedLanguage: (input: Language) => void;
}

const Header = (props: IProps) => {
	const menuPlanEditor: boolean = arePermissionsInUserPermissions([PERMISSIONS.WRITEMENUPLAN]);
	const reduxLanguages: Language[] = useSelector((state: RootState) => state.languages);
	const reduxCurrency: string = useSelector((state: RootState) => state.currency);
	const reduxCultureCode: string = useSelector((state: RootState) => state.cultureCode);

	const changeStartDate = (input: Date | null): void => {
		if (input) {
			props.menuPlan.startDate = input;
			props.setMenuPlan(clone(props.menuPlan));
		}
	};

	const changeEndDate = (input: Date | null): void => {
		if (input) {
			props.menuPlan.endDate = input;
			props.setMenuPlan(clone(props.menuPlan));
		}
	};

	const changeCaloriesLimit = (input: number): void => {
		props.menuPlan.setCaloriesLimit(input);
		props.setMenuPlan(clone(props.menuPlan));
	};

	const changeCaloriesPerDayLimit = (input: string): void => {
		props.menuPlan.setCaloriesLimitPerDay(Number(input));
		props.setMenuPlan(clone(props.menuPlan));
	};

	const changePriceLimit = (input: number): void => {
		props.menuPlan.setPriceLimit(input);
		props.setMenuPlan(clone(props.menuPlan));
	};

	const changePricePerDayLimit = (input: string): void => {
		props.menuPlan.setPriceLimitPerDay(Number(input));
		props.setMenuPlan(clone(props.menuPlan));
	};

	const handleSelectLanguage = (_e: any, selectedLanguage: string) => {
		props.setSelectedLanguage(
			reduxLanguages[
				reduxLanguages.findIndex((element) => element.cultureCode == selectedLanguage)
			]
		);
	};

	const handleChangeTitle = (input: string): void => {
		const value: string = input;
		props.menuPlan.title = {
			...props.menuPlan.title,
			...{
				[props.selectedLanguage.cultureCode]: value,
			},
		};
		props.setMenuPlan(clone(props.menuPlan));
	};

	const handleTranslateAll = async () => {
		await props.menuPlan.translateMenuPlan(
			reduxCultureCode,
			props.selectedLanguage.cultureCode
		);
		props.setMenuPlan(clone(props.menuPlan));
	};

	const handleTranslateInput = async (_name: string) => {
		await props.menuPlan.translateTitle(reduxCultureCode, props.selectedLanguage.cultureCode);
		props.setMenuPlan(clone(props.menuPlan));
	};

	const isTranslatable = (): boolean => {
		if (!menuPlanEditor) return false;
		if (reduxLanguages && reduxCultureCode !== undefined) {
			if (reduxCultureCode === props.selectedLanguage.cultureCode) {
				return false;
			} else {
				return true;
			}
		}
		return false;
	};

	const renderDateSelects = (): JSX.Element => {
		return (
			<div className="row">
				<div className="col-6">
					<DateSelect
						label={t('menuPlan:START')}
						value={props.menuPlan.startDate}
						disabled={!menuPlanEditor}
						changeValue={changeStartDate}
						notNullable={true}
					/>
				</div>
				<div className="col-6">
					<DateSelect
						label={t('menuPlan:END')}
						value={props.menuPlan.endDate}
						disabled={!menuPlanEditor}
						changeValue={changeEndDate}
						notNullable={true}
					/>
				</div>
			</div>
		);
	};

	const renderLanguageSwitch = (): JSX.Element => {
		return (
			<LanguageSwitch
				handleSelectLanguage={handleSelectLanguage}
				selectedLanguage={props.selectedLanguage.cultureCode}
				translatable={true}
				onTranslateClick={handleTranslateAll}
				entryLanguage={reduxCultureCode}
				content={
					<div className="row mt-2">
						<div className="col-6">
							<label>{t('menuPlan:TITLE')}</label>
							<div className="input-group">
								<SimpleInput
									value={
										props.menuPlan.title?.[
											props.selectedLanguage.cultureCode
										] ?? ''
									}
									handleChange={handleChangeTitle}
									disabled={!menuPlanEditor}
									error-key="TitleTranslations"
								/>
								<RenderIf
									entitlements={[ENTITLEMENTS.TRANSLATION_SERVICE]}
									condition={isTranslatable()}
								>
									<InputTranslateButton
										name="titleTranslations"
										onClick={handleTranslateInput}
									/>
								</RenderIf>
							</div>
						</div>
						<div className="col-6">
							<RowManagement
								selectedCultureCode={props.selectedLanguage.cultureCode}
								entryLanguage={reduxCultureCode}
								languages={reduxLanguages}
								menuPlan={props.menuPlan}
								setMenuPlan={props.setMenuPlan}
								disabled={!menuPlanEditor}
							/>
						</div>
					</div>
				}
			/>
		);
	};

	const getClassNameOfCaloriesInputField = (): string => {
		return areEntitlementsInUserEntitlements([ENTITLEMENTS.PRICE]) ? 'col-6' : 'col-12';
	};

	const renderLimits = (): JSX.Element => {
		return (
			<>
				<label>{t('menuPlan:MAX_LIMIT_PER_PERSON')}</label>
				<div className="row">
					<div className={getClassNameOfCaloriesInputField()}>
						<div className="input-group mb-3">
							<RoundedInput
								disabled={!menuPlanEditor}
								handleChange={changeCaloriesLimit}
								value={props.menuPlan.caloriesLimit ?? 0}
							/>
							<span className="input-group-text">{t('measurments:CAL')}</span>
						</div>
					</div>
					<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>
						<div className="col-6">
							<div className="input-group ">
								<RoundedInput
									disabled={!menuPlanEditor}
									handleChange={changePriceLimit}
									value={props.menuPlan.priceLimit ?? 0}
								/>
								<span className="input-group-text">{reduxCurrency}</span>
							</div>
						</div>
					</RenderIf>
				</div>
				<label>{t('menuPlan:MAX_LIMIT_PER_PERSON_PER_DAY')}</label>
				<div className="row">
					<div className={getClassNameOfCaloriesInputField()}>
						<div className="input-group mb-3">
							<InputFieldSimple
								defaultValue={String(props.menuPlan.stringifyCaloriesLimitPerDay())}
								disabled={true}
								handleValueChange={changeCaloriesPerDayLimit}
								validateRegex={regexValidationCalories}
							/>
							<span className="input-group-text">{t('measurments:CAL')}</span>
						</div>
					</div>
					<RenderIf entitlements={[ENTITLEMENTS.PRICE]}>
						<div className="col-6">
							<div className="input-group mb-3">
								<InputFieldSimple
									defaultValue={props.menuPlan.stringifyPriceLimitPerDay()}
									disabled={true}
									handleValueChange={changePricePerDayLimit}
									validateRegex={regexValidationPrice}
								/>
								<span className="input-group-text">{reduxCurrency}</span>
							</div>
						</div>
					</RenderIf>
				</div>
			</>
		);
	};

	return (
		<>
			<div className="col-6">{renderLanguageSwitch()}</div>
			<div className="col-6">
				{renderDateSelects()}
				<hr />
				{renderLimits()}
			</div>
		</>
	);
};

export default Header;
