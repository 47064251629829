import { faCamera } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';

interface IProps {
	className?: string;
	src: string;
	onClick?: () => void;
}

const ImageWithFallback = (props: IProps) => {
	const [hasError, setError] = useState(false);
	const onError = () => {
		setError(true);
	};

	if (hasError) {
		return (
			<div className="card-picture-placeholder d-flex justify-content-center align-items-center">
				<FontAwesomeIcon size="3x" icon={faCamera} color="white" />
			</div>
		);
	}

	return (
		<>
			<div className="card-picture">
				<img
					style={{ height: '100%', width: '100%', objectFit: 'contain' }}
					alt="thumbnail"
					onError={onError}
					src={props.src}
					onClick={props.onClick}
				/>
			</div>
		</>
	);
};

export default ImageWithFallback;
