import * as apiGet from 'api/nutrient/GetNutrientV1';
import * as Nutrient from 'types/_general/Store/Nutrient';

export type Type = Nutrient.Type[];

export function create(): Type {
	return [];
}

export async function getFromApi(): Promise<Type> {
	const response = await apiGet.callApi();
	return response.getOrDefault([]);
}

export function getById(obj: Type, id: string): Nutrient.Type {
	const output = obj.find((e) => e.id === id);
	if (output) return output;
	return Nutrient.create();
}

export function getBySearchTerm(obj: Type, searchTerm: string): Type {
	const output = obj.filter((e) =>
		(e.name ?? '').toLowerCase().includes(searchTerm.toLowerCase())
	);
	if (output) return output;
	return [];
}

export function filterByIds(obj: Type, ids: string[]): Type {
	return obj.filter((e) => ids.includes(e.id));
}

export function filterByIdsExclusion(obj: Type, ids: string[]): Type {
	return obj.filter((e) => !ids.includes(e.id));
}

export function search(obj: Type, searchTerm: string): Type {
	return obj.filter((e: Nutrient.Type) =>
		e.name.toLowerCase().startsWith(searchTerm.toLowerCase())
	);
}
