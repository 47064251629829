import * as State from 'components/desktop/_general/Input/RoundedInput/RoundedInputState';
import { useHandler } from 'hooks/useHandler';

export default function useRoundedInputState() {
	const [state, rf] = useHandler<State.Type>(State.create());

	return {
		state,
		handlers: {
			onBlur: rf.createReducer(State.onBlur),
			onFocus: rf.createReducer(State.onFocus),
			setIsActive: rf.createReducer(State.setIsActive),
			setValue: rf.createReducer(State.setValue),
		},
	};
}
