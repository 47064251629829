import { NutriScore } from 'api/baseIngredient/GetBaseIngredientIdV1';
import * as apiPost from 'api/baseIngredient/PostBaseIngredientV1';
import * as apiPut from 'api/baseIngredient/PutBaseIngredientV1';
import { Characteristic } from 'enums/characteristic';
import { MEDIACOLLECTION } from 'enums/mediaCollection';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { uploadMedia } from 'functions/uploadMedia';
import * as AllergenList from 'types/BaseIngredient/Detail/AllergenList';
import * as BaseIngredient from 'types/BaseIngredient/Detail/BaseIngredient';
import * as Co2Label from 'types/BaseIngredient/Detail/Co2Label';
import * as Co2List from 'types/BaseIngredient/Detail/Co2List';
import * as Image from 'types/BaseIngredient/Detail/Image';
import * as IngredientList from 'types/BaseIngredient/Detail/IngredientList';
import * as NutrientValueList from 'types/BaseIngredient/Detail/NutrientValueList';
import * as UnitWeightList from 'types/BaseIngredient/Detail/UnitWeightList';
import * as CategoryList from 'types/_general/Store/CategoryList';
import * as TagList from 'types/_general/Store/TagList';
import * as Translation from 'types/_general/Translation';

const defaultCultureCode = 'de-CH';

export type Type = {
	id: string | null;
	allergenIds: string[];
	calories: number;
	carbonDioxideLabel: Co2Label.Type | null;
	carbonDioxideValue: number;
	categorieIds: string[];
	characteristic: Characteristic | null;
	co2Footprints: Co2List.Type;
	density: number;
	hasNutriScore: boolean | null;
	nutriScore: NutriScore | null;
	image: Image.Type | null;
	ingredients: IngredientList.Type;
	name: Translation.Type<string>;
	nutriScoreCategory: NutriScoreCategory | null;
	nutriScoreCategoryIsSetByUser: boolean;
	nutrientValues: NutrientValueList.Type;
	scalingFactor: number;
	seasonIds: string[];
	selectedCultureCode: string;
	tagIds: string[];
	unitWeights: UnitWeightList.Type;
};

export function create(): Type {
	return {
		id: null,
		allergenIds: [],
		calories: 0,
		carbonDioxideLabel: null,
		carbonDioxideValue: 0,
		categorieIds: [],
		characteristic: null,
		co2Footprints: [],
		density: 1,
		hasNutriScore: null,
		image: null,
		ingredients: [],
		name: Translation.create(),
		nutriScoreCategory: null,
		nutriScoreCategoryIsSetByUser: false,
		nutrientValues: [],
		scalingFactor: 1,
		nutriScore: null,
		seasonIds: [],
		selectedCultureCode: defaultCultureCode,
		tagIds: [],
		unitWeights: [],
	};
}

export function setName(state: Type, input: { cultureCode: string; value: string }): Type {
	return { ...state, name: Translation.change(state.name, input.cultureCode, input.value) };
}

export function setDensity(state: Type, value: number): Type {
	return { ...state, density: value };
}

export function setCharacteristic(state: Type, value: Characteristic): Type {
	return { ...state, characteristic: value };
}

export function setScalingFactor(state: Type, value: number): Type {
	return { ...state, scalingFactor: value };
}

export function setCalories(state: Type, value: number): Type {
	return { ...state, calories: value };
}

export function setTagIds(state: Type, ids: string[]): Type {
	return { ...state, tagIds: ids };
}

export function setSeasonIds(state: Type, ids: string[]): Type {
	return { ...state, seasonIds: ids };
}

export function setAllergenIds(state: Type, ids: string[]): Type {
	return { ...state, allergenIds: ids };
}

export function setNutrientValues(state: Type, nutrientValues: NutrientValueList.Type): Type {
	return { ...state, nutrientValues: nutrientValues };
}

export function setNutriScoreCategoryIsSetByUser(state: Type, isLocked: boolean): Type {
	if (isLocked) {
		return {
			...state,
			nutriScoreCategoryIsSetByUser: false,
			nutriScoreCategory: null,
			hasNutriScore: false,
		};
	}
	return { ...state, nutriScoreCategoryIsSetByUser: true };
}

export function setHasNutriScore(state: Type, hasNutriScore: boolean): Type {
	return { ...state, hasNutriScore: hasNutriScore };
}

export function setNutriScoreCategory(state: Type, category: NutriScoreCategory): Type {
	return { ...state, nutriScoreCategory: category };
}

export function setSelectedCultureCode(state: Type, cultureCode: string): Type {
	return { ...state, selectedCultureCode: cultureCode };
}

export function setCo2Footprints(state: Type, co2List: Co2List.Type): Type {
	return { ...state, co2Footprints: co2List };
}

export function setCategories(state: Type, input: CategoryList.Type): Type {
	return { ...state, categorieIds: input.map((e) => e.id) };
}

export function setUnitWeights(state: Type, input: UnitWeightList.Type): Type {
	return { ...state, unitWeights: input };
}

export function load(_state: Type, input: Type): Type {
	return input;
}

export async function save(state: Type, cultureCode: string): Promise<Type> {
	if (state.id) {
		const newState = await BaseIngredient.putToApi(state.id, mapToApi(state));

		return newState
			.map((x) => BaseIngredient.mapToState(BaseIngredient.mapFromApi(x), cultureCode))
			.getOrDefault(state);
	}
	const id = await BaseIngredient.postToApi(mapToApi(state));
	return {
		...state,
		id: id,
	};
}

export async function translateAndSetName(
	state: Type,
	input:
		| {
				fromCultureCode: string;
				text: string;
		  }
		| undefined
): Promise<Type> {
	if (!input) return state;
	if (!input.fromCultureCode) return state;
	if (input.fromCultureCode === state.selectedCultureCode) return state;
	const translatedText: string = await Translation.getTranslationFromApi({
		fromCultureCode: input.fromCultureCode,
		toCultureCode: state.selectedCultureCode,
		text: input.text,
	});

	return {
		...state,
		name: Translation.change(state.name, state.selectedCultureCode, translatedText),
	};
}

export async function uploadImage(state: Type, input: FormData | null | undefined): Promise<Type> {
	if (!input) return state;
	return await uploadMedia<Type>(state, input, MEDIACOLLECTION.BaseIngredient);
}

export async function getAndSetSuggestedTags(
	state: Type,
	cultureCode: string | undefined
): Promise<Type> {
	if (!cultureCode) return state;
	const suggestedTags = await TagList.getFromApiSuggested(state.name[cultureCode], cultureCode);
	const newTagIds: string[] = TagList.applySuggestedTags(suggestedTags, state.tagIds);
	return { ...state, tagIds: newTagIds };
}

function mapToApi(state: Type): apiPut.Request & apiPost.Request {
	return {
		...state,
		nutrientValues: NutrientValueList.mapToApi(state.nutrientValues),
		unitWeights: UnitWeightList.mapToApi(state.unitWeights),
		seasons: state.seasonIds,
		tags: state.tagIds,
		categories: state.categorieIds,
		allergens: AllergenList.mapFromUniqIdList(state.allergenIds),
		imageId: state.image?.id ?? null,
		nameTranslations: state.name,
		carbonDioxide: state.co2Footprints,
		characteristic: state.characteristic ?? Characteristic.SolidQuantifiable,
		hasNutriScore: state.hasNutriScore ?? false,
		nutriScoreCategoryIsSetByUser: state.nutriScoreCategoryIsSetByUser,
	};
}
