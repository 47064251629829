import { Recipe } from 'api/menu/GetMenuIdV1';
import * as apiGetMenuIdV1 from 'api/menu/GetMenuIdV1';
import * as apiPutMenuV1 from 'api/menu/PutMenuV1';
import * as apiPostCalculate from 'api/menuPlanner/PostMenuPlannerCalculateV1';
import * as apiGetRecipeIdV1 from 'api/recipe/GetRecipeIdV1';
import * as apiPostTranslation from 'api/translation/PostTranslationV1';
import { NutriScoreCategory } from 'enums/nutriScoreCategory';
import { mapToSaveMenuDto } from 'functions/mappers/Menu/mapToSaveMenuDto';
import {
	assocTranslationUnsafe,
	dissocTranslationUnsafe,
	getTranslation,
} from 'functions/objectExtensions';
import { Optional } from 'functions/promiseExtensions';
import { SystemStatus } from 'types/SystemStatus/SystemStatus';
import { TagLight } from 'types/Tag/TagLight';
import { TagMappingDto } from 'types/Tag/TagMappingDto';
import { PostTranslationDto } from 'types/Translation/PostTranslationDto';
import { LocalizedTranslation } from 'types/_general/LocalizedTranslation';

export enum MenuEditType {
	NEW = 'NEW',
	COPY = 'COPY',
	EDIT = 'EDIT',
}

export type Menu = {
	id: string | null;
	copiedFrom: string | null;
	nameTranslations: LocalizedTranslation<string>;
	recipes: Recipe[];
	shortDescriptionTranslations: LocalizedTranslation<string>;
	status: Status | null;
	systemStatus: SystemStatus;
	tags: TagMappingDto[];
	editType: MenuEditType;
};

export type Type = Menu;

export type Status = {
	id: string;
	color: string | null;
	order: number;
	name: string | null;
};

export type NutrientValue = {
	category: string;
	categoryId: string;
	isMacroNutrient: boolean;
	nutrient: string;
	nutrientId: string;
	value: number;
	total: number;
	unit: string;
	unitId: string;
	nutrientCategorySortOrder: number;
	source: string | null;
};

export type CarbonDioxideLabel = {
	color: string | null;
	label: string | null;
};

export const defaultMenu = (): Menu => {
	return {
		id: null,
		copiedFrom: null,
		nameTranslations: {} as LocalizedTranslation<string>,
		recipes: [],
		shortDescriptionTranslations: {} as LocalizedTranslation<string>,
		status: null,
		systemStatus: 'New',
		tags: [],
		editType: MenuEditType.NEW,
	};
};

export function create(): Menu {
	return defaultMenu();
}

export async function createAsync(menu: Type | null, params: any) {
	if (params && params.edit === 'true') {
		return menu || defaultMenu();
	}
	return defaultMenu();
}

export async function getFromApi(menu: Type | null, params: any): Promise<Type> {
	if (params && params.edit === 'true' && menu) {
		return menu;
	}
	const response = await apiGetMenuIdV1.callApi(params.id || params.menuId);

	return response
		.map((x) => {
			return {
				id: x.id,
				nameTranslations: x.nameTranslations,
				copiedFrom: null,
				recipes: x.recipes,
				shortDescriptionTranslations: x.shortDescriptionTranslations,
				status: x.status,
				systemStatus: x.systemStatus,
				tags: x.tags,
				editType: MenuEditType.EDIT,
			};
		})
		.orElse(() => create() as any);
}

function changeNameTranslationsWithUniqueNewName(
	menu: LocalizedTranslation<string>
): LocalizedTranslation<string> {
	return Object.keys(menu).reduce((newState: any, key) => {
		newState[key] = menu[key] + ' - Copy';
		return newState;
	}, {});
}
export async function getFromApiAsCopy(menu: Type | null, params: any): Promise<Type> {
	if (params && params.edit === 'true' && menu) {
		return menu;
	}
	const response = await getFromApi(menu, params);
	const updatedState = changeNameTranslationsWithUniqueNewName(response.nameTranslations);

	return {
		...response,
		nameTranslations: updatedState,
		id: null,
		editType: MenuEditType.COPY,
		copiedFrom: response.id,
	};
}

export function PropertyEquals<T, K>(f: (t: T) => K, value: K) {
	return (t: T) => {
		const actual = f(t);
		return actual === value;
	};
}

export const calculateNutrientsAsync = async (menu: Type) => {
	const request: apiPostCalculate.Request = {
		items: [
			menu.recipes.map((x) => {
				return { id: x.id, type: 'Recipe' };
			}),
		],
	};
	const result = (await apiPostCalculate.callApi(request)).map((x) => x.first());
	return result;
};

export type AddRecipePayload = {
	recipeId: string;
	menuId: string | null;
	type: MenuEditType;
	prepareTime: apiGetMenuIdV1.PrepareTime;
};

export type HandleValueChange = { key: keyof Type; value: string; selectedCultureCode: string };

export type NutriScoreType = {
	selectedRecipeId: string;
	input: boolean;
};

export type NutriScoreCategoryType = {
	selectedRecipeId: string;
	input: NutriScoreCategory;
};

export type MenuTranslationPayload = {
	name: string;
	reduxCultureCode: string;
	selectedCultureCode: string;
};

export const valueChangeReducer = (menu: Type, payload: HandleValueChange): Type =>
	payload.value === ''
		? dissocTranslationUnsafe(menu, payload.key, payload.selectedCultureCode)
		: assocTranslationUnsafe(menu, payload.key, payload.selectedCultureCode, payload.value);

export const setRecipesReducer = (menu: Type, recipes: Recipe[]): Type => ({
	...menu,
	recipes: recipes,
});
export const handleStatusReducer = (menu: Type, status: Status) => ({ ...menu, status });
export const handleSetSystemStatusReducer = (menu: Type, systemStatus: SystemStatus) => ({
	...menu,
	systemStatus,
});

export const handleSetTagsReducer = (menu: Type, tags: TagLight[]) => ({
	...menu,
	tags: tags.map((x) => ({ ...x, source: null })),
});

export const setNutriScoreReducer = (menu: Type, payload: NutriScoreType) => ({
	...menu,
	recipes: menu.recipes.mapIf(
		PropertyEquals((x) => x.id, payload.selectedRecipeId),
		(x) => {
			return { ...x, hasNutriScore: payload.input };
		}
	),
});

export const handleSetNutriScoreCategoryReducer = (
	menu: Type,
	payload: NutriScoreCategoryType
) => ({
	...menu,
	recipes: menu.recipes.mapIf(
		PropertyEquals((x) => x.id, payload.selectedRecipeId),
		(x) => {
			return { ...x, nutriScoreCategory: payload.input };
		}
	),
});

const translatePayload = async (text: string, payload: MenuTranslationPayload) => {
	const translationDto = {
		fromCultureCode: payload.reduxCultureCode,
		toCultureCode: payload.selectedCultureCode,
		text: text,
	} as PostTranslationDto;

	return await apiPostTranslation.callApi(translationDto);
};

export const handleMenuTranslateReducer = async (menu: Type, payload: MenuTranslationPayload) => {
	const result = await getTranslation(menu, payload.name, payload.reduxCultureCode).mapAsync(
		async (t) => {
			const response = await translatePayload(t, payload);
			return Optional.Just(
				assocTranslationUnsafe(
					menu,
					payload.name,
					payload.selectedCultureCode,
					response.getOrDefault('')
				)
			);
		}
	);

	return result.getOrDefault(menu);
};

const mergePrepareTimeWithApiResponse = (
	recipes: Recipe[],
	recipeFromApi: apiGetRecipeIdV1.ResponseData,
	payload: AddRecipePayload
) => {
	return [
		// remove the recipe if it was already present
		...recipes.filter((y) => y.id !== payload.recipeId),
		// and add it to the end of the list
		{
			...recipeFromApi,
			prepareTime: payload.prepareTime,
			price:
				recipeFromApi.price && recipeFromApi.persons && recipeFromApi.persons > 0
					? recipeFromApi.price / recipeFromApi.persons
					: null,
		},
	];
};
export const handleAddMenuRecipeReducer = async (menu: Type, payload: AddRecipePayload) => {
	const recipe = await apiGetRecipeIdV1.callApi(payload.recipeId);

	const menuOrNewMenu = await Optional.Maybe(menu).orElseAsync(async () => {
		switch (payload.type) {
			case MenuEditType.COPY:
				return await getFromApiAsCopy(null, { id: payload.menuId });
			case MenuEditType.NEW:
				return await create();
			default:
				return await getFromApi(null, { id: payload.menuId });
		}
	});

	const newRecipes = recipe
		.map((x) => mergePrepareTimeWithApiResponse(menuOrNewMenu.recipes, x, payload))
		.getOrDefault(menuOrNewMenu.recipes);

	return { ...menuOrNewMenu, recipes: newRecipes };
};
export const putMenuReducer = async (menu: Type) => {
	const response = await apiPutMenuV1.callApi(menu.id as string, mapToSaveMenuDto(menu));
	return response
		.map((x) => {
			return {
				id: x.id,
				nameTranslations: x.nameTranslations,
				recipes: x.recipes,
				shortDescriptionTranslations: x.shortDescriptionTranslations,
				status: x.status,
				systemStatus: x.systemStatus,
				tags: x.tags,
				editType: MenuEditType.EDIT,
			} as Type;
		})
		.getOrDefault(menu);
};
